import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { tsXLXS } from 'ts-xlsx-export';
import moment from 'moment-timezone';
import { BillingClaimsView, ProcedureAmountsQuery } from '@graphql/billing';
import { BillingClaimView } from '@bluefox/models/Billing';
import { Modal, Button, Progress, Icon } from 'semantic-ui-react';
import { humanizeText } from '@bluefox/lib/humanize';
import { DateFormats } from '@bluefox/models/Dates';
import {
  formatDatetimeToMMDDYYY,
  formatDateToMMhDDhYYYY,
} from '@bluefox/lib/formatters';
interface BillingClaimViewData {
  billingClaims: BillingClaimView[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface Cpt {
  cpt: string;
}

interface ProcedureAmountsData {
  cptCodes: Cpt[];
}

interface BillingExcelExportModalProps {
  criteria?: any;
}

const BillingExcelExportModal = ({
  criteria,
}: BillingExcelExportModalProps) => {
  const [open, setOpen] = useState(false);
  const [excelData, setExcelData] = useState<any[] | undefined>([]);

  const [
    getClaims,
    {
      data: viewData,
      loading: viewLoading,
      error: viewError,
      refetch: viewRefetch,
    },
  ] = useLazyQuery<BillingClaimViewData>(BillingClaimsView);

  const [getProcedureAmounts, { data: procedureAmountsData }] =
    useLazyQuery<ProcedureAmountsData>(ProcedureAmountsQuery);

  const adminCptCodes = procedureAmountsData?.cptCodes.map((c) => c.cpt);

  const handleLoadXlsx = () => {
    if (!excelData) return;
    tsXLXS()
      .exportAsExcelFile(excelData)
      .saveAsExcelFile(
        `Canid-Billing-Claim-List-${moment().format(
          DateFormats.DATE_WITH_TIME_SECONDS
        )}`
      );
    setOpen(false);
  };

  useEffect(() => {
    if (!viewData) return;

    const claimsList = viewData.billingClaims.map((c) => {
      let cptCodes = c.vaccinationsCptCodes
        ? c.vaccinationsCptCodes.map((cpt) => cpt.cptCode)
        : ['-'];

      const adminTotalAmount = c.vaccinationsCptCodes
        ? c.vaccinationsCptCodes
            .filter((cpt) => adminCptCodes?.includes(cpt.cptCode))
            .map((cpt) => cpt.paidAmount ?? 0)
            .reduce((accum, curr) => (accum += curr), 0)
        : '-';

      const InventoryTotalAmount = c.vaccinationsCptCodes
        ? c.vaccinationsCptCodes
            .filter((cpt) => !adminCptCodes?.includes(cpt.cptCode))
            .map((cpt) => cpt.paidAmount ?? 0)
            .reduce((accum, curr) => (accum += curr), 0)
        : '-';

      return {
        'Claim ID': c.claimId,
        Practice: c.practiceName ? c.practiceName : '-',
        Patient: c.fullName
          ? `${c.fullName} (${moment(c.birthdate).format(DateFormats.DATE)})`
          : '-',
        'Insurance Company': c.insuranceName
          ? `${c.insuranceName} - ${c.insuranceMemberId}`
          : '-',
        'Service Date': c.claim.givenAt
          ? formatDatetimeToMMDDYYY(c.claim.givenAt)
          : '-',
        'CPT Codes': cptCodes.join(', '),
        'Charged Amount': `$${c.totalClaimAmount}`,
        'Administration Amount': adminTotalAmount,
        'Inventory Amount': InventoryTotalAmount,
        'RHC Number': c.rhcClaimNumber ?? '-',
        'Check Number': c.checkNumber,
        'Inventory Used':
          c.inventoryVfcCount > 0 || c.inventoryPrivateCount > 0
            ? `VFC: ${
                c.inventoryVfcCount ? c.inventoryVfcCount : '0'
              } - Private ${
                c.inventoryPrivateCount ? c.inventoryPrivateCount : '0'
              }`
            : '-',
        'VFC Eligible': c.insuranceVfcEligible,
        Status: c.status,
        Tags: c.tags?.join(', '),
        'EOB Link': c.note?.url,
        'EOB Status': c.eobStatus
          ? humanizeText(c.eobStatus, { capitalize: 'first', delimiter: '_' })
          : '-',
        'Dismissed Reason': c.dismissedReason
          ? humanizeText(c.dismissedReason, {
              capitalize: 'first',
              delimiter: '_',
            })
          : '-',
        'Unclaimed Reason': c.unclaimedReason
          ? humanizeText(c.unclaimedReason, {
              capitalize: 'first',
              delimiter: '_',
            })
          : '-',
      };
    });

    setExcelData(claimsList);
  }, [viewData]);

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={
        <Button
          style={{ marginBottom: '0.5rem' }}
          inverted
          size="small"
          color="blue"
          floated="right"
          onClick={() => {
            getClaims({
              variables: {
                criteria,
              },
            });

            getProcedureAmounts({
              variables: {
                type: 'Administration',
              },
            });
          }}
        >
          Export EXCEL
        </Button>
      }
    >
      <Modal.Header>Export as Excel file?</Modal.Header>
      <Modal.Content>
        {viewLoading ? (
          <div>
            Loading Excel data...
            <Progress size="small" color="teal" indicating percent={100} />
          </div>
        ) : viewError ? (
          <div>
            <Icon name="warning sign" color="red" />
            There was an error trying to load Excel data
          </div>
        ) : (
          <div>
            Excel data is ready to be exported{' '}
            <Icon name="check" color="olive" />
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>No</Button>
        <Button
          primary
          content="Yes"
          onClick={() => handleLoadXlsx()}
          disabled={viewLoading || !excelData}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default BillingExcelExportModal;
