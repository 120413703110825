import MainLayout from '@ui/MainLayout';
import OrganizationAndPracticeSelector, {
  SearchValuesProps as OrgAndOractSearchValuesProps,
} from '@bluefox/ui/OrganizationAndPracticeSelector';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Checkbox, Container } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import BillingReport, {
  BillingReportHandle,
} from '@ui/NewBilling/BillingReport';
import PreviewInvoiceModal, {
  FormValues,
  addDayToDateStringYYYhMMhDD,
} from '@ui/NewBilling/PreviewInvoiceModal';
import ExtraItems, { ExtraItemsHandle } from '@ui/NewBilling/ExtraItems';
import { useMutation } from '@apollo/client';
import {
  BillingPreviewPdf,
  ConfirmInvoice,
  DiscardInvoiceMutation as DiscardDraftInvoice,
} from '@graphql/billing';
import {
  BillingReportPageProps,
  InvoiceAndBillingReportPdfProps,
  InvoicePageProps,
} from '@bluefox/pdf/InvoiceAndBillingReportPdf';
import logoImg from '@assets/canid-invoice-logo.png';
import {
  formatDateToYYYYhMMhDD,
  reformatYYYYhMMhDDStringtoMMsDDsYYYY,
} from '@bluefox/lib/formatters';
import {
  ConfirmationModalReturn,
  useConfirmationModalAwait,
} from '@bluefox/ui/ConfirmationModal';
import { InvoicingStrategy } from '@bluefox/models/Organization';

const NewBillingScreen = () => {
  const extraItemsRef = useRef<ExtraItemsHandle>(null);
  const billingReportRef = useRef<BillingReportHandle>(null);
  const [orgAndPractSearchValues, orgAndPractSetSearchValues] = useState<
    Partial<OrgAndOractSearchValuesProps>
  >({});
  const [previewInvoiceFormValues, setPreviewInvoiceFormValues] =
    useState<FormValues>({});
  const [openPreviewInvoiceModal, setOpenPreviewInvoiceModal] = useState(false);
  const [invoicePdf, setInvoicePdf] =
    useState<InvoiceAndBillingReportPdfProps>();
  const [someReviewedClaim, setSomeReviewedClaim] = useState(false);
  const [showPdfResponse, setShowPdfResponse] = useState(false);
  const [pdfResponse, setPdfResponse] =
    useState<InvoiceAndBillingReportPdfProps>();
  const [disablePagination, setDisablePagination] = useState(false);

  const [getBillingPreviewPdf, { loading: getBillingPreviewPdfLoading }] =
    useMutation<{ UpsertInvoice: InvoiceAndBillingReportPdfProps }>(
      BillingPreviewPdf
    );

  const [confirmInvoice, { loading: confirmInvoiceLoading }] =
    useMutation(ConfirmInvoice);

  const [discardDraftInvoice, { loading: discardDraftInvoiceLoading }] =
    useMutation(DiscardDraftInvoice);

  const { render: renderConfirmationModal, open: openConfirmationModal } =
    useConfirmationModalAwait();

  const practices = useMemo(
    () =>
      orgAndPractSearchValues?.selectedPractices &&
      orgAndPractSearchValues.selectedPractices.length > 0
        ? orgAndPractSearchValues.selectedPractices
        : orgAndPractSearchValues?.selectedOrganization?.practices &&
          orgAndPractSearchValues.selectedOrganization.practices.length > 0
        ? orgAndPractSearchValues.selectedOrganization.practices
        : [],
    [
      orgAndPractSearchValues.selectedOrganization?.practices,
      orgAndPractSearchValues.selectedPractices,
    ]
  );
  const practiceIds = useMemo(() => practices?.map((p) => p.id), [practices]);

  const shouldPreventSendBillingReportCall = useMemo(() => {
    return (
      orgAndPractSearchValues.selectedOrganization?.settings?.invoicing
        ?.strategy === InvoicingStrategy.BY_PRACTICE &&
      (!orgAndPractSearchValues.selectedPractices ||
        orgAndPractSearchValues.selectedPractices.length < 1)
    );
  }, [orgAndPractSearchValues]);

  const handleShowPreviewInvoiceModal = useCallback(async () => {
    try {
      setInvoicePdf(undefined);
      const response = await getBillingPreviewPdf({
        variables: { data: { practicesIdList: practiceIds } },
      });
      /**
       * Due to logoImg is an asset and package library don't have access to
       * assets, it is needed to read the logo in Internal Tool and pass it
       * as a parameter to the modal which will show the PDF
       */
      const invoice = response.data?.UpsertInvoice.invoice;
      const billingReport = response.data?.UpsertInvoice.billingReport;
      const version = response.data?.UpsertInvoice.version;
      setInvoicePdf({
        version,
        invoice: {
          ...invoice,
          header: { ...invoice?.header, logoImg },
        } as InvoicePageProps,
        billingReport: {
          ...billingReport,
          footer: { ...billingReport?.footer, logoImg },
        } as BillingReportPageProps,
      });

      const discountText = invoice?.body?.discount
        ? ' (applied rebate: [discount])'
        : '';

      const links = practices
        .map(
          (practice) =>
            `- https://app.canid.io/${practice.handler}/billing/invoices`
        )
        .join(`\n`);
      const d = new Date();
      const emailBody = `Your invoice for the duration of [date] is due, for a total of [total]${discountText}.

A copy of this invoice can be accessed by this (these) link(s): 
${links} 

As a reminder, the billing details that shows payment status on all of your claims can now be found in your Canid portal.

Thank you for choosing us,

Canid`;
      setPreviewInvoiceFormValues({
        date: formatDateToYYYYhMMhDD(d),
        dueDate: addDayToDateStringYYYhMMhDD(
          formatDateToYYYYhMMhDD(d),
          orgAndPractSearchValues.selectedOrganization?.settings?.stripeSettings
            ?.daysUntilDue || 0
        ),
        hour: '21:00',
        emailBody: emailBody,
        emailList:
          orgAndPractSearchValues.selectedOrganization?.settings
            ?.invoiceReceiverEmails,
      });
      setOpenPreviewInvoiceModal(true);
    } catch (e) {
      toast({
        title: `Get PDF Preview error: ${e}`,
        type: 'error',
        time: 5000,
      });
    }
  }, [
    getBillingPreviewPdf,
    orgAndPractSearchValues.selectedOrganization?.settings
      ?.invoiceReceiverEmails,
    orgAndPractSearchValues.selectedOrganization?.settings?.stripeSettings
      ?.daysUntilDue,
    practiceIds,
    practices,
  ]);

  const handleSendInvoice = useCallback(
    async (values: FormValues) => {
      if (
        (await openConfirmationModal(
          `Are you sure you want to send the invoice?`,
          'Send Invoice'
        )) === ConfirmationModalReturn.NO
      ) {
        return;
      }

      try {
        await confirmInvoice({
          variables: {
            organizationId: orgAndPractSearchValues.selectedOrganization?.id,
            emails: values.emailList,
            date: values.date
              ? reformatYYYYhMMhDDStringtoMMsDDsYYYY(values.date)
              : undefined,
            dueDate: values.dueDate
              ? reformatYYYYhMMhDDStringtoMMsDDsYYYY(values.dueDate)
              : undefined,
            time: values.hour,
            emailBody: values.emailBody,
          },
        });
        extraItemsRef.current?.callRefetch();
        billingReportRef.current?.callRefetch();
        toast({
          title: `Invoice sent`,
          type: 'success',
          time: 5000,
        });
        setPreviewInvoiceFormValues({} as FormValues);
        setOpenPreviewInvoiceModal(false);
      } catch (error) {
        toast({
          title: `Send invoice failed.Error: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [
      confirmInvoice,
      openConfirmationModal,
      orgAndPractSearchValues.selectedOrganization?.id,
    ]
  );

  const handleDiscardDraftInvoice = useCallback(async () => {
    try {
      const response = await discardDraftInvoice({
        variables: {
          organizationId: orgAndPractSearchValues.selectedOrganization?.id,
        },
      });
      if (response.data?.discardDraftInvoice?.code !== 200) {
        toast({
          title: `Discard invoice failed. Error: ${response.data?.DiscardInvoice?.message}`,
          type: 'error',
          time: 5000,
        });
        return;
      }
      setPreviewInvoiceFormValues({} as FormValues);
      setOpenPreviewInvoiceModal(false);
      toast({
        title: `Invoice discarded`,
        type: 'success',
        time: 5000,
      });
    } catch (error) {
      toast({
        title: `Discard invoice failed.Error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  }, [discardDraftInvoice, orgAndPractSearchValues.selectedOrganization?.id]);

  const handleShowPdfResponse = useCallback(async () => {
    try {
      const response = await getBillingPreviewPdf({
        variables: { data: { practicesIdList: practiceIds } },
      });
      setPdfResponse(response.data?.UpsertInvoice);
      await handleDiscardDraftInvoice();
    } catch (error) {
      toast({
        title: `getBillingPreviewPdf failed.Error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  }, [getBillingPreviewPdf, handleDiscardDraftInvoice, practiceIds]);

  return (
    <MainLayout path={[{ text: 'Billing' }, { text: 'Invoice' }]}>
      <Container style={{ marginBottom: '3rem', paddingBottom: '2rem' }} fluid>
        <OrganizationAndPracticeSelector
          organizationSettings={true}
          multiplePracticesSelect={
            !(
              orgAndPractSearchValues.selectedOrganization?.settings?.invoicing
                ?.strategy === InvoicingStrategy.BY_PRACTICE
            )
          }
          searchValues={orgAndPractSearchValues}
          setSearchValues={orgAndPractSetSearchValues}
          style={{ paddingBottom: '2rem' }}
        />
        <ExtraItems
          practices={orgAndPractSearchValues?.practices}
          ref={extraItemsRef}
        />
        <BillingReport
          practiceIds={
            shouldPreventSendBillingReportCall
              ? undefined
              : orgAndPractSearchValues?.practicesIds
          }
          ref={billingReportRef}
          style={{ marginTop: '2rem' }}
          onSomeReviewedClaimChange={setSomeReviewedClaim}
          disablePagination={disablePagination}
        />
        <div
          style={{
            margin: '2rem 0',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            primary
            onClick={handleShowPreviewInvoiceModal}
            loading={getBillingPreviewPdfLoading || discardDraftInvoiceLoading}
            disabled={
              getBillingPreviewPdfLoading ||
              !practiceIds ||
              practiceIds.length === 0 ||
              !someReviewedClaim
            }
          >
            Preview Invoice
          </Button>
        </div>
        <div
          style={{
            margin: '2rem 0',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <PreviewInvoiceModal
            loading={confirmInvoiceLoading}
            open={openPreviewInvoiceModal}
            formValues={previewInvoiceFormValues}
            setFormValues={setPreviewInvoiceFormValues}
            onClose={async () => {
              await handleDiscardDraftInvoice();
            }}
            invoicePdf={invoicePdf}
            onSubmit={async (values) => {
              await handleSendInvoice(values);
            }}
            daysUntilDue={
              orgAndPractSearchValues.selectedOrganization?.settings
                ?.stripeSettings?.daysUntilDue
            }
          />
        </div>
        {renderConfirmationModal()}
        <Checkbox
          label="Show PDF Response"
          checked={showPdfResponse}
          onChange={(_, { checked }) => {
            setShowPdfResponse(!!checked);
          }}
        />
        {showPdfResponse && (
          <div style={{ margin: '2rem 0' }}>
            <Button
              loading={
                getBillingPreviewPdfLoading || discardDraftInvoiceLoading
              }
              disabled={
                getBillingPreviewPdfLoading ||
                !practiceIds ||
                practiceIds.length === 0 ||
                !someReviewedClaim
              }
              onClick={handleShowPdfResponse}
            >
              Get PDF Response
            </Button>
            <Button
              disabled={!!!pdfResponse}
              onClick={() => {
                navigator.clipboard.writeText(
                  JSON.stringify(pdfResponse, null, 2) || ''
                );
                toast({
                  title: `PDF response copied to the clipboard`,
                  type: 'success',
                  time: 3000,
                });
              }}
            >
              Copy to clipboard
            </Button>
            <Checkbox
              label="Disable pagination"
              checked={disablePagination}
              onChange={(_, { checked }) => {
                setDisablePagination(!!checked);
              }}
            />
            {pdfResponse && (
              <pre style={{ backgroundColor: 'rgb(250,250,250)' }}>
                {JSON.stringify(pdfResponse, null, 2)}
              </pre>
            )}
          </div>
        )}
      </Container>
    </MainLayout>
  );
};

export default NewBillingScreen;
