import { gql } from '@apollo/client';

import { VaccineFullFragment } from '@bluefox/graphql/vaccines';
import { InventoryFullFragmentIT } from '@bluefox/graphql/inventory';

export const InventoryQuery = gql`
  query InventoryQuery(
    $practiceId: uuid!
    $likeQuery: String
    $status: inventory_statuses_enum_comparison_exp
    $typesFilter: jsonb_comparison_exp
    $doses: Int_comparison_exp
    $vfc: Boolean_comparison_exp
  ) {
    vaccines(
      where: {
        inventories: {
          practiceId: { _eq: $practiceId }
          status: $status
          doses: $doses
          vfc: $vfc
        }
        types: $typesFilter
        _or: [
          { name: { _ilike: $likeQuery } }
          { inventories: { lot: { _ilike: $likeQuery } } }
        ]
      }
      order_by: { name: asc }
    ) {
      ...VaccineFull
      inventory: inventories(
        where: {
          practiceId: { _eq: $practiceId }
          status: $status
          doses: $doses
          vfc: $vfc
        }
        order_by: { expiration: asc }
      ) {
        ...InventoryFull
      }
    }
  }

  ${InventoryFullFragmentIT}
  ${VaccineFullFragment}
`;

export const InventoryByIdQuery = gql`
  query InventoryByIdQuery($id: uuid!) {
    inventory: inventories_by_pk(id: $id) {
      ...InventoryFull
      vaccine {
        ...VaccineFull
      }
      practice {
        timezone
      }
    }
  }

  ${InventoryFullFragmentIT}
  ${VaccineFullFragment}
`;

export const InsertInventoryMutation = gql`
  mutation InsertInventoryMutation(
    $practiceId: uuid!
    $vaccineId: uuid!
    $lot: String!
    $expiration: date!
    $status: inventory_statuses_enum
    $doses: Int
    $vfc: Boolean
    $alternativeLotNumber: jsonb
  ) {
    inventory: insert_inventories_one(
      object: {
        practiceId: $practiceId
        vaccineId: $vaccineId
        lot: $lot
        expiration: $expiration
        status: $status
        doses: $doses
        vfc: $vfc
        alternativeLotNumber: $alternativeLotNumber
      }
    ) {
      id
    }
  }
`;

export const UpdateInventoryMutation = gql`
  mutation UpdateInventoryMutation(
    $id: uuid!
    $vaccineId: uuid!
    $lot: String!
    $expiration: date!
    $status: inventory_statuses_enum
    $doses: Int
    $vfc: Boolean
    $alternativeLotNumber: jsonb
  ) {
    inventory: update_inventories_by_pk(
      pk_columns: { id: $id }
      _set: {
        vaccineId: $vaccineId
        lot: $lot
        expiration: $expiration
        status: $status
        doses: $doses
        vfc: $vfc
        alternativeLotNumber: $alternativeLotNumber
      }
    ) {
      id
    }
  }
`;

export const QueryInventoriesUnderThreshold = gql`
  query QueryInventoriesUnderThreshold($criteria: inventories_bool_exp!) {
    inventories(where: { _and: [{ status: { _neq: retired } }, $criteria] }) {
      id
      expiration
      doses
      lot
      practice {
        name
      }
      vaccine {
        name
      }
    }
  }
`;

export const InventoryThresholdsQuery = gql`
  query InventoryThresholdsQuery(
    $practiceId: uuid!
    $routineTypes: [String!]
    $nonRoutineTypes: [String!]
  ) {
    routine: thresholds_by_practice_vaccine(
      where: { practiceId: { _eq: $practiceId }, type: { _in: $routineTypes } }
      order_by: [
        { type: asc }
        { vaccine: { name: asc } }
        { vaccine: { saleNdc: asc } }
      ]
    ) {
      thresholdId
      vaccine {
        id
        saleNdc
      }
      name
      type
      countVfcThreshold
      countNoVfcThreshold
      countNoVfcInventory
      countVfcInventory
      notes
    }
    nonRoutine: thresholds_by_practice_vaccine(
      where: {
        practiceId: { _eq: $practiceId }
        type: { _in: $nonRoutineTypes }
      }
      order_by: [
        { type: asc }
        { vaccine: { name: asc } }
        { vaccine: { saleNdc: asc } }
      ]
    ) {
      vaccine {
        id
        saleNdc
      }
      name
      type
      countVfcThreshold
      countNoVfcThreshold
      countNoVfcInventory
      countVfcInventory
      notes
    }
  }
`;

export const SaveThresholdMutation = gql`
  mutation SaveThresholdMutation(
    $practiceId: uuid!
    $vaccineId: uuid!
    $vfc: Int
    $noVfc: Int
    $notes: jsonb
  ) {
    insert_practice_thresholds_one(
      object: {
        practiceId: $practiceId
        vaccineId: $vaccineId
        vfc: $vfc
        noVfc: $noVfc
        notes: $notes
      }
      on_conflict: {
        constraint: practice_thresholds_practice_id_vaccine_id_key
        update_columns: [vfc, noVfc, notes]
      }
    ) {
      id
    }
  }
`;

export const BorrowingInventoryAdjustments = gql`
  query BorrowingInventoryAdjustments(
    $criteria: inventory_adjustments_bool_exp
  ) {
    adjustments: inventory_adjustments(
      where: $criteria
      order_by: { createdAt: desc }
    ) {
      id
      createdAt
    }
    aggregating: inventory_adjustments_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const BorrowingInventoryAdjustmentsDetails = gql`
  query BorrowingInventoryAdjustmentsDetails(
    $criteria: inventory_adjustment_details_bool_exp
  ) {
    details: inventory_adjustment_details(
      where: $criteria
      order_by: { inventory_adjustment: { vfc: asc }, type: asc }
    ) {
      id
      currentDoses
      newDoses
      type
      inventory {
        vaccine {
          name
          types
          saleNdc
        }
        lot
        vfc
      }
    }
  }
`;

export const InventoryVaccinesByTypesQuery = gql`
  query InventoryVaccinesByTypesQuery($practiceId: uuid!, $types: [String!]!) {
    inventories(
      where: {
        practiceId: { _eq: $practiceId }
        status: { _eq: received }
        vaccine: { types: { _has_keys_all: $types } }
      }
    ) {
      vaccineId
    }
  }
`;
