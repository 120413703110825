import { Button, Card, Modal } from 'semantic-ui-react';

import { useCallback, useMemo, useState } from 'react';
import {
  AdjustmentReason,
  InventoryAdjustment,
  InventoryAdjustmentDetail,
  InventoryAdjustmentDetailStatuses,
  InventoryAdjustmentStatus,
  InventoryAdjustmentTypes,
} from '@bluefox/models/InventoryAdjustment';
import { Session, useApplicationState } from '@bluefox/contexts';
import { InventoryStatus } from '@bluefox/models/Inventory';
import InventoryAdjustmentTable from './InventoryAdjustmentTable';

const buildType = (detail: InventoryAdjustmentDetail) => {
  return detail.selected
    ? InventoryAdjustmentTypes.CONFIRMATION
    : detail.operatorAmmend
    ? InventoryAdjustmentTypes.AMENDED
    : InventoryAdjustmentTypes.REJECTED;
};

interface InventoryAdjustmentTablesProps {
  adjustment: InventoryAdjustment;
  updateAdjustment: (
    details: InventoryAdjustmentDetail[],
    nowDate?: Date
  ) => void;
  statuses: InventoryAdjustmentStatus[];
  isApplied: boolean;
  updateAdjustmentDetails: (
    detailOrDetails: InventoryAdjustmentDetail | InventoryAdjustmentDetail[]
  ) => void;
  adjustmentReasons?: AdjustmentReason[];
}

const buildStatusLog = (
  detail: InventoryAdjustmentDetail,
  date: Date,
  session: Session | undefined
) => {
  const newStatusLog = {
    account: session?.account,
    type: buildType(detail),
    status: InventoryAdjustmentDetailStatuses.APPLIED,
    updatedAt: date,
  } as any;
  if (detail.operatorAmmend) {
    newStatusLog.operatorAmmend = detail.operatorAmmend;
  }
  if (detail.operatorRejection) {
    newStatusLog.operatorRejection = detail.operatorRejection;
  }

  return {
    ...detail,
    statusLog: [newStatusLog, ...(detail.statusLog ? detail.statusLog : [])],
  } as InventoryAdjustmentDetail;
};

const InventoryAdjustmentTables = ({
  adjustment,
  updateAdjustment,
  statuses,
  isApplied,
  updateAdjustmentDetails,
  adjustmentReasons,
}: InventoryAdjustmentTablesProps) => {
  const { session } = useApplicationState();

  //States

  const [
    openApplySelectedConfirmationModal,
    setOpenApplySelectedConfirmationModal,
  ] = useState(false);
  const [openSelectAllConfirmationModal, setOpenSelectAllConfirmationModal] =
    useState(false);
  const details = useMemo(() => adjustment.details || [], [adjustment.details]);

  const saveSelected = useCallback(
    (detailsParam?: InventoryAdjustmentDetail[]) => {
      const nowDate = new Date();
      const selectedDetails = (detailsParam ?? details)
        .filter(
          (d) =>
            d.status === InventoryAdjustmentDetailStatuses.PENDING &&
            (d.selected || d.operatorAmmend || d.operatorRejection)
        )
        .map(
          (d) =>
            ({
              ...d,
              ...buildStatusLog(d, nowDate, session),
              type: buildType(d),
              status: InventoryAdjustmentDetailStatuses.APPLIED,
            }) as InventoryAdjustmentDetail
        );
      updateAdjustment(selectedDetails, nowDate);
    },
    [details, session, updateAdjustment]
  );

  const selectAllDetailsAndSave = useCallback(() => {
    const selectedDetails = details
      .filter((d) => d.status === InventoryAdjustmentDetailStatuses.PENDING)
      .map((d) => {
        // Select all details which are not selected or edited or rejected
        if (!d.selected && !d.operatorAmmend && !d.operatorRejection) {
          return { ...d, selected: true } as InventoryAdjustmentDetail;
        }
        return d;
      });
    saveSelected(selectedDetails);
  }, [details, saveSelected]);

  const onDetailSelected = useCallback(
    (detail: InventoryAdjustmentDetail) => {
      const newDetail: InventoryAdjustmentDetail = {
        ...detail,
        selected: !detail.selected,
        operatorAmmend: undefined,
        operatorRejection: undefined,
      };
      updateAdjustmentDetails(newDetail);
    },
    [updateAdjustmentDetails]
  );

  const expiredInventoryDetails = useMemo(
    () =>
      details.filter(
        (detail) => detail.inventory?.status === InventoryStatus.expired
      ),
    [details]
  );
  const inventoryDetails = useMemo(
    () =>
      details.filter(
        (detail) => detail.inventory?.status !== InventoryStatus.expired
      ),
    [details]
  );

  //LOGIC

  if (!details?.length) return null;

  return (
    <>
      <Card fluid>
        {!isApplied && (
          <Card.Content
            style={{
              padding: '0.5em 1em 0.5em',
            }}
          >
            <Button
              floated="right"
              color="teal"
              content="Apply All"
              onClick={() => setOpenSelectAllConfirmationModal(true)}
            />
            <Button
              floated="right"
              color="teal"
              content="Apply Selection"
              onClick={() => setOpenApplySelectedConfirmationModal(true)}
            />
          </Card.Content>
        )}
        <Card.Content>
          {expiredInventoryDetails && expiredInventoryDetails.length > 0 && (
            <InventoryAdjustmentTable
              statuses={statuses}
              isApplied={isApplied}
              updateAdjustmentDetails={updateAdjustmentDetails}
              onDetailSelected={onDetailSelected}
              details={expiredInventoryDetails}
              title="Expired"
              adjustmentReasons={adjustmentReasons}
            />
          )}
          {inventoryDetails && inventoryDetails.length > 0 && (
            <InventoryAdjustmentTable
              statuses={statuses}
              isApplied={isApplied}
              updateAdjustmentDetails={updateAdjustmentDetails}
              onDetailSelected={onDetailSelected}
              details={inventoryDetails}
              title="Inventory"
              adjustmentReasons={adjustmentReasons}
            />
          )}
        </Card.Content>
      </Card>

      <Modal
        onClose={() => {
          setOpenSelectAllConfirmationModal(false);
        }}
        open={openSelectAllConfirmationModal}
        size="small"
        closeIcon
      >
        <Modal.Header>Apply all adjustments?</Modal.Header>
        <Modal.Actions>
          <Button
            type="button"
            content="Cancel"
            onClick={() => {
              setOpenSelectAllConfirmationModal(false);
            }}
          />
          <Button
            primary
            type="submit"
            content="Confirm"
            onClick={() => {
              setOpenSelectAllConfirmationModal(false);
              selectAllDetailsAndSave();
            }}
          />
        </Modal.Actions>
      </Modal>

      <Modal
        onClose={() => {
          setOpenApplySelectedConfirmationModal(false);
        }}
        open={openApplySelectedConfirmationModal}
        size="small"
        closeIcon
      >
        <Modal.Header>Apply Selected adjustments?</Modal.Header>
        <Modal.Actions>
          <Button
            type="button"
            content="Cancel"
            onClick={() => {
              setOpenApplySelectedConfirmationModal(false);
            }}
          />
          <Button
            primary
            type="submit"
            content="Confirm"
            onClick={() => {
              setOpenApplySelectedConfirmationModal(false);
              saveSelected();
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
export default InventoryAdjustmentTables;
