import { Button, Form, Modal } from 'semantic-ui-react';
import {
  balanceMovementReasonsOptions,
  balanceMovementTypesOptions,
  CreateBalanceInput,
  MovementReason,
  MovementReason as BalanceMovementReason,
  MovementType as BalanceMovementType,
} from '@bluefox/models/BalanceMovements';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { SaveBalanceMovement } from '@bluefox/graphql/balanceMovements';
import { debounce } from '@bluefox/lib/debounce';
import { hasTwoDecimals } from '@bluefox/lib/validations/numbers';

type BalanceMovementFormProps = {
  organizationId?: string;
  practiceId?: string;
  onBalanceMovementSaved?: () => void;
  disabled?: boolean;
};

const BalanceMovementForm = ({
  organizationId,
  practiceId,
  disabled,
}: BalanceMovementFormProps) => {
  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const resetBalanceMovement = () =>
    ({
      amount: 0,
      type: BalanceMovementType.CREDIT,
      reason: BalanceMovementReason.INVENTORY_AT_LAUNCH,
      organizationId: organizationId,
      practiceId: practiceId,
    }) as CreateBalanceInput;

  const [balanceMovement, setBalanceMovement] = useState(
    resetBalanceMovement()
  );
  const [addCreditModalOpened, setAddCreditModalOpened] = useState(false);
  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);
  const [successModalOpened, setSuccessModalOpened] = useState(false);

  const [saveBalanceMovement] = useMutation(SaveBalanceMovement);

  const disableSaveButton = () =>
    balanceMovement.amount === undefined ||
    balanceMovement.amount <= 0 ||
    !hasTwoDecimals(balanceMovement.amount) ||
    isNaN(balanceMovement.amount);

  const onModalClose = () => {
    setBalanceMovement(resetBalanceMovement());
    setAddCreditModalOpened(false);
  };

  const getAmountErrorMessage = (): string | undefined => {
    if (balanceMovement.amount === undefined) {
      return;
    }

    if (balanceMovement.amount && balanceMovement.amount < 0) {
      return 'Only Positive numbers allowed';
    }

    if (!hasTwoDecimals(balanceMovement.amount)) {
      return 'Only 2 decimals allowed';
    }
  };

  const onClickConfirmButton = async () => {
    await saveBalanceMovement({
      variables: {
        balanceMovement,
      },
      onCompleted: () => {
        setBalanceMovement(resetBalanceMovement());
        setConfirmationModalOpened(false);
        setAddCreditModalOpened(false);
        setSuccessModalOpened(true);
      },
    });
  };

  useEffect(() => {
    setBalanceMovement({
      ...balanceMovement,
      organizationId: organizationId || '',
      practiceId: practiceId || undefined,
    });
  }, [practiceId, organizationId]);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  return (
    <>
      <Modal
        closeIcon
        trigger={
          <Button disabled={disabled} color="green">
            Add Balance Movement
          </Button>
        }
        onClose={onModalClose}
        onOpen={() => setAddCreditModalOpened(true)}
        open={addCreditModalOpened}
      >
        <Modal.Content>
          <Form>
            <Form.Group widths={'equal'}>
              <Form.Select
                label="Movement reason:"
                options={balanceMovementReasonsOptions.filter(
                  (option) =>
                    option.value !== MovementReason.INVOICE_CREATION &&
                    option.value !== MovementReason.INVOICE_PAYMENT
                )}
                value={balanceMovement.reason}
                onChange={(event, { value }) => {
                  setBalanceMovement({
                    ...balanceMovement,
                    reason: value as BalanceMovementReason,
                  });
                }}
              />
              <Form.Select
                label="Movement type:"
                options={balanceMovementTypesOptions}
                value={balanceMovement.type}
                onChange={(event, { value }) => {
                  setBalanceMovement({
                    ...balanceMovement,
                    type: value as BalanceMovementType,
                  });
                }}
              />
              <Form.Input
                error={getAmountErrorMessage()}
                type="number"
                label="Movement Amount:"
                value={balanceMovement.amount}
                onChange={(event, { value }) => {
                  setBalanceMovement({
                    ...balanceMovement,
                    amount: parseFloat(value),
                  });
                }}
              />
            </Form.Group>
            <Form.Group widths={'equal'}>
              <Form.TextArea
                label="Add a comment"
                onChange={(event, { value }) => {
                  setBalanceMovement({
                    ...balanceMovement,
                    comment: value as string,
                  });
                }}
              />
            </Form.Group>
          </Form>

          <Modal.Actions>
            <Button
              style={{
                float: 'right',
                marginBottom: '1rem',
              }}
              content="Save"
              primary
              icon="save"
              disabled={disableSaveButton()}
              onClick={() => {
                setConfirmationModalOpened(true);
              }}
            />
            <Button
              type="button"
              content="Cancel"
              icon="cancel"
              style={{ float: 'right' }}
              onClick={onModalClose}
            />
          </Modal.Actions>
        </Modal.Content>
      </Modal>

      <Modal open={confirmationModalOpened}>
        <Modal.Content>
          You are about to create a <b>{balanceMovement.type}</b> for the amount
          of $<b>{balanceMovement.amount}</b>. Do you want to continue?
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              float: 'right',
              marginBottom: '1rem',
            }}
            content="Yes"
            primary
            onClick={onClickConfirmButton}
            icon="save"
          />
          <Button
            type="button"
            content="No"
            icon="cancel"
            style={{ float: 'right' }}
            onClick={() => setConfirmationModalOpened(false)}
          />
        </Modal.Actions>
      </Modal>

      <Modal open={successModalOpened}>
        <Modal.Content>
          Balance Movement added successfully. To visualize the changes, please
          click on the refresh button.
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              float: 'right',
              marginBottom: '1rem',
            }}
            content="Accept"
            primary
            onClick={() => setSuccessModalOpened(false)}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default BalanceMovementForm;
