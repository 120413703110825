import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation } from '@apollo/client';
import { AppendTicketDetail } from '@bluefox/graphql/tickets';
import { Ticket, TicketStatusEnum } from '@bluefox/models/Tickets';
import { ChatMessage } from './ChatMessage';
import Chat from './index';
import { Button, Modal, Popup } from 'semantic-ui-react';
import { useApplicationState, usePractice } from '@bluefox/contexts';
import { Source } from './types';
import { toast } from 'react-semantic-toasts';
import { useHistory, useParams } from 'react-router-dom';

type TicketChatProps = {
  ticket: Ticket;
  chatHeader: ReactNode;
  componentSource: Source;
};

const TicketChat = ({
  ticket,
  chatHeader,
  componentSource,
}: TicketChatProps) => {
  const { session, isEmbedded } = useApplicationState();
  const { handler } = usePractice();
  const history = useHistory();
  const { ticketType: ticketTypeParam, ticketId: ticketIdParam } = useParams<{
    ticketType: string;
    ticketId?: string;
  }>();
  const [open, setOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);

  const [appendDetailMutation] = useMutation(AppendTicketDetail);

  const handleOpen = useCallback(async () => {
    setOpen(true);
    if (ticketIdParam) {
      componentSource === Source.PP
        ? history.push(
            `/${handler}/tickets/${ticketTypeParam}/${ticketIdParam}`
          )
        : history.push(`/tickets/${ticketTypeParam}/${ticketIdParam}`);
    } else {
      componentSource === Source.PP
        ? history.push(`/${handler}/tickets/${ticket.type}/${ticket.id}`)
        : history.push(`/tickets/${ticket.type}/${ticket.id}`);
    }
  }, []);

  const handleClose = useCallback(async () => {
    try {
      if (ticket.detail.showNewMessageTo === componentSource) {
        await appendDetailMutation({
          variables: {
            id: ticket.id,
            detail: {
              showNewMessageTo: '',
            },
          },
        });
      }
    } catch (error) {
      toast({
        title: `Chat button will continue to display 'New Message' alert. Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    } finally {
      setOpen(false);
      if (ticketIdParam) {
        componentSource === Source.PP
          ? history.push(`/${handler}/tickets/${ticketTypeParam}`)
          : history.push(`/tickets`);
      } else {
        componentSource === Source.PP
          ? history.push(`/${handler}/tickets/${ticket.type}`)
          : history.push(`/tickets`);
      }
    }
  }, [ticket, componentSource]);

  const differentFromClosed = useMemo(
    () => ticket.status !== TicketStatusEnum.CLOSED,
    [ticket.status, TicketStatusEnum]
  );

  const openOnTicketId = useMemo(
    () => ticketIdParam === ticket.id,
    [ticketIdParam, ticket]
  );

  useEffect(() => {
    if (!ticket.detail.messages?.length) return;

    const _chatMessages = ticket.detail.messages.map((message) => {
      const position = message.sentFrom === componentSource ? 'right' : 'left';
      let title = message.author;
      if (componentSource === Source.PP) {
        title =
          message.sentFrom === Source.IT
            ? 'Canid Physician Help'
            : message.author;
      }
      return {
        message: {
          id: window.crypto.randomUUID(),
          date: message.date,
          title,
          message: message.message,
          position,
        },
        position,
      } as ChatMessage;
    });

    setChatMessages(_chatMessages);
  }, [ticket]);

  return (
    <Modal
      size="fullscreen"
      dimmer="blurring"
      closeOnDimmerClick={false}
      closeOnEscape={false}
      open={open || openOnTicketId}
      onClose={handleClose}
      onOpen={handleOpen}
      closeIcon
      trigger={
        <Popup
          content={differentFromClosed ? 'Messages' : 'View Message History'}
          trigger={
            <Button
              size="small"
              icon="mail outline"
              color={differentFromClosed ? 'teal' : 'grey'}
              style={{ margin: 0 }}
              onClick={handleOpen}
            />
          }
        />
      }
    >
      <Modal.Content>
        {chatHeader}
        <Chat
          isLargeModal={isEmbedded ? false : true}
          allowSend={differentFromClosed}
          messages={chatMessages}
          onSendNewMessage={async (newMessage) => {
            try {
              await appendDetailMutation({
                variables: {
                  id: ticket.id,
                  detail: {
                    messages: [
                      ...(ticket?.detail?.messages || []),
                      {
                        message: newMessage,
                        author: `${session?.account?.lastName}, ${session?.account?.firstName}`,
                        date: new Date(),
                        sentFrom: componentSource,
                      },
                    ],
                    showNewMessageTo:
                      componentSource === Source.PP ? Source.IT : Source.PP,
                  },
                },
              });
            } catch (error) {
              toast({
                title: `Error sending message. Callback error: ${error}`,
                type: 'error',
                time: 5000,
              });
            }
          }}
        ></Chat>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary type="button" onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default TicketChat;
