import { Source } from '@bluefox/ui/Chat/types';
import { Practice } from './Practice';
import { PracticeAccount } from './Account';

export enum TicketTypes {
  BUG_REPORT = 'Bug_report',
  WASTED_DOSES_REPORT = 'Wasted_doses_report',
  INVENTORY_RECEIPT_ISSUES = 'Inventory_receipt_issues',
  FEATURE_REQUEST = 'Feature_request',
  CALL_REQUEST = 'Call_request',
  INVENTORY_ADJUSTMENT = 'Inventory_adjustment',
  VACCINE_ORDER = 'Vaccine_order',
  BORROWING_REQUEST = 'Borrowing_request',
  BORROWING_ISSUE = 'Borrowing_issue',
  PACKING_SLIP = 'Packing_slip',
}

export enum TicketStatusEnum {
  OPEN = 'open',
  CLOSED = 'closed',
  CLOSED_AND_REMOVED = 'closed_and_removed',
  DONE = 'done',
  IN_PROGRESS = 'in_progress',
  DISMISSED = 'dismissed',
  REQUESTED = 'requested',
}

export const ticketTypeOptions = [
  {
    key: TicketTypes.BUG_REPORT,
    text: 'Bug Report',
    value: TicketTypes.BUG_REPORT,
  },
  {
    key: TicketTypes.CALL_REQUEST,
    text: 'Call Request',
    value: TicketTypes.CALL_REQUEST,
  },
  {
    key: TicketTypes.FEATURE_REQUEST,
    text: 'Feature Request',
    value: TicketTypes.FEATURE_REQUEST,
  },
  {
    key: TicketTypes.INVENTORY_ADJUSTMENT,
    text: 'Inventory Adjustment',
    value: TicketTypes.INVENTORY_ADJUSTMENT,
  },
  {
    key: TicketTypes.INVENTORY_RECEIPT_ISSUES,
    text: 'Inventory Receipt Issues',
    value: TicketTypes.INVENTORY_RECEIPT_ISSUES,
  },
  {
    key: TicketTypes.PACKING_SLIP,
    text: 'Packing Slip',
    value: TicketTypes.PACKING_SLIP,
  },
  {
    key: TicketTypes.VACCINE_ORDER,
    text: 'Vaccine Order',
    value: TicketTypes.VACCINE_ORDER,
  },
  {
    key: TicketTypes.WASTED_DOSES_REPORT,
    text: 'Wasted Doses Report',
    value: TicketTypes.WASTED_DOSES_REPORT,
  },
];

export const ticketStatusOptions = [
  { key: TicketStatusEnum.OPEN, text: 'Open', value: TicketStatusEnum.OPEN },
  {
    key: TicketStatusEnum.CLOSED,
    text: 'Closed',
    value: TicketStatusEnum.CLOSED,
  },
  { key: TicketStatusEnum.DONE, text: 'Done', value: TicketStatusEnum.DONE },
  {
    key: TicketStatusEnum.IN_PROGRESS,
    text: 'In Progress',
    value: TicketStatusEnum.IN_PROGRESS,
  },
  {
    key: TicketStatusEnum.DISMISSED,
    text: 'Dismissed',
    value: TicketStatusEnum.DISMISSED,
  },
  {
    key: TicketStatusEnum.REQUESTED,
    text: 'Requested',
    value: TicketStatusEnum.REQUESTED,
  },
];

export interface TicketsResponse {
  tickets: TicketResponse[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

export interface TicketQueryResponse {
  tickets: Ticket[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

export interface TicketFiles {
  id: string;
  fileId: string;
}
export interface Ticket {
  id?: string;
  status: TicketStatusEnum;
  type: TicketTypes;
  detail: TicketDetailType;
  ticketsFiles?: TicketFiles[];
  createdAt?: string;
  practiceId: string;
  practice?: Practice;
  practiceAccount?: PracticeAccount;
}

export interface TicketDetailType {
  fields: TicketDetailFieldType[];
  messages?: TicketMessage[];
  showNewMessageTo?: Source | '';
  reviewed?: boolean;
  closeTicketResolution?: string;
}

export interface TicketMessage {
  message: string;
  author: string;
  date: string;
  sentFrom: Source;
}

export interface TicketDetailFieldType {
  title: string;
  detail: string;
}

export interface OutputDetail {
  [key: string]: string | string[] | undefined;
  patient?: string;
  dateOfService?: string;
  issueType?: string;
  insurance?: string;
  correctedInsurance?: string;
  relevantVaccines?: string;
  issueDescription?: string;
}

export interface OutputTicket extends Ticket {
  details: OutputDetail;
}

export enum CallUrgency {
  ASAP = 'ASAP',
  DATE = 'DATE',
}

export interface TicketResponse {
  ticket: Ticket;
}
