import { InvoicingStrategy } from '@bluefox/models/Organization';
import { Card, Grid, Message } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import {
  GetOrganizationById,
  SubscribeToOrganizationById,
} from '@bluefox/graphql/organizations';
import { useCallback, useEffect, useMemo } from 'react';
import {
  GetPracticeById,
  SubscribeToPracticeById,
} from '@bluefox/graphql/practices';

export type EntityBalanceProps = {
  entityId: string;
  invoicingStrategy: InvoicingStrategy;
};

const EntityBalance = (props: EntityBalanceProps) => {
  const { entityId, invoicingStrategy } = props;

  const fields = ['balance', 'name'];

  const getQuery = useMemo(
    () =>
      invoicingStrategy === InvoicingStrategy.BY_ORGANIZATION
        ? GetOrganizationById
        : GetPracticeById,
    [invoicingStrategy]
  );

  const getSubscription = useMemo(
    () =>
      invoicingStrategy === InvoicingStrategy.BY_ORGANIZATION
        ? SubscribeToOrganizationById
        : SubscribeToPracticeById,
    [invoicingStrategy]
  );

  const { data, subscribeToMore, error } = useQuery<{
    response: { balance: number; name: string };
  }>(getQuery(fields), {
    variables: {
      id: entityId,
    },
  });

  const buildMetaMessage = useCallback((): string => {
    if (!data || !data.response) {
      return '';
    }
    const balance = Math.abs(data.response.balance);
    const messageHeader =
      data.response.balance >= 0 ? 'Available Balance:' : 'Amount to Paid:';
    return `${messageHeader} $${balance.toFixed(2)}`;
  }, [data]);

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: getSubscription(fields),
      variables: { id: entityId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return subscriptionData.data;
      },
    });
    return () => unsubscribe();
  }, [entityId, subscribeToMore]);

  if (error) {
    return <Message error>{error.message}</Message>;
  }

  return (
    <Grid.Column width="4">
      <Card header={data?.response.name} meta={buildMetaMessage()} />
    </Grid.Column>
  );
};

export default EntityBalance;
