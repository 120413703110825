import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  SolvePendingBorrows,
  GetClaimsVFCInconsistencies,
  SolveRejectedBorrows,
} from '@bluefox/graphql/billing';
import { GetSimpleInventoryForBorrowing } from '@bluefox/graphql/inventory';
import { InventoryStatus } from '@bluefox/models/Inventory';
import PracticeFilter from '@ui/Filters/PracticeFilter';
import {
  Button,
  Container,
  Dropdown,
  Header,
  Input,
  Label,
  Menu,
  MenuItem,
  Message,
  Table,
} from 'semantic-ui-react';
import { Inventory } from '@bluefox/models/models';
import MainLayout from '@ui/MainLayout';
import { toast } from 'react-semantic-toasts';
import { useApplicationState } from '@bluefox/contexts';
import { debounce } from '@bluefox/lib/debounce';
import {
  transformData,
  Inconsistencies,
  InconsistencyWithClaimIdsAndVaccineToReturn,
  getTotalQuantity,
  Change,
  buildOptions,
  handleAlternative,
  handleAlternativeQuantity,
} from '@bluefox/ui/Borrowing/borrowingFunctions';
import { borrowingStrings } from '@bluefox/ui/Borrowing/borrowingFunctions';
import {
  AdjustmentReasons,
  InventoryAdjustmentDetailStatuses,
  InventoryAdjustmentTypes,
} from '@bluefox/models/InventoryAdjustment';
import { VFCInconsistencyStatuses } from '@bluefox/models/VFCInconsistency';

const SolvePendingBorrowsScreen = () => {
  const { session } = useApplicationState();
  const [searchPractice, setSearchPractice] = useState<string>('all');
  const [inconsistencies, setInconsistencies] = useState<
    InconsistencyWithClaimIdsAndVaccineToReturn[]
  >([]);
  const [inventory, setInventory] = useState<Inventory[]>([]);

  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const { refetch: refetchGetClaimsVFCInconsistencies } =
    useQuery<Inconsistencies>(GetClaimsVFCInconsistencies(false, false), {
      variables: {
        criteria: {
          practiceId: { _eq: searchPractice },
          status: { _eq: 'pending' },
          type: { _eq: 'borrow' },
          readyToBeSwapped: { _eq: true },
        },
      },
      skip: searchPractice === 'all',
      onCompleted(data) {
        setInconsistencies(transformData(data.vfcInconsistencies));
        getInventories({
          variables: {
            criteria: {
              practiceId: { _eq: searchPractice },
              status: {
                _eq: InventoryStatus.received,
              },
            },
          },
        });
      },
    });

  const [getInventories] = useLazyQuery(GetSimpleInventoryForBorrowing, {
    onCompleted(data) {
      setInventory(
        data.inventories.map(
          ({ __typename, ...i }: { __typename: string }) => i // Typename is left out
        )
      );
    },
  });

  const [
    updateBorrowPendingVfcInconsistencies,
    { loading: updatePendingBorrowsLoading },
  ] = useMutation(SolvePendingBorrows);

  const [SolveRejected, { loading: rejectedLoading }] =
    useMutation(SolveRejectedBorrows);

  const loading = useMemo(
    () => updatePendingBorrowsLoading || rejectedLoading,
    [updatePendingBorrowsLoading, rejectedLoading]
  );

  function handleRejectedQuantity(
    inconsistency: InconsistencyWithClaimIdsAndVaccineToReturn,
    quantity: number
  ) {
    const confirmedQuantity = getTotalQuantity(inconsistency.vaccineToReturn);
    setInconsistencies((prevValue) =>
      prevValue.map((inconsist) => {
        if (inconsist.inventoryId === inconsistency.inventoryId) {
          return {
            ...inconsistency,
            rejected:
              quantity <=
              inconsistency.vfcInconsistentClaims.length - confirmedQuantity
                ? quantity
                : inconsistency.vfcInconsistentClaims.length -
                  confirmedQuantity,
          };
        }
        return inconsist;
      })
    );
  }

  async function prepareQueryObjects(
    inconsistencies: InconsistencyWithClaimIdsAndVaccineToReturn[]
  ) {
    let solvedRejectedExecuted = false; // to know if there is any rejected that was executed so it removes them from pertinent arrays

    function extractAndRemoveLastNVfcInconsistentClaims(
      data: InconsistencyWithClaimIdsAndVaccineToReturn
    ) {
      // Extract ids of vfcInconsistentClaims items
      const vfcInconsistentClaimIds = data.vfcInconsistentClaims.map(
        (item) => item.id
      );

      // Get the last n items from the array
      if (data.rejected === 0) return data;
      const rejectedTimes = data.rejected;
      const lastNIds = vfcInconsistentClaimIds.slice(-rejectedTimes);

      if (lastNIds.length > 0) {
        SolveRejected({
          variables: {
            idsToReject: lastNIds,
          },
        });
        solvedRejectedExecuted = true;
      }

      // Remove items from vfcInconsistentClaims that have id in lastNIds
      data.vfcInconsistentClaims = data.vfcInconsistentClaims.filter(
        (item) => !lastNIds.includes(item.id)
      );

      return data;
    }

    const inconsistenciesWithoutRejecteds = inconsistencies.map(
      (inconsistencia) =>
        extractAndRemoveLastNVfcInconsistentClaims(inconsistencia)
    );

    const inventoryAdjustmentDetailsData = inconsistenciesWithoutRejecteds.map(
      (i) => {
        let initialNumber = 0; // This numbers tracks what claims to update source/target
        const returnedInventoryAdjustmentDetailsData = i.vaccineToReturn
          .filter((i) => !!i.inventoryId && i.quantity > 0)
          .map((vtr) => {
            // vtr = vaccineToReturn
            const foundInventory = inventory.find(
              (inv) => inv.id === vtr.inventoryId
            )!;
            const obj = {
              currentDoses: foundInventory.doses,
              newDoses: foundInventory.doses - vtr.quantity,
              type: InventoryAdjustmentTypes.RETURNED,
              reason: AdjustmentReasons.BORROWING,
              status: InventoryAdjustmentDetailStatuses.APPLIED,
              comment: '',
              statusLog: [
                {
                  account: session?.account,
                  status: InventoryAdjustmentDetailStatuses.APPLIED,
                  from: borrowingStrings.STATUSLOG.FROM,
                  updatedAt: new Date(),
                },
              ],
              inventory: {
                data: {
                  id: vtr.inventoryId,
                  practiceId: foundInventory.practiceId,
                  vaccineId: foundInventory.vaccineId,
                  lot: foundInventory.lot,
                  expiration: foundInventory.expiration,
                  doses: foundInventory.doses - vtr.quantity,
                  vfc: foundInventory.vfc,
                },
                on_conflict: {
                  constraint: borrowingStrings.CONSTRAINT.INVENTORIESPRIMARYKEY,
                  update_columns: [borrowingStrings.UPDATECOLUMNS.DOSES],
                },
              },
              sourceClaimVfcInconsistencies: {
                data: i.vfcInconsistentClaims
                  .slice(initialNumber, initialNumber + vtr.quantity)
                  .map((inconsistency) => ({
                    id: inconsistency.id,
                    claimId: inconsistency.claimId,
                    status: VFCInconsistencyStatuses.SOLVED,
                    inventoryId: inconsistency.inventoryId,
                    vaccinationId: inconsistency.vaccinationId,
                    practiceId: inconsistency.practiceId,
                    claimUpdatesId: inconsistency.claimUpdatesId,
                  })),
                on_conflict: {
                  constraint:
                    borrowingStrings.CONSTRAINT.CLAIMVFCINCONSISTENCIESIDKEY,
                  update_columns: [
                    borrowingStrings.UPDATECOLUMNS.SOURCEADJUSTMENTDETAIL,
                    borrowingStrings.UPDATECOLUMNS.STATUS,
                  ],
                },
              },
              targetClaimVfcInconsistencies: {
                data: [],
                on_conflict: {
                  constraint:
                    borrowingStrings.CONSTRAINT.CLAIMVFCINCONSISTENCIESIDKEY,
                  update_columns: [
                    borrowingStrings.UPDATECOLUMNS.TARGETADJUSTMENTDETAIL,
                    borrowingStrings.UPDATECOLUMNS.STATUS,
                  ],
                },
              },
            };
            initialNumber += vtr.quantity;
            return obj;
          });

        initialNumber = 0; // This numbers tracks what claims to update source/target
        const recoveredInventoryAdjustmentDetailsData = i.vaccineToReturn
          .filter((i) => !!i.inventoryId && i.quantity > 0)
          .map((vtr) => {
            const {
              id: originalInventoryId,
              vaccine: _,
              ...originalInventory
            } = inventory.find((inv) => inv.id === vtr.inventoryId)!;
            const {
              id: foundInventoryId,
              vaccine: __,
              ...foundInventory
            } = inventory.find(
              (inv) =>
                inv.vaccineId === originalInventory.vaccineId &&
                inv.lot === originalInventory.lot &&
                inv.status === originalInventory.status &&
                inv.expiration === originalInventory.expiration &&
                inv.vfc !== originalInventory.vfc
            ) || {
              ...originalInventory,
              doses: 0,
              vfc: !originalInventory.vfc,
            };
            const obj = {
              currentDoses: foundInventory.doses,
              newDoses: foundInventory.doses + vtr.quantity,
              type: InventoryAdjustmentTypes.RECOVERED,
              reason: AdjustmentReasons.BORROWING,
              status: InventoryAdjustmentDetailStatuses.APPLIED,
              comment: '',
              statusLog: [
                {
                  account: session?.account,
                  status: InventoryAdjustmentDetailStatuses.APPLIED,
                  from: borrowingStrings.STATUSLOG.FROM,
                  updatedAt: new Date(),
                },
              ],
              inventory: {
                data: {
                  ...foundInventory,
                  ...(foundInventoryId ? { id: foundInventoryId } : {}),
                  doses: foundInventory.doses + vtr.quantity,
                },
                on_conflict: {
                  constraint: borrowingStrings.CONSTRAINT.INVENTORIESPRIMARYKEY,
                  update_columns: [borrowingStrings.UPDATECOLUMNS.DOSES],
                },
              },
              sourceClaimVfcInconsistencies: {
                data: [],
                on_conflict: {
                  constraint:
                    borrowingStrings.CONSTRAINT.CLAIMVFCINCONSISTENCIESIDKEY,
                  update_columns: [
                    borrowingStrings.UPDATECOLUMNS.SOURCEADJUSTMENTDETAIL,
                    borrowingStrings.UPDATECOLUMNS.STATUS,
                  ],
                },
              },
              targetClaimVfcInconsistencies: {
                data: i.vfcInconsistentClaims
                  .slice(initialNumber, initialNumber + vtr.quantity)
                  .map((inconsistency) => ({
                    id: inconsistency.id,
                    claimId: inconsistency.claimId,
                    status: VFCInconsistencyStatuses.SOLVED,
                    inventoryId: inconsistency.inventoryId,
                    vaccinationId: inconsistency.vaccinationId,
                    practiceId: inconsistency.practiceId,
                    claimUpdatesId: inconsistency.claimUpdatesId,
                  })),
                on_conflict: {
                  constraint:
                    borrowingStrings.CONSTRAINT.CLAIMVFCINCONSISTENCIESIDKEY,
                  update_columns: [
                    borrowingStrings.UPDATECOLUMNS.TARGETADJUSTMENTDETAIL,
                    borrowingStrings.UPDATECOLUMNS.STATUS,
                  ],
                },
              },
            };
            initialNumber += vtr.quantity;
            return obj;
          });

        const dataToReturn = [
          ...returnedInventoryAdjustmentDetailsData,
          ...recoveredInventoryAdjustmentDetailsData,
        ];
        if (dataToReturn.length > 0) return dataToReturn.flat();
        return null;
      }
    );

    const allInventoryAdjustmentDetails = inventoryAdjustmentDetailsData
      .filter((detail) => detail !== null)
      .flat();

    function mergeInventoryEntries(entries: any[]) {
      const mergedMap = new Map();

      for (const entry of entries) {
        const key = `${entry.inventory.data.id}-${entry.inventory.data.lot}-${entry.inventory.data.vfc}`;
        if (!mergedMap.has(key)) {
          mergedMap.set(key, {
            ...entry,
            doseChanges: [
              {
                type: entry.type,
                change: entry.newDoses - entry.currentDoses,
              },
            ],
          });
        } else {
          const existingEntry = mergedMap.get(key);

          // Merge claim inconsistencies
          existingEntry.sourceClaimVfcInconsistencies.data = [
            ...existingEntry.sourceClaimVfcInconsistencies.data,
            ...entry.sourceClaimVfcInconsistencies.data,
          ];
          existingEntry.targetClaimVfcInconsistencies.data = [
            ...existingEntry.targetClaimVfcInconsistencies.data,
            ...entry.targetClaimVfcInconsistencies.data,
          ];

          // Collect all dose changes
          existingEntry.doseChanges.push({
            type: entry.type,
            change: entry.newDoses - entry.currentDoses,
          });

          // Update status logs
          existingEntry.statusLog = [
            ...existingEntry.statusLog,
            ...entry.statusLog,
          ];
        }
      }

      // Process the merged entries
      return Array.from(mergedMap.values()).map((entry) => {
        const netDoseChange = entry.doseChanges.reduce(
          (sum: number, change: Change) => {
            return sum + change.change;
          },
          0
        );
        const finalDoses = entry.currentDoses + netDoseChange;

        return {
          ...entry,
          type: netDoseChange >= 0 ? 'recovered' : 'returned',
          currentDoses: entry.currentDoses,
          newDoses: finalDoses,
          inventory: {
            ...entry.inventory,
            data: {
              ...entry.inventory.data,
              doses: finalDoses,
            },
          },
        };
      });
    }

    const mergedInventoryAdjustmentDetails = mergeInventoryEntries(
      allInventoryAdjustmentDetails
    );

    const vfcInventoryAdjustmentDetails =
      mergedInventoryAdjustmentDetails.filter(
        (detail) => detail.inventory.data.vfc
      );
    const privateInventoryAdjustmentDetails =
      mergedInventoryAdjustmentDetails.filter(
        (detail) => !detail.inventory.data.vfc
      );

    const inventoryAdjustments = [
      {
        practiceId: searchPractice,
        status: InventoryAdjustmentDetailStatuses.APPLIED,
        statusLog: [
          {
            account: session?.account,
            status: InventoryAdjustmentDetailStatuses.APPLIED,
            from: borrowingStrings.STATUSLOG.FROM,
            updatedAt: new Date(),
          },
        ],
        vfc: true,
        inventoryAdjustmentDetails: {
          data: vfcInventoryAdjustmentDetails,
        },
      },
      {
        practiceId: searchPractice,
        status: InventoryAdjustmentDetailStatuses.APPLIED,
        statusLog: [
          {
            account: session?.account,
            status: InventoryAdjustmentDetailStatuses.APPLIED,
            from: borrowingStrings.STATUSLOG.FROM,
            updatedAt: new Date(),
          },
        ],
        vfc: false,
        inventoryAdjustmentDetails: {
          data: privateInventoryAdjustmentDetails,
        },
      },
    ];

    const mutationObjects = inventoryAdjustments.filter((adjustment) => {
      adjustment.inventoryAdjustmentDetails.data.map((caso) => {
        return delete caso.doseChanges;
      });
      return adjustment.inventoryAdjustmentDetails.data.length > 0;
    });

    if (mutationObjects.length > 0) {
      try {
        await updateBorrowPendingVfcInconsistencies({
          variables: {
            objects: mutationObjects,
          },
        });
        refetchGetClaimsVFCInconsistencies();
        toast({
          title: 'Borrows Solved Successfully',
          type: 'success',
          time: 1000,
        });
      } catch (e) {
        toast({
          title: `Failed Solving Borrow Pending Inconsistencies. ${e}`,
          type: 'error',
          time: 5000,
        });
      }
    } else if (solvedRejectedExecuted) {
      toast({
        title: 'Rejecteds Solved Successfully',
        type: 'success',
        time: 1000,
      });
      try {
        await refetchGetClaimsVFCInconsistencies();
      } catch (e) {
        toast({
          title: `Refetch error: ${e}`,
          type: 'error',
          time: 5000,
        });
      }
    } else {
      toast({
        title: `Nothing to solve`,
        type: 'error',
        time: 1000,
      });
    }
  }

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  return (
    <MainLayout
      path={[
        { text: 'Borrowing', to: '/billing/general/borrowing' },
        { text: 'Fix Pending Borrows' },
      ]}
    >
      <Container fluid>
        <Menu borderless style={{ display: 'flex', flexDirection: 'column' }}>
          <Menu.Menu
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Menu.Item>
              <PracticeFilter
                setPracticeSearch={setSearchPractice}
                practiceSearch={searchPractice}
                suspended="notSuspended"
                practices="select"
              />
            </Menu.Item>
            <div style={{ display: 'flex', gap: '10px' }}>
              <MenuItem>
                <Button
                  primary
                  onClick={() => {
                    debouncedRef.current?.cancel();
                    debouncedRef.current = debounce(() => {
                      prepareQueryObjects(inconsistencies);
                    }, 500);

                    debouncedRef.current();
                  }}
                  disabled={
                    !searchPractice || searchPractice === 'all' || loading
                  }
                >
                  Solve Borrows
                </Button>
              </MenuItem>
            </div>
          </Menu.Menu>
        </Menu>

        {inconsistencies.length > 0 ? (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Inventory Used</Table.HeaderCell>
                <Table.HeaderCell>Borrowed Quantity</Table.HeaderCell>
                <Table.HeaderCell>Alternatives</Table.HeaderCell>
                <Table.HeaderCell>
                  Confirmed Quantity to Return
                </Table.HeaderCell>
                <Table.HeaderCell>Rejected Quantity</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {inconsistencies.map(
                (
                  inconsistency: InconsistencyWithClaimIdsAndVaccineToReturn
                ) => {
                  return (
                    <Table.Row key={inconsistency.inventoryId}>
                      <Table.Cell>
                        <Header>
                          {inconsistency.inventory?.vaccine?.name}
                          <Label
                            content={
                              inconsistency.inventory.vfc ? 'VFC' : 'Private'
                            }
                            size="tiny"
                            color={
                              inconsistency.inventory.vfc ? 'orange' : 'teal'
                            }
                          />
                          <Header.Subheader>
                            {inconsistency.inventory.vaccine.saleNdc} |{' '}
                            {inconsistency.inventory.vaccine?.types?.map(
                              (t) => (
                                <Label key={t} color="black" size="mini">
                                  {t}
                                </Label>
                              )
                            )}
                          </Header.Subheader>
                        </Header>
                      </Table.Cell>

                      <Table.Cell>
                        {inconsistency.vfcInconsistentClaims.length}
                      </Table.Cell>

                      <Table.Cell>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                          }}
                        >
                          {inconsistency.vaccineToReturn.map(
                            (vToReturn, indx) => {
                              const options = buildOptions(
                                inconsistency,
                                indx,
                                inventory
                              );
                              if (!options.length) {
                                if (indx === 0) {
                                  return (
                                    <p key={`paragraph${indx}`}>
                                      No alternatives found.
                                    </p>
                                  );
                                } else {
                                  return undefined;
                                }
                              } else {
                                return (
                                  <Dropdown
                                    style={{ height: '60px' }}
                                    key={indx}
                                    selection
                                    fluid
                                    value={vToReturn.inventoryId}
                                    onChange={(_, { value }) => {
                                      handleAlternative(
                                        inconsistency.inventoryId,
                                        indx,
                                        value as string,
                                        inconsistencies,
                                        setInconsistencies
                                      );
                                    }}
                                    options={options}
                                    placeholder="Choose an option"
                                  />
                                );
                              }
                            }
                          )}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                          }}
                        >
                          {inconsistency.vaccineToReturn.map(
                            (vToReturn, indx) => {
                              if (vToReturn.inventoryId) {
                                return (
                                  <Input
                                    style={{ height: '60px' }}
                                    key={indx}
                                    placeholder="0"
                                    value={vToReturn.quantity || 0}
                                    onChange={(_, { value }) => {
                                      if (Number(value) >= 0) {
                                        handleAlternativeQuantity(
                                          inconsistency.inventoryId,
                                          indx,
                                          Math.min(
                                            Number(value),
                                            inconsistency.vfcInconsistentClaims
                                              .length -
                                              getTotalQuantity(
                                                inconsistency.vaccineToReturn
                                              ) -
                                              inconsistency.rejected +
                                              Number(value)
                                          ) <= 0
                                            ? 0
                                            : Math.min(
                                                Number(value),
                                                inconsistency
                                                  .vfcInconsistentClaims
                                                  .length -
                                                  getTotalQuantity(
                                                    inconsistency.vaccineToReturn
                                                  ) -
                                                  inconsistency.rejected +
                                                  Number(value)
                                              ),
                                          inventory,
                                          setInconsistencies
                                        );
                                      }
                                    }}
                                    disabled={!vToReturn.inventoryId}
                                  />
                                );
                              } else if (
                                buildOptions(inconsistency, indx, inventory)
                                  .length
                              ) {
                                return (
                                  <div
                                    key={`div${indx}`}
                                    style={{ height: '60px' }}
                                  ></div>
                                );
                              }
                              return undefined;
                            }
                          )}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                          }}
                        >
                          <Input
                            placeholder="0"
                            type="number"
                            max={inconsistency.vfcInconsistentClaims.length}
                            value={inconsistency.rejected || 0}
                            onChange={(_, { value }) => {
                              if (Number(value) >= 0) {
                                handleRejectedQuantity(
                                  inconsistency,
                                  Math.min(
                                    Number(value),
                                    inconsistency.vfcInconsistentClaims.length
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  );
                }
              )}
            </Table.Body>
          </Table>
        ) : (
          <Message>
            {searchPractice === 'all'
              ? 'Select a Practice to find Pending Borrows'
              : 'No Pending Borrows found for this Practice'}
          </Message>
        )}
      </Container>
    </MainLayout>
  );
};

export default SolvePendingBorrowsScreen;
