import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { toast } from 'react-semantic-toasts';
import { Button, Form } from 'semantic-ui-react';

import {
  InsertDeveloMappingMutation,
  UpdateDeveloMappingMutation,
} from '@bluefox/graphql/integrations/develo';
import { MappedItem } from '@bluefox/models/integrations/Develo';
import { PracticeAccountProfile } from '@bluefox/models/Account';
import ProviderPicker from '@bluefox/ui/ProviderPicker';

interface ProviderMappingFormProps {
  close: () => void;
  data: MappedItem | undefined;
  refetchMappings: () => void;
  mappedKeys: string[];
  practiceId: string | undefined;
  isEdition: boolean;
}

const ProviderMappingForm = ({
  close,
  data,
  refetchMappings,
  mappedKeys,
  practiceId,
  isEdition,
}: ProviderMappingFormProps) => {
  const [providerId, setProviderId] = useState<string | undefined>(
    data ? data.value.data : ''
  );
  const [selectedProviderProfile, setSelectedProviderProfile] =
    useState<PracticeAccountProfile>();
  const [develoKey, setDeveloKey] = useState<string>(data?.key ? data.key : '');
  const [value, setValue] = useState<{
    data?: string;
    name: string;
  }>({
    data: data?.value.data || '',
    name: data?.value.name || '',
  });

  const [saveProviderMapping] = useMutation(
    data?.id ? UpdateDeveloMappingMutation : InsertDeveloMappingMutation
  );

  const handleSubmit = () => {
    const key = develoKey.toLowerCase().trim();
    if (mappedKeys.includes(key) && !isEdition && data?.key !== key) {
      toast({
        title: 'Provider is already mapped.',
        type: 'error',
        time: 5000,
      });
      close();
      return;
    }

    saveProviderMapping({
      variables: {
        id: data?.id,
        practiceId: data?.practice?.id || practiceId,
        key: key,
        type: 'provider',
        value: value,
      },
    })
      .then((r) => {
        toast({
          title: 'Mapping saved successfully',
          type: 'success',
          time: 1000,
        });
        close();
        refetchMappings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  useEffect(() => {
    if (!data) return;

    setValue({
      data: providerId,
      name:
        data.value.name ||
        `${selectedProviderProfile?.providerFirstName} ${selectedProviderProfile?.providerLastName}`,
    });
  }, [data]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Input
            value={develoKey}
            onChange={(_, { value }) => setDeveloKey(value)}
            fluid
            label="Develo provider ID"
            placeholder="Develo provider ID"
            required
          />
        </Form.Field>
        <Form.Field>
          <label>Select Canid provider</label>
          <ProviderPicker
            defaultValue={data?.value.data}
            placeholder="Select Provider"
            practiceId={data?.practice?.id || practiceId}
            onChange={(providerId) => setProviderId(providerId)}
            onProviderSelected={(provider) => {
              setSelectedProviderProfile(provider.profile);
              setValue({
                data: provider.id,
                name: `${provider.account.firstName} ${provider.account.lastName}`,
              });
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={close} type="button">
          Cancel
        </Button>
        <Button primary type="submit" content="Save" icon="save" />
      </Form.Field>
    </Form>
  );
};

export default ProviderMappingForm;
