import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { AppendTicketDetail, GetTickets } from '@bluefox/graphql/tickets';
import { Ticket } from '@bluefox/models/Tickets';
import Chat, { ChatMessage } from '@bluefox/ui/Chat';
import {
  Button,
  Label,
  Modal,
  Popup,
  SemanticCOLORS,
  Table,
} from 'semantic-ui-react';
import { useApplicationState } from '@bluefox/contexts';
import { Source } from '../Chat/types';
import { toast } from 'react-semantic-toasts';
import { GetClaimsVFCInconsistencies } from '@bluefox/graphql/billing';

type BorrowingChatModalProps = {
  ticket: Ticket;
  chatHeader?: ChatHeader;
  chatSource: 'issue' | 'request';
  componentSource: Source;
};

type ChatHeader = {
  patient: string;
  dateOfBirth: string | Date;
  insuranceName: string;
  insuranceMemberId?: string | number;
  insuranceElegibilty?: boolean | undefined;
  dateOfService: string | Date;
  inventoryUsed?: boolean | undefined;
  ndc?: string;
  vaccine?: string;
  lot?: string;
};

const BorrowingChatModal = ({
  ticket,
  chatHeader,
  componentSource,
  chatSource,
}: BorrowingChatModalProps) => {
  const { session } = useApplicationState();
  const [open, setOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);

  const insurance = 'insurance';
  const inventory = 'inventory';

  const [appendDetailMutation] = useMutation(AppendTicketDetail, {
    refetchQueries: [GetTickets(componentSource === Source.PP)],
  });

  const handleOpen = useCallback(async () => {
    setOpen(true);
    if (ticket.detail.showNewMessageTo === componentSource) {
      try {
        await appendDetailMutation({
          variables: {
            id: ticket.id,
            detail: {
              showNewMessageTo: '',
            },
          },
        });
      } catch (error) {
        toast({
          title: `Chat button will continue to display 'New Message' alert. Callback error: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    }
  }, [open]);

  const elegibility = useCallback((field: 'insurance' | 'inventory') => {
    if (field === insurance && chatHeader?.insuranceElegibilty === undefined) {
      return {
        color: 'yellow',
        message: 'Eligibility not found',
      };
    } else if (
      field === insurance &&
      chatHeader?.insuranceElegibilty === true
    ) {
      return {
        color: 'orange',
        message: 'VFC Eligible',
      };
    } else if (field === inventory && chatHeader?.inventoryUsed === undefined) {
      return {
        color: 'yellow',
        message: 'Inventory not found',
      };
    } else if (field === inventory && chatHeader?.inventoryUsed === true) {
      return {
        color: 'orange',
        message: 'VFC Eligible',
      };
    } else {
      return {
        color: 'teal',
        message: 'Private',
      };
    }
  }, []);

  useEffect(() => {
    if (!ticket.detail.messages?.length) return;

    const _chatMessages = ticket.detail.messages.map((message) => {
      const position = message.sentFrom === componentSource ? 'right' : 'left';
      let title = message.author;
      if (componentSource === Source.PP) {
        title =
          message.sentFrom === Source.IT
            ? 'Canid Physician Help'
            : message.author;
      }
      return {
        message: {
          id: window.crypto.randomUUID(),
          date: message.date,
          title,
          message: message.message,
          position,
        },
        position,
      } as ChatMessage;
    });

    setChatMessages(_chatMessages);
  }, [ticket]);

  return (
    <Modal
      dimmer="blurring"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={handleOpen}
      trigger={
        <Popup
          content="Messages"
          trigger={
            <Button
              size="small"
              icon="mail outline"
              color={'teal'}
              style={{ marginLeft: '0.2rem' }}
              onClick={handleOpen}
            />
          }
        />
      }
    >
      <Modal.Content>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
                Patient (DoB)
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
                Insurance
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                Date Of Service
              </Table.HeaderCell>
              {chatSource === 'issue' && (
                <>
                  <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                    Inventory Used <hr /> NDC
                  </Table.HeaderCell>
                  <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                    Vaccine <hr /> Lot
                  </Table.HeaderCell>
                </>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row key={window.crypto.randomUUID()}>
              <Table.Cell textAlign="center">
                {`${chatHeader?.patient}`}
                <br />
                {chatSource === 'issue'
                  ? `(${chatHeader?.dateOfBirth})`
                  : `${chatHeader?.dateOfBirth}`}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {chatHeader?.insuranceName}{' '}
                {chatSource === 'issue' && (
                  <>
                    <Label
                      content={elegibility(insurance).message}
                      size="tiny"
                      color={elegibility(insurance).color as SemanticCOLORS}
                    />
                    <Label
                      basic
                      size="tiny"
                      content={`Member ID: ${chatHeader?.insuranceMemberId}`}
                      style={{ marginTop: '0.2rem' }}
                    />
                  </>
                )}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {chatHeader?.dateOfService}
              </Table.Cell>
              {chatSource === 'issue' && (
                <>
                  <Table.Cell textAlign="center">
                    {
                      <Label
                        content={elegibility(inventory).message}
                        size="tiny"
                        color={elegibility(inventory).color as SemanticCOLORS}
                        style={{ marginTop: '0.2rem' }}
                      />
                    }
                    <hr />
                    {chatHeader?.ndc}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {chatHeader?.vaccine}
                    <hr />
                    {chatHeader?.lot}
                  </Table.Cell>
                </>
              )}
            </Table.Row>
          </Table.Body>
        </Table>
        <Chat
          messages={chatMessages}
          onSendNewMessage={async (newMessage) => {
            try {
              await appendDetailMutation({
                variables: {
                  id: ticket.id,
                  detail: {
                    messages: [
                      ...(ticket?.detail?.messages || []),
                      {
                        message: newMessage,
                        author: `${session?.account?.lastName}, ${session?.account?.firstName}`,
                        date: new Date(),
                        sentFrom: componentSource,
                      },
                    ],
                    showNewMessageTo:
                      componentSource === Source.PP ? Source.IT : Source.PP,
                  },
                },
              });
            } catch (error) {
              toast({
                title: `Error sending message. Callback error: ${error}`,
                type: 'error',
                time: 5000,
              });
            }
          }}
        ></Chat>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary type="button" onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BorrowingChatModal;
