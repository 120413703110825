import { gql } from '@apollo/client';

export const GetBalanceMovements = gql`
  query GetBalanceMovements(
    $where: balance_movements_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [balance_movements_order_by!] = { date: desc, createdAt: desc }
  ) {
    balanceMovements: balance_movements(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      comment
      createdAt
      currentBalance
      date
      externalId
      externalInvoiceId
      organizationId
      type
      reason
      amountToPay
      organization {
        name
      }
      practice {
        id
        name
      }
      invoice {
        status
        amount
        id
        totalPrivateAdminAmount
        totalVfcAdminAmount
      }
      amount
    }
    totalBalanceMovements: balance_movements_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const SaveBalanceMovement = gql`
  mutation SaveBalanceMovement($balanceMovement: SaveBalanceMovementInput!) {
    SaveBalanceMovement(data: $balanceMovement) {
      movementId
    }
  }
`;
