import { useCallback, useMemo, useState } from 'react';
import SearchForm, { SearchValuesProps } from './SearchForm';

import { Card, Icon, Message } from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/client';
import {
  BillingInvoicesQuery,
  SaveInvoiceStatusMutation,
} from '@graphql/billing';
import { BillingInvoice } from '@bluefox/models/Billing';
import { InvoiceStatus } from '@bluefox/models/Invoice';
import {
  ConfirmationModalReturn,
  useConfirmationModalAwait,
} from '@bluefox/ui/ConfirmationModal';
import InvoiceListTable from './InvoiceListTable';
import styled from 'styled-components';
import InvoiceListPagination, {
  PaginationValuesProps,
} from './InvoiceListPagination';
import InvoicesExcelExportModal from '@ui/InvoicesExcelExportModal';
import { toast, ToastOptions } from 'react-semantic-toasts';
import { RegenerateInvoiceMutation } from '@bluefox/graphql/billing';
import InvoicePdfModal from '@bluefox/ui/Billing/InvoicePdfModal';
import InvoicePaymentDateModal from '@ui/Billing/InvoicePaymentDateModal';
import {
  BillingReportPageProps,
  InvoiceAndBillingReportPdfProps,
} from '@bluefox/pdf/InvoiceAndBillingReportPdf';
import { addDays, formatDateToMMhDDhYYYY } from '@bluefox/lib/formatters';
import { InvoicePageProps } from '@bluefox/models/pdf/InvoiceAndBillingReportPdf';
import logoImg from '@assets/canid-invoice-logo.png';

export const StyledPagination = styled.div({
  marginTop: '1rem',
  textAlign: 'center',
});
interface InvoicesData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  invoices: BillingInvoice[];
  allPractices: [
    {
      id: string;
      name: string;
    },
  ];
}

const pageLimit = 20;

const InvoiceList = () => {
  const [searchFormValues, setSearchFormValues] = useState<SearchValuesProps>(
    {} as SearchValuesProps
  );
  const [paginationValues, setPaginationValues] =
    useState<PaginationValuesProps>({ activePage: 1 });
  const { render: renderConfirmationModal, open: openConfirmationModal } =
    useConfirmationModalAwait();

  const [pdfModalBillingInvoice, setPdfModalBillingInvoice] =
    useState<InvoiceAndBillingReportPdfProps>();
  const [paymentDateModalBillingInvoice, setPaymentDateModalBillingInvoice] =
    useState<{
      billingInvoice: BillingInvoice;
      newInvoiceStatusOnConfirm?: InvoiceStatus;
    }>();

  const practicesIds = useMemo(
    () => searchFormValues.practicesIds,
    [searchFormValues.practicesIds]
  );

  const andCriteria = useMemo(() => {
    let criteria: Array<{ [key: string]: any }> = [
      {
        status: {
          _in: [
            InvoiceStatus.PENDING,
            InvoiceStatus.PROCESSING,
            InvoiceStatus.ERROR,
            InvoiceStatus.ERROR_STRIPE,
            InvoiceStatus.PAID,
            InvoiceStatus.DISCARDED,
          ],
        },
      },
    ];
    if (practicesIds) {
      criteria = [
        ...criteria,
        {
          claimCptCodes: {
            claim: {
              practiceId: { _in: practicesIds },
            },
          },
        },
      ];
    }
    if (searchFormValues?.status) {
      criteria = [...criteria, { status: { _eq: searchFormValues.status } }];
    }
    if (searchFormValues?.billingMethod) {
      criteria = [
        ...criteria,
        { billMethod: { _eq: searchFormValues.billingMethod } },
      ];
    }
    if (searchFormValues?.dateFrom || searchFormValues?.dateTo) {
      const date: Record<string, string> = {};

      if (searchFormValues?.dateFrom) {
        date['_gte'] = formatDateToMMhDDhYYYY(searchFormValues?.dateFrom);
      }
      if (searchFormValues?.dateTo) {
        date['_lt'] = formatDateToMMhDDhYYYY(
          addDays(searchFormValues?.dateTo, 1)
        );
      }

      criteria = [...criteria, { date }];
    }

    if (searchFormValues?.paymentDateFrom || searchFormValues?.paymentDateTo) {
      const paymentDate: Record<string, string> = {};

      if (searchFormValues?.paymentDateFrom) {
        paymentDate['_gte'] = formatDateToMMhDDhYYYY(
          searchFormValues?.paymentDateFrom
        );
      }
      if (searchFormValues?.paymentDateTo) {
        paymentDate['_lt'] = formatDateToMMhDDhYYYY(
          addDays(searchFormValues?.paymentDateTo, 1)
        );
      }

      criteria = [...criteria, { paymentDate }];
    }
    return criteria?.length > 0 ? { _and: [...criteria] } : {};
  }, [
    practicesIds,
    searchFormValues.billingMethod,
    searchFormValues?.dateFrom,
    searchFormValues?.dateTo,
    searchFormValues?.paymentDateFrom,
    searchFormValues?.paymentDateTo,
    searchFormValues.status,
  ]);

  const { data, error, loading, refetch } = useQuery<InvoicesData>(
    BillingInvoicesQuery,
    {
      variables: {
        criteria: andCriteria,
        limit: pageLimit,
        offset: (paginationValues.activePage - 1) * pageLimit,
      },
      skip: !practicesIds || practicesIds.length === 0,
    }
  );

  const [saveInvoiceStatusMutation] = useMutation(SaveInvoiceStatusMutation);
  const [regenerateInvoiceMutation] = useMutation(RegenerateInvoiceMutation);

  const saveInvoiceStatus = useCallback(
    async (billingInvoice: BillingInvoice, invoiceStatus: InvoiceStatus) => {
      try {
        await saveInvoiceStatusMutation({
          variables: {
            invoiceId: billingInvoice.id,
            status: invoiceStatus,
          },
        });
        await refetch();
      } catch (e) {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [refetch, saveInvoiceStatusMutation]
  );

  const handleInvoiceStatus = useCallback(
    async (billingInvoice: BillingInvoice, invoiceStatus: InvoiceStatus) => {
      if (invoiceStatus === InvoiceStatus.DISCARDED) {
        const confirm = await openConfirmationModal(`Setting the invoice as
        discarded will discard the invoice in Stripe and it will no longer
        be editable. Also, claims and CPT codes involved in the invoice will
        change their statuses back to "paid" and will be available for
        another NEW Invoice. Press ok to confirm.`);
        if (confirm === ConfirmationModalReturn.YES) {
          await saveInvoiceStatus(billingInvoice, invoiceStatus);
        }
        return;
      }

      if (
        invoiceStatus === InvoiceStatus.PAID &&
        billingInvoice.billMethod === 'manual'
      ) {
        setPaymentDateModalBillingInvoice({
          billingInvoice,
          newInvoiceStatusOnConfirm: invoiceStatus,
        });
        // The change of status will be done inside InvoicePaymentDateModal
        return;
      }

      await saveInvoiceStatus(billingInvoice, invoiceStatus);
    },
    [openConfirmationModal, saveInvoiceStatus]
  );

  const regenerateInvoice = useCallback(
    async (billingInvoice: BillingInvoice) => {
      try {
        const response = await regenerateInvoiceMutation({
          variables: {
            invoiceId: billingInvoice.id,
          },
        });

        const toastData: ToastOptions =
          response.data?.reGenerateInvoice.code === 200
            ? { type: 'success', title: 'Invoice regenerated successfully' }
            : { type: 'error', title: 'An error has ocurred' };

        toast({
          ...toastData,
          time: toastData.type === 'success' ? 1000 : 5000,
        });
        await refetch();
      } catch (error) {
        toast({
          title: `Callback error: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [refetch, regenerateInvoiceMutation]
  );

  const handleRegenerateInvoice = useCallback(
    async (billingInvoice: BillingInvoice) => {
      const confirm = await openConfirmationModal(`Are you sure you want to
    regenerate invoice in Stripe?`);
      if (confirm === ConfirmationModalReturn.YES) {
        await regenerateInvoice(billingInvoice);
      }
    },
    [openConfirmationModal, regenerateInvoice]
  );

  return (
    <>
      <Card fluid style={{ marginTop: '1rem' }}>
        <Card.Content>
          <Card.Header
            as={'h3'}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Icon
                name="file alternate outline"
                style={{ marginRight: '0.6rem' }}
              />
              Invoices List
            </div>
            <InvoicesExcelExportModal criteria={andCriteria} />
          </Card.Header>
          <Card.Description>
            {renderConfirmationModal()}
            <SearchForm
              searchValues={searchFormValues}
              setSearchValues={setSearchFormValues}
            />
            {error ? (
              <Message error>{error.message}</Message>
            ) : (
              <>
                <InvoiceListTable
                  loading={loading}
                  invoices={data?.invoices}
                  onShowPDF={(billingInvoice: BillingInvoice) => {
                    /**
                     * Due to logoImg is an asset and package library don't
                     * have access to assets, it is needed to read the logo in
                     * Internal Tool and pass it as a parameter to the modal
                     * which will show the PDF
                     */
                    const version = billingInvoice.pdfData?.version;
                    const invoice = billingInvoice.pdfData?.invoice;
                    const billingReport = billingInvoice.pdfData?.billingReport;
                    setPdfModalBillingInvoice({
                      version,
                      invoice: {
                        ...invoice,
                        header: { ...invoice?.header, logoImg },
                      } as InvoicePageProps,
                      billingReport: {
                        ...billingReport,
                        footer: { ...billingReport?.footer, logoImg },
                      } as BillingReportPageProps,
                    });
                  }}
                  onEditPaymentDate={(billingInvoice) =>
                    setPaymentDateModalBillingInvoice({ billingInvoice })
                  }
                  onRegenerateInvoice={handleRegenerateInvoice}
                  onStatusChanged={handleInvoiceStatus}
                  onRefetch={refetch}
                />
                <StyledPagination>
                  <InvoiceListPagination
                    totalPages={
                      data?.invoices && data?.invoices?.length > 0
                        ? Math.ceil(
                            data?.aggregating.aggregate.count / pageLimit
                          )
                        : 1
                    }
                    paginationValues={paginationValues}
                    setPaginationValues={setPaginationValues}
                  />
                </StyledPagination>
              </>
            )}
          </Card.Description>
        </Card.Content>
      </Card>
      {/*-----Invoice PDF MODAL-----*/}
      <InvoicePdfModal
        invoiceProps={pdfModalBillingInvoice}
        onClose={() => setPdfModalBillingInvoice(undefined)}
      />

      {/*-----PAYMENT DATE MODAL-----*/}
      {/* This modal is used to set the Payment Date, but also, to change the invoice status if invoiceStatus === InvoiceStatus.PAID &&
        billingInvoice.billMethod === 'manual' */}
      <InvoicePaymentDateModal
        open={!!paymentDateModalBillingInvoice}
        onOpen={() => {}}
        onClose={() => setPaymentDateModalBillingInvoice(undefined)}
        invoice={paymentDateModalBillingInvoice?.billingInvoice}
        onSave={async () => {
          // Change invoice status if it is requiered
          if (paymentDateModalBillingInvoice?.newInvoiceStatusOnConfirm) {
            try {
              await saveInvoiceStatus(
                paymentDateModalBillingInvoice.billingInvoice,
                paymentDateModalBillingInvoice.newInvoiceStatusOnConfirm
              );
              // It is not necessary to call refetch due to it called inside saveInvoiceStatus function
              return;
            } catch (error) {
              toast({
                title: `Callback error: ${error}`,
                type: 'error',
                time: 5000,
              });
            }
          }
          refetch?.();
        }}
      />
    </>
  );
};

export default InvoiceList;
