import { Link, Route } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { PatientsQuery } from '@graphql/patients';
import { PracticePatient, Sex } from '@bluefox/models/Patient';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import {
  Card,
  Icon,
  Input,
  Menu,
  Message,
  Pagination,
  Placeholder,
  Segment,
  Table,
  Button,
  Container,
  Checkbox,
  Popup,
} from 'semantic-ui-react';
import AddPatientModal from '@ui/AddPatientModal';
import { usePractice } from '@bluefox/contexts/Practice';
import MainLayout from '@ui/MainLayout';
import PatientFormModal from '@ui/PatientFormModal';
import { DateFormats } from '@bluefox/models/Dates';
import PatientNotesModal from '@ui/PatientNotesModal';
import { PatientNotes } from '@bluefox/models/Patient';
import { whereLikeInput } from '@bluefox/graphql/utils';
import CustomModal from '@bluefox/ui/CustomModal';
import PatientGuardiansList from '@bluefox/ui/PatientGuardiansList';

export interface PatientsData {
  patients: PracticePatient[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

const ENTRIES_PER_PAGE = 15;

const PatientsScreen = () => {
  const practice = usePractice();
  const [searchQuery, setSearchQuery] = useState('');
  const [dobQuery, setDobQuery] = useState<Date | undefined>(undefined);
  const [criteria, setCriteria] = useState<object>({});
  const [showNullMrn, setShowNullMrn] = useState(false);

  const [typing, setTyping] = useState(false);
  const [page, setPage] = useState(0);

  const [openPatientNotes, setOpenPatientNotes] = useState(false);
  const [selectedPracticePatientId, setSelectedPracticePatientId] =
    useState<string>('');
  const [selectedPatientNotes, setSelectedPatientNotes] = useState<
    PatientNotes[]
  >([]);

  useEffect(() => {
    let _criteria = {};

    if (searchQuery) {
      _criteria = {
        _or: [
          { mrn: { _ilike: whereLikeInput(searchQuery) } },
          {
            patientData: {
              _or: [
                { firstName: { _ilike: whereLikeInput(searchQuery) } },
                { lastName: { _ilike: whereLikeInput(searchQuery) } },
              ],
            },
          },
        ],
      };
    }

    if (dobQuery) {
      _criteria = {
        _and: [
          { ..._criteria },
          { patientData: { birthdate: { _eq: dobQuery } } },
        ],
      };
    }

    if (showNullMrn) {
      _criteria = {
        _and: [
          { ..._criteria },
          {
            _or: [
              {
                mrn: { _eq: '' },
              },
              {
                mrn: { _is_null: true },
              },
            ],
          },
        ],
      };
    }

    setCriteria(_criteria);
  }, [searchQuery, dobQuery, showNullMrn]);

  const { data, loading, error, refetch } = useQuery<PatientsData>(
    PatientsQuery,
    {
      variables: {
        practiceId: practice.id,
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
      skip: !dobQuery && !!searchQuery && typing,
    }
  );

  useEffect(() => {
    if (!!searchQuery && searchQuery.length < 3) {
      setTyping(true);
      setPage(0);
    } else {
      setTyping(false);
    }
  }, [searchQuery]);

  const handleOnSave = () => {
    refetch();
  };

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Patients' },
      ]}
    >
      <Container fluid>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="stethoscope" style={{ marginRight: '0.6rem' }} />
              Patients
              <AddPatientModal onSave={() => refetch()} />
            </Card.Header>
            <Card.Description>
              <Menu
                borderless
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                }}
              >
                <Menu.Item>
                  <Checkbox
                    toggle
                    checked={showNullMrn}
                    onChange={(_, { checked }) => {
                      setShowNullMrn(!!checked);
                    }}
                  />
                  <label style={{ marginLeft: '0.5rem' }}>
                    <b>Show only patients without MRN</b>
                  </label>
                </Menu.Item>
                <Menu.Menu
                  position="right"
                  style={{ display: 'flex', flexWrap: 'wrap' }}
                >
                  <Menu.Item>
                    <Input
                      data-automation-id="patients-search"
                      value={searchQuery}
                      onChange={(_, { value }) => {
                        setSearchQuery(value);
                      }}
                      icon="search"
                      placeholder="Search..."
                      loading={typing || loading}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <DateTimePicker
                      data-automation-id="patients-day-of-birth"
                      placeholderText="Search by Date of Birth"
                      selected={dobQuery}
                      onChange={(d) => setDobQuery(d ? (d as Date) : undefined)}
                      onSelect={(value) =>
                        setDobQuery(value ? (value as Date) : undefined)
                      }
                      onClear={() => setDobQuery(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      scrollableYearDropdown
                      isClearable
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>

              {error ? (
                <Message error>{error.message}</Message>
              ) : (
                <Table selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Preferred Name</Table.HeaderCell>
                      <Table.HeaderCell>First Name</Table.HeaderCell>
                      <Table.HeaderCell>Middle Name</Table.HeaderCell>
                      <Table.HeaderCell>Last Name</Table.HeaderCell>
                      <Table.HeaderCell>Date of Birth / Age</Table.HeaderCell>
                      <Table.HeaderCell>Sex</Table.HeaderCell>
                      <Table.HeaderCell width={4}></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {loading ? (
                      <Table.Row>
                        <Table.Cell colSpan={7}>
                          <Segment basic>
                            <Placeholder fluid>
                              <Placeholder.Header>
                                <Placeholder.Line />
                                <Placeholder.Line />
                              </Placeholder.Header>
                            </Placeholder>
                          </Segment>
                        </Table.Cell>
                      </Table.Row>
                    ) : !!data?.patients.length ? (
                      data?.patients.map(({ id, patientData, notes }) => {
                        return (
                          <Table.Row
                            key={id}
                            onClick={() => {}}
                            data-automation-id={`patients-${id}`}
                          >
                            <Table.Cell>{patientData.preferredName}</Table.Cell>
                            <Table.Cell>{patientData.firstName}</Table.Cell>
                            <Table.Cell>{patientData.middleName}</Table.Cell>
                            <Table.Cell>{patientData.lastName}</Table.Cell>
                            <Table.Cell>
                              <Moment format="MM-DD-YYYY">
                                {patientData.birthdate}
                              </Moment>
                              {` / `}
                              <Moment
                                durationFromNow
                                date={patientData.birthdate}
                                trim
                                format="y [years], M [months], d [days]"
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Icon
                                name={
                                  patientData.sex === Sex.Female
                                    ? 'venus'
                                    : patientData.sex === Sex.Male
                                    ? 'mars'
                                    : 'asexual'
                                }
                              />
                              {patientData.sex}
                            </Table.Cell>
                            <Table.Cell>
                              <StyledIconButtonGroup>
                                <Button
                                  as={Link}
                                  to={`/practices/${practice.handler}/patients/${id}/info`}
                                  size="tiny"
                                  icon="user"
                                  content="Patient Info"
                                  color="teal"
                                />
                                <Button
                                  as={Link}
                                  to={`/practices/${practice.handler}/patients/${id}/insurances`}
                                  size="tiny"
                                  icon="dollar sign"
                                  content="Insurances"
                                  color="teal"
                                />
                              </StyledIconButtonGroup>
                            </Table.Cell>
                            <Table.Cell>
                              <Popup
                                content="Notes"
                                trigger={
                                  <Button
                                    primary
                                    size="mini"
                                    icon="file alternate outline"
                                    onClick={() => {
                                      setOpenPatientNotes(true);
                                      setSelectedPatientNotes(notes);
                                      setSelectedPracticePatientId(id);
                                    }}
                                  />
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan={7}>
                          <Message>No Patients found.</Message>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>

                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                      <Table.HeaderCell colSpan={7} textAlign="right">
                        <Pagination
                          disabled={!total || total < ENTRIES_PER_PAGE}
                          defaultActivePage={1}
                          boundaryRange={0}
                          siblingRange={1}
                          onPageChange={(e, { activePage }) =>
                            setPage(activePage as number)
                          }
                          totalPages={totalPages}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              )}
            </Card.Description>
          </Card.Content>
        </Card>
        <Route
          path={`/practices/${practice.handler}/patients/:practicePatientId/info`}
          exact
        >
          <PatientFormModal onClose={handleOnSave} />
        </Route>
        <Route
          path={`/practices/${practice.handler}/patients/:practicePatientId/guardians`}
        >
          <CustomModal modalHeader="Patient Guardians List">
            <PatientGuardiansList onClose={refetch} />
          </CustomModal>
        </Route>
      </Container>
      <PatientNotesModal
        open={openPatientNotes}
        onClose={() => setOpenPatientNotes(false)}
        onSave={() => {}}
        practicePatientId={selectedPracticePatientId}
        patientNotes={selectedPatientNotes}
      />
    </MainLayout>
  );
};

const StyledIconButtonGroup = styled.div`
  float: right;
  @media (max-width: 768px) {
    float: left;
  }
`;

export default PatientsScreen;
