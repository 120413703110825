import { gql } from '@apollo/client';

export const PracticesQuery = gql`
  query PracticesQuery($where: practices_bool_exp) {
    practices(order_by: { name: asc }, where: $where) {
      id
      handler
      name
      npi
      profile
      state
      settings
    }
  }
`;

export const PracticesScreenQuery = gql`
  query PracticesQuery($where: practices_bool_exp) {
    practices(order_by: { name: asc }, where: $where) {
      id
      handler
      name
      npi
      profile
      state
      settings
      inventoryAdjustments_aggregate(
        where: { status: { _in: [pending, partially_applied] } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const BillablePracticesQuery = gql`
  query BillablePracticesQuery {
    practices(
      distinct_on: [name]
      where: { _or: [{ isBillable: { _eq: true } }] }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`;

export const PracticeSettingsQuery = gql`
  query PracticeSettingsQuery($id: uuid!) {
    practice: practices_by_pk(id: $id) {
      settings
      isBillable
      suspended
    }
  }
`;

export const PracticeQuery = gql`
  query PracticeQuery($id: uuid!) {
    practices(where: { id: { _eq: $id } }) {
      name
      profile
      state
      handler
      npi
      accounts(
        order_by: { account: { firstName: asc, lastName: asc }, suspended: asc }
      ) {
        profile
        role
        title
        pcp
        suspended
        id
        account {
          firstName
          lastName
          npi
          email
          id
          taxonomyCode
        }
      }
    }
  }
`;

export const InsertPracticeMutation = gql`
  mutation InsertPracticeMutation(
    $handler: String!
    $npi: String!
    $name: String!
    $profile: jsonb!
  ) {
    practice: insert_practices_one(
      object: { handler: $handler, npi: $npi, name: $name, profile: $profile }
    ) {
      id
      handler
      name
    }
  }
`;

export const SavePracticeMutation = gql`
  mutation SavePracticeMutation($obj: practices_insert_input!) {
    practice: insert_practices_one(
      object: $obj
      on_conflict: {
        constraint: practices_handler_key
        update_columns: [
          handler
          name
          npi
          profile
          settings
          issName
          iisAlias
          timezone
          organizationId
        ]
      }
    ) {
      id
      handler
      name
      issName
      iisAlias
      claimMappings {
        id
      }
    }
  }
`;

export const PracticeBillingStrategyMutation = gql`
  mutation PracticeBillingStrategyMutation(
    $practiceId: uuid!
    $strategy: jsonb
  ) {
    update_practices(
      where: { id: { _eq: $practiceId } }
      _append: { settings: $strategy }
    ) {
      affected_rows
      returning {
        id
        settings
      }
    }
  }
`;

export const SetBillablePracticeMutation = gql`
  mutation SetBillablePracticeMutation(
    $practiceId: uuid!
    $isBillable: Boolean
    $suspended: Boolean
  ) {
    update_practices_by_pk(
      pk_columns: { id: $practiceId }
      _set: { isBillable: $isBillable, suspended: $suspended }
    ) {
      id
      handler
      isBillable
      suspended
    }
  }
`;

export const UpdatePracticeSettingsMutation = gql`
  mutation UpdatePracticeSettingsMutation(
    $practiceId: uuid!
    $criteria: jsonb
  ) {
    update_practices_by_pk(
      pk_columns: { id: $practiceId }
      _append: { settings: $criteria }
    ) {
      id
      settings
    }
  }
`;

export const GetBillablePracticeQuery = gql`
  query GetBillablePracticeQuery($practiceId: uuid!) {
    practice: practices_by_pk(id: $practiceId) {
      id
      isBillable
    }
  }
`;

export const AllPracticesQuery = gql`
  query AllPracticesQuery($criteria: practices_bool_exp) {
    allPractices: practices(
      distinct_on: handler
      where: $criteria
      order_by: { handler: asc }
    ) {
      id
      name
      handler
      settings
    }
  }
`;

export const GetPracticeSiteVisits = gql`
  query GetPracticeSiteVisits($practiceId: uuid) {
    site_visits(
      where: { practiceId: { _eq: $practiceId } }
      limit: 2
      order_by: { date: desc }
    ) {
      id
      date
    }
  }
`;

export const InsertPracticeSiteVisit = gql`
  mutation InsertPracticeSiteVisit($date: date, $practiceId: uuid) {
    insert_site_visits_one(object: { date: $date, practiceId: $practiceId }) {
      id
      date
      practiceId
    }
  }
`;

export const UpdatePracticeSiteVisit = gql`
  mutation UpdatePracticeSiteVisit($id: uuid!, $date: date) {
    update_site_visits_by_pk(pk_columns: { id: $id }, _set: { date: $date }) {
      id
      date
      practiceId
    }
  }
`;

export const BorrowingPracticesAndTicketsQuery = gql`
  query BorrowingPracticesAndTicketsQuery {
    practices(
      distinct_on: [name]
      where: { _or: [{ isBillable: { _eq: true } }] }
      order_by: { name: asc }
    ) {
      id
      name
    }
    tickets: ticketing_tickets(
      where: { type: { _eq: "Borrowing_issue" }, status: { _eq: "open" } }
    ) {
      id
      detail
    }
  }
`;

export const GetPracticeById = (fields: string[]) => gql`
  query GetPracticeById($id: uuid!) {
    response: practices_by_pk(id: $id) {
      ${fields.join('\n')}
    }
  }
`;

export const SubscribeToPracticeById = (fields: string[]) => gql`
  subscription SubscribeToPracticeById($id: uuid!) {
    response: practices_by_pk(id: $id) {
      ${fields.join('\n')}
    }
  }
`;

export const GetSlackChannelsToReport = gql`
  query GetSlackChannelsToReport {
    channels: channels_to_report {
      value
      text
    }
  }
`;
