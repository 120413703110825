import React, { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { PracticeSettingsBilling } from '@bluefox/models/Practice';
import StatePicker from '@bluefox/ui/StatePicker';
import { UpdatePracticeSettingsMutation } from '@graphql/practices';
import { Button, Confirm, Form, Icon } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { BillingStrategyBox } from '@bluefox/models/Mappings';
import { humanizeText } from '@bluefox/lib/humanize';

interface BoxFormProps {
  close: () => void;
  practiceId: string;
  data: Data;
  onSave: () => void;
  billing: PracticeSettingsBilling | undefined;
  box: string;
}

type Data = {
  npi?: string;
  name?: string;
  address?: {
    zip?: string;
    city?: string;
    state?: string;
    street?: string;
    zipExtension?: string;
  };
};

const formFilled = (data: Data): boolean => {
  // Check if 'npi' and 'name' are not empty or undefined
  if (!data.npi || !data.name) {
    return false;
  }

  // Check if 'address' is provided and its keys are not empty or undefined
  if (data.address) {
    if (
      !data.address.zip ||
      !data.address.city ||
      !data.address.state ||
      !data.address.street ||
      !data.address.zipExtension
    ) {
      return false;
    }
  } else {
    return false;
  }

  return true;
};

const BoxForm = ({
  close,
  billing,
  data,
  practiceId,
  box,
  onSave,
}: BoxFormProps) => {
  const [formData, setFormData] = useState<Data>(data);
  const [showConfirm, setShowConfirm] = useState(false);

  const [savePracticeSettingsBilling, { loading }] = useMutation(
    UpdatePracticeSettingsMutation
  );

  const handleSubmit = async () => {
    let billingObject;
    if (billing) {
      box === BillingStrategyBox.RENDERING_PROVIDER
        ? (billingObject = {
            ...billing,
            [`${box}`]: {
              name: formData.name,
            },
          })
        : (billingObject = {
            ...billing,
            [`${box}`]: {
              name: formData.name,
              npi: formData.npi,
              address: {
                zip: formData.address?.zip,
                city: formData.address?.city,
                state: formData.address?.state,
                street: formData.address?.street,
                zipExtension: formData.address?.zipExtension,
              },
            },
          });
      try {
        await savePracticeSettingsBilling({
          variables: {
            practiceId,
            criteria: {
              billing: billingObject,
            },
          },
        });
        toast({
          title: `${humanizeText(box, {
            deCamelCase: true,
            capitalize: 'all',
          })} saved successfully`,
          type: 'success',
          time: 1000,
        });
        onSave();
        close();
      } catch (error) {
        toast({
          title: `${humanizeText(box, {
            deCamelCase: true,
            capitalize: 'all',
          })} could not be saved. Callback error: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    }
  };

  const handleRemove = async () => {
    if (billing && box in billing) {
      let billingObject = { ...billing };
      let boxToRemove = box as keyof PracticeSettingsBilling;
      delete billingObject[boxToRemove];
      try {
        await savePracticeSettingsBilling({
          variables: {
            practiceId,
            criteria: {
              billing: billingObject,
            },
          },
        });
        toast({
          title: `${humanizeText(box, {
            deCamelCase: true,
            capitalize: 'all',
          })} removed successfully`,
          type: 'success',
          time: 1000,
        });
        onSave();
        close();
      } catch (error) {
        toast({
          title: `${humanizeText(box, {
            deCamelCase: true,
            capitalize: 'all',
          })} could not be removed. Callback error: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    }
  };

  const disabled = useMemo(() => {
    if (box === BillingStrategyBox.RENDERING_PROVIDER) {
      return !formData.name;
    } else if (
      box === BillingStrategyBox.SERVICE_FACILITY_LOCATION ||
      box === BillingStrategyBox.BILLING_PROVIDER
    ) {
      return !formFilled(formData);
    }
  }, [box, formData]);

  return (
    <>
      <Form onSubmit={handleSubmit} data-automation-id="vaccine-mapping-form">
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              label="Name"
              required
              value={formData.name}
              onChange={(e) =>
                setFormData((prev: Data) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
            />
          </Form.Field>
        </Form.Group>
        {box !== BillingStrategyBox.RENDERING_PROVIDER && (
          <>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  label="NPI"
                  required
                  value={formData.npi}
                  onChange={(e) =>
                    setFormData((prev: Data) => ({
                      ...prev,
                      npi: e.target.value,
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  label="ZIP"
                  required
                  value={formData.address?.zip}
                  onChange={(e) =>
                    setFormData((prev: Data) => ({
                      ...prev,
                      address: {
                        ...prev.address,
                        zip: e.target.value,
                      },
                    }))
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  label="City"
                  required
                  value={formData.address?.city}
                  onChange={(e) =>
                    setFormData((prev: Data) => ({
                      ...prev,
                      address: {
                        ...prev.address,
                        city: e.target.value,
                      },
                    }))
                  }
                />
              </Form.Field>
              <Form.Field required>
                <label>State</label>
                <StatePicker
                  fluid
                  value={formData.address?.state || ''}
                  onChange={(value) =>
                    setFormData((prev: Data) => ({
                      ...prev,
                      address: {
                        ...prev.address,
                        state: value,
                      },
                    }))
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  label="Street"
                  required
                  value={formData.address?.street}
                  onChange={(e) =>
                    setFormData((prev: Data) => ({
                      ...prev,
                      address: {
                        ...prev.address,
                        street: e.target.value,
                      },
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  label="Zip Extension"
                  required
                  value={formData.address?.zipExtension}
                  onChange={(e) =>
                    setFormData((prev: Data) => ({
                      ...prev,
                      address: {
                        ...prev.address,
                        zipExtension: e.target.value,
                      },
                    }))
                  }
                />
              </Form.Field>
            </Form.Group>
          </>
        )}
        {billing && box in billing && (
          <>
            <Form.Group>
              <Form.Field>
                <Button
                  color="red"
                  onClick={() => setShowConfirm(true)}
                  type="button"
                >
                  Remove{' '}
                  {humanizeText(box, {
                    deCamelCase: true,
                    capitalize: 'all',
                  })}
                </Button>
              </Form.Field>
            </Form.Group>
            <Confirm
              open={showConfirm}
              content={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '1rem 2rem 1rem 2rem',
                    backgroundColor: '#fff6f6',
                  }}
                >
                  <Icon
                    name="warning sign"
                    size="big"
                    color="red"
                    style={{ marginRight: '1rem' }}
                  />
                  <p>Are you sure you want to remove this configuration?</p>
                </div>
              }
              cancelButton="No"
              confirmButton="Yes"
              size="mini"
              onCancel={() => {
                setShowConfirm(false);
              }}
              onConfirm={handleRemove}
            />
          </>
        )}
        <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={close} type="button">
            Cancel
          </Button>
          <Button
            primary
            type="submit"
            content="Save"
            icon="save"
            disabled={disabled || loading}
          />
        </Form.Field>
      </Form>
    </>
  );
};

export default BoxForm;
