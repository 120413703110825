import React, { useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Button,
  Confirm,
  Dropdown,
  Label,
  List,
  Popup,
  Table,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';

import { UpdateEcwAppointmentStatusMutation } from '../../graphql/bot';

import { ProcessEcwAppointmentManually } from '@bluefox/graphql/integrations/ecw';
import {
  EcwRawAppointment,
  appointmentsStatusOptions,
  parseEcwInsuranceRelationship,
} from '@bluefox/models/integrations/Ecw';
import {
  formatDateStrToMMDDYYYY,
  formatToMMDDYYYY_HHMM,
} from '@bluefox/lib/formatters';
import {
  setDropdownBackgroundColor,
  setDropdownBorderColor,
} from '@bluefox/lib/commonStyles';

type EcwAppointmentsRowProps = {
  data: EcwRawAppointment;
  onSave: () => void;
  statusReasonDic:
    | {
        [key: string]: string;
      }
    | undefined;
};

const EcwAppointmentsRow = ({
  data,
  onSave,
  statusReasonDic,
}: EcwAppointmentsRowProps) => {
  const [updateAppointmentStatus] = useMutation(
    UpdateEcwAppointmentStatusMutation
  );

  const [showConfirmRunSub, setShowConfirmRunSub] = useState(false);

  const [runEcwAppointment] = useMutation(ProcessEcwAppointmentManually, {
    variables: {
      appointmentId: data.id,
    },
    onCompleted: () => onSave(),
  });

  const handleAppointmentStatusUpdate = useCallback(
    async (status: string) => {
      try {
        await updateAppointmentStatus({
          variables: {
            id: data.id,
            status,
          },
        });
        toast({
          title: 'Status saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      } catch (error) {
        toast({
          title: `Callback error: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [updateAppointmentStatus]
  );

  const handleRunAppointment = useCallback(async () => {
    try {
      const response = await runEcwAppointment();

      if (response.data.processedAppointment.code === 400) {
        toast({
          title: 'There was an error trying to run appointment',
          type: 'error',
          time: 5000,
        });
        return;
      }

      toast({
        title: 'Action ran successfully',
        type: 'success',
        time: 1000,
      });
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  }, [runEcwAppointment]);

  return (
    <Table.Row>
      <Table.Cell>{data.practice.name}</Table.Cell>
      <Table.Cell>
        <Dropdown
          style={{
            backgroundColor: setDropdownBackgroundColor(data?.status),
            borderColor: setDropdownBorderColor(data?.status),
            maxWidth: '8rem',
          }}
          fluid
          selection
          options={appointmentsStatusOptions}
          value={data?.status}
          onChange={(e, data) => {
            handleAppointmentStatusUpdate(data.value?.toString() || '');
          }}
        />
      </Table.Cell>
      <Table.Cell>
        {data?.statusReason ? statusReasonDic?.[data.statusReason] : '-'}
      </Table.Cell>
      <Table.Cell>
        <div>
          {`${data.patient.firstName} ${data.patient.lastName}`}
          <p style={{ margin: '0' }}>{`(${data.patient.dob})`}</p>
          <p style={{ fontSize: '0.8rem', fontWeight: '700', margin: '0' }}>
            MRN: {data.mrn}
          </p>
          <Popup
            on="click"
            trigger={<a>More info...</a>}
            content={
              <List>
                <List.Item>
                  <List.Header>MRN</List.Header>
                  {data.mrn || '-'}
                </List.Item>
                <List.Item>
                  <List.Header>First Name</List.Header>
                  {data.patient.firstName || '-'}
                </List.Item>
                {data.patient.middleName && (
                  <List.Item>
                    <List.Header>Middle Name</List.Header>
                    {data.patient.middleName}
                  </List.Item>
                )}
                <List.Item>
                  <List.Header>Last Name</List.Header>
                  {data.patient.lastName || '-'}
                </List.Item>
                <List.Item>
                  <List.Header>Birthdate</List.Header>
                  {data.patient.dob || '-'}
                </List.Item>
                <List.Item>
                  <List.Header>Sex</List.Header>
                  {data.patient.sex || '-'}
                </List.Item>
                <List.Item>
                  <List.Header>Ethnicity</List.Header>
                  {data.patient.ethnicity || '-'}
                </List.Item>
                <List.Item>
                  <List.Header>Race</List.Header>
                  {data.patient.race || '-'}
                </List.Item>
                <List.Item>
                  <List.Header>VFC</List.Header>
                  {data.patient.vfc || '-'}
                </List.Item>
                <List.Item>
                  <List.Header>Email</List.Header>
                  {data.patient.email || '-'}
                </List.Item>
                <List.Item>
                  <List.Header>Phone Number</List.Header>
                  {data.patient.mobileNumber
                    ? data.patient.mobileNumber
                    : data.patient.phone || '-'}
                </List.Item>
                <List.Item>
                  <List.Header>Address</List.Header>
                  {`${data.patient.address}, ${data.patient.city}, ${data.patient.state} - ${data.patient.zipCode}` ||
                    '-'}
                </List.Item>
              </List>
            }
          />
        </div>
      </Table.Cell>
      <Table.Cell>
        {data.insurance ? (
          <div>
            <span style={{ whiteSpace: 'pre-wrap' }}>
              {data.insurance.name}
            </span>
            <p style={{ fontSize: '0.8rem', fontWeight: '700', margin: '0' }}>
              Member Id: {data.insurance.suscriberNo}
            </p>
            <Popup
              on="click"
              trigger={<a>More info...</a>}
              content={
                <List>
                  <List.Item>
                    <List.Header>Company Name</List.Header>
                    {
                      <span style={{ whiteSpace: 'pre-wrap' }}>
                        {data.insurance.name || '-'}
                      </span>
                    }
                  </List.Item>
                  <List.Item>
                    <List.Header>Member ID</List.Header>
                    {data.insurance.suscriberNo || '-'}
                  </List.Item>
                  <List.Item>
                    <List.Header>Group ID</List.Header>
                    {data.insurance.groupNo || '-'}
                  </List.Item>
                  <List.Item>
                    <List.Header>Relationship To Subscriber</List.Header>
                    {parseEcwInsuranceRelationship(data.insurance.grRel) || '-'}
                  </List.Item>
                  <List.Item>
                    <List.Header>Holder</List.Header>
                    {data.holder
                      ? `${data.holder.firstName} ${data.holder.lastName}`
                      : '-'}
                  </List.Item>
                  <List.Item>
                    <List.Header>Holder Birthdate</List.Header>
                    {data.holder ? data.holder.dob : '-'}
                  </List.Item>
                </List>
              }
            />
          </div>
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        {data.encounterDate ? (
          <div>
            {formatDateStrToMMDDYYYY(data.encounterDate) || '-'}
            <p>
              <b>{`${data.appointmentTime.slice(0, -3)} hs` || '-'}</b>
            </p>
            <Label basic size="small" content={data.appointmentStatus} />
          </div>
        ) : (
          '-'
        )}
        <div>
          <b>ID:</b> {data.appointmentId}
        </div>
      </Table.Cell>
      <Table.Cell>
        {data.error ? (
          <Popup
            on="click"
            trigger={<Button size="tiny" content="Show Error" />}
            position="top right"
            content={
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {data.error.message || '-'}
              </span>
            }
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        {formatToMMDDYYYY_HHMM(data.createdAt.toString())}
      </Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          trigger={
            <Button
              size="tiny"
              color="teal"
              icon="play"
              onClick={() => setShowConfirmRunSub(true)}
              disabled={data.status !== 'error'}
            />
          }
          content="Run Appointment"
        />
      </Table.Cell>
      <Confirm
        size="mini"
        content="Are you sure you want to run appointment?"
        confirmButton="Yes"
        open={showConfirmRunSub}
        onCancel={() => setShowConfirmRunSub(false)}
        onConfirm={() => {
          handleRunAppointment();
          setShowConfirmRunSub(false);
        }}
      />
    </Table.Row>
  );
};

export default EcwAppointmentsRow;
