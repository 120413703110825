import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import {
  formatDateStringToDateTimeZero,
  reformatYYYYhMMhDDStringtoMMsDDsYYYY,
} from '@bluefox/lib/formatters';
import {
  BillingNotificationContent,
  Notification,
} from '@bluefox/models/Notification';
import { Table } from 'semantic-ui-react';
import { NotificationListTableRowChild } from '@bluefox/ui/Notifications/NotificationListTable/common';
import BillingNotificationMessage from '@bluefox/ui/Notifications//NotificationMessage/BillingNotificationMessage';
import { renderGivenAt } from '../../common';

type Props = {
  notification: Notification;
  practiceTimezone?: string;
  children?: ReactElement<NotificationListTableRowChild>;
  internalTools?: boolean;
};

const BillingNotificationListTableRow = ({
  notification,
  practiceTimezone,
  children,
  internalTools,
}: Props) => {
  const content = useMemo(
    () => notification.content as BillingNotificationContent,
    [notification.content]
  );

  const renderChildren = useCallback(() => {
    return Children.map(children, (child: ReactNode) => {
      if (isValidElement<NotificationListTableRowChild>(child))
        return cloneElement<NotificationListTableRowChild>(child, {
          notification,
        });
    });
  }, [notification]);

  return (
    <Table.Row>
      {internalTools && (
        <Table.Cell> {notification?.practice?.name ?? '-'}</Table.Cell>
      )}
      <Table.Cell>
        {notification?.updatedAt
          ? reformatYYYYhMMhDDStringtoMMsDDsYYYY(
              formatDateStringToDateTimeZero(
                notification?.updatedAt as unknown as string
              )
            )
          : '-'}
      </Table.Cell>
      <Table.Cell>{content?.fullPatienName ?? '-'}</Table.Cell>
      <Table.Cell>{content?.mrn ?? '-'}</Table.Cell>
      <Table.Cell>
        {content?.givenAt
          ? renderGivenAt(
              content?.givenAt
              /* Billing givenAt already has the practice timezone applied */
              /*
              practiceTimezone
                ? practiceTimezone
                : notification?.practice?.timezone */
            )
          : '-'}
      </Table.Cell>
      <Table.Cell>
        <BillingNotificationMessage
          notification={notification}
          practiceTimezone={
            practiceTimezone
              ? practiceTimezone
              : notification?.practice?.timezone
          }
        />
      </Table.Cell>
      <Table.Cell>{renderChildren()}</Table.Cell>
    </Table.Row>
  );
};

export default BillingNotificationListTableRow;
