import { Dispatch, SetStateAction } from 'react';

import { Form } from 'semantic-ui-react';
import {
  BalanceMovementFilter,
  balanceMovementReasonsOptions,
  balanceMovementTypesOptions,
  HasInvoice,
  hasInvoiceOptions,
  MovementReason as BalanceMovementReason,
  MovementType as BalanceMovementType,
  SelectAll,
  selectAllOption,
} from '@bluefox/models/BalanceMovements';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';

type AdvancedFiltersProps = {
  setBalanceMovementFilter: Dispatch<SetStateAction<BalanceMovementFilter>>;
  balanceMovementFilter: BalanceMovementFilter;
};

const AdvancedFilters = ({
  setBalanceMovementFilter,
  balanceMovementFilter,
}: AdvancedFiltersProps) => {
  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <Form.Group widths="equal">
        <Form.Select
          label="Movement reason:"
          options={[...selectAllOption, ...balanceMovementReasonsOptions]}
          value={balanceMovementFilter.reason}
          onChange={(_, { value }) => {
            setBalanceMovementFilter({
              ...balanceMovementFilter,
              reason: value as BalanceMovementReason | SelectAll,
            });
          }}
        />
        <Form.Select
          label="Movement type:"
          options={[...selectAllOption, ...balanceMovementTypesOptions]}
          value={balanceMovementFilter.type}
          onChange={(_, { value }) => {
            setBalanceMovementFilter({
              ...balanceMovementFilter,
              type: value as BalanceMovementType | SelectAll,
            });
          }}
        />
        <Form.Select
          label="Has Invoice"
          options={[...selectAllOption, ...hasInvoiceOptions]}
          value={balanceMovementFilter.hasInvoice}
          onChange={(_, { value }) => {
            setBalanceMovementFilter({
              ...balanceMovementFilter,
              hasInvoice: value as HasInvoice | SelectAll,
            });
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <DateTimePicker
            placeholderText="From:"
            selected={balanceMovementFilter.dateFrom}
            onChange={(value) => {
              setBalanceMovementFilter((prevVal) => ({
                ...prevVal,
                dateFrom: value ? (value as Date) : undefined,
              }));
            }}
            onSelect={(value) =>
              setBalanceMovementFilter((prevVal) => ({
                ...prevVal,
                dateFrom: value ? (value as Date) : undefined,
              }))
            }
            onClear={() =>
              setBalanceMovementFilter((prevVal) => ({
                ...prevVal,
                dateFrom: undefined,
              }))
            }
            dateFormat={DateFormats.DATE}
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            dropdownMode="select"
            isClearable
          />
        </Form.Field>
        <Form.Field>
          <DateTimePicker
            forFilter
            placeholderText="To:"
            selected={balanceMovementFilter.dateTo}
            onChange={(value) => {
              setBalanceMovementFilter((prevVal) => ({
                ...prevVal,
                dateTo: value ? (value as Date) : undefined,
              }));
            }}
            onSelect={(value) =>
              setBalanceMovementFilter((prevVal) => ({
                ...prevVal,
                dateTo: value ? (value as Date) : undefined,
              }))
            }
            onClear={() =>
              setBalanceMovementFilter((prevVal) => ({
                ...prevVal,
                dateTo: undefined,
              }))
            }
            dateFormat={DateFormats.DATE}
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            dropdownMode="select"
            isClearable
          />
        </Form.Field>
        <Form.Button
          content="Clear"
          primary
          onClick={() =>
            setBalanceMovementFilter({
              type: SelectAll.ALL,
              reason: SelectAll.ALL,
              hasInvoice: SelectAll.ALL,
              dateFrom: undefined,
              dateTo: undefined,
            })
          }
        />
      </Form.Group>
    </Form>
  );
};

export default AdvancedFilters;
