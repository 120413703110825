import { gql } from '@apollo/client';

export const AthenaSubscriptionsQuery = gql`
  query AthenaSubscriptionsQuery(
    $criteria: athena_subscriptions_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    subscriptions: athena_subscriptions(
      order_by: { practice: { name: asc }, createdAt: desc }
      where: $criteria
      offset: $offset
      limit: $limit
    ) {
      id
      practiceId
      type
      status
      error
      metadata
      practice {
        name
      }
    }
    aggregating: athena_subscriptions_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const AllPracticesWithAthena = gql`
  query AllPracticesWithAthena {
    allPractices: practices(
      order_by: { name: asc }
      where: {
        settings: { _contains: { athena: { enabled: true } } }
        suspended: { _eq: false }
      }
    ) {
      id
      name
    }
  }
`;

export const AthenaOutboundActionsQuery = gql`
  query AthenaOutboundActionsQuery(
    $criteria: athena_outbound_actions_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    outboundActions: athena_outbound_actions(
      order_by: { practice: { name: asc }, createdAt: desc }
      where: $criteria
      offset: $offset
      limit: $limit
    ) {
      id
      entityRef
      error
      metadata
      response
      status
      type
      iisStatus
      practiceId
      practicePatientId
      practice {
        name
        timezone
      }
      practicePatient {
        id
        mrn
        patientData {
          lastName
          firstName
        }
        consentForIis
      }
    }
    aggregating: athena_outbound_actions_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const ProcessAthenaSubscriptionManuallyMutation = gql`
  mutation ProcessAthenaSubscriptionManuallyMutation($subscriptionId: uuid!) {
    ProcessAthenaSubscriptionManually(subscriptionId: $subscriptionId) {
      code
    }
  }
`;

export const TriggerAthenaOutboundActionMutation = gql`
  mutation TriggerAthenaOutboundActionMutation($outboundActionId: uuid!) {
    triggerOutboundActionManually(outboundActionId: $outboundActionId) {
      code
      body
    }
  }
`;

export const UpdateIisStatusMutation = gql`
  mutation UpdateIisStatusMutation(
    $outboundActionId: uuid!
    $iisStatus: String!
  ) {
    updatedIisStatus: update_athena_outbound_actions_by_pk(
      pk_columns: { id: $outboundActionId }
      _set: { iisStatus: $iisStatus }
    ) {
      id
      iisStatus
    }
  }
`;

export const UpdateSubscriptionStatusMutation = gql`
  mutation UpdateSubscriptionStatusMutation(
    $subscriptionId: uuid!
    $status: String!
  ) {
    updatedStatus: update_athena_subscriptions_by_pk(
      pk_columns: { id: $subscriptionId }
      _set: { status: $status }
    ) {
      id
      status
    }
  }
`;

export const UpdateOutboundActionStatusEmrMutation = gql`
  mutation UpdateOutboundActionStatusEmrMutation(
    $outboundActionId: uuid!
    $statusEmr: String!
  ) {
    updatedStatusEmr: update_athena_outbound_actions_by_pk(
      pk_columns: { id: $outboundActionId }
      _set: { status: $statusEmr }
    ) {
      id
      status
    }
  }
`;

export const OutboundActionSubscription = gql`
  subscription OutboundActionSubscription($outboundActionId: uuid!) {
    outboundActionSubscription: athena_outbound_actions_by_pk(
      id: $outboundActionId
    ) {
      status
      error
    }
  }
`;

export const AthenaMappingsQuery = gql`
  query AthenaMappingsQuery(
    $criteria: athena_mappings_bool_exp
    $limit: Int
    $offset: Int
  ) {
    athenaMappings: athena_mappings(
      where: $criteria
      offset: $offset
      limit: $limit
      order_by: { key: asc }
    ) {
      id
      key
      type
      value
      practice {
        id
        name
      }
    }
    allPractices: practices(
      distinct_on: id
      where: { settings: { _contains: { athena: { enabled: true } } } }
    ) {
      id
      name
    }
    aggregating: athena_mappings_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const InsertAthenaMappingMutation = gql`
  mutation InsertAthenaMappingMutation(
    $practiceId: uuid
    $type: String
    $key: String
    $value: jsonb
  ) {
    insertAthenaMapping: insert_athena_mappings(
      objects: {
        practiceId: $practiceId
        type: $type
        key: $key
        value: $value
      }
    ) {
      affected_rows
    }
  }
`;

export const UpdateAthenaMappingMutation = gql`
  mutation UpdateAthenaMappingMutation(
    $id: uuid!
    $practiceId: uuid
    $key: String!
    $value: jsonb!
  ) {
    update_athena_mappings_by_pk(
      pk_columns: { id: $id }
      _set: { key: $key, value: $value, practiceId: $practiceId }
    ) {
      id
      practiceId
      key
      value
    }
  }
`;

export const DeleteMappingMutation = gql`
  mutation DeleteMappingMutation($mappingId: uuid!) {
    delete_athena_mappings_by_pk(id: $mappingId) {
      id
    }
  }
`;

export const ProvidersQuery = gql`
  query ProvidersQuery($practiceIds: [uuid!]) {
    providers: practice_accounts(
      where: {
        practiceId: { _in: $practiceIds }
        title: { _in: [MD, MA, DO, NP, Nurse, PA] }
      }
    ) {
      id
      title
      account {
        firstName
        lastName
        email
      }
    }
  }
`;

export const AthenaMappingsDataQuery = gql`
  query AthenaMappingData($criteria: athena_mappings_bool_exp!) {
    mapping: athena_mappings(where: $criteria) {
      id
      key
    }
  }
`;
