import { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';
import { Link } from 'react-router-dom';

import {
  BillingClaimsView,
  ProcedureAmountsQuery,
  BillingClaimsCount,
  UpdateUploadedToEmr,
  UpdatePaymentReportedToEmr,
} from '@graphql/billing';
import {
  BillingClaimView,
  BillingClaimStatus,
  EobStatus,
} from '@bluefox/models/Billing';
import {
  Card,
  Icon,
  Menu,
  Message,
  Placeholder,
  Segment,
  Table,
  Button,
  Container,
  Label,
  Dropdown,
  Popup,
  List,
  Checkbox,
  Input,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import { humanizeText } from '@bluefox/lib/humanize';
import UpdateBillingClaimForm from '@ui/Billing/UpdateBillingClaimForm';
import { usePractice } from '@bluefox/contexts';
import EmrClaimNumberForm from '@ui/Billing/EmrClaimNumberForm';
import { DateFormats } from '@bluefox/models/Dates';
import {
  formatDateToMMDDYYYY,
  formatDateToMMDDYYYYV2,
  formatDatetimeToMMDDYYY,
} from '@bluefox/lib/formatters';

interface Cpt {
  cpt: string;
}

interface ClaimsCountData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface ProcedureAmountsData {
  cptCodes: Cpt[];
}

interface BillingClaimViewData {
  billingClaims: BillingClaimView[];
}

const ENTRIES_PER_PAGE = 15;

const ClaimsToUploadToEmrScreen = () => {
  const { claimId } = useParams<{ claimId: string }>();
  const history = useHistory();
  const billingClaimsRef = useRef(null);

  const practice = usePractice();

  const [criteria, setCriteria] = useState<object>({});
  const [searchPatient, setSearchPatient] = useState<string>('');
  const [searchUploadedToEmr, setSearchUploadedToEmr] = useState<
    boolean | string | undefined
  >('all');
  const [searchReportedToEmr, setSearchReportedToEmr] = useState<
    boolean | string | undefined
  >('all');

  const [pageCount, setPageCount] = useState(0);
  const [isQuerying, setIsQuerying] = useState(false);

  const [totalClaimsLoading, setTotalClaimsLoading] = useState(false);

  const [showInfoMsg, setShowInfoMsg] = useState(true);

  const paidClosedBilled = [
    'paid',
    'partially_paid',
    'closed',
    'partially_billed',
    'billed',
  ];

  const [
    getClaims,
    {
      data: viewData,
      loading: viewLoading,
      error: viewError,
      refetch: viewRefetch,
    },
  ] = useLazyQuery<BillingClaimViewData>(BillingClaimsView);

  const { data: procedureAmountsData } = useQuery<ProcedureAmountsData>(
    ProcedureAmountsQuery,
    {
      variables: {
        type: 'Administration',
      },
    }
  );

  const [getClaimsCount, { data: claimsCount, loading: claimsCountLoading }] =
    useLazyQuery<ClaimsCountData>(BillingClaimsCount);
  const handleUploadedToEmrValue = (value: boolean | undefined) => {
    const uploadedValue = value;
    setSearchUploadedToEmr(uploadedValue);
    setShowInfoMsg(true);
  };

  const handleReportedToEmrValue = (value: boolean | undefined) => {
    const reportedValue = value;
    setSearchReportedToEmr(reportedValue);
    setShowInfoMsg(true);
  };

  const handleSearchOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      getClaims({
        variables: {
          criteria,
          limit: ENTRIES_PER_PAGE,
          offset: ENTRIES_PER_PAGE * pageCount,
        },
      });
    }
  };

  useEffect(() => {
    let _criteria = {};
    let _searchUploadedToEmr = undefined;
    let _searchReportedToEmr = undefined;

    if (searchUploadedToEmr !== 'all') {
      searchUploadedToEmr
        ? (_searchUploadedToEmr = {
            _is_null: true,
          })
        : (_searchUploadedToEmr = { _is_null: false });
      _criteria = {
        status: {
          _nin: ['unclaimed', 'pending'],
        },
      };
    } else {
      _searchUploadedToEmr = {};
    }

    if (searchReportedToEmr !== 'all') {
      searchReportedToEmr
        ? (_searchReportedToEmr = {
            _is_null: true,
          })
        : (_searchReportedToEmr = { _is_null: false });

      _criteria = {
        status: {
          _in: paidClosedBilled,
        },
      };
    } else {
      _searchReportedToEmr = {};
    }

    _criteria = {
      ..._criteria,
      practiceId: {
        _eq: practice.id,
      },

      fullName: {
        _ilike: `%${searchPatient}%`,
      },

      createdAt: { _gte: '2023-03-27' },
      uploadedToEmrAt: _searchUploadedToEmr,
      paymentReportedToEmrAt: _searchReportedToEmr,
    };

    setTotalClaimsLoading(false);
    setPageCount(0);
    setCriteria(_criteria);
  }, [searchPatient, searchUploadedToEmr, searchReportedToEmr, practice.id]);

  useEffect(() => {
    if (pageCount < 0 || !isQuerying) return;
    getClaims({
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: ENTRIES_PER_PAGE * pageCount,
      },
    });
  }, [pageCount]);

  return (
    <>
      <MainLayout
        path={[
          { text: 'Practices', to: '/practices' },
          { text: practice.name, to: `/practices/${practice.handler}` },
          { text: 'Claims to upload to EMR' },
        ]}
      >
        <Container fluid>
          <Card fluid style={{ marginTop: '1rem' }}>
            <Card.Content>
              <Card.Header as={'h3'}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Icon name="money" style={{ marginRight: '0.6rem' }} />
                    Claims to upload to EMR
                  </div>
                </div>
              </Card.Header>
              <Card.Description>
                {showInfoMsg && (
                  <Message
                    warning
                    header="Important information!"
                    content="Please click the green Search button to run the query with the selected filters."
                  />
                )}
                <Menu
                  borderless
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <Menu.Menu>
                    <Menu.Item>
                      <Input
                        placeholder="Search patient..."
                        icon="search"
                        value={searchPatient}
                        onChange={(_, { value }) => {
                          setSearchPatient(value);
                          setShowInfoMsg(true);
                        }}
                        onKeyDown={handleSearchOnKeyDown}
                      />
                    </Menu.Item>
                    <Menu.Item>
                      <Dropdown
                        style={{ minWidth: '15rem' }}
                        placeholder="Filter uploaded to EMR"
                        fluid
                        selection
                        onChange={(e, data) => {
                          handleUploadedToEmrValue(data.value as boolean);
                        }}
                        options={[
                          {
                            text: 'All',
                            value: 'all',
                          },
                          {
                            text: 'Uploaded',
                            value: false,
                          },
                          {
                            text: 'Pending',
                            value: true,
                          },
                        ]}
                      />
                    </Menu.Item>
                    <Menu.Item>
                      <Dropdown
                        style={{ minWidth: '15rem' }}
                        placeholder="Filter payment reported to EMR"
                        fluid
                        selection
                        onChange={(e, data) => {
                          handleReportedToEmrValue(data.value as boolean);
                        }}
                        options={[
                          {
                            text: 'All',
                            value: 'all',
                          },
                          {
                            text: 'Reported',
                            value: false,
                          },
                          {
                            text: 'Pending',
                            value: true,
                          },
                        ]}
                        disabled={searchUploadedToEmr === true}
                      />
                    </Menu.Item>
                  </Menu.Menu>
                  <Menu.Menu position="right">
                    <Menu.Item>
                      <Button
                        fluid
                        content="Search"
                        icon="search"
                        color="olive"
                        onClick={() => {
                          setIsQuerying(true);
                          getClaims({
                            variables: {
                              criteria,
                              limit: ENTRIES_PER_PAGE,
                              offset: ENTRIES_PER_PAGE * pageCount,
                            },
                          });
                          setShowInfoMsg(false);
                        }}
                      />
                    </Menu.Item>
                  </Menu.Menu>
                </Menu>
                {viewError ? (
                  <Message error>{viewError.message}</Message>
                ) : (
                  <div ref={billingClaimsRef}>
                    <Table selectable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Practice</Table.HeaderCell>
                          <Table.HeaderCell width={3}>Patient</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">
                            Insurance Company
                          </Table.HeaderCell>
                          <Table.HeaderCell width={4}>
                            Service Date / Provider
                          </Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">
                            CPT Codes
                          </Table.HeaderCell>
                          <Table.HeaderCell>Amount</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">
                            RHC Claim Number
                          </Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">
                            Inventory Used
                          </Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">
                            Status
                          </Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        {viewLoading ? (
                          <Table.Row>
                            <Table.Cell colSpan={10}>
                              <Segment basic>
                                <Placeholder fluid>
                                  <Placeholder.Header>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                  </Placeholder.Header>
                                </Placeholder>
                              </Segment>
                            </Table.Cell>
                          </Table.Row>
                        ) : !!viewData?.billingClaims.length ? (
                          viewData.billingClaims.map((bc) => {
                            return (
                              <ClaimsToUploadToEmrRow
                                key={bc.claimId}
                                data={bc}
                                onSave={viewRefetch}
                                paidClosedBilled={paidClosedBilled}
                              />
                            );
                          })
                        ) : (
                          <Table.Row>
                            <Table.Cell colSpan={11}>
                              <Message>
                                {!viewData
                                  ? 'Please select a filter to start'
                                  : 'No Claims Found'}
                              </Message>
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell>
                            <Button
                              basic
                              content={
                                !totalClaimsLoading
                                  ? 'Show total'
                                  : `Total: ${claimsCount?.aggregating.aggregate.count}`
                              }
                              loading={claimsCountLoading}
                              onClick={() => {
                                getClaimsCount({
                                  variables: {
                                    criteria,
                                  },
                                });
                                setTotalClaimsLoading(true);
                              }}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell colSpan={10} textAlign="right">
                            <Button
                              basic
                              icon="angle left"
                              onClick={() => setPageCount(pageCount - 1)}
                              disabled={pageCount === 0}
                            />
                            <Button
                              basic
                              icon="angle right"
                              onClick={() => setPageCount(pageCount + 1)}
                              disabled={
                                !viewData?.billingClaims.length ||
                                viewData.billingClaims.length < ENTRIES_PER_PAGE
                              }
                            />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    </Table>
                  </div>
                )}
              </Card.Description>
            </Card.Content>
          </Card>
        </Container>
      </MainLayout>
      {!!claimId && (
        <UpdateBillingClaimForm
          readonly={true}
          claimId={claimId}
          adminCptCodes={procedureAmountsData}
          onClose={() => {
            history.push(
              `/practices/${practice.handler}/claims-to-upload-to-emr`
            );
          }}
          onSave={() => {
            viewRefetch();
            history.push(
              `/practices/${practice.handler}/claims-to-upload-to-emr`
            );
          }}
        />
      )}
    </>
  );
};

//-------------------------ClaimsToUploadToEmrRow------------------------//

interface ClaimsToUploadToEmrRowProps {
  data: BillingClaimView;
  onSave: () => void;
  paidClosedBilled: string[];
}

const ClaimsToUploadToEmrRow = ({
  data,
  onSave,
  paidClosedBilled,
}: ClaimsToUploadToEmrRowProps) => {
  const [activeRowId, setActiveRowId] = useState('');
  const [uploadedToEmr, setUploadedToEmr] = useState<boolean>(
    data.claim.uploadedToEmrAt ? true : false
  );
  const [paymentReportedToEmr, setPaymentReportedToEmr] = useState<boolean>(
    data.claim.paymentReportedToEmrAt ? true : false
  );
  const [updateUploadedToEmr] = useMutation(UpdateUploadedToEmr);
  const [updatePaymentReported] = useMutation(UpdatePaymentReportedToEmr);

  const allowedStatesToReport = [
    'paid',
    'partially_paid',
    'closed',
    'partially_billed',
    'billed',
  ];

  const handleUploadedToEmr = () => {
    updateUploadedToEmr({
      variables: {
        claimId: data.claimId,
        uploadedToEmrAt: data.uploadedToEmrAt ? null : new Date(),
      },
    })
      .then((r) => {
        if (r.data.claims.uploadedToEmrAt === null) {
          toast({
            title: 'Claim upload deleted successfully',
            type: 'success',
            time: 1000,
          });
        } else {
          toast({
            title: 'Claim uploaded to EMR successfully',
            type: 'success',
            time: 1000,
          });
        }
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handlePaymentReported = () => {
    if (!allowedStatesToReport.includes(data.claim.status)) {
      return toast({
        title:
          'Only claims with status Paid, Partially Paid, Closed, Partially Billed, or Billed can be reported',
        type: 'error',
        time: 5000,
      });
    }

    if (!uploadedToEmr) {
      return toast({
        title: 'Must upload claim first',
        type: 'error',
        time: 3000,
      });
    }

    updatePaymentReported({
      variables: {
        claimId: data.claimId,
        paymentReportedToEmrAt: data.claim.paymentReportedToEmrAt
          ? null
          : new Date(),
      },
    })
      .then((r) => {
        if (r.data.claims.paymentReportedToEmrAt === null) {
          toast({
            title: 'Payment report deleted successfully',
            type: 'success',
            time: 1000,
          });
        } else {
          toast({
            title: 'Payment reported to EMR successfully',
            type: 'success',
            time: 1000,
          });
        }
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleActiveRow = (id: string) => {
    setActiveRowId(id);
  };

  useEffect(() => {
    if (!uploadedToEmr) {
      setPaymentReportedToEmr(false);
      updatePaymentReported({
        variables: {
          claimId: data.claimId,
          paymentReportedToEmrAt: null,
        },
      });
    }
  }, [uploadedToEmr]);

  const selectEOBStatusIcon = () => {
    if (data.claim.eobStatus === EobStatus.PENDING) {
      return 'clock outline';
    }
    if (data.claim.eobStatus === EobStatus.DOWNLOADED) {
      return 'download';
    }
    if (data.claim.eobStatus === EobStatus.IN_PROGRESS) {
      return 'spinner';
    }
    if (data.claim.eobStatus === EobStatus.INCORRECTLY_DOWNLOADED) {
      return 'download';
    }
    if (data.claim.eobStatus === EobStatus.NEW_SUBMITTED) {
      return 'checked calendar';
    }
    if (data.claim.eobStatus === EobStatus.WAITING_PORTAL) {
      return 'wait';
    }
    return 'x';
  };

  const selectEOBStatusIconColor = () => {
    if (data.claim.eobStatus === EobStatus.PENDING) {
      return 'grey';
    }
    if (data.claim.eobStatus === EobStatus.DOWNLOADED) {
      return 'olive';
    }
    if (data.claim.eobStatus === EobStatus.IN_PROGRESS) {
      return 'yellow';
    }
    if (data.claim.eobStatus === EobStatus.INCORRECTLY_DOWNLOADED) {
      return 'orange';
    }
    if (data.claim.eobStatus === EobStatus.NEW_SUBMITTED) {
      return 'blue';
    }
    if (data.claim.eobStatus === EobStatus.WAITING_PORTAL) {
      return 'orange';
    }
    return 'red';
  };

  const selectEOBStatusTextInfo = () => {
    const header = 'EOB';
    if (data.claim.eobStatus === EobStatus.PENDING) {
      return `${header} pending`;
    }
    if (data.claim.eobStatus === EobStatus.DOWNLOADED) {
      return `${header} downloaded`;
    }
    if (data.claim.eobStatus === EobStatus.IN_PROGRESS) {
      return `${header} in progress`;
    }
    if (data.claim.eobStatus === EobStatus.INCORRECTLY_DOWNLOADED) {
      return `${header} incorrectly downloaded`;
    }
    if (data.claim.eobStatus === EobStatus.NEW_SUBMITTED) {
      return `New Submitted`;
    }
    if (data.claim.eobStatus === EobStatus.WAITING_PORTAL) {
      return `${header} Waiting portal`;
    }
    return `${header} not found`;
  };

  useEffect(() => {
    if (!data) {
      return;
    }
  }, [data]);

  return (
    <Table.Row
      style={{
        backgroundColor:
          data.claim.uploadedToEmrAt || data.claim.paymentReportedToEmrAt
            ? '#eaf7fa'
            : null,
      }}
      positive={
        !!data.claim.uploadedToEmrAt && !!data.claim.paymentReportedToEmrAt
      }
      negative={
        !data.claim.uploadedToEmrAt && !data.claim.paymentReportedToEmrAt
      }
      active={data.claimId === activeRowId}
      onClick={() => {
        if (data.claimId === activeRowId) {
          setActiveRowId('');
        } else {
          handleActiveRow(data.claimId);
        }
      }}
    >
      <Table.Cell>
        <Popup
          on="click"
          pinned
          size="large"
          trigger={
            <StyledPopupTrigger>{data.claim.practice.name}</StyledPopupTrigger>
          }
          content={
            <List>
              <List.Item>
                <b>NPI: </b>
                {data.updateLastNpi ? data.updateLastNpi : data.practiceNpi}
              </List.Item>
              <List.Item>
                <b>Federal Tax ID: </b>
                {data.updateLastEmployerId
                  ? data.updateLastEmployerId
                  : data.practiceProfile.federalTaxId}
              </List.Item>
              <List.Item>
                <b>Provider : </b>
                {data.prescriberFirstName
                  ? `${data.prescriberFirstName} ${data.prescriberLastName}`
                  : '-'}
              </List.Item>
              <List.Item>
                <b>Address: </b>
                {`${data.claim.practice.profile.address?.street}, ${data.claim.practice.profile.address?.city} ${data.claim.practice.profile.address?.state} - ${data.claim.practice.profile.address?.zip}`}
              </List.Item>
            </List>
          }
        />
      </Table.Cell>
      <Table.Cell>
        {`${data.claim.practicePatient.patientData.firstName} ${
          data.claim.practicePatient.patientData.lastName
        } (${formatDatetimeToMMDDYYY(
          data.claim.practicePatient.patientData.birthdate
        )})`}
      </Table.Cell>
      <Table.Cell>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {data.claim.status === BillingClaimStatus.PENDING ||
          data.claim.status === BillingClaimStatus.UNCLAIMED
            ? data.insuranceName
            : data.insuranceName
              ? data.insuranceName
              : '-'}

          <div>
            <Label
              basic
              content={`Member ID: ${
                (data.claim.status === BillingClaimStatus.PENDING ||
                  data.claim.status === BillingClaimStatus.UNCLAIMED) &&
                data.insuranceMemberId
                  ? data.insuranceMemberId
                  : data.insuranceMemberId
                    ? data.insuranceMemberId
                    : '-'
              }`}
              size="tiny"
            />
            {(data.claim.status === BillingClaimStatus.PENDING ||
              data.claim.status === BillingClaimStatus.UNCLAIMED) &&
            data.insuranceVfcEligible ? (
              <Label
                content="VFC Eligible"
                size="tiny"
                color="orange"
                style={{ marginTop: '0.2rem' }}
              />
            ) : data.insuranceVfcEligible ? (
              <Label
                content="VFC Eligible"
                size="tiny"
                color="orange"
                style={{ marginTop: '0.2rem' }}
              />
            ) : null}
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div>
          {data.claim.givenAt
            ? `${formatDateToMMDDYYYYV2(String(data.claim.givenAt))} (${
                data.providerFirstName || '-'
              } ${data.providerLastName || '-'})`
            : '-'}
        </div>
      </Table.Cell>
      <Table.Cell textAlign="center">
        {data.claim.status === BillingClaimStatus.PENDING &&
        data.vaccinationsCptCodes ? (
          data.vaccinationsCptCodes.map((cpt) => {
            return (
              <div key={cpt.id}>
                <Label
                  style={{ margin: '0.1rem 0' }}
                  color={cpt.chargeAmount === null ? 'red' : 'grey'}
                >
                  <div>{`${cpt.cptCode} x${cpt.count}`}</div>
                  <div style={{ textAlign: 'center', margin: '0.2rem 0' }}>
                    {cpt.chargeAmount !== null ? `$${cpt.chargeAmount}` : '-'}
                  </div>
                </Label>
              </div>
            );
          })
        ) : !!data.vaccinationsCptCodes && data.vaccinationsCptCodes.length ? (
          data.vaccinationsCptCodes.map((item) => {
            return (
              <Label
                key={item.id}
                basic={
                  data.status === BillingClaimStatus.DENIED && !item.paidAmount
                }
                color={
                  item.paidAmount
                    ? 'olive'
                    : data.status === BillingClaimStatus.DENIED &&
                        !item.paidAmount
                      ? 'red'
                      : 'grey'
                }
                content={item.cptCode}
                size="small"
                style={{ margin: '0.1rem 0' }}
              />
            );
          })
        ) : (
          <Popup
            trigger={
              <Icon name="warning sign" size="large" color="orange" inverted />
            }
            content="Vaccinations were removed or there is an error with cpt codes."
          />
        )}
      </Table.Cell>
      <Table.Cell>
        {data.status === BillingClaimStatus.PENDING &&
        data.vaccinationsCptCodes ? (
          '$' +
          data.vaccinationsCptCodes.reduce((accum, cpt) => {
            return (
              accum +
              (cpt.chargeAmount === null ? 0 : cpt.chargeAmount * cpt.count)
            );
          }, 0)
        ) : (
          <>
            {data.claim.totalClaimAmount
              ? `$${data.claim.totalClaimAmount.toFixed(2)}`
              : '-'}
          </>
        )}
      </Table.Cell>
      <Table.Cell collapsing textAlign="center">
        {data.rhcClaimNumber ? data.rhcClaimNumber : '-'}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {!!data.inventoryVfcCount && (
          <Label
            style={{ marginBottom: '0.2rem' }}
            size="small"
            content={`VFC: ${data.inventoryVfcCount}`}
            color="orange"
          />
        )}
        {!!data.inventoryPrivateCount && (
          <Label
            style={{ marginBottom: '0.2rem' }}
            size="small"
            content={`Private: ${data.inventoryPrivateCount}`}
            color="teal"
          />
        )}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Label
          basic
          color="blue"
          content={humanizeText(data.status, {
            capitalize: 'first',
            delimiter: '_',
          })}
        />
      </Table.Cell>
      <Table.Cell>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {data.claim.note?.note && (
            <Popup
              size="small"
              content={`Note: ${data.claim.note?.note}`}
              trigger={<Icon name="info circle" size="large" color="teal" />}
            />
          )}
          {data.dismissedReason && (
            <Popup
              size="small"
              content={`Dismissed reason: ${humanizeText(data.dismissedReason, {
                delimiter: '_',
              })}`}
              trigger={<Icon name="info circle" size="large" color="orange" />}
            />
          )}

          {(data.status === BillingClaimStatus.ACCEPTED ||
            data.status === BillingClaimStatus.ACKNOWLEDGED) &&
          data.eobStatus ? (
            <Popup
              size="small"
              content={selectEOBStatusTextInfo()}
              trigger={
                <Icon
                  name={selectEOBStatusIcon()}
                  color={selectEOBStatusIconColor()}
                  size="large"
                />
              }
            />
          ) : null}
          <Popup
            content="Edit"
            size="small"
            trigger={
              <div>
                <EmrClaimNumberForm
                  claimId={data.claimId}
                  emrClaimNumber={data.claim.emrClaimNumber}
                  onSave={onSave}
                  trigger={<Button primary size="tiny" icon="edit" />}
                />
              </div>
            }
          />
          <Popup
            content="View Claim"
            size="small"
            trigger={
              <Button
                primary
                className="min-component-vertical-margin"
                icon="eye"
                size="tiny"
                as={Link}
                to={`./claims-to-upload-to-emr/${data.claimId}`}
              />
            }
          />
          <Popup
            position="left center"
            on="click"
            trigger={
              <Icon
                name="info circle"
                size="large"
                color="grey"
                style={{ cursor: 'pointer' }}
              />
            }
            content={
              <List
                style={{
                  maxHeight: '50rem',
                  overflowY: 'auto',
                  padding: '1rem',
                }}
              >
                <List.Item>
                  <List.Header>EMR claim number</List.Header>
                  {data.claim.emrClaimNumber || '-'}
                </List.Item>
                {data.vaccinationsCptCodes?.map((v) => {
                  return (
                    <Card>
                      <List>
                        <List.Item>
                          <List.Header>CPT code</List.Header>
                          {v.cptCode || '-'}
                        </List.Item>
                        <List.Item>
                          <List.Header>Claim amount</List.Header>
                          {v.claimAmount ? `$${v.claimAmount}` : '-'}
                        </List.Item>
                        <List.Item>
                          <List.Header>Paid amount</List.Header>
                          {v.paidAmount ? `$${v.paidAmount}` : '-'}
                        </List.Item>
                      </List>
                    </Card>
                  );
                })}
              </List>
            }
          />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Checkbox
            checked={uploadedToEmr === undefined ? undefined : uploadedToEmr}
            onClick={() => handleUploadedToEmr()}
            onChange={(_, { checked }) => setUploadedToEmr(!!checked)}
          />
          <StyledLabel>Uploaded to EMR</StyledLabel>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <Checkbox
            checked={
              paymentReportedToEmr === undefined
                ? undefined
                : paymentReportedToEmr
            }
            onClick={() => handlePaymentReported()}
            onChange={(_, { checked }) => setPaymentReportedToEmr(!!checked)}
            disabled={
              !uploadedToEmr ||
              !allowedStatesToReport.includes(data.claim.status)
            }
          />
          <StyledLabel>Reported to EMR</StyledLabel>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

const StyledPopupTrigger = styled.div`
  font-weight: 700;

  &:hover {
    color: #a2a2a2;
    cursor: pointer;
  }
`;

const StyledLabel = styled.label`
  margin-left: 0.5rem;
`;

export default ClaimsToUploadToEmrScreen;
