import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  InsertBillingStrategyMutation,
  UpdateBillingStrategyMappingMutation,
} from '@graphql/mappings';
import { BillingStrategy } from '@bluefox/models/Mappings';
import { BillingStrategyOptions } from '@bluefox/models/Mappings';
import { Button, Checkbox, Form } from 'semantic-ui-react';
import InsuranceCompanyPicker from '@bluefox/ui/InsuranceCompanyPicker';
import ProviderPicker from '@bluefox/ui/ProviderPicker';
import { useApplicationState, usePractice } from '@bluefox/contexts';
import { PracticeQuery } from '@graphql/practices';
import { BillingClaimsMappingType } from '@bluefox/models/Billing';
import { GetBillingStrategyIndividualBillingRenderingProvider } from '@bluefox/graphql/mappings';

interface BillingStrategyMappingFormProps {
  close: () => void;
  data: BillingStrategy | null;
  billingStrategy?: string;
  onSave: () => void;
  allowedUsersToEditNpiAndTaxId?: string[];
}

type BillingStrategyWithUndefinedId = {
  id?: string;
  cpid?: string;
  type?: string;
  enabled?: boolean;
  practiceId?: string;
  providerId?: string | null;
  insuranceCompanyId?: string | null;
  taxId?: string;
  npi?: string;
  strategy?: string;
}; //Deuda técnica / TODO -> Importar este type cambiando de lugar el de insurance mappings

const BillingStrategyMappingForm = ({
  close,
  data,
  billingStrategy,
  onSave,
  allowedUsersToEditNpiAndTaxId,
}: BillingStrategyMappingFormProps) => {
  const practice = usePractice();
  const { session } = useApplicationState();

  const [providerId, setProviderId] = useState<string | undefined>(
    data?.providerId || ''
  );
  const [insuranceId, setInsuranceId] = useState<string>(
    data?.insuranceCompanyId || ''
  );
  const [cpid, setCpid] = useState<string>(data?.payerId || '');
  const [taxId, setTaxId] = useState<string>(data?.taxId || '');
  const [npi, setNpi] = useState<string>(data?.npi || '');
  const [individualBillingProvider, setIndividualBillingProvider] = useState<
    BillingStrategyWithUndefinedId | undefined
  >({ enabled: false });
  const [individualRenderingProvider, setIndividualRenderingProvider] =
    useState<BillingStrategyWithUndefinedId | undefined>({ enabled: false });
  const [selectedProviderProfile, setSelectedProviderProfile] = useState(null);

  const isAllowedToEditNpiAndTaxId = allowedUsersToEditNpiAndTaxId
    ? allowedUsersToEditNpiAndTaxId.includes(session?.account?.email || '')
    : false;

  const [getIndividualBillingRenderingProvider] = useLazyQuery(
    GetBillingStrategyIndividualBillingRenderingProvider,
    {
      onCompleted(data) {
        let individualBillingProviderChanged = false;
        let individualRenderingProviderChanged = false;
        if (data.mappings.length > 0) {
          data.mappings.forEach((mapping: BillingStrategy) => {
            if (
              mapping.type ===
              BillingClaimsMappingType.INDIVIDUAL_BILLING_PROVIDER
            ) {
              individualBillingProviderChanged = true;
              setIndividualBillingProvider({
                enabled: mapping.enabled,
                id: mapping.id,
              });
            } else if (
              mapping.type ===
              BillingClaimsMappingType.INDIVIDUAL_RENDERING_PROVIDER
            ) {
              individualRenderingProviderChanged = true;
              setIndividualRenderingProvider({
                enabled: mapping.enabled,
                id: mapping.id,
              });
            }
          });
        }
        if (!individualBillingProviderChanged) {
          setIndividualBillingProvider({
            enabled: false,
          });
        }
        if (!individualRenderingProviderChanged) {
          setIndividualRenderingProvider({
            enabled: false,
          });
        }
      },
    }
  );

  const [saveBillingStrategyMapping, { loading }] = useMutation(
    data?.id
      ? UpdateBillingStrategyMappingMutation(
          billingStrategy === BillingStrategyOptions.PRACTICE
        )
      : InsertBillingStrategyMutation
  );

  const pushNewMappingArray = useCallback(
    (mapping, mappingArray, type) => {
      let mappingToPush: BillingStrategyWithUndefinedId = {};
      switch (billingStrategy) {
        case BillingStrategyOptions.PROVIDER:
          mappingToPush = {
            providerId,
          };
          break;
        case BillingStrategyOptions.PRACTICE_AND_INSURANCE:
          mappingToPush = {
            cpid,
            insuranceCompanyId: insuranceId,
          };
          break;
        case BillingStrategyOptions.PROVIDER_AND_INSURANCE:
          mappingToPush = {
            providerId,
            insuranceCompanyId: insuranceId,
            cpid,
          };
          break;
        default:
          return;
      }

      mappingToPush = {
        ...mappingToPush,
        practiceId: practice.id,
        strategy: billingStrategy,
        type,
        enabled: mapping.enabled,
        ...(mapping?.id ? { id: mapping.id } : {}),
      };
      if (!mapping.enabled && mapping.id) {
        mappingArray.push(mappingToPush);
      } else if (mapping.enabled) {
        mappingArray.push(mappingToPush);
      }
    },
    [billingStrategy, cpid, insuranceId, practice.id, providerId]
  );

  const handleSubmit = async () => {
    const newClaimMappings = [] as BillingStrategyWithUndefinedId[];
    pushNewMappingArray(
      individualBillingProvider,
      newClaimMappings,
      BillingClaimsMappingType.INDIVIDUAL_BILLING_PROVIDER
    );
    pushNewMappingArray(
      individualRenderingProvider,
      newClaimMappings,
      BillingClaimsMappingType.INDIVIDUAL_RENDERING_PROVIDER
    );
    !data?.id &&
      npi &&
      taxId &&
      newClaimMappings.push({
        practiceId: practice.id,
        providerId: providerId ? providerId : null,
        cpid,
        insuranceCompanyId: insuranceId ? insuranceId : null,
        taxId,
        npi,
        strategy: billingStrategy,
        type: BillingClaimsMappingType.NPI_TAXID,
      });

    try {
      await saveBillingStrategyMapping({
        variables: {
          claimMappings: newClaimMappings,
          id: data?.id,
          practiceId: practice.id,
          providerId: providerId ? providerId : null,
          cpid: cpid ? cpid : null,
          insuranceId: insuranceId ? insuranceId : null,
          taxId,
          npi,
          strategy: billingStrategy,
          practicePofileTaxId:
            billingStrategy === BillingStrategyOptions.PRACTICE
              ? { federalTaxId: taxId }
              : {},
          practiceCriteria:
            billingStrategy === BillingStrategyOptions.PRACTICE ? { npi } : {},
          practiceAccountPofileTaxId:
            billingStrategy === BillingStrategyOptions.PROVIDER
              ? { federalTaxId: taxId }
              : {},
          accountCriteria:
            billingStrategy === BillingStrategyOptions.PROVIDER ? npi : {},
          profile: selectedProviderProfile
            ? selectedProviderProfile
            : {
                federalTaxId: taxId,
              },
        },
        refetchQueries: [
          {
            query: PracticeQuery,
            variables: {
              id: practice.id,
            },
          },
        ],
      });

      toast({
        title: 'Mapping saved successfully',
        type: 'success',
        time: 1000,
      });
      onSave();
      close();
    } catch (error) {
      toast({
        title: `Mapping could not be saved. Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  };

  const renderToggle = useCallback(
    (
      disabled: boolean,
      label: string,
      enable: boolean | undefined,
      setEnable: any,
      isObject: boolean
    ) => {
      return (
        <Form.Field disabled={disabled}>
          <Checkbox
            disabled={disabled}
            label={label}
            toggle
            checked={enable}
            onChange={(_, { checked }) => {
              isObject
                ? setEnable((prev: BillingStrategyWithUndefinedId) => {
                    return {
                      ...prev,
                      enabled: !!checked,
                    };
                  })
                : setEnable(!!checked);
            }}
          />
        </Form.Field>
      );
    },
    []
  );

  useEffect(() => {
    if (billingStrategy === BillingStrategyOptions.PRACTICE) return;
    let criteria;
    switch (billingStrategy) {
      case BillingStrategyOptions.PROVIDER:
        criteria = {
          practiceId: {
            _eq: practice.id,
          },
          strategy: {
            _eq: billingStrategy,
          },
          providerId: {
            _eq: providerId,
          },
          insuranceCompanyId: {
            _is_null: true,
          },
          type: {
            _in: [
              BillingClaimsMappingType.INDIVIDUAL_BILLING_PROVIDER,
              BillingClaimsMappingType.INDIVIDUAL_RENDERING_PROVIDER,
            ],
          },
        };
        break;
      case BillingStrategyOptions.PRACTICE_AND_INSURANCE:
        criteria = {
          practiceId: {
            _eq: practice.id,
          },
          strategy: {
            _eq: billingStrategy,
          },
          providerId: {
            _is_null: true,
          },
          cpid: {
            _eq: cpid,
          },
          type: {
            _in: [
              BillingClaimsMappingType.INDIVIDUAL_BILLING_PROVIDER,
              BillingClaimsMappingType.INDIVIDUAL_RENDERING_PROVIDER,
            ],
          },
        };
        break;
      case BillingStrategyOptions.PROVIDER_AND_INSURANCE:
        criteria = {
          practiceId: {
            _eq: practice.id,
          },
          strategy: {
            _eq: billingStrategy,
          },
          providerId: {
            _eq: providerId,
          },
          cpid: {
            _eq: cpid,
          },
          type: {
            _in: [
              BillingClaimsMappingType.INDIVIDUAL_BILLING_PROVIDER,
              BillingClaimsMappingType.INDIVIDUAL_RENDERING_PROVIDER,
            ],
          },
        };
        break;
    }

    getIndividualBillingRenderingProvider({
      variables: {
        criteria,
      },
    });
  }, [
    billingStrategy,
    cpid,
    getIndividualBillingRenderingProvider,
    insuranceId,
    practice.id,
    providerId,
  ]);

  return (
    <>
      <Form onSubmit={handleSubmit} data-automation-id="vaccine-mapping-form">
        <Form.Group widths="equal">
          {billingStrategy === BillingStrategyOptions.PROVIDER ||
          billingStrategy === BillingStrategyOptions.PROVIDER_AND_INSURANCE ? (
            <Form.Field>
              <label>Provider</label>
              <ProviderPicker
                defaultValue={providerId}
                placeholder="Select Provider"
                onChange={(providerId) => setProviderId(providerId)}
                onProviderSelected={(provider) => {
                  setSelectedProviderProfile(provider.profile);
                }}
              />
            </Form.Field>
          ) : null}
          {billingStrategy === BillingStrategyOptions.PRACTICE_AND_INSURANCE ||
          billingStrategy === BillingStrategyOptions.PROVIDER_AND_INSURANCE ? (
            <Form.Field>
              <label>Insurance Company</label>
              <InsuranceCompanyPicker
                placeholder="Insurance Name"
                value={insuranceId}
                onCompanySelected={(company) => {
                  setCpid(company.cpid || '');
                  setInsuranceId(company.id);
                }}
                includePayerId
              />
            </Form.Field>
          ) : null}
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              value={taxId}
              onChange={(_, { value }) => setTaxId(value)}
              fluid
              label="Tax ID"
              placeholder="Tax ID"
              required={taxId.length > 0 || npi.length > 0}
              disabled={!isAllowedToEditNpiAndTaxId}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              value={npi}
              onChange={(_, { value }) => setNpi(value)}
              fluid
              label="NPI"
              placeholder="NPI"
              required={taxId.length > 0 || npi.length > 0}
              disabled={!isAllowedToEditNpiAndTaxId}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          {renderToggle(
            !isAllowedToEditNpiAndTaxId,
            'Individual Billing Provider',
            individualBillingProvider?.enabled,
            setIndividualBillingProvider,
            true
          )}
          {renderToggle(
            !isAllowedToEditNpiAndTaxId,
            'Individual Rendering Provider',
            individualRenderingProvider?.enabled,
            setIndividualRenderingProvider,
            true
          )}
        </Form.Group>
        <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={close} type="button">
            Cancel
          </Button>
          <Button
            primary
            type="submit"
            content="Save"
            icon="save"
            disabled={
              (taxId.length > 0 && !npi.length) ||
              (npi.length > 0 && !taxId.length) ||
              loading ||
              (!individualBillingProvider?.id &&
                !individualBillingProvider?.enabled &&
                !individualRenderingProvider?.id &&
                !individualRenderingProvider?.enabled &&
                !npi &&
                !taxId)
            }
          />
        </Form.Field>
      </Form>
    </>
  );
};

export default BillingStrategyMappingForm;
