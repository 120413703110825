import { useCallback, useMemo, useState } from 'react';
import {
  AdjustmentReason,
  AdjustmentReasons,
  InventoryAdjustmentDetail,
  InventoryAdjustmentDetailStatuses,
  InventoryAdjustmentStatus,
} from '@bluefox/models/InventoryAdjustment';
import { Button, Popup, Segment, Table } from 'semantic-ui-react';
import InventoryAdjustmentTableRowForm, {
  FormValues,
} from './InventoryAdjustmentTableRowForm';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';
import { toast } from 'react-semantic-toasts';

interface InventoryAdjustmentTableRowProps {
  detail: InventoryAdjustmentDetail;
  statuses: InventoryAdjustmentStatus[];
  onSelectDetail: () => void;
  adjustmentApplied: boolean;
  updateAdjustmentDetails: (
    detailOrDetails: InventoryAdjustmentDetail | InventoryAdjustmentDetail[]
  ) => void;
  adjustmentReasons?: AdjustmentReason[];
}
const InventoryAdjustmentTableRow = ({
  detail,
  statuses,
  onSelectDetail,
  adjustmentApplied,
  updateAdjustmentDetails,
  adjustmentReasons,
}: InventoryAdjustmentTableRowProps) => {
  const [selected, setSelected] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({} as FormValues);
  const [edition, setEdition] = useState(false);
  const [rejection, setRejection] = useState(false);

  const handleEditionSubmit = useCallback(
    (formValues: FormValues) => {
      if (
        formValues.newDoses === undefined ||
        !isStringNotEmpty(formValues.reason)
      ) {
        toast({
          title: 'Please set a new doses amount and select a Reason',
          type: 'error',
          time: 2000,
        });
        return;
      }
      updateAdjustmentDetails({
        ...detail,
        selected: undefined,
        operatorAmmend: {
          previousDoses: detail.originalNewDoses,
          newDoses: formValues.newDoses,
          reason: formValues.reason,
        },
        operatorRejection: undefined,
        newDoses: formValues.newDoses,
        reason: AdjustmentReasons.OTHER,
      });
      setEdition(false);
    },
    [detail, updateAdjustmentDetails]
  );

  const handleRejection = useCallback(
    (formValues: FormValues) => {
      if (!isStringNotEmpty(formValues.reason)) {
        toast({
          title: 'Please set a new doses amount and select a Reason',
          type: 'error',
          time: 2000,
        });
        return;
      }
      updateAdjustmentDetails({
        ...detail,
        selected: undefined,
        operatorAmmend: undefined,
        operatorRejection: {
          previousDoses: detail.originalNewDoses,
          reason: formValues.reason,
          newDoses: detail.currentDoses,
        },
        newDoses: detail.currentDoses,
        reason: AdjustmentReasons.OTHER,
      });
      setRejection(false);
    },
    [detail, updateAdjustmentDetails]
  );

  const getStatus = (
    statusValue: InventoryAdjustmentDetailStatuses
  ): string => {
    const status = statuses.find((status) => status.value === statusValue);
    return status?.text || '-';
  };

  const detailApplied = useMemo(
    () => detail.status === InventoryAdjustmentDetailStatuses.APPLIED,
    [detail.status]
  );

  const adjustmentReasonText = useMemo(() => {
    if (detail.reason) {
      const reason = adjustmentReasons?.find(
        (reason) => reason.value === detail.reason
      );
      return reason?.text || '-';
    }
    return '-';
  }, [adjustmentReasons, detail.reason]);

  return (
    <>
      <Table.Row key={detail.id}>
        <Table.Cell>
          {detail.inventory?.vaccine?.name}
          {detail.operatorAmmend &&
            ` (AMENDED. Practice reported ${detail.operatorAmmend?.previousDoses} doses. Adjusted to ${detail.operatorAmmend?.newDoses} doses. Reason: ${detail.operatorAmmend?.reason})`}
          {detail.operatorRejection &&
            ` (REJECTED. Practice reported ${detail.operatorRejection?.previousDoses} doses. Reason: ${detail.operatorRejection?.reason})`}
          {detail.status === InventoryAdjustmentDetailStatuses.APPLIED &&
            !detail.operatorAmmend &&
            !detail.operatorRejection &&
            ' (APPROVED)'}
        </Table.Cell>
        <Table.Cell>{detail.inventory?.lot}</Table.Cell>
        <Table.Cell>{detail.currentDoses}</Table.Cell>
        <Table.Cell>{detail.newDoses}</Table.Cell>
        <Table.Cell>{getStatus(detail.status)}</Table.Cell>
        <Table.Cell textAlign="center">
          <Button
            size="mini"
            disabled={adjustmentApplied || detailApplied}
            color={!selected ? 'teal' : 'red'}
            onClick={() => {
              setSelected((prevValue) => !prevValue);
              setEdition(false);
              setRejection(false);
              onSelectDetail();
            }}
            icon={selected ? 'undo' : 'check'}
          />

          {(detail.comment || detail.reason !== AdjustmentReasons.OTHER) && (
            <Popup
              trigger={
                <Button size="mini" color="teal" icon="comment alternate" />
              }
              content={`[${adjustmentReasonText}] ${detail.comment || '-'}`}
              on="click"
              pinned
            />
          )}
          <Button
            size="mini"
            disabled={adjustmentApplied || detailApplied}
            onClick={() => {
              setSelected(false);
              setFormValues(
                detail.operatorAmmend
                  ? ({
                      newDoses: detail.operatorAmmend.newDoses,
                      reason: detail.operatorAmmend.reason,
                    } as FormValues)
                  : ({} as FormValues)
              );
              setEdition((prevValue) => !prevValue);
              setRejection(false);
            }}
            color={edition || detail?.operatorAmmend ? 'red' : 'teal'}
            icon="edit"
          />
          <Button
            size="mini"
            disabled={adjustmentApplied || detailApplied}
            icon="cancel"
            onClick={() => {
              setFormValues(
                detail.operatorRejection
                  ? ({
                      reason: detail.operatorRejection.reason,
                    } as FormValues)
                  : ({} as FormValues)
              );
              setSelected(false);
              setEdition(false);
              setRejection((prevValue) => !prevValue);
            }}
            color={rejection || detail?.operatorRejection ? 'red' : 'teal'}
          />
          {detail.statusLog && (
            <Popup
              trigger={<Button size="mini" color="teal" icon="file code" />}
              on="click"
              pinned
              wide="very"
              position="left center"
            >
              <Segment
                color="teal"
                raised
                style={{
                  overflow: 'auto',
                  maxHeight: '40vh',
                  maxWidth: '40hh',
                }}
              >
                <pre>{JSON.stringify(detail.statusLog, null, 2)}</pre>
              </Segment>
            </Popup>
          )}
        </Table.Cell>
      </Table.Row>
      {(edition || rejection) && (
        <Table.Row key={`expanded-${detail.id}`}>
          <Table.Cell colSpan={6}>
            <InventoryAdjustmentTableRowForm
              title={edition ? 'Edit' : 'Reject'}
              showNewDoses={edition}
              formValues={formValues}
              setFormValues={setFormValues}
              onSubmit={(formValues) => {
                edition
                  ? handleEditionSubmit(formValues)
                  : handleRejection(formValues);
              }}
              onCancel={() => {
                setEdition(false);
                setRejection(false);
              }}
            />
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};

export default InventoryAdjustmentTableRow;
