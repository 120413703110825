import { renderToStaticMarkup } from 'react-dom/server';

export function reactElementToText(element: React.ReactElement): string {
  try {
    // Convert element to HTML text
    const elementAsText = renderToStaticMarkup(element);

    // Remove HTML tags
    const notificationMessageAsText = elementAsText.replace(/<[^>]+>/g, '');

    // HTML decode
    const doc = new DOMParser().parseFromString(
      notificationMessageAsText,
      'text/html'
    );
    const textContent = doc.documentElement.textContent;

    return textContent;
  } catch (error) {
    return '';
  }
}
