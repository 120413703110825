import { gql } from '@apollo/client';

export const InsertTicketMutation = gql`
  mutation InsertTicket($detail: jsonb, $status: String, $type: String) {
    insert_ticketing_tickets_one(
      object: { status: $status, type: $type, detail: $detail }
    ) {
      id
    }
  }
`;

export const CreateTicketMutation = gql`
  mutation CreateTicketMutation($ticket: ticketing_tickets_insert_input!) {
    insert_ticketing_tickets_one(object: $ticket) {
      id
    }
  }
`;

export const CreateTicketMutationForBorrowingIssue = (
  redundant: boolean
) => gql`
  mutation CreateTicketMutationForBorrowingIssue($ticket: ticketing_tickets_insert_input!, ${
    redundant ? '$idsArray: [uuid!]' : ''
  }) {
  insert_ticketing_tickets_one(object: $ticket) {
    id
  }
  ${
    redundant
      ? `update_billing_claim_vfc_inconsistencies(
      where: { id: { _in: $idsArray } }
      _set: { status: pending, redundantWithId: null, readyToBeSwapped: false }
    ) {
      affected_rows
    }`
      : ``
  }
}
`;

export const DeleteTicketMutation = gql`
  mutation DeleteTicketMutation($ticketId: uuid!) {
    delete_ticketing_tickets_by_pk(id: $ticketId) {
      id
    }
  }
`;

export const UpdateTicketMutation = gql`
  mutation UpdateTicketMutation($ticketId: uuid!, $detail: jsonb) {
    update_ticketing_tickets_by_pk(
      pk_columns: { id: $ticketId }
      _set: { detail: $detail }
    ) {
      id
      detail
    }
  }
`;

export const TicketQuery = gql`
  query TicketQuery(
    $limit: Int
    $offset: Int
    $status: [String!]
    $type: [String!]
    $practiceFilter: jsonb_comparison_exp
  ) {
    tickets: ticketing_tickets(
      order_by: { createdAt: desc }
      where: {
        _and: {
          status: { _in: $status }
          type: { _in: $type }
          detail: $practiceFilter
        }
      }
      limit: $limit
      offset: $offset
    ) {
      detail
      id
      status
      type
      createdAt
      ticketsFiles {
        fileId
        id
      }
      practiceAccount {
        account {
          firstName
          lastName
          email
        }
      }
    }
    aggregating: ticketing_tickets_aggregate(
      where: {
        _and: {
          status: { _in: $status }
          type: { _in: $type }
          detail: $practiceFilter
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const practiceProfileTicketQuery = gql`
  query practiceProfileTicketQuery(
    $limit: Int
    $offset: Int
    $practiceFilter: jsonb_comparison_exp
  ) {
    tickets: ticketing_tickets(
      order_by: { createdAt: desc }
      where: { _and: { status: { _neq: "closed" }, detail: $practiceFilter } }
      limit: $limit
      offset: $offset
    ) {
      detail
      id
      status
      type
      createdAt
      ticketsFiles {
        fileId
        id
      }
    }
    aggregating: ticketing_tickets_aggregate(
      where: { _and: { detail: $practiceFilter, status: { _neq: "closed" } } }
      order_by: { createdAt: desc }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const UpdateTicketStatus = gql`
  mutation TicketMutation($id: uuid!, $status: String) {
    update_ticketing_tickets_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      id
    }
  }
`;

export const insertTicketsFiles = gql`
  mutation insertTicketsFiles(
    $objects: [ticketing_tickets_files_insert_input!]!
  ) {
    insert_ticketing_tickets_files(objects: $objects) {
      returning {
        fileId
        id
        ticketId
      }
    }
  }
`;

export const VaccineRequestsQuery = gql`
  query VaccineRequestsQuery($limit: Int, $offset: Int) {
    vaccineRequests: ticketing_tickets(
      where: { type: { _eq: "Vaccine_order" }, status: { _eq: "requested" } }
      limit: $limit
      offset: $offset
    ) {
      id
      type
      status
      detail
    }
    aggregating: ticketing_tickets_aggregate(
      where: { type: { _eq: "Vaccine_order" }, status: { _eq: "requested" } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const VaccineRequestByPracticeQuery = gql`
  query VaccineRequestByPracticeQuery($practiceFilter: jsonb_comparison_exp) {
    vaccineRequests: ticketing_tickets(
      where: {
        type: { _eq: "Vaccine_order" }
        status: { _eq: "requested" }
        detail: $practiceFilter
      }
    ) {
      id
      type
      status
      detail
    }
  }
`;

export const InsertVaccineRequestsMutation = gql`
  mutation InsertVaccineRequestsMutation(
    $objects: [ticketing_tickets_insert_input!]!
  ) {
    insert_ticketing_tickets(objects: $objects) {
      affected_rows
    }
  }
`;

export const UpdateBorrowingClosedAndRemovedTicketsWasReadByPp = gql`
  mutation UpdateBorrowingClosedAndRemovedTicketsWasReadByPp($id: uuid!) {
    update_ticketing_tickets_by_pk(
      pk_columns: { id: $id }
      _set: { wasReadByPp: true }
    ) {
      id
    }
  }
`;

export const UpdateBorrowingClosedAndRemovedTicketsWasReadByPpMany = gql`
  mutation UpdateBorrowingClosedAndRemovedTicketsWasReadByPpMany(
    $idArray: [uuid!]
  ) {
    update_ticketing_tickets(
      where: { id: { _in: $idArray } }
      _set: { wasReadByPp: true }
    ) {
      affected_rows
    }
  }
`;

export const AppendTicketDetail = gql`
  mutation AppendTicketDetail($detail: jsonb, $id: uuid!) {
    ticket: update_ticketing_tickets_by_pk(
      pk_columns: { id: $id }
      _append: { detail: $detail }
    ) {
      id
      detail
    }
  }
`;

export const GetTickets = (ticketsForPP: boolean = true) => gql`
  query GetTickets(
    $criteria: ticketing_tickets_bool_exp = {}
    $limit: Int
    $offset: Int
  ) {
    tickets: ticketing_tickets_order_vw(order_by: {${
      ticketsForPP ? 'orderForPP: asc' : 'orderForIT: asc'
    }},
      where: {
        ticket: $criteria
      }
      limit: $limit
      offset: $offset
      ) {
        ticket{
          createdAt
          detail
          id
          type
          wasReadByPp
          status
          ticketsFiles {
            fileId
            id
          }
          practiceAccount {
            account {
              firstName
              lastName
              email
            }
          }
        }
      }

    aggregating: ticketing_tickets_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;
