import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import VaccinesPriceListTableRow from './VaccinesPriceListTableRow';
import { VaccinePricingData } from '@screens/vaccines/VaccinesPriceList';

type VaccinesPriceListTableProps = {
  vaccines?: VaccinePricingData[];
};

const VaccinesPriceListTable = ({ vaccines }: VaccinesPriceListTableProps) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const onRowClick = (index: number) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={4}>Vaccine</Table.HeaderCell>
          <Table.HeaderCell width={2}>NDC</Table.HeaderCell>
          <Table.HeaderCell>CPT Code</Table.HeaderCell>
          <Table.HeaderCell>Manufacturer</Table.HeaderCell>
          <Table.HeaderCell width={3}>Package</Table.HeaderCell>
          <Table.HeaderCell>Catalogue Price</Table.HeaderCell>
          <Table.HeaderCell>Starting Date</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {vaccines && vaccines.length > 0 ? (
          vaccines?.map((v, index) => (
            <VaccinesPriceListTableRow
              key={v.id}
              vaccine={v}
              index={index}
              activeIndex={activeIndex}
              onRowClick={() => onRowClick(index)}
            />
          ))
        ) : (
          <Table.Row>
            <Table.Cell>No data</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default VaccinesPriceListTable;
