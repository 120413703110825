export type Message = {
  id?: string;
  date: string | Date;
  title: string;
  message: string;
  position?: 'left' | 'right';
};

export enum Source {
  IT = 'IT',
  PP = 'PP',
}
