import {
  Notification,
  NotificationCommentSources,
} from '@bluefox/models/Notification';
import Chat, { ChatHandle, ChatMessage } from '@bluefox/ui/Chat';
import { Message } from '@bluefox/ui/Chat/types';
import { useEffect, useMemo, useRef } from 'react';
import { Button, Modal } from 'semantic-ui-react';

type Props = {
  open: boolean;
  notification?: Notification;
  source: NotificationCommentSources;
  onClose: () => void;
  onSendNewMessage?: (message: string) => void;
  replaceNameForDifferentOrigin?: string;
  children?: React.ReactElement;
  isShownInExtensionWindow?: boolean;
};

const CommentsModal = ({
  open,
  onClose,
  notification,
  source,
  onSendNewMessage,
  replaceNameForDifferentOrigin,
  children,
  isShownInExtensionWindow,
}: Props) => {
  const chatRef = useRef<ChatHandle>(null);

  const messages = useMemo(
    () =>
      notification?.comments?.map(
        (comment) =>
          ({
            message: {
              id: window.crypto.randomUUID(),
              date: comment.date,
              title:
                replaceNameForDifferentOrigin && comment.origin !== source
                  ? replaceNameForDifferentOrigin
                  : comment.name,
              message: comment.message,
            } as Message,
            position: comment.origin === source ? 'right' : 'left',
          }) as ChatMessage
      ) || ([] as ChatMessage[]),
    [notification?.comments, source]
  );

  // Due to onOpen event is not working properly in this modal, there is no other chance to use a useEffect to manage the onOpen modal event.
  useEffect(() => {
    if (open) {
      chatRef.current?.scrollDown();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
      style={isShownInExtensionWindow ? { marginTop: '2.5rem' } : {}}
    >
      <Modal.Header>Comments</Modal.Header>
      <Modal.Content>
        {children}
        <Chat
          messages={messages as ChatMessage[]}
          onSendNewMessage={onSendNewMessage}
          isShownInExtensionWindow={isShownInExtensionWindow}
          ref={chatRef}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CommentsModal;
