import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Icon,
  Label,
  Popup,
  Select,
  SemanticCOLORS,
  Table,
} from 'semantic-ui-react';
import {
  VFCInconsistency,
  VFCInconsistencyStatuses,
  VFCInconsistencyTypes,
} from '@bluefox/models/VFCInconsistency';
import { Enum } from '@bluefox/models/Generics';
import BorrowingInfoBox from './BorrowingInfoBox';
import { BorrowingNotes } from './BorrowingNotes';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import BorrowingRedundantWithBox from '@bluefox/ui/Borrowing/BorrowingRedundantWithBox';
import { toast } from 'react-semantic-toasts';
import { Ticket } from '@bluefox/models/Tickets';
import BorrowingIssueForm from './BorrowingIssueForm';
import BorrowingReportCodeModal from '@bluefox/ui/Borrowing/BorrowingReportCodeModal';
import { humanizeText } from '@bluefox/lib/humanize';
import { StateKey } from '@bluefox/constants/vaccineBorrowingReportCodes';
import BorrowingChatModal from '@bluefox/ui/Borrowing/BorrowingChatModal';
import { ReadyToBeSwappedMutation } from '@bluefox/graphql/billing';
import { useMutation } from '@apollo/client';
import { Source } from '@bluefox/ui/Chat/types';
import { chatHeaderForBorrowingIssue } from '@bluefox/ui/Borrowing/borrowingFunctions';
import { AppendTicketDetail } from '@bluefox/graphql/tickets';

interface InconsistenciesTableRowProps {
  inconsistency: VFCInconsistency;
  inconsistencyStatuses: Enum[];
  inconsistencyTypes: Enum[];
  updateInconsistencyTypeAndStatusMutation: any;
  openConfirmationModalAndAsignRedundantIds?: any;
  onSetInconsistencyType: (inconsistency: VFCInconsistency) => void;
  adjustmentApplied: boolean;
  ticket?: Ticket;
}

const InconsistenciesTableRow = (props: InconsistenciesTableRowProps) => {
  const {
    inconsistency,
    inconsistencyTypes,
    inconsistencyStatuses,
    updateInconsistencyTypeAndStatusMutation,
    openConfirmationModalAndAsignRedundantIds,
    ticket,
  } = props;

  const getButtonProps = useCallback(
    (readyToBeSwapped: boolean | undefined) => {
      if (readyToBeSwapped) {
        return {
          popupContent: 'Ready to be swapped',
          triggerButtonColor: 'teal',
          triggerButtonIcon: 'checkmark',
          toastSuccess: 'Ready to be swapped!',
          toastError:
            'There was an error trying to mark the case as ready to be swapped',
        };
      } else {
        return {
          popupContent: 'Mark it to be swapped?',
          triggerButtonColor: 'grey',
          triggerButtonIcon: 'minus',
          toastSuccess: 'Not ready to be swapped.',
          toastError:
            'An error occurred and the case is still not ready to be swapped',
        };
      }
    },
    []
  );
  const {
    popupContent,
    triggerButtonColor,
    triggerButtonIcon,
    toastSuccess,
    toastError,
  } = useMemo(
    () => getButtonProps(inconsistency.readyToBeSwapped),
    [getButtonProps, inconsistency.readyToBeSwapped]
  );
  const [reviewed, setReviewed] = useState<boolean>(
    ticket?.detail?.reviewed || false
  );

  const practiceState = inconsistency?.practice?.profile.address?.state;

  const [readyToBeSwapped] = useMutation(ReadyToBeSwappedMutation);
  const [updateReviewed] = useMutation(AppendTicketDetail, {
    onCompleted(data) {
      setReviewed(data?.ticket?.detail?.reviewed);
    },
  });

  const onChangeSelectHandler = async (
    param: VFCInconsistencyTypes | VFCInconsistencyStatuses
  ) => {
    const statusByType = [
      {
        type: VFCInconsistencyTypes.BAD_VFC,
        status: VFCInconsistencyStatuses.SOLVED,
      },
      {
        type: VFCInconsistencyTypes.BORROW,
        status: VFCInconsistencyStatuses.PENDING,
      },
      {
        type: VFCInconsistencyTypes.UNKNOWN,
        status: VFCInconsistencyStatuses.CREATED,
      },
    ];

    const status =
      statusByType.find((arr) => arr.type === param)?.status || undefined;

    await updateInconsistencyTypeAndStatusMutation({
      variables: {
        id: inconsistency.id,
        type: status ? param : inconsistency.type,
        status: status || param,
      },
    })
      .then(() => {
        toast({
          title: 'Status and Type updated successfully',
          type: 'success',
          time: 1000,
        });
      })
      .catch((e: Error) => {
        toast({
          title: 'Error updating Status and Type',
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleReadyToSwap = useCallback(async () => {
    try {
      await readyToBeSwapped({
        variables: {
          id: inconsistency.id,
          readyToBeSwapped: !inconsistency.readyToBeSwapped,
        },
      });
      toast({
        title: toastSuccess,
        type: 'success',
        time: 2000,
      });
    } catch (error) {
      toast({
        title: `${toastError}. Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  }, [
    inconsistency.id,
    inconsistency.readyToBeSwapped,
    readyToBeSwapped,
    toastError,
    toastSuccess,
  ]);

  const patientData = useMemo(
    () => inconsistency.claim?.practicePatient.patientData,
    [inconsistency.claim?.practicePatient.patientData]
  );
  const insurance = useMemo(
    () => inconsistency.claim?.insurance[0],
    [inconsistency.claim?.insurance]
  );
  const inventory = useMemo(
    () => inconsistency.inventory,
    [inconsistency.inventory]
  );

  const handleReviewed = useCallback(
    async (reviewed: boolean) => {
      try {
        await updateReviewed({
          variables: {
            id: ticket?.id,
            detail: {
              ...ticket?.detail,
              reviewed,
            },
          },
        });
        toast({
          title: `Marked item as ${reviewed ? 'reviewed' : 'not reviewed'}.`,
          type: 'success',
          time: 2000,
        });
      } catch (error) {
        toast({
          title: `Failed to mark case as ${
            reviewed ? 'reviewed' : 'not reviewed'
          }: ${error}`,
          type: 'error',
          time: 5000,
        });
      }
    },
    [ticket?.detail, ticket?.id, updateReviewed]
  );

  return (
    <Table.Row key={inconsistency.id}>
      <Table.Cell textAlign="center">{inconsistency.practice?.name}</Table.Cell>
      <Table.Cell textAlign="center">
        {`${patientData?.firstName} ${patientData?.lastName} (${
          patientData?.birthdate
            ? formatDatetimeToMMDDYYY(patientData.birthdate)
            : '-'
        })`}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {insurance?.insuranceCompanyName || ''}{' '}
        <Label
          content={insurance?.vfcEligible ? 'VFC Eligible' : 'Private'}
          size="tiny"
          color={insurance?.vfcEligible ? 'orange' : 'teal'}
        />
        <Label
          basic
          size="tiny"
          content={`Member ID: ${insurance?.memberId || '-'}`}
          style={{ marginTop: '0.2rem' }}
        />
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.claim?.givenAt
          ? formatDatetimeToMMDDYYY(inconsistency.claim?.givenAt).replaceAll(
              '-',
              '/'
            )
          : '-'}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inventory?.vfc ? (
          <>
            <Label
              content="VFC Eligible"
              size="tiny"
              color="orange"
              style={{ marginTop: '0.2rem' }}
            />
          </>
        ) : (
          <>
            <Label
              content={'Private'}
              size="tiny"
              color={'teal'}
              style={{ marginTop: '0.2rem' }}
            />
          </>
        )}
        <hr />
        {inventory?.vaccine.saleNdc}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inventory?.vaccine.name}
        <hr />
        {inventory?.lot}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Select
          options={inconsistencyTypes}
          onChange={(_, { value }) => {
            onChangeSelectHandler(value as VFCInconsistencyTypes);
          }}
          required
          value={inconsistency.type}
          disabled={inconsistency.status === VFCInconsistencyStatuses.REDUNDANT}
        />
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.status === VFCInconsistencyStatuses.REDUNDANT ? (
          <>
            <BorrowingRedundantWithBox
              redundantWith={inconsistency.redundantWith}
            />
            <hr />
            <Button
              negative
              content="Revert to pending"
              onClick={() =>
                openConfirmationModalAndAsignRedundantIds([
                  inconsistency.id,
                  inconsistency.redundantWithId,
                ])
              }
            />
          </>
        ) : (
          <Select
            options={inconsistencyStatuses.filter(
              (status) => status.value !== VFCInconsistencyStatuses.REDUNDANT
            )}
            onChange={(_, { value }) => {
              onChangeSelectHandler(value as VFCInconsistencyStatuses);
            }}
            required
            value={inconsistency.status}
            disabled={inconsistency.type !== VFCInconsistencyTypes.BORROW}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        <BorrowingReportCodeModal
          inconsistency={inconsistency}
          state={
            humanizeText(practiceState!, {
              capitalize: 'all',
            }) as StateKey
          }
        />
      </Table.Cell>
      <Table.Cell
        textAlign="center"
        style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            gap: '0.2rem',
          }}
        >
          <BorrowingInfoBox
            firstName={patientData?.firstName || '-'}
            lastName={patientData?.lastName || '-'}
            memberId={insurance?.memberId || '-'}
            birthdate={patientData?.birthdate}
            serviceDate={
              inconsistency.claim?.givenAt && inconsistency.claim?.givenAt
            }
            vaxName={inventory?.vaccine.name || '-'}
            lot={inventory?.lot || '-'}
          />
          <BorrowingNotes
            note={inconsistency.notes}
            mutationId={inconsistency.id}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.2rem',
          }}
        >
          {ticket ? (
            <>
              <div
                style={{
                  display: 'flex',
                  gap: '0.2rem',
                }}
              >
                <BorrowingIssueForm ticket={ticket} />
                <BorrowingChatModal
                  ticket={ticket}
                  chatSource="issue"
                  chatHeader={chatHeaderForBorrowingIssue(
                    patientData,
                    insurance,
                    inventory,
                    inconsistency
                  )}
                  componentSource={Source.IT}
                />
                {ticket.detail.showNewMessageTo === Source.IT && (
                  <p style={{ margin: '0.25rem 0' }}>
                    <Icon name="flag" color="red" /> New comment
                  </p>
                )}
              </div>
              <Button
                basic
                style={{ boxShadow: 'none' }}
                onClick={() => handleReviewed(!reviewed)}
              >
                <Icon
                  name={reviewed ? 'checkmark' : 'x'}
                  color={reviewed ? 'green' : 'red'}
                />
                <span>
                  {reviewed ? 'Issue Reviewed' : 'Issue Not Reviewed'}
                </span>
              </Button>
            </>
          ) : (
            <div style={{ display: 'flex' }}>
              <Popup
                content={popupContent}
                trigger={
                  <Button
                    color={triggerButtonColor as SemanticCOLORS}
                    basic={!inconsistency.readyToBeSwapped}
                    onClick={handleReadyToSwap}
                    icon={triggerButtonIcon}
                  />
                }
              />
            </div>
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default InconsistenciesTableRow;
