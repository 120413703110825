import {
  balanceMovementReasonsOptions,
  BalanceMovements,
  balanceMovementTypesOptions,
} from '@bluefox/models/BalanceMovements';
import { TableCell, TableRow } from 'semantic-ui-react';
import { TableActionButtons } from '@bluefox/ui/BalanceMovements/tableActionButtons';
import { useMemo } from 'react';
import { reformatYYYYhMMhDDStringtoMMsDDsYYYY } from '@bluefox/lib/formatters';
import { humanizeText } from '@bluefox/lib/humanize';

type BalanceMovementTableProps = {
  balanceMovement: BalanceMovements;
  onClickShowInvoice: () => void;
  onClickMoreInfo: () => void;
  isActive?: boolean;
  isPracticePortal?: boolean;
};
const BalanceMovementsTableRow = ({
  balanceMovement,
  onClickShowInvoice,
  onClickMoreInfo,
  isActive,
  isPracticePortal = false,
}: BalanceMovementTableProps) => {
  const reasonLabel = useMemo((): string => {
    const reason = balanceMovement.reason;
    const reasonLabel = balanceMovementReasonsOptions.find(
      (option) => option.value === reason
    );
    return reasonLabel?.text || reason;
  }, [balanceMovement]);

  const typeLabel = useMemo((): string => {
    const type = balanceMovement.type;
    const typeLabel = balanceMovementTypesOptions.find(
      (option) => option.value === type
    );
    return typeLabel?.text || type;
  }, [balanceMovement]);

  return (
    <TableRow key={`inner-row-${balanceMovement.id}`}>
      <TableCell className={isActive ? 'highlighted-border-top-left' : ''}>
        {reformatYYYYhMMhDDStringtoMMsDDsYYYY(balanceMovement.date || '')}
      </TableCell>
      <TableCell className={isActive ? 'highlighted-border-top' : ''}>
        {`${balanceMovement.organization?.name}${
          balanceMovement.practice ? ` (${balanceMovement.practice?.name})` : ''
        }`}{' '}
      </TableCell>
      <TableCell className={isActive ? 'highlighted-border-top' : ''}>
        {typeLabel}
      </TableCell>
      <TableCell className={isActive ? 'highlighted-border-top' : ''}>
        {reasonLabel}
      </TableCell>
      <TableCell className={isActive ? 'highlighted-border-top' : ''}>
        $ {balanceMovement.amount?.toFixed(2)}
      </TableCell>
      <TableCell className={isActive ? 'highlighted-border-top' : ''}>
        $ {balanceMovement.currentBalance?.toFixed(2)}
      </TableCell>
      <TableCell className={isActive ? 'highlighted-border-top' : ''}>
        {balanceMovement.invoice?.status
          ? humanizeText(balanceMovement.invoice.status, {
              capitalize: 'all',
              delimiter: '_',
            })
          : 'No Invoice Related'}
      </TableCell>
      <TableCell className={isActive ? 'highlighted-border-top' : ''}>{`$ ${
        balanceMovement.amountToPay?.toFixed(2) || 0.0
      }`}</TableCell>
      <TableCell className={isActive ? 'highlighted-border-top-right' : ''}>
        <TableActionButtons
          isPracticePortal={isPracticePortal}
          onClickMoreInfo={onClickMoreInfo}
          showPfdDisabled={!balanceMovement.invoice}
          onClickShowInvoice={onClickShowInvoice}
          balanceMovement={balanceMovement}
        />
      </TableCell>
    </TableRow>
  );
};

export default BalanceMovementsTableRow;
