import React, { useMemo } from 'react';
import { Button, Label, Message, Popup, Table, Icon } from 'semantic-ui-react';
import {
  VFCInconsistency,
  VFCInconsistencyStatuses,
} from '@bluefox/models/VFCInconsistency';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import BorrowedVaccinesReportAnIssue from './BorrowedVaccinesReportAnIssue';
import BorrowingRedundantWithBox from '@bluefox/ui/Borrowing/BorrowingRedundantWithBox';
import { useMutation } from '@apollo/client';
import {
  GetInconsistenciesToSwap,
  ReadyToBeSwappedMutation,
} from '@bluefox/graphql/billing';
import { toast } from 'react-semantic-toasts';
import BorrowingReportCodeModal from '@bluefox/ui/Borrowing/BorrowingReportCodeModal';
import { humanizeText } from '@bluefox/lib/humanize';
import ChatModal from '@bluefox/ui/Borrowing/BorrowingChatModal';
import { Ticket } from '@bluefox/models/Tickets';
import { Source } from '../Chat/types';
import { chatHeaderForBorrowingIssue } from './borrowingFunctions';

type BorrowedVaccinesTableRowProps = {
  inconsistency: VFCInconsistency;
  activeOpenTicket?: boolean;
  summary?: boolean;
  ticket?: Ticket;
  source: Source;
};

export const BorrowedVaccinesTableRow = ({
  inconsistency,
  activeOpenTicket,
  summary,
  ticket,
  source,
}: BorrowedVaccinesTableRowProps) => {
  const [readyToBeSwapped] = useMutation(ReadyToBeSwappedMutation, {
    refetchQueries: [GetInconsistenciesToSwap(source === Source.PP, false)],
  });

  const handleReadyToSwap = async () => {
    try {
      await readyToBeSwapped({
        variables: {
          id: inconsistency.id,
          readyToBeSwapped: !inconsistency.readyToBeSwapped,
        },
      });
      toast({
        title: `${
          !inconsistency.readyToBeSwapped
            ? 'Ready to be swapped!'
            : 'Not ready to be swapped.'
        }`,
        type: 'success',
        time: 2000,
      });
    } catch (error) {
      toast({
        title: `${
          !inconsistency.readyToBeSwapped
            ? 'There was an error trying to mark the case as ready to be swapped'
            : 'An error occurred and the case is still not ready to be swapped'
        }. Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  };

  const patientData = useMemo(
    () => inconsistency.claim?.practicePatient?.patientData,
    [inconsistency.claim?.practicePatient?.patientData]
  );
  const insurance = useMemo(
    () => inconsistency.claim?.insurance[0],
    [inconsistency.claim?.insurance[0]]
  );
  const inventory = useMemo(
    () => inconsistency.inventory,
    [inconsistency.inventory]
  );

  return (
    <Table.Row key={inconsistency.id}>
      <Table.Cell textAlign="center">
        {`${patientData?.firstName} ${patientData?.lastName} (${
          patientData?.birthdate
            ? formatDatetimeToMMDDYYY(patientData?.birthdate)
            : '-'
        })`}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {insurance?.insuranceCompanyName || ''}{' '}
        <Label
          content={insurance?.vfcEligible ? 'VFC Eligible' : 'Private'}
          size="tiny"
          color={insurance?.vfcEligible ? 'orange' : 'teal'}
        />
        <Label
          basic
          size="tiny"
          content={`Member ID: ${insurance?.memberId || '-'}`}
          style={{ marginTop: '0.2rem' }}
        />
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.claim?.givenAt
          ? formatDatetimeToMMDDYYY(inconsistency.claim?.givenAt).replaceAll(
              '-',
              '/'
            )
          : '-'}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inventory?.vfc ? (
          <>
            <Label
              content="VFC Eligible"
              size="tiny"
              color="orange"
              style={{ marginTop: '0.2rem' }}
            />
          </>
        ) : (
          <>
            <Label
              content={'Private'}
              size="tiny"
              color={'teal'}
              style={{ marginTop: '0.2rem' }}
            />
          </>
        )}
        <hr />
        {inventory?.vaccine.saleNdc}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inventory?.vaccine.name}
        <hr />
        {inventory?.lot}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.status === VFCInconsistencyStatuses.REDUNDANT && (
          <BorrowingRedundantWithBox
            redundantWith={inconsistency.redundantWith}
          />
        )}
      </Table.Cell>
      {!summary ? (
        <>
          <Table.Cell>
            <BorrowingReportCodeModal
              inconsistency={inconsistency}
              state={humanizeText(
                inconsistency.practice?.profile.address?.state!,
                {
                  capitalize: 'all',
                }
              )}
            />
          </Table.Cell>
          <Table.Cell textAlign="center">
            {activeOpenTicket ? (
              <Message
                warning
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem',
                }}
              >
                {ticket ? (
                  <>
                    Review in progress{<br />}
                    <ChatModal
                      ticket={ticket!}
                      chatSource="issue"
                      chatHeader={chatHeaderForBorrowingIssue(
                        patientData,
                        insurance,
                        inventory,
                        inconsistency
                      )}
                      componentSource={Source.PP}
                    />
                    {ticket.detail.showNewMessageTo === source && (
                      <p style={{ margin: '0.25rem 0' }}>
                        <Icon name="flag" color="red" /> New comment
                      </p>
                    )}
                  </>
                ) : (
                  'The borrowing case that is redundant with this one is being reviewed'
                )}
              </Message>
            ) : (
              <>
                <Popup
                  content={
                    inconsistency.readyToBeSwapped
                      ? 'Ready to swap'
                      : 'Mark as ready to swap?'
                  }
                  trigger={
                    <Button
                      color={inconsistency.readyToBeSwapped ? 'teal' : 'grey'}
                      basic={!inconsistency.readyToBeSwapped}
                      disabled={activeOpenTicket}
                      onClick={handleReadyToSwap}
                      icon={
                        inconsistency.readyToBeSwapped ? 'checkmark' : 'minus'
                      }
                    />
                  }
                />
                <BorrowedVaccinesReportAnIssue
                  inconsistency={inconsistency}
                  disabled={!!inconsistency.readyToBeSwapped}
                  redundantWith={inconsistency.redundantWithId || undefined}
                />
              </>
            )}
          </Table.Cell>
        </>
      ) : (
        <Table.Cell>{inconsistency.borrowingReasonCode}</Table.Cell>
      )}
    </Table.Row>
  );
};
