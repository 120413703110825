import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { usePractice } from '@bluefox/contexts/Practice';
import { toast } from 'react-semantic-toasts';
import { Button, Form, Icon, Modal, Popup, Segment } from 'semantic-ui-react';
import { CreateTicketMutationForBorrowingIssue } from '@bluefox/graphql/tickets';
import { VFCInconsistency } from '@bluefox/models/VFCInconsistency';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import { GetClaimsVFCInconsistencies } from '@bluefox/graphql/billing';
import {
  Ticket,
  TicketDetailType,
  TicketStatusEnum,
  TicketTypes,
} from '@bluefox/models/Tickets';

type BorrowedVaccinesReportAnIssueProps = {
  inconsistency: VFCInconsistency;
  disabled: boolean;
  redundantWith?: string;
};
const BorrowedVaccinesReportAnIssue = ({
  inconsistency,
  disabled,
  redundantWith = undefined,
}: BorrowedVaccinesReportAnIssueProps) => {
  const { name, handler, id: practiceId } = usePractice();
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');
  const inconsistencyDetail = {
    patient: `${inconsistency.claim?.practicePatient.patientData
      .firstName} ${inconsistency.claim?.practicePatient.patientData
      .lastName} (${
      inconsistency.claim?.practicePatient.patientData.birthdate
        ? formatDatetimeToMMDDYYY(
            inconsistency.claim?.practicePatient.patientData.birthdate
          )
        : '-'
    })`,
    dateOfService: inconsistency.claim?.givenAt
      ? formatDatetimeToMMDDYYY(inconsistency.claim?.givenAt).replaceAll(
          '-',
          '/'
        )
      : '-',
    insurance: inconsistency.claim?.insurance[0]?.insuranceCompanyName || '',
    memberId: inconsistency.claim?.insurance[0]?.memberId || '-',
    eligibility: inconsistency.claim?.insurance[0]?.vfcEligible
      ? 'VFC Eligible'
      : 'Private',
    inventoryUsed: inconsistency.inventory?.vfc ? 'VFC' : 'Private',
    vaccine: {
      name: inconsistency.inventory?.vaccine.name,
      lot: inconsistency.inventory?.lot,
      ndc: inconsistency.inventory?.vaccine.saleNdc,
    },
  };

  const [createTicket] = useMutation(
    CreateTicketMutationForBorrowingIssue(!!redundantWith)
  );

  const handleSubmit = async () => {
    const ticketDetail: TicketDetailType = {
      fields: [
        { title: 'VfcInconsistencyId', detail: inconsistency.id || '' },
        { title: 'Practice', detail: name },
        { title: 'Handler', detail: handler },
        { title: 'Patient', detail: inconsistencyDetail.patient },
        { title: 'Insurance', detail: inconsistencyDetail.insurance },
        { title: 'Member ID', detail: inconsistencyDetail.memberId },
        { title: 'Eligibility', detail: inconsistencyDetail.eligibility },
        { title: 'Date of Service', detail: inconsistencyDetail.dateOfService },
        { title: 'Inventory Used', detail: inconsistencyDetail.inventoryUsed },
        { title: 'NDC', detail: inconsistencyDetail.vaccine.ndc || '' },
        { title: 'Vaccine', detail: inconsistencyDetail.vaccine.name || '' },
        { title: 'Lot', detail: inconsistencyDetail.vaccine.lot || '' },
        { title: 'Issue', detail: description },
      ],
    };

    const ticket: Ticket = {
      practiceId,
      detail: ticketDetail,
      status: TicketStatusEnum.OPEN,
      type: TicketTypes.BORROWING_ISSUE,
    };

    try {
      await createTicket({
        variables: {
          ticket,
          ...(redundantWith && {
            idsArray: [inconsistency.redundantWithId, inconsistency.id],
          }),
        },
        refetchQueries: [GetClaimsVFCInconsistencies(true, true)],
      });
      toast({
        title: 'The issue has been submitted',
        type: 'success',
        time: 5000,
      });
    } catch (e) {
      toast({
        title: `There was an error submitting the ticket. Callback error: ${e}`,
        type: 'error',
        time: 5000,
      });
    }
  };

  return (
    <Modal
      dimmer="blurring"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <Popup
          content="Request a review over this case"
          trigger={
            <Button
              primary
              disabled={disabled}
              icon="ticket"
              onClick={() => setOpen(true)}
            />
          }
        />
      }
    >
      <Modal.Header>
        <Icon name="bullhorn" />
        Issue with inconsistency
      </Modal.Header>
      <Modal.Content>
        Please use this form to request a review over this selected
        inconsistency.
        <Segment padded>
          <Form id="borrowing-inconsistency-issue-form" onSubmit={handleSubmit}>
            <Form.Input
              fluid
              label="Date of Service"
              value={inconsistencyDetail.dateOfService}
            />
            <Form.Input
              fluid
              label="Patient"
              value={inconsistencyDetail.patient}
            />
            <Form.Input
              fluid
              label="Insurance"
              value={inconsistencyDetail.insurance}
            />
            <Form.Input
              fluid
              label="Member ID"
              value={inconsistencyDetail.memberId}
            />
            <Form.Input
              fluid
              label="Eligibility"
              value={inconsistencyDetail.eligibility}
            />
            <Form.Input
              fluid
              label="Type of Inventory Used"
              value={inconsistencyDetail.inventoryUsed}
            />
            <Form.Input
              fluid
              label="Vaccine Name"
              value={inconsistencyDetail.vaccine.name}
            />
            <Form.Input
              fluid
              label="Vaccine Lot"
              value={inconsistencyDetail.vaccine.lot}
            />
            <Form.TextArea
              onChange={(e) => setDescription(e.target?.value.toString())}
              required
              label="Please describe what is wrong with the inconsistency"
              placeholder="Description"
            />
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          type="submit"
          form="borrowing-inconsistency-issue-form"
          disabled={!description}
        >
          Submit
        </Button>

        <Button secondary type="button" onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BorrowedVaccinesReportAnIssue;
