import { InsuranceCompanyPlan } from '@bluefox/models/insuranceCompanyPlan';
import { Button, TableCell, TableRow } from 'semantic-ui-react';

type InsurancesCompaniesPlansTableRowProps = {
  plan: InsuranceCompanyPlan;
  onEditButtonClick: () => void;
};

const InsurancesCompaniesPlansTableRow = ({
  plan,
  onEditButtonClick,
}: InsurancesCompaniesPlansTableRowProps) => {
  return (
    <TableRow>
      <TableCell> {plan.insuranceCompany.name}</TableCell>
      <TableCell> {plan.name}</TableCell>
      <TableCell> {plan.isVfc ? 'Eligible VFC' : 'Not Eligible'}</TableCell>
      <TableCell>{plan.notes}</TableCell>
      <TableCell>
        <Button size="mini" onClick={onEditButtonClick} icon="pencil" />
      </TableCell>
    </TableRow>
  );
};

export default InsurancesCompaniesPlansTableRow;
