import { useState, useEffect, useCallback } from 'react';
import { Route, useHistory } from 'react-router-dom';

import moment from 'moment-timezone';
import { toast } from 'react-semantic-toasts';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { usePractice } from '@bluefox/contexts/Practice';
import {
  Container,
  Table,
  Header,
  Loader,
  Message,
  Button,
  Segment,
  Dropdown,
  Menu,
  List,
  Icon,
  Modal,
  Card,
  Form,
  Label,
  Popup,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import {
  ClaimResubmitMutation,
  ClaimsQuery,
  SaveClaimControlNumberMutation,
} from '@graphql/billing';
import {
  BillingClaim,
  BillingClaimStatus,
  cptCodeUnpaidReasonOptions,
} from '@bluefox/models/Billing';
import { humanizeText } from '@bluefox/lib/humanize';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import PatientFormModal from '@ui/PatientFormModal';
import CustomModal from '@bluefox/ui/CustomModal';
import InsurancesList from '@bluefox/ui/InsurancesList';
import InsurancesForm from '@bluefox/ui/InsurancesForm';
import ScrollDraggingContainer from '@bluefox/ui/ScrollDraggingContainer';
import { DateFormats } from '@bluefox/models/Dates';
import { formatDateToMMDDYYYYV2 } from '@bluefox/lib/formatters/dates';

interface BillingClaimData {
  claims: BillingClaim[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

const ClaimsScreen = () => {
  const history = useHistory();
  const practice = usePractice();
  const [status, setStatus] = useState<string>('error');
  const [updatedAtCriteria, setUpdatedAtCriteria] = useState<object>({});

  const [initialDate, setInitialDate] = useState<Date | null | undefined>(null);
  const [finalDate, setFinalDate] = useState<Date | null | undefined>(null);

  const [openClaimControlNumberForm, setOpenClaimControlNumberForm] =
    useState(false);
  const [controlNumber, setControlNumber] = useState('');
  const [claimId, setClaimId] = useState('');

  const { data, loading, error, refetch } = useQuery<BillingClaimData>(
    ClaimsQuery,
    {
      variables: {
        status,
        criteria: { practiceId: { _eq: practice.id } },
        updatedAtCriteria,
      },
    }
  );

  const [saveClaimControlNumber] = useMutation(SaveClaimControlNumberMutation);

  const handleSubmit = () => {
    saveClaimControlNumber({
      variables: {
        claimId,
        claimControlNumber: controlNumber,
      },
    })
      .then(() => {
        toast({
          title: 'Control number saved successfully',
          type: 'success',
          time: 1000,
        });
        refetch();
        cleanAndClose();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const cleanAndClose = () => {
    setControlNumber('');
    setOpenClaimControlNumberForm(false);
  };

  const handleOnSave = () => {
    refetch();
    history.goBack();
  };

  useEffect(() => {
    if (initialDate && !finalDate) {
      setUpdatedAtCriteria({ _gte: initialDate });
    }
    if (finalDate && !initialDate) {
      setUpdatedAtCriteria({ _lte: finalDate });
    }
    if (finalDate && initialDate) {
      setUpdatedAtCriteria({ _gte: initialDate, _lte: finalDate });
    }
  }, [initialDate, finalDate]);

  const statusOptions = [
    { key: 'error', text: 'Error', value: 'error' },
    { key: 'submitted', text: 'Submitted', value: 'submitted' },
    { key: 'processed', text: 'Processed', value: 'processed' },
    { key: 'toresubmit', text: 'To Resubmit', value: 'toresubmit' },
  ];

  return (
    <>
      <MainLayout
        path={[
          { text: 'Practices', to: '/practices' },
          { text: practice.name, to: `/practices/${practice.handler}` },
          { text: 'Claims' },
        ]}
        loading={loading}
      >
        <Container fluid data-automation-id="it-claims">
          <Card fluid style={{ marginTop: '1rem' }}>
            <Card.Content>
              <Card.Header as={'h3'}>
                <div>
                  <Icon
                    name="money bill alternate outline"
                    style={{ marginRight: '0.6rem' }}
                  />
                  Billing Claims
                </div>
              </Card.Header>
              <Card.Description>
                <Menu borderless>
                  <Menu.Menu
                    position="right"
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Menu.Item>
                      <DateTimePicker
                        data-automation-id="claims-initial-date"
                        placeholderText="Search by Date From"
                        selected={initialDate}
                        onChange={(d) =>
                          setInitialDate(d ? (d as Date) : undefined)
                        }
                        onSelect={(value) =>
                          setInitialDate(value ? (value as Date) : undefined)
                        }
                        onClear={() => setInitialDate(undefined)}
                        maxDate={new Date()}
                        dateFormat={DateFormats.DATE}
                        showYearDropdown
                        showMonthDropdown
                        scrollableYearDropdown
                        isClearable
                      />
                    </Menu.Item>
                    <Menu.Item>
                      <DateTimePicker
                        data-automation-id="claims-final-date"
                        placeholderText="Search by Date of To"
                        selected={finalDate}
                        onChange={(d) =>
                          setFinalDate(d ? (d as Date) : undefined)
                        }
                        onSelect={(value) =>
                          setFinalDate(value ? (value as Date) : undefined)
                        }
                        onClear={() => setFinalDate(undefined)}
                        maxDate={new Date()}
                        dateFormat={DateFormats.DATE}
                        showYearDropdown
                        showMonthDropdown
                        scrollableYearDropdown
                        isClearable
                      />
                    </Menu.Item>
                    <Menu.Item>
                      <Dropdown
                        onChange={(_, { value }) => {
                          setStatus(value ? value.toString() : 'error');
                        }}
                        placeholder="Select Status"
                        selection
                        defaultValue={status}
                        options={statusOptions}
                      />
                    </Menu.Item>
                  </Menu.Menu>
                </Menu>
                {loading && <Loader />}
                {error && <Message error>{error.message}</Message>}
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={2}>
                        Vaccinated at
                      </Table.HeaderCell>
                      <Table.HeaderCell>Response</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Updated at</Table.HeaderCell>
                      <Table.HeaderCell>Insurance</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Patient</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Claim Control Number
                      </Table.HeaderCell>
                      {status === BillingClaimStatus.TO_RESUBMIT && (
                        <Table.HeaderCell></Table.HeaderCell>
                      )}
                      <Table.HeaderCell width={5}></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {!!data?.claims.length ? (
                      data?.claims?.map((claim) => {
                        return (
                          <Table.Row key={claim.id}>
                            <Table.Cell textAlign="center">
                              {!!claim.vaccinations.length ? (
                                formatDateToMMDDYYYYV2(String(claim.givenAt))
                              ) : (
                                <Popup
                                  trigger={
                                    <Icon
                                      size="large"
                                      name="warning circle"
                                      color="grey"
                                    />
                                  }
                                  size="small"
                                  content="This claim does not have any vaccinations associated"
                                />
                              )}
                              {!!claim.vaccinations.length &&
                              (claim.status === BillingClaimStatus.ERROR ||
                                claim.status ===
                                  BillingClaimStatus.TO_RESUBMIT) ? (
                                <Label
                                  basic
                                  size="small"
                                  content={`Provider: ${claim.vaccinations[0].prescriber?.account.firstName} ${claim.vaccinations[0].prescriber?.account.lastName}`}
                                />
                              ) : null}
                            </Table.Cell>
                            <Table.Cell>
                              {claim.status ===
                              BillingClaimStatus.TO_RESUBMIT ? (
                                <>
                                  <ResponseCellContent claim={claim} />
                                  <Card>
                                    <Card.Content>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <label>
                                          <b>Notes:</b>
                                        </label>
                                        {claim.note?.note}
                                      </div>
                                    </Card.Content>
                                  </Card>
                                </>
                              ) : (
                                <ResponseCellContent claim={claim} />
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              {moment(claim.updatedAt).format(DateFormats.DATE)}
                            </Table.Cell>
                            <Table.Cell>
                              {!!claim.practicePatient.insurances.length
                                ? claim.practicePatient.insurances[0].company
                                    ?.name
                                : '-'}
                            </Table.Cell>
                            <Table.Cell>
                              {claim.practicePatient?.patientData.firstName}{' '}
                              {claim.practicePatient?.patientData.lastName} (
                              {moment(
                                claim.practicePatient?.patientData.birthdate
                              ).format(DateFormats.DATE)}
                              )
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {claim.claimControlNumber
                                ? claim.claimControlNumber
                                : '-'}
                            </Table.Cell>
                            {status === BillingClaimStatus.TO_RESUBMIT && (
                              <Table.Cell>
                                <Popup
                                  trigger={
                                    <Label
                                      style={{ cursor: 'pointer' }}
                                      basic
                                      size="small"
                                      content="Claim detail"
                                    />
                                  }
                                  on="click"
                                  content={<ClaimDetail claim={claim} />}
                                />
                              </Table.Cell>
                            )}
                            <Table.Cell textAlign="center">
                              {claim.status === BillingClaimStatus.ERROR && (
                                <StyledButtonContainer>
                                  <Button
                                    as={Link}
                                    to={`/practices/${practice.handler}/claims/patients/${claim.practicePatient?.id}/info`}
                                    color="teal"
                                    size="tiny"
                                  >
                                    <StyledButtonIcon>
                                      <Icon name="user" />
                                      <Icon
                                        name="cog"
                                        corner="top right"
                                        color="red"
                                      />
                                    </StyledButtonIcon>
                                    Fix Patient
                                  </Button>
                                  <Button
                                    as={Link}
                                    to={`/practices/${practice.handler}/claims/patients/${claim.practicePatient?.id}/insurances`}
                                    color="teal"
                                    size="tiny"
                                  >
                                    <StyledButtonIcon>
                                      <Icon name="dollar" />
                                      <Icon
                                        name="cog"
                                        corner="top right"
                                        color="red"
                                      />
                                    </StyledButtonIcon>
                                    Fix Insurance
                                  </Button>
                                  <ResubmitButton
                                    claimId={claim.id ?? ''}
                                    refetch={refetch}
                                  />
                                </StyledButtonContainer>
                              )}
                              {claim.status ===
                                BillingClaimStatus.TO_RESUBMIT && (
                                <StyledButtonContainer>
                                  <Button
                                    onClick={() => {
                                      setClaimId(claim.id);
                                      setControlNumber(
                                        claim.claimControlNumber || ''
                                      );
                                      setOpenClaimControlNumberForm(true);
                                    }}
                                    size="tiny"
                                    color="teal"
                                  >
                                    <Icon name="file alternate outline" />
                                    Claim Control Number
                                  </Button>
                                  <ResubmitButton
                                    claimId={claim.id ?? ''}
                                    refetch={refetch}
                                    disabled={!claim.claimControlNumber}
                                  />
                                </StyledButtonContainer>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })
                    ) : (
                      <Table.Row>
                        <Table.Cell
                          colSpan={
                            status === BillingClaimStatus.TO_RESUBMIT ? 8 : 7
                          }
                        >
                          <Message>No clamis Found</Message>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Card.Description>
            </Card.Content>
          </Card>
        </Container>
        <Route
          path={`/practices/${practice.handler}/claims/patients/:practicePatientId/info`}
          exact
        >
          <PatientFormModal onClose={handleOnSave} />
        </Route>
        <Route
          exact
          path={`/practices/${practice.handler}/claims/patients/:practicePatientId/insurances/:insuranceId`}
        >
          <InsurancesForm />
        </Route>
        <Route
          path={`/practices/${practice.handler}/claims/patients/:practicePatientId/insurances`}
        >
          <CustomModal modalHeader="Insurance List">
            <InsurancesList />
          </CustomModal>
        </Route>
      </MainLayout>
      <Modal
        closeIcon
        onClose={() => setOpenClaimControlNumberForm(false)}
        open={openClaimControlNumberForm}
        size="tiny"
      >
        <Header>
          Save Claim Control Number
          <Header.Subheader>(EOB / ERA)</Header.Subheader>
        </Header>
        <Modal.Content>
          <Form id="update-claim-control-number-form" onSubmit={handleSubmit}>
            <Form.Field>
              <Form.Input
                value={controlNumber}
                onChange={(_, { value }) => setControlNumber(value)}
                fluid
                label="Claim Control Number (EOB / ERA)"
                placeholder="Claim Control Number (EOB / ERA)"
                required
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            content="Cancel"
            onClick={() => setOpenClaimControlNumberForm(false)}
          />
          <Button
            primary
            type="submit"
            content="Save"
            icon="save"
            form="update-claim-control-number-form"
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ClaimsScreen;

const useResubmitMutation = () => {
  const [resubmit, { loading }] = useMutation(ClaimResubmitMutation);

  return {
    resubmit,
    loading,
  };
};

const ResubmitButton = ({
  claimId,
  onResubmitted,
  refetch,
  disabled,
}: {
  claimId: string;
  onResubmitted?: () => void;
  refetch: () => void;
  disabled?: boolean;
}) => {
  const [resubmitted, setResubmitted] = useState(false);

  const { resubmit, loading } = useResubmitMutation();

  const handleOnClick = useCallback(() => {
    setResubmitted(true);
    resubmit({
      variables: {
        claimId,
      },
    })
      .then((r) => {
        if (onResubmitted) onResubmitted();
        refetch();
      })
      .catch((e) => {
        console.error(e);
        setResubmitted(false);
      });
  }, [resubmit, claimId, onResubmitted]);

  return (
    <Button
      onClick={handleOnClick}
      loading={loading}
      disabled={loading || resubmitted || disabled}
      size="tiny"
      icon={resubmitted ? 'check' : 'sync'}
      content={resubmitted ? 'Resubmitted' : 'Resubmit'}
      color={resubmitted ? 'grey' : 'teal'}
    />
  );
};

const ResponseCellContent = ({ claim }: { claim: any }) => {
  const [update] = claim.updates;

  if (!update) return null;

  const list = (
    <Card
      style={{
        padding: '0.5rem',
        overflow: 'auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <label>
          <b>Errors:</b>
        </label>
        <List>
          {update.response?.errors?.map((e: any, i: number) => (
            <List.Item key={i}>
              <List.Content>
                <List.Header>{e.field}</List.Header>
                <List.Description
                  style={{
                    maxHeight: '15rem',
                  }}
                >
                  {e.description}
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </div>
    </Card>
  );

  return update.response?.errors?.length > 3 ? (
    <Modal
      trigger={<Button content="View Response" disabled={!claim.updates} />}
    >
      <Modal.Header>Response</Modal.Header>
      <Modal.Content>{list}</Modal.Content>
    </Modal>
  ) : (
    list
  );
};

interface ClaimDetailProps {
  claim: BillingClaim;
}

const ClaimDetail = ({ claim }: ClaimDetailProps) => {
  return (
    <List>
      <List.Item>
        <b>CPT Codes: </b>
        {claim.cptCodes.length
          ? claim.cptCodes.map((cpt) => (
              <Card
                style={{
                  margin: '0.2rem 0rem 0.6rem 0rem',
                  padding: '0.4rem',
                }}
              >
                {cpt.cptCode || '-'} x {cpt.units || '-'} (
                {humanizeText(cpt.status ? cpt.status : '-', {
                  delimiter: '_',
                  capitalize: 'first',
                })}
                )
                <p>
                  <b>Unpaid reason: </b>
                  {cpt.note.unpaidReason?.text || '-'}
                </p>
              </Card>
            ))
          : '-'}
      </List.Item>
      <List.Item>
        <b>RHC Number: </b>
        {claim.rhcClaimNumber || '-'}
      </List.Item>
      <List.Item>
        <b>EOB Link: </b>
        {claim.note?.url || '-'}
      </List.Item>
      <List.Item>
        <b>Notes: </b>
        {claim.note?.note || '-'}
      </List.Item>
    </List>
  );
};

const StyledButtonIcon = styled(Icon.Group)`
  margin-right: 0.5rem;
`;
const StyledButtonContainer = styled.div`
  a.button {
    margin: 0.1rem;
  }
`;
