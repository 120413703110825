import React, { useMemo } from 'react';
import {
  Card,
  CardProps,
  SemanticShorthandCollection,
  Table,
} from 'semantic-ui-react';
type BalanceMovementsMoreInfoRowProps = {
  pcAdminFeeClaimed?: number;
  vcfAdminFeeClaimed?: number;
  timeRestored?: number;
  balanceMovementId: string;
};

const BalanceMovementsMoreInfoRow = (
  props: BalanceMovementsMoreInfoRowProps
) => {
  const {
    pcAdminFeeClaimed,
    vcfAdminFeeClaimed,
    timeRestored,
    balanceMovementId,
  } = props;

  const items: SemanticShorthandCollection<CardProps> = useMemo(() => {
    return [
      {
        header: `$ ${pcAdminFeeClaimed}`,
        meta: 'Private vaccine admin fee claimed',
      },
      {
        header: `$ ${vcfAdminFeeClaimed}`,
        meta: 'VFC admin fee claimed',
      },
      {
        header: `$ ${timeRestored}`,
        meta: 'Your time restored',
      },
    ];
  }, [pcAdminFeeClaimed, vcfAdminFeeClaimed, timeRestored]);

  return (
    <Table.Row key={`more-actions-inner-row${balanceMovementId}`}>
      <Table.Cell className="highlighted-border-expanded-row-cell" colSpan={9}>
        <Card.Group textAlign={'center'} centered items={items} />
      </Table.Cell>
    </Table.Row>
  );
};

export default BalanceMovementsMoreInfoRow;
