import React, { useState, useEffect } from 'react';
import { Grid, Label, Table, Modal, Message } from 'semantic-ui-react';
import { BillingClaimStatus, BillingClaimView } from '@bluefox/models/Billing';
import moment from 'moment/moment';
import ExpandedRow from '@screens/billing/general/generalExpandedRow';
import PatientFormModal from '@ui/PatientFormModal';
import InsurancesList, { InsuranceListProp } from '@bluefox/ui/InsurancesList';
import { humanizeText } from '@bluefox/lib/humanize';
import { TagsForm } from '@ui/Tags';
import { DateFormats } from '@bluefox/models/Dates';
import styled from 'styled-components';
import { COLORS_BY_NAME } from '@bluefox/constants';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import { useQuery } from '@apollo/client';
import { GetNotificationsByEntityRefIdQuery } from '@graphql/communications';
import { Notification } from '@bluefox/models/Notification';

interface Cpt {
  cpt: string;
}

interface ProcedureAmountsData {
  cptCodes: Cpt[];
}

interface ClaimTableProps {
  claims: BillingClaimView[];
  onClaimUpdate: () => void;
  onPatientSelection: (patientId: string) => void;
  onEditInsuranceClick: (props: InsuranceListProp) => void;
  refetch: () => void;
  procedureAmountsData?: ProcedureAmountsData;
  patientClaimsOpened?: boolean;
}

const ClaimsTable = (props: ClaimTableProps) => {
  // Start States
  const { claims, onClaimUpdate, refetch } = props;
  const [activeIndex, setActiveIndex] = useState(-1);
  const [claimIdForIndex, setClaimIdForIndex] = useState('');

  const [selectedPracticePatientId, setSelectedPracticePatientId] =
    useState('');
  const [editInsuranceModalData, setEditInsuranceModalData] =
    useState<InsuranceListProp>({});
  const [isEditInsuranceModalOpened, setIsEditInsuranceModalOpened] =
    useState(false);
  const [isTagsModalOpened, setIsTagsModalOpened] = useState(false);
  const [notificationSentIds, setNotificationSentIds] = useState<string[]>([]);

  //End States

  const onClickTitle = (index: number) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const onCloseInsuranceListModalHandler = () => {
    setEditInsuranceModalData({});
    setIsEditInsuranceModalOpened(false);
  };

  const handleSeePatientsClaimsClick = (id: string) => {
    window.open(`/billing/practice-patient/${id}`, '_blank');
  };

  const showInsuranceName = (
    claim: BillingClaimView,
    showMemberId?: boolean
  ) => {
    if (claim.selfPay) return '-';

    if (showMemberId) {
      if (
        claim.status === BillingClaimStatus.PENDING &&
        !!claim.claim.practicePatient.insurances?.length
      ) {
        return claim.claim.practicePatient.insurances[0].memberId || '-';
      }
      return claim.insuranceMemberId;
    }

    return claim.insuranceName || '-';
  };

  const getTableCells = (
    claim: BillingClaimView,
    isActive: boolean,
    practicePatientId: string
  ) => {
    return (
      <>
        <Table.Cell className={isActive ? 'highlighted-border-top-left' : ''}>
          <b>{claim.claim.practice.name}</b>
        </Table.Cell>

        <Table.Cell className={isActive ? 'highlighted-border-top' : ''}>
          {`${claim.claim.practicePatient.patientData.firstName} ${claim.claim.practicePatient.patientData.lastName}`}
          <br />
          {`(${moment(claim.claim.practicePatient.patientData.birthdate).format(
            DateFormats.DATE
          )})`}
        </Table.Cell>

        <Table.Cell className={isActive ? 'highlighted-border-top' : ''}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                {showInsuranceName(claim)}
                <br />
                <Label
                  basic
                  content={`Member ID: ${showInsuranceName(claim, true)}`}
                  size="tiny"
                />

                {(claim.status === BillingClaimStatus.PENDING ||
                  claim.status === BillingClaimStatus.UNCLAIMED) &&
                claim.insuranceVfcEligible ? (
                  <>
                    <Label
                      content="VFC Eligible"
                      size="tiny"
                      color="orange"
                      style={{ marginTop: '0.2rem' }}
                    />
                  </>
                ) : (
                  <>
                    <Label
                      content={
                        claim.insuranceVfcEligible ? 'VFC Eligible' : 'Private'
                      }
                      size="tiny"
                      color={claim.insuranceVfcEligible ? 'orange' : 'teal'}
                      style={{ marginTop: '0.2rem' }}
                    />
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Table.Cell>
        <Table.Cell className={isActive ? 'highlighted-border-top' : ''}>
          {claim.claim.givenAt
            ? formatDatetimeToMMDDYYY(claim.claim.givenAt)
            : '-'}
        </Table.Cell>
        <Table.Cell className={isActive ? 'highlighted-border-top' : ''}>
          {claim.status === BillingClaimStatus.PENDING &&
          claim.vaccinationsCptCodes ? (
            '$' +
            claim.vaccinationsCptCodes.reduce((accum, cpt) => {
              return (
                accum +
                (cpt.chargeAmount === null ? 0 : cpt.chargeAmount * cpt.count)
              );
            }, 0)
          ) : (
            <>
              {claim.claim.totalClaimAmount !== null
                ? `$${claim.claim.totalClaimAmount.toFixed(2)}`
                : '-'}
            </>
          )}
        </Table.Cell>
        <Table.Cell className={isActive ? 'highlighted-border-top' : ''}>
          {!!claim.inventoryVfcCount && (
            <>
              <Label image size="tiny" color="orange">
                VFC
                <Label.Detail>{claim.inventoryVfcCount}</Label.Detail>
              </Label>
              <br />
            </>
          )}
          {!!claim.inventoryPrivateCount && (
            <Label image size="tiny" color="teal">
              Private
              <Label.Detail>{claim.inventoryPrivateCount}</Label.Detail>
            </Label>
          )}
        </Table.Cell>
        <Table.Cell
          textAlign="center"
          className={isActive ? 'highlighted-border-top-right' : ''}
        >
          <p style={{ fontWeight: '500' }}>
            {humanizeText(claim.claim.status, {
              capitalize: 'first',
              delimiter: '_',
            })}
          </p>
          {!props.patientClaimsOpened && (
            <StyledSeePatientClaimsButton
              onClick={() => handleSeePatientsClaimsClick(practicePatientId)}
            >
              See all claims
            </StyledSeePatientClaimsButton>
          )}
        </Table.Cell>
      </>
    );
  };

  // USE EFFECTS
  useEffect(() => {
    if (Object.keys(editInsuranceModalData).length === 0) return;
    setIsEditInsuranceModalOpened(true);
  }, [editInsuranceModalData]);

  useEffect(() => {
    setActiveIndex(
      claims.findIndex((claim) => claim.claimId === claimIdForIndex)
    );
  }, [claims]);

  // USE EFFECTS

  const {
    loading: notificationSentIdsLoading,
    refetch: notificationSentIdsRefetch,
  } = useQuery<{
    communicationNotifications: Notification[];
  }>(GetNotificationsByEntityRefIdQuery, {
    variables: {
      entityRefIds: claims.map((claim) => claim.claim.id),
    },
    skip: !claims || !claims.length,
    onError: (error) => {
      setNotificationSentIds([]);
    },
    onCompleted: (data) => {
      const ids = data.communicationNotifications?.map(
        (notification) => notification.entityRefId as string
      );
      setNotificationSentIds(ids);
    },
  });

  return (
    <Table.Body>
      {!!claims.length ? (
        claims.map((claim, index) => {
          const isCurrentlyDue = (date: Date) => {
            const dueDate = moment(date);
            const diff = dueDate.diff(moment(), 'days');
            return diff <= 0;
          };

          const isOverdue =
            isCurrentlyDue(claim.inMediationDueDate) ||
            isCurrentlyDue(claim.inMediationDueDateFrom) ||
            isCurrentlyDue(claim.inMediationDueDate);

          return (
            <>
              <Table.Row
                key={`row-data-${index}`}
                //active={activeIndex === index}
                warning={claim.selfPay}
                negative={isOverdue}
                onClick={() => {
                  onClickTitle(index);
                  setClaimIdForIndex(claim.claimId);
                }}
              >
                {getTableCells(
                  claim,
                  activeIndex === index,
                  claim.practicePatientId
                )}
              </Table.Row>

              {activeIndex === index && (
                <Table.Row key={`row-expanded-${index}`}>
                  <Table.Cell
                    className={
                      activeIndex === index
                        ? 'highlighted-border-expanded-row-cell'
                        : ''
                    }
                    colSpan="7"
                    warning={claim.selfPay}
                    negative={isOverdue}
                  >
                    <ExpandedRow
                      onStatusSave={onClaimUpdate}
                      claim={claim}
                      isOverdue={isOverdue}
                      onPatientSelection={(patientId: string) =>
                        setSelectedPracticePatientId(patientId)
                      }
                      onEditInsuranceClick={(props: InsuranceListProp) =>
                        setEditInsuranceModalData(props)
                      }
                      onClickAddTag={() => setIsTagsModalOpened(true)}
                      procedureAmountsData={props.procedureAmountsData}
                      isPatientClaimsOpened={props.patientClaimsOpened}
                      memberId={showInsuranceName(claim, true)}
                      notificationSentIdsLoading={notificationSentIdsLoading}
                      notificationSentIds={notificationSentIds}
                      notificationSentIdsRefetch={notificationSentIdsRefetch}
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan={7}>
            <Message>No Claims Found.</Message>
          </Table.Cell>
        </Table.Row>
      )}
      <PatientFormModal
        practPatientId={selectedPracticePatientId}
        onClose={() => setSelectedPracticePatientId('')}
        onCancel={() => setSelectedPracticePatientId('')}
        onSave={() => {
          setSelectedPracticePatientId('');
          refetch();
        }}
        goBackOnClose={false}
      />
      <Modal
        size="large"
        closeIcon
        onClose={onCloseInsuranceListModalHandler}
        open={isEditInsuranceModalOpened}
      >
        <InsurancesList
          propPracticePatientId={editInsuranceModalData.propPracticePatientId}
          propPracticeId={editInsuranceModalData.propPracticeId}
        />
      </Modal>

      <Modal
        closeIcon
        open={isTagsModalOpened}
        onClose={() => {
          setIsTagsModalOpened(false);
        }}
      >
        <TagsForm />
      </Modal>
    </Table.Body>
  );
};

const StyledSeePatientClaimsButton = styled.div`
  margin-left: 0.8rem;
  color: ${COLORS_BY_NAME['Carolina Blue']};

  &:hover {
    cursor: pointer;
    color: ${COLORS_BY_NAME['Pale Cornflower Blue']};
  }
`;

export default ClaimsTable;
