import React, { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { useParams, useHistory, Link } from 'react-router-dom';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import PhoneInput from '@bluefox/ui/PhoneInput';
import StatePicker from '@bluefox/ui/StatePicker';
import {
  SavePracticePatientMutation,
  PracticePatientByIdQuery,
  GetPatientForValidation,
} from '@bluefox/graphql/patients';
import { PracticeSettingsQuery } from '@bluefox/graphql/practices';
import { Address } from '@bluefox/models/Address';
import {
  Sex,
  PracticePatient,
  raceOptions,
  ethnicityOptions,
} from '@bluefox/models/Patient';
import { GuardianRelationships, Guardian } from '@bluefox/models/Guardian';
import { PracticeSettings } from '@bluefox/models/Practice';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Header,
  Radio,
  Icon,
  List,
  Card,
  Modal,
  ModalActions,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { usePractice } from '@bluefox/contexts/Practice';
import { useApplicationState, useSession } from '@bluefox/contexts';
import { getAge } from '@bluefox/lib/getAge';
import styled from 'styled-components';

interface RouterParamsType {
  practicePatientId?: string;
}

interface PracticePatientData {
  practicePatient: PracticePatient;
}

interface SavedPracticePatient {
  practicePatient: PracticePatient;
}

interface DuplicatedPatient {
  id: string;
  patientData: {
    birthdate: Date;
    firstName: string;
    id: string;
    lastName: string;
  };
  mrn: string;
  active: boolean;
}
interface DumplicatedPatientData {
  practicePatients: DuplicatedPatient[];
}

interface PracticeSettingsData {
  practice: {
    settings: PracticeSettings;
  };
}

interface PatientFormProps {
  onSave?: (practicePatient: PracticePatient) => void;
  leftActions?: {
    submit?: boolean;
    callback?: (data: PracticePatient) => void;
    onClick?: () => void;
    basic?: boolean;
    'data-automation-id'?: string;
    primary?: boolean;
    type?: 'submit' | 'reset' | 'button';
    content?: any;
    icon?: any;
    name: string;
  }[];
  rightActions?: {
    submit?: boolean;
    callback?: (data: PracticePatient) => void;
    onClick?: () => void;
    basic?: boolean;
    'data-automation-id'?: string;
    primary?: boolean;
    type?: 'submit' | 'reset' | 'button';
    content?: any;
    icon?: any;
    name: string;
  }[];
  shortForm?: boolean;
  practPatientId?: string;
  handler?: string;
  internalTools?: boolean;
}

const PatientForm = ({
  onSave,
  leftActions,
  rightActions,
  shortForm,
  practPatientId,
  internalTools,
}: PatientFormProps) => {
  const { isEmbedded } = useApplicationState();
  const practicePatientId = useParams<RouterParamsType>().practicePatientId
    ? useParams<RouterParamsType>().practicePatientId
    : practPatientId;
  const session = useSession();
  const history = useHistory();
  const practice = usePractice();
  const currentRouteArr = history.location.pathname.split('/');
  const goBackRouteArr = currentRouteArr.slice(0, -1);
  const { mrn: newPatientMrn } = useParams<{ mrn: string }>();

  const [getPracticePatient, { data }] = useLazyQuery<PracticePatientData>(
    PracticePatientByIdQuery(false)
  );

  useEffect(() => {
    if (!practicePatientId) return;

    getPracticePatient({
      variables: {
        id: practicePatientId,
      },
    });
  }, [practicePatientId]);

  const [getPatient, { data: duplicatedPatientData }] =
    useLazyQuery<DumplicatedPatientData>(GetPatientForValidation);

  const { data: settingsData } = useQuery<PracticeSettingsData>(
    PracticeSettingsQuery,
    {
      variables: {
        id: practice.id,
      },
    }
  );

  const patientAge = data?.practicePatient.patientData.birthdate
    ? getAge(new Date(data?.practicePatient.patientData.birthdate))
    : 0;
  const [id, setId] = useState<String>();
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState<String>();
  const [preferredName, setPreferredName] = useState<String>();
  const [lastName, setLastName] = useState('');
  const [mrn, setMrn] = useState(newPatientMrn || '');
  const [sex, setSex] = useState<Sex>(Sex.Female);
  const [race, setRace] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [birthdate, setBirthdate] = useState<Date | undefined | null>(null);
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [address, setAddress] = useState<Address>({ state: 'NY' } as Address);
  const [isActive, setIsActive] = useState(true);
  const [consentForIis, setconsentForIis] = useState<boolean | undefined>();
  const [guardian, setGuardian] = useState<Guardian>();
  const [guardianRelationship, setGuardianRelationship] = useState<string>('');
  const [guardianSex, setGuardianSex] = useState('');
  const [guardianAddress, setGuardianAddress] = useState<Address>(
    {} as Address
  );
  const [openDuplicatedPatientConfirm, setOpenDuplicatedPatientConfirm] =
    useState(false);
  const [openSaveConfirm, setOpenSaveConfirm] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);

  const [errorEmail, setErrorEmail] = useState<boolean>(false);

  const [requireGuardianInfo, setRequireGuardianInfo] = useState(false);
  const patientAgeToSave = getAge(birthdate);

  const [savePatient, { loading }] = useMutation<SavedPracticePatient>(
    SavePracticePatientMutation
  );

  useEffect(() => {
    if (!duplicatedPatientData) return;
    if (
      duplicatedPatientData.practicePatients.length &&
      duplicatedPatientData.practicePatients.some((patient) => patient.active)
    ) {
      setOpenDuplicatedPatientConfirm(true);
    }
  }, [duplicatedPatientData]);

  useEffect(() => {
    if (practPatientId) return;
    if (firstName && lastName && birthdate) {
      runPatientValidationQuery();
    }
  }, [birthdate]);

  useEffect(() => {
    if (!data?.practicePatient) return;
    const { id, active, consentForIis, mrn, patientData } = Object(
      data?.practicePatient
    );
    const {
      id: idData,
      lastName,
      middleName,
      preferredName,
      firstName,
      sex,
      raceAndCreed,
      ethnicity,
      birthdate,
      email,
      phone,
      address,
      guardians,
    } = patientData;
    const tz =
      practice.timezone === ''
        ? data?.practicePatient.practice.timezone
        : practice.timezone;
    const transformedBirthdate = moment.tz(birthdate, tz).toDate();

    let _guardian;

    if (guardians.length) {
      const guardianDob = guardians[0]?.guardian?.birthdate;

      const transformedGuardianBirthdate = guardianDob
        ? moment.tz(guardianDob, tz).toDate()
        : undefined;

      _guardian = { ...guardians[0].guardian };

      _guardian.birthdate = transformedGuardianBirthdate;
    }

    setLastName(lastName);
    setMiddleName(middleName);
    setPreferredName(preferredName);
    setFirstName(firstName);
    setMrn(mrn);
    setBirthdate(transformedBirthdate);
    setEmail(email);
    setPhone(phone);
    if (sex) setSex(sex);
    setRace(raceAndCreed);
    setEthnicity(ethnicity);
    if (idData) setId(idData);
    if (address.state) setAddress(address);
    setconsentForIis(consentForIis);
    setIsActive(id ? active || false : true);
    setGuardianRelationship(guardians[0]?.relationship);
    setGuardian(_guardian);
    setGuardianSex(guardians[0]?.guardian.sex);
    setGuardianAddress(guardians[0]?.guardian.address);
  }, [data]);

  const handleConsent = (_: any, { value }: any) =>
    setconsentForIis(value === 'yes');

  function handleSavePatient() {
    const object = {
      id: data?.practicePatient.id ?? undefined,
      practiceId: data?.practicePatient.practice.id ?? practice.id,
      active: isActive,
      consentForIis: consentForIis ?? undefined,
      mrn,
      patientData: {
        data: {
          id: data?.practicePatient.patientData.id ?? undefined,
          preferredName: preferredName ? preferredName.trim() : preferredName,
          firstName: firstName ? firstName.trim() : firstName,
          middleName: middleName ? middleName.trim() : middleName,
          lastName: lastName ? lastName.trim() : lastName,
          sex,
          raceAndCreed: race,
          ethnicity,
          birthdate: moment(birthdate).format('MM-DD-YYYY'),
          email: email ? email.trim() : email,
          phone,
          address,
          hasBasicInfo: shortForm,
        },
        on_conflict: {
          constraint: 'patients_pkey',
          update_columns: [
            'firstName',
            'middleName',
            'lastName',
            'preferredName',
            'sex',
            'birthdate',
            'raceAndCreed',
            'ethnicity',
            'email',
            'phone',
            'address',
          ],
        },
      },
    };

    if (
      !shortForm &&
      settingsData?.practice.settings.guardian?.enabled &&
      requireGuardianInfo
    ) {
      //@ts-ignore
      object.patientData.data.guardians = {
        on_conflict: {
          constraint: 'guardians_patients_pkey',
          update_columns: ['relationship'],
        },
        data: [
          {
            id: data?.practicePatient?.patientData?.guardians?.length
              ? data?.practicePatient?.patientData?.guardians[0].id
              : undefined,
            relationship: guardianRelationship,
            guardian: {
              on_conflict: {
                constraint: 'guardians_pkey',
                update_columns: [
                  'firstName',
                  'lastName',
                  'phone',
                  'birthdate',
                  'email',
                  'sex',
                  'address',
                ],
              },
              data: {
                id: data?.practicePatient?.patientData?.guardians?.length
                  ? data?.practicePatient?.patientData?.guardians[0].guardian.id
                  : undefined,
                firstName:
                  guardian?.firstName && guardian.firstName.trim().length !== 0
                    ? guardian.firstName.trim()
                    : '',
                lastName:
                  guardian?.lastName && guardian.lastName.trim().length !== 0
                    ? guardian.lastName.trim()
                    : '',
                phone:
                  guardian?.phone && guardian?.phone.trim().length !== 0
                    ? guardian.phone.trim()
                    : '',
                birthdate: guardian
                  ? moment(guardian.birthdate).format('MM-DD-YYYY')
                  : undefined,
                email:
                  guardian?.email && guardian?.email?.trim().length !== 0
                    ? guardian.email.trim()
                    : '',
                sex: guardianSex,
                address: guardianAddress,
              },
            },
          },
        ],
      };
    }

    if (requireGuardianInfo && !guardian?.birthdate) {
      toast({
        title: `Please select guardian date of birth`,
        type: 'error',
        time: 5000,
      });

      return;
    }

    if (requireGuardianInfo && !guardianSex) {
      toast({
        title: `Please select guardian sex`,
        type: 'error',
        time: 5000,
      });

      return;
    }

    if (requireGuardianInfo && !guardian?.phone) {
      toast({
        title: `Please provide guardian phone number`,
        type: 'error',
        time: 5000,
      });

      return;
    }

    if (requireGuardianInfo && !guardianRelationship) {
      toast({
        title: `Please select guardian relationship with patient`,
        type: 'error',
        time: 5000,
      });

      return;
    }

    savePatient({
      variables: {
        object,
      },
    })
      .then((r) => {
        if (onSave && r.data) onSave(r.data.practicePatient);
        toast({
          title: 'Patient has been successfully saved',
          type: 'success',
          time: 1000,
        });
        if (!onSave) history.push(`${goBackRouteArr.join('/')}`);
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  }

  useEffect(() => {
    if (
      (guardian?.firstName && guardian?.firstName?.trim().length !== 0) ||
      (guardian?.lastName && guardian?.lastName?.trim().length !== 0) ||
      guardian?.birthdate ||
      guardianSex ||
      (guardian?.email && guardian.email.trim().length !== 0) ||
      (guardian?.phone && guardian.phone.trim().length !== 0) ||
      guardianRelationship
    ) {
      setRequireGuardianInfo(true);
    } else {
      setRequireGuardianInfo(false);
    }
  }, [guardian, guardianSex, guardianRelationship]);

  const handleSubmit = () => {
    if (
      !data?.practicePatient.id &&
      duplicatedPatientData?.practicePatients.length
    ) {
      setShowSaveButton(true);
      setOpenDuplicatedPatientConfirm(true);
      return;
    }

    if (!address.state) {
      toast({
        title: 'Please select a State',
        type: 'error',
        time: 5000,
      });

      return;
    }

    if (
      !shortForm &&
      settingsData?.practice.settings.guardian?.enabled &&
      !guardianSex &&
      patientAge <= 19 &&
      patientAgeToSave <= 19
    ) {
      toast({
        title: "Please select guardian's sex",
        type: 'error',
        time: 5000,
      });
      return;
    }

    if (
      !shortForm &&
      settingsData?.practice.settings.guardian?.enabled &&
      !guardian?.phone &&
      patientAge <= 19 &&
      patientAgeToSave <= 19
    ) {
      toast({
        title: `Please provide a phone number for guardian`,
        type: 'error',
        time: 5000,
      });

      return;
    }

    if (
      !shortForm &&
      settingsData?.practice.settings.guardian?.enabled &&
      !guardianRelationship &&
      patientAge <= 19 &&
      patientAgeToSave <= 19
    ) {
      toast({
        title: 'Guardian must have a relationship set with patient',
        type: 'error',
        time: 5000,
      });
      return;
    }

    setErrorEmail(false);
    handleSavePatient();
  };

  const handleConfirmSave = () => {
    handleSavePatient();
  };

  const runPatientValidationQuery = () => {
    const objA: any = {
      _and: [
        { mrn: { _eq: mrn } },
        {
          patientData: {
            id: { _neq: id },
          },
        },
      ],
    };
    const objB: any = {
      _and: [
        {
          patientData: {
            firstName: {
              _ilike: `%${firstName}%`,
            },
          },
        },
        {
          patientData: {
            lastName: {
              _ilike: `%${lastName}%`,
            },
          },
        },
        {
          patientData: {
            birthdate: {
              _eq: birthdate,
            },
          },
        },
        {
          patientData: {
            id: { _neq: id },
          },
        },
      ],
    };

    let queryPart: any = [];

    if (mrn !== '') queryPart.push(objA);
    if (firstName || lastName || birthdate) queryPart.push(objB);

    getPatient({
      variables: {
        patientCriteria: {
          practice: {
            organizationId: {
              _eq: data?.practicePatient.practice.organization?.id,
            },
          },

          _or: queryPart,
        },
      },
    });
  };

  const urlHandler = data?.practicePatient.practice.handler || practice.handler;

  return (
    <Form onSubmit={handleSubmit}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: '1rem',
        }}
      >
        <Header as="h1">Personal Information</Header>
        <Form.Group inline>
          <Form.Input
            className="mrn-input"
            inline
            data-automation-id={`patient-basic-mrn`}
            size="mini"
            value={mrn || ''}
            onChange={(_, { value }) => {
              setMrn(value);
            }}
            label="MRN:"
            placeholder="MRN"
            style={{ marginRight: isEmbedded ? '2.5rem' : '0' }}
            onBlur={() => {
              if (mrn) runPatientValidationQuery();
            }}
          />
          <Form.Field style={{ display: 'flex', justifyContent: 'center' }}>
            <label>Active:</label>
            <Radio
              toggle
              checked={isActive}
              onChange={(_, { checked }) => setIsActive(!!checked)}
            />
          </Form.Field>
        </Form.Group>
      </div>

      <Form.Group widths="equal">
        {!shortForm && (
          <Form.Input
            data-automation-id={`patient-basic-preferred-name`}
            value={preferredName || ''}
            onChange={(_, { value }) => setPreferredName(value)}
            fluid
            label="Preferred name"
            placeholder="Preferred name"
          />
        )}

        <Form.Input
          data-automation-id={`patient-basic-first-name`}
          value={firstName || ''}
          onChange={(_, { value }) => setFirstName(value)}
          fluid
          label="First name"
          placeholder="First name"
          onBlur={() => {
            if (lastName && firstName && birthdate) runPatientValidationQuery();
          }}
          required
        />
        {!shortForm && (
          <Form.Input
            data-automation-id={`patient-basic-middle-name`}
            value={middleName || ''}
            onChange={(_, { value }) => setMiddleName(value)}
            fluid
            label="Middle name"
            placeholder="Middle name"
          />
        )}
        <Form.Input
          data-automation-id={`patient-basic-last-name`}
          value={lastName || ''}
          onChange={(_, { value }) => setLastName(value)}
          fluid
          label="Last name"
          placeholder="Last name"
          onBlur={() => {
            if (lastName && firstName && birthdate) runPatientValidationQuery();
          }}
          required
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field required style={{ zIndex: '99' }}>
          <label>Date of Birth </label>
          <DateTimePicker
            isClearable
            tz={
              practice.timezone === ''
                ? data?.practicePatient.practice.timezone
                : practice.timezone
            }
            onChange={(value) => {
              setBirthdate(value ? (value as Date) : undefined);
            }}
            selected={birthdate}
            dataAutomationId={`patient-basic-date-of-birth`}
            scrollableYearDropdown
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            maxDate={new Date()}
            required
          />
        </Form.Field>
        <Form.Select
          data-automation-id={`patient-basic-sex`}
          value={sex}
          onChange={(_, { value }) => setSex(value as Sex)}
          fluid
          label="Sex"
          options={[
            { text: 'Female', value: Sex.Female, icon: 'venus' },
            { text: 'Male', value: Sex.Male, icon: 'mars' },
            { text: 'Unknown', value: Sex.Unknown, icon: 'genderless' },
          ]}
          placeholder={`${sex}`}
          required
        />
      </Form.Group>
      {!shortForm && (
        <Form.Group widths="equal">
          <Form.Select
            data-automation-id={`patient-basic-race`}
            value={race}
            onChange={(_, { value }) => setRace(value as string)}
            fluid
            label="Race"
            options={raceOptions}
            placeholder="Select race..."
          />
          <Form.Select
            data-automation-id={`patient-basic-ethnicity`}
            value={ethnicity}
            onChange={(_, { value }) => setEthnicity(value as string)}
            fluid
            label="Ethnicity"
            options={ethnicityOptions}
            placeholder="Select ethincity..."
          />
        </Form.Group>
      )}

      {!shortForm && (
        <>
          <Header as="h4" color="grey">
            Contact Information
          </Header>

          <Form.Group widths="equal">
            <Form.Input
              data-automation-id={`patient-basic-email`}
              error={errorEmail}
              value={email || ''}
              onChange={(_, { value }) => setEmail(value)}
              type="email"
              label="Email"
              placeholder="Email"
            />
            <Form.Field>
              <label>Phone</label>
              <PhoneInput
                value={phone || ''}
                onChange={(value) => setPhone(value)}
                data-automation-id={`patient-basic-phone`}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Input
              width={4}
              data-automation-id={`patient-basic-street`}
              value={address.street || ''}
              onChange={(_, { value }) =>
                setAddress({ ...address, street: value })
              }
              label="Street"
              placeholder="Street &amp; number"
              required
            />
            <Form.Input
              width={4}
              data-automation-id={`patient-basic-city`}
              value={address.city || ''}
              onChange={(_, { value }) =>
                setAddress({ ...address, city: value })
              }
              label="City"
              placeholder="City"
              required
            />
            <Form.Field width={4} required>
              <label>State</label>
              <StatePicker
                fluid
                data-automation-id={`patient-basic-state`}
                value={address.state}
                onChange={(value) => setAddress({ ...address, state: value })}
              />
            </Form.Field>
            <Form.Input
              width={4}
              data-automation-id={`patient-basic-zip`}
              value={address.zip || ''}
              onChange={(_, { value }) =>
                setAddress({ ...address, zip: value })
              }
              label="Zip code"
              placeholder="10010"
              required
            />
          </Form.Group>
        </>
      )}

      {getAge(birthdate) >= 19 && (
        <StyledConsentContainer>
          <label>
            The patient has given consent to upload immunizations to the state
            registry for documentation purposes
          </label>
          <StyledConsentCheckbox>
            <StyledConsentItem>Yes</StyledConsentItem>
            <Radio
              value="yes"
              name="consentForIis"
              checked={consentForIis}
              onChange={handleConsent}
            />
            <StyledConsentItem>No</StyledConsentItem>
            <Radio
              value="no"
              name="consentForIis"
              checked={consentForIis === false}
              onChange={handleConsent}
            />
          </StyledConsentCheckbox>
        </StyledConsentContainer>
      )}

      {!shortForm && settingsData?.practice.settings.guardian?.enabled && (
        <>
          <Header as="h4" color="grey">
            Guardian Information
          </Header>
          <Form.Group widths="equal">
            <Form.Input
              data-automation-id="patient-basic-guardian-firstname"
              value={guardian?.firstName || ''}
              onChange={(_, { value }) =>
                setGuardian({ ...guardian, firstName: value } as Guardian)
              }
              label="First name"
              placeholder="Guardian first name"
              required={
                (patientAge <= 19 && patientAgeToSave <= 19) ||
                requireGuardianInfo
              }
            />
            <Form.Input
              data-automation-id="patient-basic-guardian-lastname"
              value={guardian?.lastName || ''}
              onChange={(_, { value }) =>
                setGuardian({ ...guardian, lastName: value } as Guardian)
              }
              label="Last name"
              placeholder="Guardian last name"
              required={
                (patientAge <= 19 && patientAgeToSave <= 19) ||
                requireGuardianInfo
              }
            />
            <Form.Field
              required={
                (patientAge <= 19 && patientAgeToSave <= 19) ||
                requireGuardianInfo
              }
            >
              <label>Date of Birth </label>
              <DateTimePicker
                isClearable
                tz={
                  practice.timezone === ''
                    ? data?.practicePatient.practice.timezone
                    : practice.timezone
                }
                onChange={(value) => {
                  setGuardian({
                    ...guardian,
                    birthdate: value ? (value as Date) : undefined,
                  } as Guardian);
                }}
                selected={
                  guardian?.birthdate
                    ? new Date(guardian?.birthdate)
                    : undefined
                }
                dataAutomationId="patient-basic-guardian-date-of-birth"
                scrollableYearDropdown
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                maxDate={new Date()}
                required={patientAge <= 19 && patientAgeToSave <= 19}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Select
              clearable
              data-automation-id={`patient-basic-guardian-sex`}
              value={guardianSex}
              onChange={(_, { value }) =>
                setGuardianSex((value as Sex) || undefined)
              }
              fluid
              label="Sex"
              options={[
                { text: 'Female', value: Sex.Female, icon: 'venus' },
                { text: 'Male', value: Sex.Male, icon: 'mars' },
                { text: 'Unknown', value: Sex.Unknown, icon: 'genderless' },
              ]}
              placeholder="Select sex..."
              required={
                (settingsData.practice.settings.guardian.enabled &&
                  patientAge <= 19 &&
                  patientAgeToSave <= 19) ||
                requireGuardianInfo
              }
            />
            <Form.Input
              data-automation-id="patient-basic-guardian-email"
              error={errorEmail}
              value={guardian?.email || ''}
              onChange={(_, { value }) =>
                setGuardian({ ...guardian, email: value } as Guardian)
              }
              type="email"
              label="Email"
              placeholder="Email"
            />
            <Form.Field
              required={
                (patientAge <= 19 && patientAgeToSave <= 19) ||
                requireGuardianInfo
              }
            >
              <label>Phone</label>
              <PhoneInput
                value={guardian?.phone || ''}
                onChange={(value) =>
                  setGuardian({ ...guardian, phone: value } as Guardian)
                }
                data-automation-id="patient-basic-guardian-phone"
              />
            </Form.Field>
            <Form.Dropdown
              clearable
              data-automation-id="patient-basic-guardian-relationship"
              fluid
              selection
              placeholder="Select relationship..."
              value={guardianRelationship}
              onChange={(_, { value }) =>
                setGuardianRelationship(value as string)
              }
              label="Relationship with patient"
              options={[
                { text: 'Father', value: GuardianRelationships.FATHER },
                { text: 'Mother', value: GuardianRelationships.MOTHER },
                { text: 'Self', value: GuardianRelationships.SELF },
                { text: 'Other', value: GuardianRelationships.OTHER },
              ]}
              required={
                (patientAge <= 19 && patientAgeToSave <= 19) ||
                requireGuardianInfo
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              width={4}
              data-automation-id={`patient-basic-guardian-street`}
              value={guardianAddress?.street || ''}
              onChange={(_, { value }) =>
                setGuardianAddress({ ...guardianAddress, street: value })
              }
              label="Street"
              placeholder="Street &amp; number"
            />
            <Form.Input
              width={4}
              data-automation-id={`patient-basic-guardian-city`}
              value={guardianAddress?.city || ''}
              onChange={(_, { value }) =>
                setGuardianAddress({ ...guardianAddress, city: value })
              }
              label="City"
              placeholder="City"
            />
            <Form.Field width={4}>
              <label>State</label>
              <StatePicker
                fluid
                data-automation-id={`patient-basic-guardian-state`}
                value={guardianAddress?.state || ''}
                onChange={(value) =>
                  setGuardianAddress({ ...guardianAddress, state: value })
                }
              />
            </Form.Field>
            <Form.Input
              width={4}
              data-automation-id={`patient-basic-guardian-zip`}
              value={guardianAddress?.zip || ''}
              onChange={(_, { value }) =>
                setGuardianAddress({ ...guardianAddress, zip: value })
              }
              label="Zip code"
              placeholder="10010"
            />
          </Form.Group>
        </>
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {practicePatientId &&
        settingsData?.practice.settings.guardian?.enabled ? (
          <Link
            to={
              session.account?.role === 'staff'
                ? `/practices/${practice.handler}/patients/${data?.practicePatient.id}/guardians`
                : `/${practice.handler}/patients/${data?.practicePatient.id}/guardians`
            }
          >
            <Button
              inverted
              color="blue"
              content="Show Guardians List"
              style={{ marginBottom: practicePatientId ? null : '0.5rem' }}
            />
          </Link>
        ) : (
          <div>
            {leftActions?.map((action, i) => (
              <Button
                key={i}
                disabled={loading}
                loading={loading}
                {...action}
              />
            ))}
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {rightActions?.map((action, i) => (
            <Button key={i} disabled={loading} loading={loading} {...action} />
          ))}
        </div>
      </div>

      <Modal
        style={{ marginTop: isEmbedded ? '4rem' : '' }}
        size="tiny"
        open={openDuplicatedPatientConfirm}
        onOpen={() => setOpenDuplicatedPatientConfirm(true)}
        onClose={() => setOpenDuplicatedPatientConfirm(false)}
      >
        <Modal.Content scrolling style={{ padding: '0' }}>
          <Modal.Description>
            <>
              <StyledConfirm>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon
                    name="warning sign"
                    size="big"
                    color="red"
                    style={{ marginRight: '1rem' }}
                  />
                  <h3 style={{ margin: '0' }}>Possible duplicated patient/s</h3>
                </div>
              </StyledConfirm>
              {duplicatedPatientData?.practicePatients.map((patient) => {
                return (
                  <Card centered style={{ marginBottom: '1rem' }}>
                    <List style={{ margin: '1rem' }}>
                      <List.Item>
                        <List.Header>MRN</List.Header>
                        {patient.mrn || '-'}
                      </List.Item>
                      <List.Item>
                        <List.Header>Fisrt Name</List.Header>
                        {patient.patientData.firstName}
                      </List.Item>
                      <List.Item>
                        <List.Header>Last Name</List.Header>
                        {patient.patientData.lastName}
                      </List.Item>
                      <List.Item>
                        <List.Header>Birthdate</List.Header>
                        {patient.patientData.birthdate}
                      </List.Item>
                    </List>
                    <Button
                      primary
                      content="Go to patient"
                      onClick={() => {
                        setOpenDuplicatedPatientConfirm(false);
                        internalTools
                          ? history.push(
                              `/practices/${urlHandler}/patients/${patient.id}/info`
                            )
                          : history.push(
                              `/${urlHandler}/patients/${patient.id}/`
                            );
                      }}
                    />
                  </Card>
                );
              })}
            </>
          </Modal.Description>
        </Modal.Content>

        <ModalActions>
          <Button
            content="Cancel"
            onClick={() => {
              setOpenDuplicatedPatientConfirm(false);
              setShowSaveButton(false);
            }}
          />

          {showSaveButton && (
            <Button
              negative
              icon="save"
              content="Save anyways"
              onClick={() => setOpenSaveConfirm(true)}
            />
          )}
        </ModalActions>
        <Modal
          open={openSaveConfirm}
          onOpen={() => setOpenSaveConfirm(true)}
          onClose={() => setOpenSaveConfirm(false)}
          onConfirm={handleConfirmSave}
          confirmButton={<Button negative content="Yes" />}
          size="tiny"
        >
          <StyledConfirm>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                name="warning sign"
                size="big"
                color="red"
                style={{ marginRight: '1rem' }}
              />
              <h3 style={{ margin: '0' }}>Warning!</h3>
            </div>
          </StyledConfirm>
          <Modal.Content>
            Creating a duplicate patient will generate information distributed
            (such as insurances, vaccinations and appoinments) among the
            duplicate profiles. Are you sure you want to save a duplicated
            patient?
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Cancel"
              onClick={() => setOpenSaveConfirm(false)}
            />
            <Button negative content="Yes" onClick={handleConfirmSave} />
          </Modal.Actions>
        </Modal>
      </Modal>
    </Form>
  );
};

const StyledConsentContainer = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 25px 0 20px 0;
`;

const StyledConsentCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 50px;
`;

const StyledConsentItem = styled.div`
  margin: 0 10px 0 10px;
`;

const StyledConfirm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0rem 1rem 0rem;
  margin: 0;
  background-color: #fff6f6;
  border-bottom: 1px solid #dddee0;
  flex-direction: column;
`;

export default PatientForm;
