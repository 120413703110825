import { Notification, NotificationType } from '@bluefox/models/Notification';
import OrderNotificationMessage from './OrderNotificationMessage';
import BillingNotificationMessage from './BillingNotificationMessage';
import VaxSyncIntegrationNotificationMessage from './VaxSyncIntegrationNotificationMessage';

type Props = {
  notification: Notification;
  practiceTimezone?: string;
};

const NotificationMessage = ({ notification, practiceTimezone }: Props) => {
  const currentPracticeTimezone = practiceTimezone
    ? practiceTimezone
    : notification.practice?.timezone;
  switch (notification.type) {
    case NotificationType.order:
      return OrderNotificationMessage({ notification });
    case NotificationType.claim:
      return BillingNotificationMessage({
        notification,
        practiceTimezone: currentPracticeTimezone,
      });
    case NotificationType.athena:
    case NotificationType.develo:
    case NotificationType.ecw:
    case NotificationType.vax_sync:
      return VaxSyncIntegrationNotificationMessage({
        notification,
        practiceTimezone: currentPracticeTimezone,
      });
    default:
      return null;
  }
};

export default NotificationMessage;
