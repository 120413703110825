import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import ChatList from './ChatList';
import { Message } from './types';
import SendChatMessage from './SendChatMessage';
import { Card, Grid, GridColumn } from 'semantic-ui-react';

export type ChatMessage = { message: Message; position?: 'left' | 'right' };

export type ChatHandle = {
  scrollDown: () => void;
};

type Props = {
  messages: ChatMessage[];
  onSendNewMessage?: (message: string) => void;
  allowSend?: boolean;
  isLargeModal?: boolean;
  isShownInExtensionWindow?: boolean;
};

const Chat = forwardRef<ChatHandle, Props>(
  (
    {
      messages,
      onSendNewMessage: onNewMessage,
      allowSend = true,
      isLargeModal = false,
      isShownInExtensionWindow = false,
    },
    ref
  ) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    const scrollDown = useCallback(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, []);

    useEffect(() => {
      scrollDown();
    }, [messages, scrollRef]);

    useImperativeHandle(
      ref,
      () => {
        return {
          scrollDown,
        };
      },
      [scrollDown]
    );

    return (
      <Grid columns="equal">
        {!isShownInExtensionWindow && <GridColumn></GridColumn>}
        <GridColumn width={`${isLargeModal ? 8 : 16}`}>
          <Card fluid>
            <Card.Content>
              <div
                style={{
                  maxHeight: isShownInExtensionWindow ? '28vh' : '40vh',
                  overflow: 'auto',
                  padding: '0.5rem',
                }}
                ref={scrollRef}
              >
                <ChatList messages={messages} />
              </div>
            </Card.Content>
            <Card.Content extra>
              {allowSend && (
                <SendChatMessage
                  onSubmit={onNewMessage}
                  isShownInExtensionWindow={isShownInExtensionWindow}
                />
              )}
            </Card.Content>
          </Card>
        </GridColumn>
        {!isShownInExtensionWindow && <GridColumn></GridColumn>}
      </Grid>
    );
  }
);

export default Chat;
