import React from 'react';
import { usePractice } from '@bluefox/contexts/Practice';
import MainLayout from '@ui/MainLayout';
import { BorrowingHistoryViewHandler } from '@bluefox/ui/Borrowing/BorrowingHistoryViewHandler';
import { Source } from '@bluefox/ui/Chat/types';

const BorrowingSummary = () => {
  const practice = usePractice();

  return (
    <MainLayout
      path={[
        { text: 'Practices', to: '/practices' },
        { text: practice.name, to: `/practices/${practice.handler}` },
        { text: 'Borrowing Summary' },
      ]}
    >
      <BorrowingHistoryViewHandler
        practiceId={practice.id}
        source={Source.IT}
      />
    </MainLayout>
  );
};

export default BorrowingSummary;
