import { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import {
  CptCode,
  BillingClaimStatus,
  cptCodeUnpaidReasonOptions,
  CptResolutionStatus,
  cptResolutionStatusOptions,
  CptCodeStatus,
  claimDismissedOptions,
} from '@bluefox/models/Billing';
import {
  Form,
  Dropdown,
  Input,
  Checkbox,
  Label,
  Card,
  Popup,
  Icon,
} from 'semantic-ui-react';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';

interface Cpt {
  cpt: string;
}

interface ProcedureAmountsData {
  cptCodes: Cpt[];
}

interface CptCodeFormProps {
  cptCodeData: CptCode;
  status: BillingClaimStatus;
  adminCptCodes?: ProcedureAmountsData;
  onChangeCpt: (cptCode: CptCode) => void;
  onChangeStatus: BillingClaimStatus;
  disableSave: (value: boolean) => void;
}

const CptCodeForm = ({
  cptCodeData,
  status,
  adminCptCodes,
  onChangeCpt,
  onChangeStatus,
  disableSave,
}: CptCodeFormProps) => {
  const [cptCode, setCptCode] = useState<CptCode>(cptCodeData);
  const validCptCodes = adminCptCodes?.cptCodes.map((c) => c.cpt);
  const isAdminCptCode = validCptCodes?.includes(cptCodeData.cptCode);

  const [paidAmount, setPaidAmount] = useState<string>(
    cptCodeData.paidAmount ? cptCodeData.paidAmount.toString() : ''
  );
  const [cptCodeNote, setCptCodeNote] = useState(cptCodeData.note?.message);
  const [cptCodeUnpaidReason, setCptCodeUnpaidReason] = useState({
    value: cptCodeData.note?.unpaidReason?.value,
    text: cptCodeData.note?.unpaidReason?.text,
  });
  const [cptCompensated, setCptCompensated] = useState(
    cptCodeData.status === CptCodeStatus.COMPENSATED ||
      cptCodeData.status === CptCodeStatus.COMPENSATED_AND_IN_MEDIATION
      ? true
      : false
  );
  const [cptInMediation, setCptInMediation] = useState(
    cptCodeData.status === CptCodeStatus.IN_MEDIATION ||
      cptCodeData.status === CptCodeStatus.COMPENSATED_AND_IN_MEDIATION
      ? true
      : false
  );
  const [cptDismissed, setCptDismissed] = useState(
    cptCodeData.status === CptCodeStatus.DISMISSED ? true : false
  );
  const [cptDismissedReason, setCptDismissedReason] = useState(
    cptCodeData.dismissedReason ? cptCodeData.dismissedReason : null
  );
  const [cptResolution, setCptResolution] = useState(cptCodeData.resolution);
  const [cptMediationDue, setCptMediationDue] = useState<
    Date | undefined | null
  >(null);
  const [compensationAmount, setCompensationAmount] = useState(
    cptCodeData.compensationAmount
      ? cptCodeData.compensationAmount.toString()
      : null
  );
  const [inMediationAmount, setInMediationAmount] = useState(
    cptCodeData.inMediationAmount
      ? cptCodeData.inMediationAmount.toString()
      : null
  );

  const onlyNumbers = /^[0-9]*\.?[0-9]*$/;

  const getCptCodeStatus = () => {
    return cptCompensated && cptInMediation
      ? CptCodeStatus.COMPENSATED_AND_IN_MEDIATION
      : cptCompensated
        ? CptCodeStatus.COMPENSATED
        : cptInMediation
          ? CptCodeStatus.IN_MEDIATION
          : cptDismissed
            ? CptCodeStatus.DISMISSED
            : null;
  };

  const cleanForm = () => {
    cptCode.compensationAmount = null;
    cptCode.inMediationAmount = null;
    cptCode.resolution = null;
    cptCode.inMediationDueDate = undefined;
    setCptCodeUnpaidReason({
      value: '',
      text: '',
    });
    setCptCompensated(false);
    setCptInMediation(false);
  };

  useEffect(() => {
    if (!cptCode) return;
    setCptMediationDue(
      cptCode.inMediationDueDate
        ? moment(cptCode.inMediationDueDate).toDate()
        : null
    );

    if (cptCode.paidAmount) {
      cptCode.status = CptCodeStatus.PAID;
      cptCode.note.unpaidReason = undefined;
      setCptDismissed(false);
      cleanForm();
    } else {
      cptCode.status = getCptCodeStatus();
    }
    onChangeCpt(cptCode);
  }, [cptCode]);

  useEffect(() => {
    if (!cptDismissed) return;
    cptCode.status = CptCodeStatus.DISMISSED;
    cptCode.dismissedReason = cptDismissedReason;
    if (paidAmount) cleanForm();
  }, [cptDismissed, cptDismissedReason, paidAmount]);

  const showUnpaid = useMemo(() => {
    return (
      onChangeStatus === BillingClaimStatus.DISMISSED ||
      onChangeStatus === BillingClaimStatus.PAID ||
      onChangeStatus === BillingClaimStatus.PARTIALLY_PAID ||
      onChangeStatus === BillingClaimStatus.CLOSED ||
      onChangeStatus === BillingClaimStatus.PARTIALLY_CLOSED ||
      onChangeStatus === BillingClaimStatus.DENIED ||
      onChangeStatus === BillingClaimStatus.TO_APPEAL ||
      status === BillingClaimStatus.IN_MEDIATION ||
      status === BillingClaimStatus.PARTIALLY_PAID ||
      status === BillingClaimStatus.PARTIALLY_CLOSED ||
      status === BillingClaimStatus.BILLED ||
      status === BillingClaimStatus.DISMISSED ||
      status === BillingClaimStatus.DENIED
    );
  }, [onChangeStatus, status]);

  const showDismissed = useMemo(() => {
    return (
      onChangeStatus === BillingClaimStatus.PAID ||
      onChangeStatus === BillingClaimStatus.PARTIALLY_PAID ||
      onChangeStatus === BillingClaimStatus.DENIED ||
      onChangeStatus === BillingClaimStatus.DISMISSED ||
      onChangeStatus === BillingClaimStatus.TO_APPEAL ||
      onChangeStatus === BillingClaimStatus.CLOSED ||
      onChangeStatus === BillingClaimStatus.PARTIALLY_CLOSED ||
      onChangeStatus === BillingClaimStatus.PARTIALLY_BILLED ||
      status === BillingClaimStatus.PAID ||
      status === BillingClaimStatus.PARTIALLY_PAID ||
      status === BillingClaimStatus.CLOSED ||
      status === BillingClaimStatus.PARTIALLY_CLOSED ||
      status === BillingClaimStatus.DENIED ||
      status === BillingClaimStatus.IN_MEDIATION ||
      status === BillingClaimStatus.DISMISSED ||
      status === BillingClaimStatus.PARTIALLY_BILLED
    );
  }, [onChangeStatus, status]);

  const showCompensated = useMemo(() => {
    return (
      (isAdminCptCode && status === BillingClaimStatus.BILLED) ||
      (isAdminCptCode && status === BillingClaimStatus.CLOSED) ||
      (isAdminCptCode && status === BillingClaimStatus.PARTIALLY_CLOSED) ||
      (isAdminCptCode && status === BillingClaimStatus.TO_RESUBMIT) ||
      (isAdminCptCode && status === BillingClaimStatus.PARTIALLY_PAID) ||
      onChangeStatus === BillingClaimStatus.PARTIALLY_PAID ||
      onChangeStatus === BillingClaimStatus.PARTIALLY_CLOSED ||
      onChangeStatus === BillingClaimStatus.DENIED ||
      onChangeStatus === BillingClaimStatus.DISMISSED ||
      onChangeStatus === BillingClaimStatus.TO_APPEAL ||
      status === BillingClaimStatus.PAID ||
      status === BillingClaimStatus.IN_MEDIATION ||
      status === BillingClaimStatus.DENIED ||
      status === BillingClaimStatus.DISMISSED
    );
  }, [isAdminCptCode, onChangeStatus, status]);

  const showInMediation = useMemo(() => {
    return (
      (isAdminCptCode && status === BillingClaimStatus.BILLED) ||
      (isAdminCptCode && status === BillingClaimStatus.CLOSED) ||
      (isAdminCptCode && status === BillingClaimStatus.PARTIALLY_CLOSED) ||
      onChangeStatus === BillingClaimStatus.PARTIALLY_PAID ||
      onChangeStatus === BillingClaimStatus.DENIED ||
      onChangeStatus === BillingClaimStatus.DISMISSED ||
      onChangeStatus === BillingClaimStatus.PARTIALLY_CLOSED ||
      (status !== BillingClaimStatus.TO_RESUBMIT &&
        status !== BillingClaimStatus.PAID)
    );
  }, [isAdminCptCode, onChangeStatus, status]);

  return (
    <Card
      fluid
      style={{
        padding: '1rem',
        border: '1px solid lightgrey',
        boxShadow: '1px 2px 5px grey',
        marginBottom: '2rem',
      }}
    >
      <Form.Field>
        <div style={{ display: 'flex' }}>
          <p>
            <b>CPT Code: </b>
            {cptCodeData.cptCode} x{cptCodeData.units}
          </p>
          <div
            style={{
              marginLeft: '1rem',
            }}
          >
            {cptCode.status === CptCodeStatus.PAID ? (
              <Label
                size="tiny"
                icon="thumbs up"
                content="Paid"
                color="olive"
              />
            ) : cptCode.status === CptCodeStatus.DISMISSED ? (
              <Label
                size="tiny"
                icon="cancel"
                content="Dismissed"
                color="red"
              />
            ) : null}
          </div>

          <div
            style={{
              marginLeft: '0.5rem',
            }}
          >
            {cptCodeData.invoices && (
              <span className="min-component-horizontal-margin">
                <Popup
                  content={`Invoiced cpt code (invoice# ${cptCodeData.invoices.invoiceNo})`}
                  trigger={
                    <Icon name="money" style={{ marginRight: '0.6rem' }} />
                  }
                />
              </span>
            )}
          </div>
        </div>
        <div style={{ marginBottom: '0.3rem' }}>
          <b>Claimed Amount: </b>${cptCodeData.claimAmount}
        </div>
        <Form.Input
          onKeyPress={(event: React.KeyboardEvent) => {
            if (!onlyNumbers.test(event.key)) {
              event.preventDefault();
            }
          }}
          value={paidAmount}
          onChange={(_, { value }) => {
            let _cptCode = {
              ...cptCode,
              paidAmount: parseFloat(value),
            };

            setCptCode(_cptCode);
            setPaidAmount(value);
            disableSave(false);
          }}
          disabled={!!cptCode.invoice}
          fluid
          label="Paid Amount"
          placeholder="Paid Amount"
        />
        {showUnpaid ? (
          <Form.Field style={{ display: 'flex', alignItems: 'center' }}>
            <label
              style={{ color: cptCode.paidAmount ? 'lightgrey' : 'black' }}
            >
              Unpaid Reason:
            </label>
            <Dropdown
              style={{ marginLeft: '0.5rem' }}
              placeholder="Select unpaid reason"
              fluid
              selection
              value={cptCodeUnpaidReason?.value}
              onChange={(_, data) => {
                const text = data.options?.filter(
                  (o) => o.value === data.value
                );

                let _cptCode = {
                  ...cptCode,
                  note: {
                    message: cptCodeNote,
                    unpaidReason: {
                      value: data.value as string,
                      text: text ? (text[0].text as string) : '',
                    },
                  },
                };

                setCptCode(_cptCode);

                setCptCodeUnpaidReason({
                  value: data.value as string,
                  text: data.text as string,
                });
              }}
              options={cptCodeUnpaidReasonOptions}
              disabled={!!paidAmount || !!cptCode.invoiceId}
            />
          </Form.Field>
        ) : null}
        {showDismissed ? (
          <Form.Group
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Form.Field
              style={{
                display: 'flex',
              }}
            >
              <label
                style={{
                  marginRight: '0.5rem',
                  color: cptCode.paidAmount ? 'lightgrey' : 'black',
                }}
              >
                Dismissed
              </label>
              <Checkbox
                checked={cptDismissed}
                onChange={(_, { checked }) => {
                  setCptDismissed(!!checked);

                  let _cptCode = {
                    ...cptCode,
                    status: (cptCode.status = getCptCodeStatus()),
                  };

                  setCptCode(_cptCode);
                }}
                disabled={!!cptCode.paidAmount || !!cptCode.invoiceId}
              />
            </Form.Field>
            {cptDismissed && (
              <Form.Field
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '50%',
                }}
              >
                <label>Dismissed Reason:</label>
                <Dropdown
                  style={{ marginLeft: '0.5rem', maxWidth: '10rem' }}
                  fluid
                  selection
                  options={claimDismissedOptions}
                  value={cptDismissedReason || ''}
                  placeholder="Select reason"
                  disabled={!!cptCode.invoiceId}
                  onChange={(e, data) => {
                    setCptDismissedReason(data.value as string);

                    let _cptCode = {
                      ...cptCode,
                      dismissedReason: data.value as string,
                    };

                    setCptCode(_cptCode);
                  }}
                />
              </Form.Field>
            )}
          </Form.Group>
        ) : null}
        <Form.Group
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {showCompensated ? (
            <>
              {isAdminCptCode && (
                <StyledCptCodeReasonContainer>
                  <div style={{ width: '100%' }}>
                    <Form.Field
                      style={{
                        display: 'flex',
                      }}
                    >
                      <label
                        style={{
                          marginRight: '0.5rem',
                          color: cptCode.paidAmount ? 'lightgrey' : 'black',
                        }}
                      >
                        Compensated
                      </label>
                      <Checkbox
                        checked={cptCompensated}
                        onChange={(_, { checked }) => {
                          setCptDismissed(false);
                          setCptCompensated(!!checked);

                          let _cptCode = {
                            ...cptCode,
                            compensationAmount: checked
                              ? cptCodeData.compensationAmount
                              : null,
                            status: (cptCode.status = getCptCodeStatus()),
                          };

                          setCptCode(_cptCode);
                        }}
                        disabled={!!cptCode.paidAmount || !!cptCode.invoiceId}
                      />
                    </Form.Field>
                    {cptCompensated && (
                      <Form.Group inline style={{ marginTop: '1rem' }}>
                        <Form.Field>
                          <label>Amount:</label>
                        </Form.Field>
                        <Form.Field width={8}>
                          <Input
                            onKeyPress={(event: React.KeyboardEvent) => {
                              if (!onlyNumbers.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={compensationAmount}
                            onChange={(_, { value }) => {
                              let _cptCode = {
                                ...cptCode,
                                compensationAmount: parseFloat(value),
                              };

                              setCptCode(_cptCode);

                              setCompensationAmount(value);
                            }}
                            fluid
                            placeholder="Amount"
                            disabled={!cptCompensated || !!cptCode.invoiceId}
                          />
                        </Form.Field>
                      </Form.Group>
                    )}
                  </div>
                </StyledCptCodeReasonContainer>
              )}
              {showInMediation ? (
                <StyledCptCodeReasonContainer>
                  <Form.Field
                    style={{
                      display: 'flex',
                    }}
                  >
                    <label
                      style={{
                        marginRight: '0.5rem',
                        color: cptCode.paidAmount ? 'lightgrey' : 'black',
                      }}
                    >
                      In Mediation
                    </label>
                    <Checkbox
                      checked={
                        cptInMediation === undefined
                          ? undefined
                          : cptInMediation
                      }
                      onChange={(_, { checked }) => {
                        setCptDismissed(false);
                        setCptInMediation(!!checked);

                        let _cptCode = {
                          ...cptCode,
                          inMediationAmount: checked
                            ? parseFloat(
                                inMediationAmount ? inMediationAmount : ''
                              )
                            : null,
                          inMediationDueDate: checked
                            ? cptMediationDue
                            : undefined,
                          resolution: checked ? cptResolution : 'none',
                          status: (cptCode.status = getCptCodeStatus()),
                        };

                        setCptCode(_cptCode);
                      }}
                      disabled={!!cptCode.paidAmount || !!cptCode.invoiceId}
                    />
                  </Form.Field>
                  {cptInMediation && (
                    <div>
                      <Form.Field style={{ marginTop: '1rem' }}>
                        <label>Mediation due date</label>
                        <DateTimePicker
                          placeholderText="Mediation due date"
                          selected={cptMediationDue}
                          onChange={(d) => {
                            let _cptCode = {
                              ...cptCode,
                              inMediationDueDate: d ? (d as Date) : null,
                            };
                            setCptCode(_cptCode);
                            setCptMediationDue(d ? (d as Date) : null);
                          }}
                          onClear={() => setCptMediationDue(null)}
                          dateFormat={DateFormats.DATE}
                          scrollableYearDropdown
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          isClearable
                        />
                      </Form.Field>
                      <Form.Field
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '1rem',
                        }}
                      >
                        <label>Resolution:</label>
                        <Dropdown
                          style={{ marginLeft: '0.5rem' }}
                          placeholder="Select unpaid reason"
                          fluid
                          selection
                          value={cptResolution as string}
                          onChange={(_, { value }) => {
                            let _cptCode = {
                              ...cptCode,
                              resolution: value as string,
                            };

                            setCptCode(_cptCode);
                            setCptResolution(value as string);
                          }}
                          options={cptResolutionStatusOptions}
                          disabled={!cptInMediation || !!cptCode.invoiceId}
                        />
                      </Form.Field>
                      {cptResolution === CptResolutionStatus.PAID && (
                        <Form.Group inline style={{ marginTop: '1rem' }}>
                          <Form.Field>
                            <label>Amount:</label>
                          </Form.Field>
                          <Form.Field width={8}>
                            <Input
                              onKeyPress={(event: React.KeyboardEvent) => {
                                if (!onlyNumbers.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              value={inMediationAmount}
                              onChange={(_, { value }) => {
                                let _cptCode = {
                                  ...cptCode,
                                  inMediationAmount: parseFloat(value),
                                };

                                setCptCode(_cptCode);

                                setInMediationAmount(value);
                              }}
                              fluid
                              placeholder="Amount"
                              disabled={!cptInMediation || !!cptCode.invoiceId}
                            />
                          </Form.Field>
                        </Form.Group>
                      )}
                    </div>
                  )}
                </StyledCptCodeReasonContainer>
              ) : null}
            </>
          ) : null}
        </Form.Group>
        <Form.Field>
          <label>Notes</label>
          <Form.TextArea
            placeholder="There are no notes for this CPT code. You can add one and save it."
            value={cptCodeNote || ''}
            disabled={!!cptCode.invoiceId}
            onChange={(_, { value }) => {
              let _cptCode = {
                ...cptCode,
                note: {
                  message: value as string,
                  unpaidReason: {
                    value: cptCodeUnpaidReason.value as string,
                    text: cptCodeUnpaidReason.text as string,
                  },
                },
              };

              setCptCode(_cptCode);
              setCptCodeNote(value as string);
              disableSave(false);
            }}
          />
        </Form.Field>
      </Form.Field>
    </Card>
  );
};

export default CptCodeForm;

const StyledCptCodeReasonContainer = styled.div`
  width: 49%;
  border: 1px dotted lightgrey;
  border-radius: 6px;
  padding: 0.5rem;
`;
