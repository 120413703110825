import { useState, useEffect, useCallback } from 'react';
import {
  useQuery,
  useMutation,
  useSubscription,
  useLazyQuery,
} from '@apollo/client';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';
import {
  AthenaOutboundActionsQuery,
  TriggerAthenaOutboundActionMutation,
  UpdateIisStatusMutation,
  UpdateOutboundActionStatusEmrMutation,
  OutboundActionSubscription,
  ProvidersQuery,
  AllPracticesWithAthena,
} from '@bluefox/graphql/integrations/athena';
import { PrescribersQuery } from '@bluefox/graphql/providers';
import { VaccineAdministratorsQuery } from '@graphql/mappings';
import {
  OutboundAction,
  outboundActionsStatusOptions,
  iisStatusOptions,
} from '@bluefox/models/integrations/Athena';
import {
  Card,
  Icon,
  Menu,
  Message,
  Placeholder,
  Segment,
  Table,
  Button,
  Container,
  Label,
  Popup,
  Dropdown,
  Pagination,
  Confirm,
  Input,
} from 'semantic-ui-react';
import { humanizeText } from '@bluefox/lib/humanize';
import MainLayout from '@ui/MainLayout';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import {
  setDropdownBackgroundColor,
  setDropdownBorderColor,
} from '@bluefox/lib/commonStyles';
import { DateFormats } from '@bluefox/models/Dates';
import VaccineInfoBox from '@ui/Vaccines/VaccineInfoBox';
import { PracticePatient } from '@bluefox/models/Patient';
import {
  Notification,
  NotificationStatus,
  NotificationType,
  VaxSyncIntegrationNotificationContent,
} from '@bluefox/models/Notification';
import { GetNotificationsByEntityRefIdQuery } from '@graphql/communications';
import { FormValues } from '@ui/Notifications/VaxSyncIntegrationNotification/VaxSyncIntegrationNotificationForm';
import VaxSyncIntegrationNotificationModal, {
  VaxSyncIntegrationNotificationModalData,
} from '@ui/Notifications/VaxSyncIntegrationNotification/VaxSyncIntegrationNotificationModal';
import { useNotifications } from '@ui/Notifications/useNotifications';
import { notificationStatusEnumToBeShownText } from '@bluefox/constants/notifications';
import { ALL } from '@bluefox/lib/options';
import NotificationStatusDropdown from '@bluefox/ui/Notifications/NotificationStatusDropdown';
import { formatDateToMMDDYYYYV2 } from '@bluefox/lib/formatters/dates';

interface ProviderData {
  account: {
    firstName: string;
    lastName: string;
    email: string;
  };
}
interface ProvidersData {
  providers: ProviderData[];
}
interface PracticeAccount {
  title: string;
}

interface VaccineAdministrator {
  email: string;
  firstName: string;
  lastName: string;
  practiceAccounts: PracticeAccount[];
}

interface VaccineAdministratorsData {
  accounts: VaccineAdministrator[];
}

interface PracticeOption {
  text: string;
  value: string;
}

interface ProviderOption {
  text: string;
  value: string;
}

interface OutboundActionsData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  outboundActions: OutboundAction[];
}

interface Practice {
  id: string;
  name: string;
}

const ENTRIES_PER_PAGE = 15;

const AthenaOutboundActions = () => {
  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);
  const [providerOptions, setProviderOptions] = useState<ProviderOption[]>([]);
  const [prescriberOptions, setPrescriberOptions] = useState<ProviderOption[]>(
    []
  );
  const [practiceIds, setPracticeIds] = useState<string[] | undefined>([]);
  const [searchPractice, setSearchPractice] = useState<string>();
  const [searchStatus, setSearchStatus] = useState<string>();
  const [searchIisStatus, setSearchIisStatus] = useState<string>();
  const [searchFromDate, setSearchFromDate] = useState<Date | null | undefined>(
    null
  );
  const [searchToDate, setSearchToDate] = useState<Date | null | undefined>(
    null
  );
  const [criteria, setCriteria] = useState<object>({});
  const [page, setPage] = useState(0);

  const [sendNotificationModalData, setSendNotificationModalData] =
    useState<VaxSyncIntegrationNotificationModalData>();
  const [formValues, setFormValues] = useState<FormValues>({} as FormValues);

  const [searchPatient, setSearchPatient] = useState<string>('');
  const [searchNotificationStatus, setSearchNotificationStatus] = useState<
    NotificationStatus | typeof ALL
  >(ALL);
  const [searchProvider, setSearchProvider] = useState<string>();
  const [searchPrescriber, setSearchPrescriber] = useState<string>();

  const { data: practicesData } = useQuery(AllPracticesWithAthena);

  const [AthenaOutboundActionsLazyQuery, { data, loading, refetch }] =
    useLazyQuery<OutboundActionsData>(AthenaOutboundActionsQuery, {
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
    });

  const [getProviders, { data: providersData }] =
    useLazyQuery<ProvidersData>(ProvidersQuery);

  const [getPrescribers, { data: prescribersData }] =
    useLazyQuery<ProvidersData>(PrescribersQuery);
  const { sendNotificationHandler, editNotificationHandler } =
    useNotifications();

  const {
    data: notificationsInScreenData,
    loading: notificationsInScreenLoading,
    refetch: notificationsInScreenRefetch,
  } = useQuery<{
    communicationNotifications: Notification[];
  }>(GetNotificationsByEntityRefIdQuery, {
    variables: {
      entityRefIds: data?.outboundActions.map((oa) => oa.id),
    },
    skip: !data?.outboundActions || !data?.outboundActions.length,
  });

  const rawEmails = data?.outboundActions.map((item) => {
    if (item.metadata.provider?.account.email) {
      return item.metadata.provider?.account.email;
    } else {
      return '';
    }
  });

  const emails = rawEmails?.filter(
    (item, idx) => rawEmails.indexOf(item) === idx
  );

  const { data: accountsData, refetch: refetchAccountsData } =
    useQuery<VaccineAdministratorsData>(VaccineAdministratorsQuery, {
      variables: {
        emails,
      },
      skip: !emails,
    });

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  useEffect(() => {
    if (!!searchPractice) {
      AthenaOutboundActionsLazyQuery();
    }
  }, [criteria]);

  useEffect(() => {
    if (!practiceIds) return;

    getPrescribers({
      variables: {
        practiceIds,
      },
    });

    getProviders({
      variables: {
        practiceIds,
      },
    });
  }, [practiceIds]);

  useEffect(() => {
    if (!data && !accountsData) return;
    refetch();
    refetchAccountsData();
  }, [data, accountsData]);

  useEffect(() => {
    if (!providersData || providersData.providers.length === 0) return;

    setProviderOptions(
      providersData.providers.map(({ account: acc }: ProviderData) => ({
        text: `${acc.firstName} ${acc.lastName}`,
        value: acc.email,
      }))
    );
  }, [providersData]);

  useEffect(() => {
    if (!prescribersData || prescribersData.providers.length === 0) return;

    setPrescriberOptions(
      prescribersData.providers.map(({ account: acc }: ProviderData) => ({
        text: `${acc.firstName} ${acc.lastName}`,
        value: acc.email,
      }))
    );
  }, [prescribersData]);

  const handleStatusValue = (value: string) => {
    const statusValue =
      !!value && value !== 'allStatus' ? value?.toString() : undefined;
    setSearchStatus(statusValue || '');
  };

  const handleIisStatusValue = (value: string) => {
    const iisStatusValue =
      !!value && value !== 'allStatus' ? value?.toString() : undefined;
    setSearchIisStatus(iisStatusValue || '');
  };

  const cleanProviderData = () => {
    setProviderOptions([]);
    setSearchProvider('');
    setPrescriberOptions([]);
    setSearchPrescriber('');
  };

  const handlePracticeValue = (value: string) => {
    const practiceIds = practicesData?.allPractices.map((p: Practice) => p.id);
    const practiceValue = !!value ? value : undefined;
    setSearchPractice(practiceValue);
    setPracticeIds(practiceValue ? [practiceValue] : practiceIds);
    cleanProviderData();
  };

  const handlePrescriberValue = (value: string) => {
    const prescriberValue =
      !!value && value !== 'allPrescribers' ? value : undefined;
    setSearchPrescriber(prescriberValue);
  };

  const handleProviderValue = (value: string) => {
    const providerValue =
      !!value && value !== 'allProviders' ? value : undefined;
    setSearchProvider(providerValue);
  };

  useEffect(() => {
    if (!practicesData) return;

    setPracticeOptions(
      practicesData.allPractices.map((p: Practice) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [practicesData]);

  useEffect(() => {
    let _criteria = {};
    let _practiceId = undefined;
    let _searchStatus = undefined;
    let _searchIisStatus = undefined;
    let _searchDateRange = undefined;
    let _searchPatient = undefined;
    let _searchProvider = {};
    let _searchPrescriber = {};

    if (searchPractice) {
      _practiceId = { _in: practiceIds };
    }

    if (searchStatus) {
      _searchStatus = { _eq: searchStatus };
    }

    if (searchIisStatus) {
      _searchIisStatus = { _eq: searchIisStatus };
    }

    if (searchFromDate || searchToDate) {
      _searchDateRange = {
        _gte: searchFromDate,
        _lte: searchToDate ?? new Date(),
      };
    }

    if (searchPatient) {
      _searchPatient = {
        _or: [
          { mrn: { _ilike: `%${searchPatient}%` } },
          {
            patientData: {
              _or: [
                { firstName: { _ilike: `%${searchPatient}%` } },
                { lastName: { _ilike: `%${searchPatient}%` } },
              ],
            },
          },
        ],
      };
    }

    if (searchPrescriber) {
      _searchPrescriber = {
        prescriber: { account: { email: searchPrescriber } },
      };
    }

    if (searchProvider) {
      _searchProvider = { provider: { account: { email: searchProvider } } };
    }

    _criteria = {
      practiceId: _practiceId,
      status: _searchStatus,
      iisStatus: _searchIisStatus,
      createdAt: _searchDateRange,
      practicePatient: _searchPatient,
      metadata: {
        _contains: { ..._searchProvider, ..._searchPrescriber },
      },
      ...(searchNotificationStatus && searchNotificationStatus !== ALL
        ? {
            notification: {
              status: { _eq: searchNotificationStatus },
            },
          }
        : {}),
    };

    setCriteria(_criteria);
  }, [
    searchPractice,
    searchStatus,
    searchIisStatus,
    searchFromDate,
    searchToDate,
    searchPatient,
    searchProvider,
    searchPrescriber,
    searchNotificationStatus,
    practiceIds,
  ]);

  const refetchNotificationInScreen = useCallback(async () => {
    try {
      await notificationsInScreenRefetch?.();
    } catch (error) {
      toast({
        title: `Failed to refetch notification list. Error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  }, [notificationsInScreenRefetch]);

  return (
    <MainLayout
      path={[
        { text: 'Athena Integration', to: '/integration-athena' },
        { text: 'Outbound Actions' },
      ]}
    >
      <Container fluid>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="list" style={{ marginRight: '0.6rem' }} />
              Outbound Actions
            </Card.Header>
            <Card.Description>
              <Menu borderless style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by practice"
                      fluid
                      search
                      selection
                      onChange={(e, data) => {
                        handlePracticeValue(data.value?.toString() || '');
                      }}
                      options={[...practiceOptions]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by Prescriber"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handlePrescriberValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All Prescribers', value: 'allPrescribers' },
                        ...prescriberOptions,
                      ]}
                      disabled={!practiceIds?.length}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by Given By"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleProviderValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All Providers', value: 'allProviders' },
                        ...providerOptions,
                      ]}
                      disabled={!practiceIds?.length}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by status"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleStatusValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All status', value: 'allStatus' },
                        { text: 'Completed', value: 'completed' },
                        { text: 'Pending', value: 'pending' },
                        { text: 'Error', value: 'error' },
                        { text: 'Finished', value: 'finished' },
                      ]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by IIS status"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleIisStatusValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All status', value: 'allStatus' },
                        { text: 'Pending', value: 'pending' },
                        { text: 'Auto', value: 'auto' },
                        { text: 'Canid', value: 'canid' },
                        { text: 'Practice', value: 'practice' },
                      ]}
                    />
                  </Menu.Item>

                  <Menu.Item>
                    <Input
                      style={{ minWidth: '15rem' }}
                      placeholder="Search patient..."
                      value={searchPatient}
                      onChange={(_, { value }) => {
                        setSearchPatient(value);
                      }}
                    />
                  </Menu.Item>
                  <Menu.Item
                    style={{
                      display: 'flex',
                    }}
                  >
                    <label
                      htmlFor="notification_status_dropdown_filter"
                      style={{ marginRight: '0.5rem' }}
                    >
                      <b>Notification Status: </b>
                    </label>
                    <NotificationStatusDropdown
                      internalTools
                      value={searchNotificationStatus}
                      onChange={(value) => {
                        setSearchNotificationStatus(
                          value as NotificationStatus | typeof ALL
                        );
                      }}
                    />
                  </Menu.Item>
                </Menu.Menu>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Menu.Item>
                    <label style={{ marginRight: '0.5rem', width: '8rem' }}>
                      <b>Search by date range:</b>
                    </label>
                    <DateTimePicker
                      placeholderText="From..."
                      selected={searchFromDate}
                      onChange={(d) => {
                        setSearchFromDate(d ? (d as Date) : undefined);
                      }}
                      onSelect={(value) =>
                        setSearchFromDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setSearchFromDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <DateTimePicker
                      placeholderText="To..."
                      selected={searchToDate}
                      onChange={(d) => {
                        setSearchToDate(d ? (d as Date) : undefined);
                      }}
                      onSelect={(value) =>
                        setSearchToDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setSearchToDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Practice</Table.HeaderCell>
                    <Table.HeaderCell>Patient</Table.HeaderCell>
                    <Table.HeaderCell>Date of Service</Table.HeaderCell>
                    <Table.HeaderCell>Vaccine</Table.HeaderCell>
                    <Table.HeaderCell>Prescriber</Table.HeaderCell>
                    <Table.HeaderCell>Given By</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Route - Site</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Error</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Status (EMR)
                    </Table.HeaderCell>
                    <Table.HeaderCell>IIS status</Table.HeaderCell>
                    <Table.HeaderCell>
                      Consent to Report to IIS
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {loading ? (
                    <Table.Row>
                      <Table.Cell colSpan={11}>
                        <Segment basic>
                          <Placeholder fluid>
                            <Placeholder.Header>
                              <Placeholder.Line />
                              <Placeholder.Line />
                            </Placeholder.Header>
                          </Placeholder>
                        </Segment>
                      </Table.Cell>
                    </Table.Row>
                  ) : !!data?.outboundActions.length &&
                    !!accountsData?.accounts.length ? (
                    data.outboundActions.map((oa) => {
                      const finalData = accountsData?.accounts.find(
                        (elem) =>
                          elem.email === oa.metadata.provider?.account.email
                      );

                      const newItem = {
                        ...oa,
                        firstName: finalData?.firstName,
                        lastName: finalData?.lastName,
                        title: finalData?.practiceAccounts[0].title,
                      };

                      return (
                        <OutboundActionsRow
                          key={newItem.id}
                          data={newItem}
                          onSave={refetch}
                          notificationsLoading={notificationsInScreenLoading}
                          notifications={
                            notificationsInScreenData?.communicationNotifications
                          }
                          onSendNotification={() => {
                            setFormValues({
                              version: 2,
                            });
                            const patientData = newItem.metadata.patient;
                            setSendNotificationModalData({
                              fullPatienName: `${patientData?.lastName} ${patientData?.firstName}`,
                              givenAt: newItem.metadata.givenAt,
                              practiceTimezone: newItem.practice.timezone,
                              mrn: newItem.metadata.patient.mrn,
                              practiceId: newItem.practiceId,
                              practicePatientId: newItem.practicePatient?.id,
                              entityRefId: newItem.id,
                              vaccinationId: oa.entityRef,
                              vaccineName: oa.metadata.vaccine?.name || '-',
                              inventoryVfc: oa.metadata.inventory?.vfc,
                              lot: oa.metadata.inventory?.lot,
                            });
                          }}
                        />
                      );
                    })
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={11}>
                        <Message>No Outbound Actions Found</Message>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                    <Table.HeaderCell colSpan={12} textAlign="right">
                      <Pagination
                        disabled={!total || total < ENTRIES_PER_PAGE}
                        defaultActivePage={1}
                        boundaryRange={0}
                        siblingRange={1}
                        onPageChange={(e, { activePage }) =>
                          setPage(activePage as number)
                        }
                        totalPages={totalPages}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Card.Description>
          </Card.Content>
        </Card>
        <VaxSyncIntegrationNotificationModal
          open={!!sendNotificationModalData}
          fullPatienName={sendNotificationModalData?.fullPatienName}
          givenAt={sendNotificationModalData?.givenAt}
          practiceTimezone={sendNotificationModalData?.practiceTimezone}
          mrn={sendNotificationModalData?.mrn}
          practiceId={sendNotificationModalData?.practiceId}
          practicePatientId={sendNotificationModalData?.practicePatientId}
          notificationType={NotificationType.athena}
          entityRefId={sendNotificationModalData?.entityRefId}
          vaccinationId={sendNotificationModalData?.vaccinationId}
          vaccineName={sendNotificationModalData?.vaccineName}
          inventoryVfc={sendNotificationModalData?.inventoryVfc}
          lot={sendNotificationModalData?.lot}
          onClose={() => {
            setFormValues({});
            setSendNotificationModalData(undefined);
          }}
          onCreate={async (notification: Notification) => {
            setFormValues({});
            setSendNotificationModalData(undefined);
            await sendNotificationHandler(notification);
            await refetchNotificationInScreen();
          }}
          onEdit={async (
            notification: Notification,
            content: VaxSyncIntegrationNotificationContent,
            newStatusLogItem: any
          ) => {
            await editNotificationHandler(
              notification,
              content,
              newStatusLogItem
            );
            await refetchNotificationInScreen();
          }}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </Container>
    </MainLayout>
  );
};

//-----------------------------------OutboundActionsRow-----------------------------------//

interface OutboundActionsRowProps {
  data: OutboundAction;
  onSave: () => void;
  onSendNotification?: () => void;
  notificationsLoading: boolean;
  notifications?: Notification[];
}

interface DosageType {
  dose: number;
  unit: string;
}

const OutboundActionsRow = ({
  data,
  onSave,
  onSendNotification,
  notificationsLoading,
  notifications,
}: OutboundActionsRowProps) => {
  const [showConfirmRunAction, setShowConfirmRunAction] = useState(false);

  const { data: oaData } = useSubscription(OutboundActionSubscription, {
    variables: {
      outboundActionId: data.id,
    },
    skip: !data,
  });

  const [triggerAthenaOutboundAction, { data: outboundActoinResponse }] =
    useMutation(TriggerAthenaOutboundActionMutation, {
      variables: {
        outboundActionId: data.id,
      },
      refetchQueries: [
        {
          query: AthenaOutboundActionsQuery,
        },
      ],
    });

  const handleTriggerAction = () => {
    triggerAthenaOutboundAction()
      .then((r) => {
        toast({
          title: 'Action ran successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const [updateIisStatus] = useMutation(UpdateIisStatusMutation);

  const [updateStatusEmr] = useMutation(UpdateOutboundActionStatusEmrMutation);

  const handleIisStatusUpdate = (iisStatus: string) => {
    updateIisStatus({
      variables: {
        outboundActionId: data.id,
        iisStatus,
      },
    })
      .then((r) => {
        toast({
          title: 'IIS status saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleStatusEmrUpdate = (statusEmr: string) => {
    updateStatusEmr({
      variables: {
        outboundActionId: data.id,
        statusEmr,
      },
    })
      .then((r) => {
        toast({
          title: 'Status saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const inventoryUsedLabelText = () => {
    if (data.metadata.inventory?.vfc) return 'VFC';
    return 'Private';
  };

  const getConsentForIis = (practicePatient: PracticePatient | undefined) => {
    if (!practicePatient || practicePatient?.consentForIis === null) {
      return '-';
    }
    return practicePatient?.consentForIis ? 'Yes' : 'No';
  };

  const renderSendNotificationButton = useCallback(() => {
    const notification = notifications?.find((n) => n.entityRefId === data.id);
    return (
      <>
        <Popup
          content={`${
            notificationStatusEnumToBeShownText[
              notification?.status as NotificationStatus
            ] || 'Send a notification'
          }`}
          trigger={
            <Button
              style={{ margin: '0.1rem' }}
              loading={notificationsLoading}
              icon="send"
              size="mini"
              color={notification ? 'green' : 'blue'}
              onClick={onSendNotification}
            />
          }
        />
        {notification?.status && (
          <p>
            <b>Notification Status:</b>
            {` ${
              notificationStatusEnumToBeShownText[
                notification?.status as NotificationStatus
              ]
            }`}
          </p>
        )}
      </>
    );
  }, [data.id, notifications, notificationsLoading, onSendNotification]);

  return (
    <Table.Row>
      <Table.Cell>{data.practice.name}</Table.Cell>
      <Table.Cell>
        {`${data.metadata.patient.firstName} ${
          data.metadata.patient.lastName
        } (${data.metadata.patient.birthdate || '-'})`}
        <Label
          basic
          size="tiny"
          content={`MRN: ${data.metadata.patient.mrn}`}
        />
      </Table.Cell>
      <Table.Cell>
        {formatDateToMMDDYYYYV2(
          String(data.metadata.givenAt),
          data.practice.timezone
        )}
      </Table.Cell>
      <Table.Cell>
        <StyledDivCol>
          {data.metadata.primaryVaccinationId ? (
            <Popup
              trigger={
                <Icon
                  name="warning sign"
                  color="yellow"
                  style={{ margin: '0 auto' }}
                />
              }
              content="Associated"
              position="top center"
            />
          ) : null}
          {data.metadata.vaccine?.name || '-'}
          <VaccineInfoBox
            ndc={data.metadata.vaccine?.saleNdc || '_'}
            manufacturer={data.metadata.vaccine?.manufacturer || '_'}
            types={data.metadata.vaccine?.types?.map((t: string) => t) || '_'}
            dosage={
              data.metadata.vaccine?.dosage?.map((item: DosageType) => {
                return `${item.dose}${item.unit}`;
              }) || '_'
            }
            inventoryUsed={inventoryUsedLabelText() || '_'}
            lot={data.metadata.inventory?.lot || '-'}
            expiration={data.metadata.inventory?.expiration || '_'}
          />
        </StyledDivCol>
      </Table.Cell>
      <Table.Cell>
        {data?.metadata?.prescriber?.account?.firstName
          ? `${data.metadata.prescriber.account.firstName} ${data.metadata.prescriber.account.lastName} (${data.metadata.prescriber.title})`
          : '-'}
      </Table.Cell>
      <Table.Cell>
        {data?.metadata?.provider?.account?.firstName
          ? `${data.metadata.provider.account.firstName} ${data.metadata.provider.account.lastName} (${data.metadata.provider.title})`
          : '-'}
      </Table.Cell>
      <Table.Cell>
        <Label
          basic={data.metadata.route !== 'SC'}
          size="small"
          color={data.metadata.route === 'SC' ? 'teal' : undefined}
          image
          content={data.metadata.route || '-'}
          detail={
            data.metadata.site
              ? humanizeText(data.metadata.site, {
                  capitalize: 'all',
                  delimiter: '_',
                })
              : '-'
          }
        ></Label>
      </Table.Cell>
      <Table.Cell>
        {oaData?.outboundActionSubscription.error ? (
          <Popup
            on="click"
            trigger={<Button size="mini" content="Show Error" />}
            position="top right"
            content={oaData.outboundActionSubscription.error.message}
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        <Label
          basic
          size="small"
          content={humanizeText(data.metadata.action, { capitalize: 'first' })}
        />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          style={{
            backgroundColor: setDropdownBackgroundColor(
              oaData?.outboundActionSubscription.status
            ),
            borderColor: setDropdownBorderColor(
              oaData?.outboundActionSubscription.status
            ),
            minWidth: '8rem',
          }}
          fluid
          selection
          options={outboundActionsStatusOptions}
          value={oaData?.outboundActionSubscription.status}
          onChange={(e, data) => {
            handleStatusEmrUpdate(data.value?.toString() || '');
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          style={{
            backgroundColor:
              data.iisStatus !== 'pending' ? '#fcfff5' : '#f2f3f3',
            borderColor: data.iisStatus !== 'pending' ? '#558555' : '#6d7878',
            maxWidth: '6.5rem',
          }}
          fluid
          selection
          options={iisStatusOptions}
          value={data.iisStatus}
          onChange={(e, data) => {
            handleIisStatusUpdate(data.value?.toString() || '');
          }}
        />
      </Table.Cell>
      <Table.Cell>{getConsentForIis(data.practicePatient)}</Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          trigger={
            <Button
              size="mini"
              color="teal"
              icon="play"
              onClick={() => {
                setShowConfirmRunAction(true);
              }}
              disabled={data.status !== 'error'}
            />
          }
          content="Run Action"
        />
        {renderSendNotificationButton()}
      </Table.Cell>
      <Confirm
        size="mini"
        content="Are you sure you want to run subscription?"
        confirmButton="Yes"
        open={showConfirmRunAction}
        onCancel={() => setShowConfirmRunAction(false)}
        onConfirm={() => {
          handleTriggerAction();
          setShowConfirmRunAction(false);
        }}
      />
    </Table.Row>
  );
};

const StyledDivCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export default AthenaOutboundActions;
