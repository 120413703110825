import { Practice, PracticeSettings } from './Practice';

export enum OrganizationSettingsPaths {
  FEE = 'fee',
  DISCOUNT = 'discount',
  INVOICING = 'invoicing',
  STRIPE_SETTINGS = 'stripeSettings',
  BILLING_REPORT = 'billingReport',
}

export interface Organization {
  id: string;
  name: string;
  handler: string;
  practices: Practice[];
  credit?: number;
  settings?: OrganizationSettings;
  balance?: number;
}

export enum StripeSettingsPaymentMethod {
  AUTO = 'auto',
  MANUAL = 'manual',
}
export interface StripeSettings {
  enabled: boolean;
  customerId?: string;
  daysUntilDue?: number;
  paymentMethod?: StripeSettingsPaymentMethod;
}
export interface OrganizationSettings {
  fee?: {
    enabled: boolean;
    description: string;
    amount: number;
    minimumAmountToRecover: number;
  };
  discount?: {
    enabled: boolean;
    description: string;
    percentage: number;
  };
  invoicing?: {
    strategy: InvoicingStrategy;
  };
  balanceMovements?: {
    availableOnPracticePortal: boolean;
  };
  invoiceReceiverEmails?: string[];
  stripeSettings?: StripeSettings;
  billingReport?: {
    showAdministrationAmounts?: boolean;
  };
}

export enum InvoicingStrategy {
  BY_PRACTICE = 'by_practice',
  BY_ORGANIZATION = 'by_organization',
}
