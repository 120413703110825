export const VACCINE_TYPES: ReadonlyArray<string> = [
  'HepB',
  'ROTAVIRUS',
  'DTAP',
  'HIB',
  'PneumoPCV',
  'POLIO',
  'FLU',
  'MMR',
  'VARICELLA',
  'HepA',
  'COVID-19',
  'TDAP',
  'HPV',
  'MENING',
  'MeningB',
  'ADENO',
  'ANTHRAX',
  'BCG',
  'CHOLERA',
  'DENGUE',
  'H1N1 flu',
  'H5N1 flu',
  'Japanese encephalitis',
  'MEASLES',
  'RABIES',
  'TETANUS',
  'Tick-borne encephalitis',
  'TYPHOID',
  'YELLOWFEVER',
  'ZOSTER',
  'PneumoPPSV',
  'HBIG',
  'RUBELLA',
  'SMALLPOX-MONKEYPOX',
  'RSV',
  'TUBERCULOSIS',
  'TD',
] as const;

export const vaccineTypeNames: { [key: string]: string } = {
  ADENO: 'Adeno',
  ANTHRAX: 'Anthrax',
  BCG: 'BCG',
  CHOLERA: 'Cholera',
  'COVID-19': 'COVID-19',
  DENGUE: 'Dengue',
  DTAP: 'DTaP',
  FLU: 'Influenza',
  'H1N1 flu': 'H1N1 Influenza',
  'H5N1 flu': 'H5N1 Influenza',
  HepA: 'HepA',
  HepB: 'HepB',
  HIB: 'Hib',
  HPV: 'HPV',
  MEASLES: 'Measles',
  MENING: 'Meningococcal',
  MeningB: 'MenB',
  MMR: 'MMR',
  PneumoPCV: 'Pneumo PCV',
  PneumoPPSV: 'Pneumo PPSV',
  POLIO: 'Polio',
  RABIES: 'Rabies',
  ROTAVIRUS: 'Rotavirus',
  TDAP: 'TDaP',
  TETANUS: 'Tetanus',
  'Tick-borne encephalitis': 'Tick-borne encephalitis',
  TYPHOID: 'Typhoid',
  VARICELLA: 'VAR',
  YELLOWFEVER: 'Yellow fever',
  ZOSTER: 'Zoster',
  'Japanese encephalitis': 'Japanese encephalitis',
  HBIG: 'Hepatitis B Immune Globulin',
  RUBELLA: 'Rubella',
  'SMALLPOX-MONKEYPOX': 'Smallpox-Monkeypox',
  RSV: 'RSV',
  TUBERCULOSIS: 'Tuberculosis',
  TD: 'TD',
} as const;

export interface RecommendedRange {
  label: string;
  rangeUnit: 'month' | 'year';
  start: number;
  end: number;
}

export interface RecommendedImmunization {
  ranges: ReadonlyArray<RecommendedRange>;
  annual: boolean;
  minimumAge: number;
  efectivePeriod: number;
}

export const RoutineTypes: ReadonlyArray<string> = [
  'HepB',
  'ROTAVIRUS',
  'DTAP',
  'HIB',
  'PneumoPCV',
  'POLIO',
  'FLU',
  'MMR',
  'VARICELLA',
  'HepA',
  'COVID-19',
  'TDAP',
  'HPV',
  'MENING',
];

export const NonRoutineTypes = VACCINE_TYPES.filter(
  (v) => !RoutineTypes.includes(v)
);

export const RecommendedImmunizations: {
  [key: string]: RecommendedImmunization;
} = {
  HepB: {
    annual: false,
    minimumAge: 0,
    efectivePeriod: Infinity,
    ranges: [
      {
        label: '1st',
        rangeUnit: 'month',
        start: 0,
        end: 1,
      },
      {
        label: '2nd',
        rangeUnit: 'month',
        start: 1,
        end: 3,
      },
      {
        label: '3rd',
        rangeUnit: 'month',
        start: 6,
        end: 18,
      },
    ],
  },

  ROTAVIRUS: {
    annual: false,
    minimumAge: 2,
    efectivePeriod: Infinity,
    ranges: [
      {
        label: '1st',
        rangeUnit: 'month',
        start: 2,
        end: 3,
      },
      {
        label: '2nd',
        rangeUnit: 'month',
        start: 4,
        end: 5,
      },
    ],
  },

  DTAP: {
    annual: false,
    minimumAge: 2,
    efectivePeriod: Infinity,
    ranges: [
      {
        label: '1st',
        rangeUnit: 'month',
        start: 2,
        end: 3,
      },
      {
        label: '2nd',
        rangeUnit: 'month',
        start: 4,
        end: 5,
      },
      {
        label: '3rd',
        rangeUnit: 'month',
        start: 6,
        end: 7,
      },
      {
        label: '4th',
        rangeUnit: 'month',
        start: 15,
        end: 19,
      },
      {
        label: '5th',
        rangeUnit: 'month',
        start: 48,
        end: 72,
      },
    ],
  },

  HIB: {
    annual: false,
    minimumAge: 2,
    efectivePeriod: Infinity,
    ranges: [
      {
        label: '1st',
        rangeUnit: 'month',
        start: 2,
        end: 3,
      },
      {
        label: '2nd',
        rangeUnit: 'month',
        start: 4,
        end: 5,
      },
      {
        label: '3rd',
        rangeUnit: 'month',
        start: 12,
        end: 14,
      },
    ],
  },

  PneumoPCV: {
    annual: false,
    minimumAge: 2,
    efectivePeriod: Infinity,
    ranges: [
      {
        label: '1st',
        rangeUnit: 'month',
        start: 2,
        end: 3,
      },
      {
        label: '2nd',
        rangeUnit: 'month',
        start: 4,
        end: 5,
      },
      {
        label: '3rd',
        rangeUnit: 'month',
        start: 6,
        end: 8,
      },
      {
        label: '4rd',
        rangeUnit: 'month',
        start: 12,
        end: 14,
      },
    ],
  },

  POLIO: {
    annual: false,
    minimumAge: 2,
    efectivePeriod: Infinity,
    ranges: [
      {
        label: '1st',
        rangeUnit: 'month',
        start: 2,
        end: 3,
      },
      {
        label: '2nd',
        rangeUnit: 'month',
        start: 4,
        end: 5,
      },
      {
        label: '3rd',
        rangeUnit: 'month',
        start: 6,
        end: 18,
      },
      {
        label: '4rd',
        rangeUnit: 'month',
        start: 48,
        end: 72,
      },
    ],
  },

  FLU: {
    annual: true,
    minimumAge: 6,
    efectivePeriod: 12,
    ranges: [],
  },

  MMR: {
    annual: false,
    minimumAge: 12,
    efectivePeriod: Infinity,
    ranges: [
      {
        label: '1st',
        rangeUnit: 'month',
        start: 12,
        end: 17,
      },
      {
        label: '2nd',
        rangeUnit: 'month',
        start: 48,
        end: 72,
      },
    ],
  },

  VARICELLA: {
    annual: false,
    minimumAge: 12,
    efectivePeriod: Infinity,
    ranges: [
      {
        label: '1st',
        rangeUnit: 'month',
        start: 12,
        end: 17,
      },
      {
        label: '2nd',
        rangeUnit: 'month',
        start: 48,
        end: 72,
      },
    ],
  },

  HepA: {
    annual: false,
    minimumAge: 12,
    efectivePeriod: Infinity,
    ranges: [
      {
        label: '1st',
        rangeUnit: 'month',
        start: 12,
        end: 23,
      },
    ],
  },

  'COVID-19': {
    annual: true,
    minimumAge: 6,
    efectivePeriod: 12,
    ranges: [],
  },

  TDAP: {
    annual: false,
    minimumAge: 84,
    efectivePeriod: Infinity,
    ranges: [
      {
        label: '1st',
        rangeUnit: 'month',
        start: 84,
        end: 96,
      },
    ],
  },

  HPV: {
    annual: false,
    minimumAge: 132,
    efectivePeriod: Infinity,
    ranges: [
      {
        label: '1st',
        rangeUnit: 'month',
        start: 132,
        end: 144,
      },
    ],
  },

  MENING: {
    annual: false,
    minimumAge: 11,
    efectivePeriod: Infinity,
    ranges: [
      {
        label: '1st',
        rangeUnit: 'month',
        start: 132,
        end: 144,
      },
      {
        label: '2nd',
        rangeUnit: 'month',
        start: 192,
        end: 192,
      },
    ],
  },

  MeningB: {
    annual: false,
    minimumAge: 0,
    efectivePeriod: Infinity,
    ranges: [],
  },

  PneumoPPSV: {
    annual: false,
    minimumAge: 2,
    efectivePeriod: Infinity,
    ranges: [],
  },

  RSV: {
    annual: false,
    efectivePeriod: Infinity,
    ranges: [],
    minimumAge: 0,
  },
} as const;

export const cvxGroupMap: {
  [key: string]: any;
} = {
  '218': {
    '218': 'COVID-19',
  },
  '48': {
    '48': 'HIB',
  },
  '49': {
    '49': 'HIB',
  },
  '17': {
    '17': 'HIB',
  },
  '120': {
    '10': 'POLIO',
    '20': 'DTAP',
    '48': 'HIB',
    '09': 'TETANUS',
  },
  '133': {
    '133': 'PneumoPCV',
  },
  '207': {
    '207': 'COVID-19',
  },
  '08': {
    '08': 'HepB',
  },
  '116': {
    '116': 'ROTAVIRUS',
  },
  '03': {
    '03': 'MMR',
  },
  '83': {
    '83': 'HepA',
  },
  '21': {
    '21': 'VARICELLA',
  },
  '130': {
    '10': 'POLIO',
    '20': 'DTAP',
  },
  '43': {
    '43': 'HepB', // adult
  },
  '115': {
    '115': 'TDAP',
  },
  '20': {
    '20': 'DTAP',
  },
  '140': {
    '140': 'FLU',
  },
  '150': {
    '150': 'FLU',
  },
  '208': {
    '208': 'COVID-19',
  },
  '186': {
    '186': 'FLU',
  },
  '187': {
    '187': 'ZOSTER',
  },
  '19': {
    '19': 'BCG',
  },
  '10': {
    '10': 'POLIO',
  },
  '114': {
    '114': 'MENING',
  },
  '33': {
    '33': 'PneumoPPSV',
  },
  '158': {
    '158': 'FLU',
  },
  '50': {
    '20': 'DTAP',
    '48': 'HIB',
    '50': 'HIB',
  },
  '94': {
    '03': 'MMR',
    '21': 'VARICELLA',
    '05': 'MEASLES',
  },
  '01': {
    '01': 'DTAP', // not exists in our db
  },
  '100': {
    '100': 'PneumoPCV',
  },
  '31': {
    '31': 'HepA', // not exists , inactive
  },
  '165': {
    '165': 'HPV',
    '62': 'HPV',
  },
  '109': {
    '109': 'PneumoPCV',
  },
  '89': {
    '89': 'POLIO', // not exists
  },
  '88': {
    '88': 'FLU', // not exists
  },
  '15': {
    '15': 'FLU',
  },
  '182': {
    '182': 'POLIO',
  },
  '45': {
    '45': 'HepB',
  },
  '122': {
    '122': 'ROTAVIRUS',
  },
  '85': {
    '85': 'HepA',
  },
  '106': {
    '106': 'DTAP',
    '11': 'DTAP',
  },
  '107': {
    '107': 'DTAP',
  },
  '22': {
    '22': 'DTAP',
  },
  '152': {
    '152': 'PneumoPCV',
  },
  '30': {
    '30': 'HBIG',
  },
  '110': {
    '08': 'HepB',
    '106': 'DTAP',
    '10': 'POLIO',
  },
  '162': {
    '162': 'MeningB',
  },
  '119': {
    '119': 'ROTAVIRUS',
  },
  '46': {
    '46': 'HIB',
  },
  '136': {
    '136': 'MENING',
  },
  '47': {
    '47': 'HIB',
  },
  '163': {
    '163': 'MeningB',
  },
  '185': {
    '185': 'FLU',
  },
  '146': {
    '08': 'HepB',
    '10': 'POLIO',
    '107': 'DTAP',
    '17': 'HIB',
  },
  '203': {
    '203': 'MENING',
  },
  '09': {
    '09': 'TETANUS',
  },
  '219': {
    '219': 'COVID-19',
  },
  '161': {
    '161': 'FLU',
  },
  '134': {
    '134': 'Japanese encephalitis',
  },
  '32': {
    '32': 'MENING',
  },
  '04': {
    '05': 'MEASLES',
    '06': 'RUBELLA',
  },
  '111': {
    '111': 'FLU',
  },
  '217': {
    '217': 'COVID-19',
  },
  '126': {
    '126': 'H1N1 flu',
  },
  '125': {
    '125': 'H1N1 flu',
  },
  '51': {
    '49': 'HIB',
    '08': 'HepB',
  },
  '141': {
    '141': 'FLU',
  },
  '52': {
    '52': 'HepA',
  },
  '137': {
    '137': 'HPV',
  },
  '103': {
    '103': 'MENING',
  },
  '35': {
    '35': 'TETANUS',
  },
  '02': {
    '02': 'POLIO',
  },
  '197': {
    '197': 'FLU',
  },
  '128': {
    '128': 'H1N1 flu',
  },
  '07': {
    '07': 'MMR',
  },
  '28': {
    '28': 'DTAP',
  },
  '113': {
    '113': 'TD',
    '20': 'TD',
    '09': 'TD',
  },
  '144': {
    '144': 'FLU',
  },
  '62': {
    '62': 'HPV',
  },
  '223': {
    '223': 'Tick-borne encephalitis',
  },
  '201': {
    '201': 'FLU',
  },
  '202': {
    '202': 'FLU',
  },
  '225': {
    '225': 'COVID-19',
  },
  '300': {
    '300': 'COVID-19',
  },
  '301': {
    '301': 'COVID-19',
  },
  '149': {
    '149': 'FLU',
  },
  '205': {
    '205': 'FLU',
  },
  '171': {
    '171': 'FLU',
  },
  '75': {
    '75': 'ANTHRAX',
    '206': 'ORTHOPOXVIRUS',
  },
  '221': {
    '221': 'COVID-19',
  },
  '227': {
    '227': 'COVID-19',
  },
  '228': {
    '228': 'COVID-19',
  },
  '229': {
    '229': 'COVID-19',
  },
  '206': {
    '206': 'ORTHOPOXVIRUS',
  },
  '147': {
    '147': 'MENING',
  },
  '168': {
    '168': 'FLU',
  },
  '166': {
    '166': 'FLU',
  },
  '3': {
    '3': 'MMR',
  },
  '215': {
    '215': 'PneumoPCV',
  },
  '05': {
    '05': 'MEASLES',
  },
  '176': {
    '176': 'RABIES',
  },
  '06': {
    '06': 'RUBELLA',
  },
  '74': {
    '74': 'ROTAVIRUS',
  },
  '91': {
    '91': 'TYPHOID',
  },
  '510': {
    '510': 'COVID-19',
  },
  '500': {
    '500': 'COVID-19',
  },
  '42': {
    '42': 'HepB',
  },
  '36': {
    '36': 'VARICELLA',
  },
  '213': {
    '213': 'COVID-19',
  },
  '210': {
    '210': 'COVID-19',
  },
  '18': {
    '18': 'RABIES',
  },
  '16': {
    '16': 'FLU',
  },
  '129': {
    '129': 'Japanese encephalitis',
  },
  '108': {
    '108': 'MENING',
  },
  '306': {
    '306': 'RSV',
  },
  '307': {
    '307': 'RSV',
  },
  '96': {
    '96': 'TUBERCULOSIS',
  },
} as const;
