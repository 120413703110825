import { useQuery } from '@apollo/client';
import {
  Inventories,
  Inventory,
  InventoryStatus,
} from '@bluefox/models/Inventory';

import {
  InventoryByStatusesQuery,
  InventoryByStatusesQueryPP,
} from '@bluefox/graphql/inventory';
import { useEffect, useState } from 'react';
import { Dropdown, DropdownItemProps, Header, Label } from 'semantic-ui-react';
import { Source } from './Chat/types';

interface InventoryPickerProps {
  practiceId: string;
  statuses?: InventoryStatus[];
  onSelect?: (inventory: Inventory) => void;
  source?: Source;
}

const InventoryPicker = ({
  practiceId,
  statuses = [InventoryStatus.received],
  onSelect,
  source,
}: InventoryPickerProps) => {
  const [selectedInventoryId, setSelectedInventoryId] = useState<string>();

  const { data } = useQuery<Inventories>(
    source === Source.PP
      ? InventoryByStatusesQueryPP
      : InventoryByStatusesQuery,
    {
      variables: {
        practiceId,
        statuses: {
          _in: statuses,
        },
      },
    }
  );

  useEffect(() => {
    if (!onSelect || !selectedInventoryId || !data) return;
    const inventory = data.inventories.find(
      (i) => i.id === selectedInventoryId
    );
    if (inventory) onSelect(inventory);
  }, [selectedInventoryId, data, onSelect]);

  const options =
    data && data?.inventories
      ? data?.inventories.map<DropdownItemProps>((i) => ({
          key: i.id,
          value: i.id,
          text:
            source === Source.PP
              ? `${i.vaccine.name} - ${i.lot} - ${i.vfc ? 'VFC' : 'Private'}`
              : `${i.lot} ${i.vaccine.name}`,
          content: (
            <Header as="h4" textAlign="justified" key={i.id}>
              <Header.Content>
                {source === Source.PP
                  ? `${i.vaccine.name} - ${i.lot}`
                  : `${i.lot} (${i.vaccine.name})`}
              </Header.Content>
              {source === Source.PP ? (
                <Header.Subheader>
                  <Label color={i.vfc ? 'orange' : 'teal'}>
                    {i.vfc ? 'VFC' : 'Private'}
                  </Label>
                  <Label>
                    Doses<Label.Detail>{i.doses}</Label.Detail>
                  </Label>
                </Header.Subheader>
              ) : (
                <Header.Subheader>
                  <Label color="orange">
                    Expiration
                    <Label.Detail>{i.expiration}</Label.Detail>
                  </Label>
                  <Label color="teal">
                    Doses<Label.Detail>{i.doses}</Label.Detail>
                  </Label>
                  {i.vfc && <Label color="red">VFC</Label>}
                </Header.Subheader>
              )}
            </Header>
          ),
        }))
      : [];

  return (
    <Dropdown
      fluid
      placeholder="Select an Inventory"
      search
      options={[{ text: null, value: undefined }, ...options]}
      selection
      onChange={(_, { value }) =>
        value ? setSelectedInventoryId(value as string) : null
      }
    />
  );
};

export default InventoryPicker;
