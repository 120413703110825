import { InsuranceCompany } from './Insurances';

export type InsuranceCompanyPlan = {
  id?: string;
  insuranceCompanyId: string;
  insuranceCompany: InsuranceCompany;
  isVfc: boolean;
  name: string;
  notes: string;
};

export type InsuranceCompanyPlanInput = Omit<
  InsuranceCompanyPlan,
  'insuranceCompany'
>;

export type InsuranceCompaniesPlanFilter = {
  insuranceCompanyId?: string;
  isVfc?: boolean;
  name?: string;
};

export const eligibilityOptions = [
  {
    text: 'Eligible For VFC',
    value: true,
  },
  {
    text: 'Not Eligible',
    value: false,
  },
];
