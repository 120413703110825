import { InvoicingStrategy, Organization } from './Organization';
import { Invoice } from '@bluefox/models/Invoice';
import { Practice } from './Practice';

export enum MovementType {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum MovementReason {
  INVOICE_PAYMENT = 'invoice_payment',
  INVOICE_CREATION = 'invoice_creation',
  INVENTORY_AT_LAUNCH = 'inventory_at_launch',
  OTHER = 'other',
  ADJUSTMENT = 'adjustment', // this type do no send data to stripe
}

export enum HasInvoice {
  YES = 'yes',
  NO = 'no',
}

// TODO: Move this to a generics types
export enum SelectAll {
  ALL = 'all',
}

export const balanceMovementReasonsOptions = [
  {
    text: 'Invoice Payment',
    value: MovementReason.INVOICE_PAYMENT,
  },
  {
    text: 'Invoice Creation',
    value: MovementReason.INVOICE_CREATION,
  },
  {
    text: 'Inventory at Launch',
    value: MovementReason.INVENTORY_AT_LAUNCH,
  },
  {
    text: 'Other',
    value: MovementReason.OTHER,
  },
  {
    text: 'Adjustment',
    value: MovementReason.ADJUSTMENT,
  },
];

export const balanceMovementTypesOptions = [
  {
    text: 'Credit',
    value: MovementType.CREDIT,
  },
  {
    text: 'Debit',
    value: MovementType.DEBIT,
  },
];

export const selectAllOption = [
  {
    text: 'All',
    value: SelectAll.ALL,
  },
];

export const hasInvoiceOptions = [
  {
    text: 'Yes',
    value: HasInvoice.YES,
  },
  {
    text: 'No',
    value: HasInvoice.NO,
  },
];

export interface BalanceMovements {
  id?: string;
  amount?: number;
  type: MovementType;
  reason: MovementReason;
  organizationId: string;
  date?: string;
  createdAt?: Date;
  updatedAt?: Date;
  invoiceId?: string;
  practiceId?: string;
  externalId?: string;
  externalInvoiceId?: string;
  currentBalance?: number;
  comment?: string;
  invoicingStrategy: InvoicingStrategy;
  invoice?: Invoice;
  practice?: Practice;
  organization?: Organization;
  amountToPay?: number;
}

type BalanceMovementBaseFields = keyof BalanceMovements;
type RelationFields<T> = `{ ${keyof T & string} ${string} }`;

type BalanceMovementRelationFields =
  | `type ${RelationFields<MovementType>}`
  | `reason ${RelationFields<MovementReason>}`
  | `organization ${RelationFields<Organization>}`
  | `invoicingStrategy ${RelationFields<InvoicingStrategy>}`
  | `invoice ${RelationFields<Invoice>}`
  | `practice ${RelationFields<Practice>}`;

export type CreateBalanceInput = Pick<
  BalanceMovements,
  'amount' | 'type' | 'reason' | 'organizationId' | 'practiceId' | 'comment'
>;

export type BalanceMovementsFields =
  | BalanceMovementBaseFields
  | BalanceMovementRelationFields;

export type BalanceMovementsExtractFields<T extends BalanceMovementsFields[]> =
  {
    [K in T[number] as K extends keyof BalanceMovements
      ? K
      : K extends `type ${RelationFields<MovementType>}`
      ? 'type'
      : K extends `reason ${RelationFields<MovementReason>}`
      ? 'reason'
      : K extends `organization ${RelationFields<Organization>}`
      ? 'organization'
      : K extends `invoicingStrategy ${RelationFields<InvoicingStrategy>}`
      ? 'invoicingStrategy'
      : K extends `invoice ${RelationFields<Invoice>}`
      ? 'invoice'
      : K extends `practice ${RelationFields<Practice>}`
      ? 'practice'
      : never]: K extends keyof BalanceMovements
      ? BalanceMovements[K]
      : K extends `type ${RelationFields<MovementType>}`
      ? Pick<MovementType, Extract<keyof MovementType, string>>
      : K extends `reason ${RelationFields<MovementReason>}`
      ? Pick<MovementReason, Extract<keyof MovementReason, string>>
      : K extends `organization ${RelationFields<Organization>}`
      ? Pick<Organization, Extract<keyof Organization, string>>
      : K extends `invoicingStrategy ${RelationFields<InvoicingStrategy>}`
      ? Pick<InvoicingStrategy, Extract<keyof InvoicingStrategy, string>>
      : K extends `invoice ${RelationFields<Invoice>}`
      ? Pick<Invoice, Extract<keyof Invoice, string>>
      : K extends `practice ${RelationFields<Practice>}`
      ? Pick<Practice, Extract<keyof Practice, string>>
      : never;
  };

export type BalanceMovementFilter = {
  organizationId?: string;
  practiceId?: string;
  type?: MovementType | SelectAll;
  reason?: MovementReason | SelectAll;
  dateFrom?: Date;
  dateTo?: Date;
  hasInvoice?: HasInvoice | SelectAll;
};

export type InvoiceDebitMovementInput = {
  invoiceId: string;
  invoiceAmount: number;
  organizationId: string;
  practiceId?: string;
  invoicingStrategy: InvoicingStrategy;
};

export type GetBalanceMovementsQueryResponse = {
  balanceMovements: BalanceMovements[];
  totalBalanceMovements: {
    aggregate: { count: number };
  };
};
