import React, { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { GetTickets, UpdateTicketStatus } from '@bluefox/graphql/tickets';
import { Ticket } from '@bluefox/models/Tickets';
import { Button, Form, Icon, Modal, Radio, Segment } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { GetClaimsVFCInconsistencies } from '@bluefox/graphql/billing';
import { BorrowingPracticesAndTicketsQuery } from '@graphql/practices';

type BorrowingIssueFormProps = {
  ticket: Ticket;
};

const BorrowingIssueForm = ({ ticket }: BorrowingIssueFormProps) => {
  const [open, setOpen] = useState(false);
  const [borrowWasRemoved, setBorrowWasRemoved] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [closeIssue] = useMutation(UpdateTicketStatus);

  const handleCloseIssue = async () => {
    try {
      await closeIssue({
        variables: {
          id: ticket.id,
          status: borrowWasRemoved ? 'closed_and_removed' : 'closed',
        },
        onCompleted(data) {
          setConfirmationOpen(false);
          setOpen(false);
        },
        refetchQueries: [
          GetTickets(false),
          GetClaimsVFCInconsistencies(false, true),
          BorrowingPracticesAndTicketsQuery,
        ],
      });
      toast({
        title: `Ticket closed successfully`,
        type: 'success',
        time: 1000,
      });
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  };

  const ticketFields = useMemo(() => {
    return ticket.detail.fields.filter(
      (field) =>
        field.title !== 'VfcInconsistencyId' && field.title !== 'Handler'
    );
  }, [ticket]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Modal
        dimmer="blurring"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        trigger={<Button color="red" content="Issue" size="mini" />}
      >
        <Modal.Header>
          <Icon name="bullhorn" />
          Issue with inconsistency
        </Modal.Header>
        <Modal.Content>
          <Segment padded>
            <Form>
              {ticketFields.map((field) =>
                field.title === 'Issue' ? (
                  <Form.TextArea
                    key={field.title}
                    label={field.title}
                    value={field.detail}
                  />
                ) : (
                  <Form.Input
                    key={field.title}
                    fluid
                    label={field.title}
                    value={field.detail}
                  />
                )
              )}
              <br />
              <Form.Field>
                <label>Was borrow removed when the issue was solved?</label>
                <Radio
                  toggle
                  checked={borrowWasRemoved}
                  onChange={(_, { checked }) => setBorrowWasRemoved(!!checked)}
                />
              </Form.Field>
            </Form>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            negative
            type="button"
            onClick={() => setConfirmationOpen(true)}
          >
            Close this issue
          </Button>
        </Modal.Actions>
        <Modal
          size="small"
          open={confirmationOpen}
          onClose={() => setConfirmationOpen(false)}
          onOpen={() => setConfirmationOpen(true)}
        >
          <Modal.Header icon="archive" content="Close this issue" />
          <Modal.Content>
            <p>
              Closing this issue means it will not be shown anymore. Please make
              sure to be done with this issue before closing it.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setConfirmationOpen(false)}>Cancel</Button>
            <Button negative onClick={handleCloseIssue}>
              <Icon name="remove" /> Close issue
            </Button>
          </Modal.Actions>
        </Modal>
      </Modal>
    </div>
  );
};

export default BorrowingIssueForm;
