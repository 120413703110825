import { COLORS_BY_NAME } from '@bluefox/constants/colors';
import { Message } from './types';
import { Card } from 'semantic-ui-react';

type Props = {
  message: Message;
  position?: 'left' | 'right';
};

function formatDateTime(date: string | Date | undefined) {
  if (!date) return '';
  const dateAsDate = typeof date === 'string' ? new Date(date) : date;
  return dateAsDate.toLocaleDateString();
}

const ChatMessage = ({ message, position }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: position === 'right' ? 'flex-end' : 'flex-start',
        marginBottom: '1rem',
      }}
    >
      <Card
        style={{
          padding: '0.5rem',
          width: 'max-content',
          minWidth: '15%',
          maxWidth: '90%',
          backgroundColor: `${
            position !== 'right' ? COLORS_BY_NAME.Lotion : COLORS_BY_NAME.Water
          }`,
        }}
      >
        <Card.Header style={{ textAlign: position }}>
          <b>{message.title}</b>
        </Card.Header>
        <Card.Meta style={{ textAlign: position }}>
          {formatDateTime(message.date)}
        </Card.Meta>
        <Card.Description
          style={{ wordBreak: 'break-word', textAlign: 'justify' }}
        >
          {message.message}
        </Card.Description>
      </Card>
    </div>
  );
};

export default ChatMessage;
