const dateToMMDDYYYY = (dateString: Date) =>
  new Intl.DateTimeFormat('en-US').format(dateString);

export const formatDateToMMDDYYYY = (dateString: string) => {
  const date = new Date(dateString);
  return dateToMMDDYYYY(date);
};

export const dateStrToMMDDYYYY = (
  date: string,
  middleDash: boolean = false
) => {
  const newDate = date.split('T')[0];
  const formattedDate = newDate.split('-');
  if (middleDash) {
    return `${formattedDate[1]}-${formattedDate[2]}-${formattedDate[0]}`;
  }
  return `${formattedDate[1]}/${formattedDate[2]}/${formattedDate[0]}`;
};

export function formatDateToYYYYhMMhDD(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${('00' + month).slice(-2)}-${('00' + day).slice(-2)}`;
}

export function formatDateToMMhDDhYYYY(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${('00' + month).slice(-2)}-${('00' + day).slice(-2)}-${year}`;
}

const dateMonthDDcommaYearFormatter = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'long',
  day: '2-digit',
});

export function formatDateMonthDDcommaYear(date: Date) {
  return dateMonthDDcommaYearFormatter.format(date);
}

export const formatDatetimeToMMDDYYY = (date: Date) => {
  const newDate = date.toString().split('T')[0];
  const formattedDate = newDate.split('-');
  return `${formattedDate[1]}-${formattedDate[2]}-${formattedDate[0]}`;
};

export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function getTimeWithTimezoneOffset(date: Date, timezoneOffset: number) {
  const nd = new Date(date);
  const utc = nd.getTime() + nd.getTimezoneOffset() * 60000;
  const adjustedTime = new Date(utc + 3600000 * timezoneOffset);

  const hours = adjustedTime.getHours();
  const minutes = adjustedTime.getMinutes();

  const formattedMinutes = (minutes < 10 ? '0' : '') + minutes;

  return { hours: hours, minutes: formattedMinutes };
}

export function reformatYYYYhMMhDDStringtoMMsDDsYYYY(date: string) {
  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
}

export function formatDateStringToDateTimeZero(date: string) {
  return date.split('T')?.[0];
}

export function getDaysInMonth(month: number, year: number) {
  if (month === 2) {
    // February
    if (year % 4 === 0) {
      // Leap year logic
      if (year % 100 === 0) {
        if (year % 400 === 0) {
          return 29; // Divisible by 400 -> leap year
        } else {
          return 28; // Divisible by 100 but not by 400 -> not a leap year
        }
      } else {
        return 29; // Divisible by 4 but not by 100 -> leap year
      }
    } else {
      return 28; // Not divisible by 4 -> not a leap year
    }
  } else {
    // Other months
    if ([4, 6, 9, 11].includes(month)) {
      return 30; // April, June, September, November
    } else {
      return 31; // January, March, May, July, August, October, December
    }
  }
}

// Use this function instead of formatDateToMMDDYYYY older version
export const formatToMMDDYYYY_HHMM = (dateString: string): string => {
  const date = new Date(dateString);

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  }).format(date);

  return formattedDate;
};

export const formatToMMDDYYYY_HHMM_V2 = (
  dateString: string,
  timeZone: string = 'UTC'
): string => {
  const date = new Date(dateString);

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: timeZone,
  }).format(date);

  return formattedDate;
};

//In case you want to format a dte to MM/DD/YYYY use this function, this one works!
export function formatDateToMMDDYYYYV2(
  date: string,
  timeZone: string = 'UTC'
): string {
  const dateOptions: Intl.DateTimeFormatOptions = {
    timeZone,
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  };
  const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
  return dateFormatter.format(new Date(date));
}

export function formatDateStrToMMDDYYYY(dateStr: string) {
  const [year, month, day] = dateStr.split('-');
  return `${month}/${day}/${year}`;
}

export const formatDatetimeToMMDDYYYYV2 = (date: Date | undefined) => {
  if (date === undefined) return undefined;
  const newDate = date.toString().split('T')[0];
  const formattedDate = newDate.split('-');
  return `${formattedDate[1]}/${formattedDate[2]}/${formattedDate[0]}`;
};
