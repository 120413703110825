import {
  formatDateStringToDateTimeZero,
  reformatYYYYhMMhDDStringtoMMsDDsYYYY,
} from '@bluefox/lib/formatters';
import { formatDateToMMDDYYYYV2 } from '@bluefox/lib/formatters/dates';

export function renderGivenAt(
  givenAt?: string | Date,
  practiceTimezone?: string
) {
  if (!givenAt) return '-';
  if (practiceTimezone) {
    return formatDateToMMDDYYYYV2(
      givenAt as unknown as string,
      practiceTimezone
    );
  } else {
    return reformatYYYYhMMhDDStringtoMMsDDsYYYY(
      formatDateStringToDateTimeZero(givenAt as unknown as string)
    );
  }
}
