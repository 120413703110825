import { useState, useEffect, useRef, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import styled from 'styled-components';
import {
  Menu,
  Header,
  Table,
  Button,
  Modal,
  Radio,
  ModalActions,
  Popup,
  Segment,
  Placeholder,
  Message,
  Confirm,
  Icon,
  Pagination,
  Input,
  Dropdown,
  DropdownItem,
  DropdownMenu,
} from 'semantic-ui-react';
import { usePractice } from '@bluefox/contexts';
import { BillingStrategyMappingsQuery } from '@bluefox/graphql/mappings';
import {
  PracticeBillingStrategyMutation,
  PracticeSettingsQuery,
} from '@graphql/practices';
import { Practice, PracticeSettings } from '@bluefox/models/Practice';
import {
  BillingStrategy,
  BillingStrategyBox,
  BillingStrategyOptions,
} from '@bluefox/models/Mappings';
import { debounce } from '@bluefox/lib/debounce';
import { whereLikeInput } from '@bluefox/graphql/utils';
import BillingStrategyMappingForm from './BillingStrategyMappingForm';
import { PracticeSettingsByHandlerQuery } from '@bluefox/graphql/practices';
import BoxForm from './BoxForm';
import { humanizeText } from '@bluefox/lib/humanize';

interface BillingStrategies {
  billingStrategies: BillingStrategy[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface SettingsData {
  practice: {
    settings: PracticeSettings;
  };
}

interface PracticeSettingsData {
  practices: Practice[];
}

const ENTRIES_PER_PAGE = 15;

const boolIcon = (exist: boolean | undefined) => {
  return exist ? 'check' : 'x';
};

const boolIconColor = (exist: boolean | undefined) => {
  return exist ? 'green' : 'red';
};

const BillingStrategyMapping = () => {
  const practice = usePractice();

  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const [open, setOpen] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState<BillingStrategy>();
  const [billingStrategy, setBillingStrategy] = useState<string | undefined>();
  const [strategyText, setStrategyText] = useState<string>('');

  const [providerSearchInput, setProviderSearchInput] = useState('');
  const [providerSearchQueryValue, setProviderSearchQueryValue] = useState('');
  const [insuranceSearchInput, setInsuranceSearchInput] = useState('');
  const [cpidSearchInput, setCpidSearchInput] = useState('');
  const [insuranceSearchQueryValue, setInsuranceSearchQueryValue] =
    useState('');
  const [cpidSearchQueryValue, setCpidSearchQueryValue] = useState('');
  const [openBoxForm, setOpenBoxForm] = useState(false);
  const [box, setBox] = useState('');
  const [boxData, setBoxData] = useState({});
  const [criteria, setCriteria] = useState<any[]>([]);

  const [page, setPage] = useState(0);

  const { data: settingsData, refetch: refetchSettingsData } =
    useQuery<SettingsData>(PracticeSettingsQuery, {
      variables: {
        id: practice.id,
      },
    });

  const { data: practiceSettings } = useQuery<PracticeSettingsData>(
    PracticeSettingsByHandlerQuery,
    {
      variables: {
        handler: 'canid',
      },
    }
  );

  const allowedUsersToEditNpiAndTaxId =
    practiceSettings?.practices[0].settings?.billing?.npiAndTaxIdEdition;

  const { data, loading, refetch } = useQuery<BillingStrategies>(
    BillingStrategyMappingsQuery,
    {
      variables: {
        practiceId: practice.id,
        strategy: billingStrategy,
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
      fetchPolicy: 'network-only',
    }
  );

  const [saveBillingStrategy] = useMutation(PracticeBillingStrategyMutation);

  const handleBillingStrategy = () => {
    saveBillingStrategy({
      variables: {
        practiceId: practice.id,
        strategy: {
          billing: {
            ...settingsData?.practice.settings.billing,
            strategy: billingStrategy,
          },
        },
      },
    })
      .then((r) => {
        toast({
          title: 'Billing strategy saved successfully',
          type: 'success',
          time: 1000,
        });
        setProviderSearchInput('');
        setProviderSearchQueryValue('');
        setInsuranceSearchInput('');
        setInsuranceSearchQueryValue('');
        setCpidSearchInput('');
        setCpidSearchQueryValue('');
        setShowConfirm(false);
        setOpenSettings(false);
        refetchSettingsData();
      })
      .catch((e) => {
        toast({
          title: `Billing strategy could not be saved. Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleChangeSelected = (selected: boolean) => {
    setIsSelected(selected);
  };

  const closeModal = () => {
    setIsSelected(false);
    setSelectedMapping(undefined);
    setOpen(false);
  };

  const handleRadioChange = (_: any, { value }: any) =>
    setBillingStrategy(value);

  useEffect(() => {
    if (!isSelected) return;
    setOpen(true);
  }, [isSelected]);

  useEffect(() => {
    if (!settingsData) return;
    setBillingStrategy(settingsData.practice?.settings?.billing?.strategy);
  }, [settingsData]);

  useEffect(() => {
    switch (billingStrategy) {
      case 'practice':
        setCriteria([]);
        break;
      case 'practiceAndInsurance':
        setCriteria([
          {
            _and: [
              {
                insuranceCompany: {
                  name: { _ilike: whereLikeInput(insuranceSearchQueryValue) },
                },
              },
              {
                cpid: {
                  _ilike: whereLikeInput(cpidSearchQueryValue),
                },
              },
            ],
          },
        ]);
        break;
      case 'provider':
        setCriteria([
          {
            practiceAccount: {
              account: {
                _or: [
                  {
                    firstName: {
                      _ilike: whereLikeInput(providerSearchQueryValue),
                    },
                  },
                  {
                    lastName: {
                      _ilike: whereLikeInput(providerSearchQueryValue),
                    },
                  },
                ],
              },
            },
          },
        ]);
        break;
      case 'providerAndInsurance':
        setCriteria([
          {
            _and: [
              {
                insuranceCompany: {
                  name: { _ilike: whereLikeInput(insuranceSearchQueryValue) },
                },
              },
              {
                cpid: {
                  _ilike: whereLikeInput(cpidSearchQueryValue),
                },
              },
            ],
          },
          {
            practiceAccount: {
              account: {
                _or: [
                  {
                    firstName: {
                      _ilike: whereLikeInput(providerSearchQueryValue),
                    },
                  },
                  {
                    lastName: {
                      _ilike: whereLikeInput(providerSearchQueryValue),
                    },
                  },
                ],
              },
            },
          },
        ]);
        break;
      default:
        break;
    }
  }, [
    billingStrategy,
    cpidSearchQueryValue,
    insuranceSearchQueryValue,
    providerSearchQueryValue,
  ]);

  useEffect(() => {
    switch (billingStrategy) {
      case 'practice':
        setStrategyText('based on: Practice');
        break;
      case 'practiceAndInsurance':
        setStrategyText('based on: Practice and Insurance');
        break;
      case 'provider':
        setStrategyText('based on: Provider');
        break;
      case 'providerAndInsurance':
        setStrategyText('based on: Provider and Insurance');
        break;
      default:
        break;
    }
  }, [billingStrategy]);

  useEffect(() => {
    if (!openSettings)
      setBillingStrategy(settingsData?.practice.settings.billing?.strategy);
  }, [openSettings, settingsData?.practice.settings.billing?.strategy]);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const billing = useMemo(
    () => settingsData?.practice.settings.billing,
    [settingsData]
  );

  const disabledAddNewBillingStrategy = useMemo(
    () => !billingStrategy || (billingStrategy === 'practice' && total >= 1),
    [billingStrategy, total]
  );

  return (
    <>
      <Menu borderless>
        <Menu.Item>
          <Header>
            Billing strategy
            <Header.Subheader>{strategyText}</Header.Subheader>
          </Header>
        </Menu.Item>

        <Menu.Menu
          position="right"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Menu.Item>
              <Dropdown text="Global Practice Settings">
                <DropdownMenu>
                  <DropdownItem
                    text={
                      <>
                        <Icon
                          name={boolIcon(
                            billing &&
                              BillingStrategyBox.RENDERING_PROVIDER in billing
                          )}
                          color={boolIconColor(
                            billing &&
                              BillingStrategyBox.RENDERING_PROVIDER in billing
                          )}
                        />
                        Box 31
                      </>
                    }
                    description="Rendering"
                    onClick={() => {
                      setBox(BillingStrategyBox.RENDERING_PROVIDER);
                      setBoxData(
                        billing &&
                          BillingStrategyBox.RENDERING_PROVIDER in billing
                          ? billing.renderingProvider!
                          : {}
                      );
                      setOpenBoxForm(true);
                    }}
                  />
                  <DropdownItem
                    text={
                      <>
                        <Icon
                          name={boolIcon(
                            billing &&
                              BillingStrategyBox.SERVICE_FACILITY_LOCATION in
                                billing
                          )}
                          color={boolIconColor(
                            billing &&
                              BillingStrategyBox.SERVICE_FACILITY_LOCATION in
                                billing
                          )}
                        />
                        Box 32
                      </>
                    }
                    description="Location"
                    onClick={() => {
                      setBox(BillingStrategyBox.SERVICE_FACILITY_LOCATION);
                      setBoxData(
                        billing &&
                          BillingStrategyBox.SERVICE_FACILITY_LOCATION in
                            billing
                          ? billing.serviceFacilityLocation!
                          : {}
                      );
                      setOpenBoxForm(true);
                    }}
                  />
                  <DropdownItem
                    text={
                      <>
                        <Icon
                          name={boolIcon(
                            billing &&
                              BillingStrategyBox.BILLING_PROVIDER in billing
                          )}
                          color={boolIconColor(
                            billing &&
                              BillingStrategyBox.BILLING_PROVIDER in billing
                          )}
                        />
                        Box 33
                      </>
                    }
                    description="Billing Provider"
                    onClick={() => {
                      setBox(BillingStrategyBox.BILLING_PROVIDER);
                      setBoxData(
                        billing &&
                          BillingStrategyBox.BILLING_PROVIDER in billing
                          ? billing.billingProvider!
                          : {}
                      );
                      setOpenBoxForm(true);
                    }}
                  />
                </DropdownMenu>
              </Dropdown>
            </Menu.Item>
            <Menu.Item>
              <Button
                icon="setting"
                content="Billing Strategy"
                onClick={() => setOpenSettings(true)}
              />
            </Menu.Item>
            <Menu.Item>
              <Button
                primary
                size="small"
                content="Add Billing Strategy"
                icon="plus"
                disabled={disabledAddNewBillingStrategy}
                onClick={() => {
                  setIsSelected(false);
                  setOpen(true);
                }}
              />
            </Menu.Item>
          </div>
          {billingStrategy !== BillingStrategyOptions.PRACTICE && (
            <div style={{ display: 'flex' }}>
              {(billingStrategy === BillingStrategyOptions.PROVIDER ||
                billingStrategy ===
                  BillingStrategyOptions.PROVIDER_AND_INSURANCE) && (
                <Menu.Item>
                  <Input
                    icon="search"
                    placeholder="Search provider..."
                    value={providerSearchInput}
                    onChange={(_, { value }) => {
                      setProviderSearchInput(value);
                      debouncedRef.current?.cancel();
                      debouncedRef.current = debounce(() => {
                        setProviderSearchQueryValue(value);
                      }, 500);
                      debouncedRef.current();
                    }}
                  />
                </Menu.Item>
              )}
              {(billingStrategy ===
                BillingStrategyOptions.PRACTICE_AND_INSURANCE ||
                billingStrategy ===
                  BillingStrategyOptions.PROVIDER_AND_INSURANCE) && (
                <>
                  <Menu.Item>
                    <Input
                      icon="search"
                      placeholder="Search insurance..."
                      value={insuranceSearchInput}
                      onChange={(_, { value }) => {
                        setInsuranceSearchInput(value);
                        debouncedRef.current?.cancel();
                        debouncedRef.current = debounce(() => {
                          setInsuranceSearchQueryValue(value);
                        }, 500);
                        debouncedRef.current();
                      }}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Input
                      icon="search"
                      placeholder="Search CPID..."
                      value={cpidSearchInput}
                      onChange={(_, { value }) => {
                        setCpidSearchInput(value);
                        debouncedRef.current?.cancel();
                        debouncedRef.current = debounce(() => {
                          setCpidSearchQueryValue(value);
                        }, 500);
                        debouncedRef.current();
                      }}
                    />
                  </Menu.Item>
                </>
              )}
            </div>
          )}
        </Menu.Menu>
      </Menu>
      <Table selectable>
        <Table.Header>
          <Table.Row>
            {billingStrategy === BillingStrategyOptions.PROVIDER ||
            billingStrategy ===
              BillingStrategyOptions.PROVIDER_AND_INSURANCE ? (
              <Table.HeaderCell width={5}>Provider</Table.HeaderCell>
            ) : null}
            {billingStrategy ===
              BillingStrategyOptions.PRACTICE_AND_INSURANCE ||
            billingStrategy ===
              BillingStrategyOptions.PROVIDER_AND_INSURANCE ? (
              <Table.HeaderCell width={4}>Insurance Company</Table.HeaderCell>
            ) : null}
            <Table.HeaderCell width={3}>Tax ID</Table.HeaderCell>
            {billingStrategy ===
              BillingStrategyOptions.PRACTICE_AND_INSURANCE ||
            billingStrategy ===
              BillingStrategyOptions.PROVIDER_AND_INSURANCE ? (
              <Table.HeaderCell width={3}>CPID</Table.HeaderCell>
            ) : null}
            <Table.HeaderCell width={3}>NPI</Table.HeaderCell>
            <Table.HeaderCell width={1}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            <Table.Row>
              <Table.Cell colSpan={6}>
                <Segment basic>
                  <Placeholder fluid>
                    <Placeholder.Header>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                  </Placeholder>
                </Segment>
              </Table.Cell>
            </Table.Row>
          ) : !!data && data.billingStrategies.length ? (
            data.billingStrategies.map((bs) => {
              return (
                <BillingStrategyMappingRow
                  key={bs.id}
                  data={bs}
                  billingStrategy={billingStrategy}
                  onChangeSelected={() => {
                    handleChangeSelected(true);
                    setSelectedMapping({
                      id: bs.id,
                      practiceAccount: bs.practiceAccount,
                      payerId: bs.payerId,
                      taxId: bs.taxId,
                      npi: bs.npi,
                      insuranceCompanyId: bs.insuranceCompanyId,
                      providerId: bs.providerId,
                      enabled: bs.enabled,
                    });
                  }}
                />
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={6}>
                <Message>No mappings found.</Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>Total: {total}</Table.HeaderCell>
            <Table.HeaderCell colSpan={5} textAlign="right">
              <Pagination
                disabled={!total || total < ENTRIES_PER_PAGE}
                defaultActivePage={1}
                boundaryRange={0}
                siblingRange={1}
                onPageChange={(e, { activePage }) =>
                  setPage(activePage as number)
                }
                totalPages={totalPages}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <Modal
        size="small"
        onClose={closeModal}
        onOpen={() => setOpen(open)}
        open={open}
        closeIcon
      >
        <Modal.Header>
          {isSelected ? 'Edit Claim Mapping' : 'Add Claim Mapping'}
        </Modal.Header>
        <Modal.Content>
          <BillingStrategyMappingForm
            close={closeModal}
            data={selectedMapping ? selectedMapping : null}
            billingStrategy={billingStrategy}
            onSave={refetch}
            allowedUsersToEditNpiAndTaxId={allowedUsersToEditNpiAndTaxId}
          />
        </Modal.Content>
      </Modal>
      <Modal
        size="small"
        onClose={() => setOpenSettings(false)}
        onOpen={() => setOpenSettings(openSettings)}
        open={openSettings}
        closeIcon
      >
        <Modal.Header>Billing Strategy</Modal.Header>
        <Modal.Content>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Radio
              style={{ marginRight: '1.5rem' }}
              label="Practice"
              name="radioGroup"
              value="practice"
              checked={billingStrategy === 'practice'}
              onChange={handleRadioChange}
            />
            <Radio
              style={{ marginRight: '1.5rem' }}
              label="Provider"
              name="radioGroup"
              value="provider"
              checked={billingStrategy === 'provider'}
              onChange={handleRadioChange}
            />
            <Radio
              style={{ marginRight: '1.5rem' }}
              label="Practice and Insurance"
              name="radioGroup"
              value="practiceAndInsurance"
              checked={billingStrategy === 'practiceAndInsurance'}
              onChange={handleRadioChange}
            />
            <Radio
              style={{ marginRight: '1.5rem' }}
              label="Provider and Insurance"
              name="radioGroup"
              value="providerAndInsurance"
              checked={billingStrategy === 'providerAndInsurance'}
              onChange={handleRadioChange}
            />
          </div>
        </Modal.Content>
        <ModalActions>
          <Button onClick={() => setOpenSettings(false)} type="button">
            Cancel
          </Button>
          <Button
            primary
            type="button"
            content="Save"
            icon="save"
            onClick={() => setShowConfirm(true)}
          />
        </ModalActions>
      </Modal>
      <Confirm
        open={showConfirm}
        content={
          <StyledConfirm>
            <Icon
              name="warning sign"
              size="big"
              color="red"
              style={{ marginRight: '1rem' }}
            />
            <p>
              Are you sure you want to change the billing strategy of this
              practice?
            </p>
          </StyledConfirm>
        }
        cancelButton="No"
        confirmButton="Yes"
        size="mini"
        onCancel={() => {
          setShowConfirm(false);
          setOpenSettings(false);
        }}
        onConfirm={handleBillingStrategy}
      />
      <Modal
        size="small"
        onClose={() => setOpenBoxForm(false)}
        onOpen={() => setOpenBoxForm(true)}
        open={openBoxForm}
        closeIcon
      >
        <Modal.Header>
          {isSelected
            ? `Edit ${humanizeText(box, {
                deCamelCase: true,
                capitalize: 'all',
              })}`
            : `Add ${humanizeText(box, {
                deCamelCase: true,
                capitalize: 'all',
              })}`}
        </Modal.Header>
        <Modal.Content>
          <BoxForm
            practiceId={practice.id}
            box={box}
            billing={settingsData?.practice.settings.billing}
            onSave={refetchSettingsData}
            data={boxData}
            close={() => setOpenBoxForm(false)}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

//------------------BILLING STRATEGY MAPPING ROW------------------//

interface BillingStrategyMappingRowProps {
  data?: BillingStrategy;
  billingStrategy: string | undefined;
  onChangeSelected: (selected: boolean) => void;
}
const BillingStrategyMappingRow = ({
  data,
  billingStrategy,
  onChangeSelected,
}: BillingStrategyMappingRowProps) => {
  return (
    <Table.Row>
      {billingStrategy === BillingStrategyOptions.PROVIDER ||
      billingStrategy === BillingStrategyOptions.PROVIDER_AND_INSURANCE ? (
        <Table.Cell>{`${data?.practiceAccount?.account.firstName} ${data?.practiceAccount?.account.lastName}`}</Table.Cell>
      ) : null}
      {billingStrategy === BillingStrategyOptions.PRACTICE_AND_INSURANCE ||
      billingStrategy === BillingStrategyOptions.PROVIDER_AND_INSURANCE ? (
        <Table.Cell>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {data?.insuranceCompany?.name}
            <p
              style={{ fontSize: '12px' }}
            >{`Payer ID: ${data?.insuranceCompany?.payerId}`}</p>
          </div>
        </Table.Cell>
      ) : null}
      <Table.Cell>{data?.taxId}</Table.Cell>

      {billingStrategy === BillingStrategyOptions.PRACTICE_AND_INSURANCE ||
      billingStrategy === BillingStrategyOptions.PROVIDER_AND_INSURANCE ? (
        <Table.Cell>{data?.cpid}</Table.Cell>
      ) : null}
      <Table.Cell>{data?.npi}</Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          content="Edit"
          trigger={
            <Button
              primary
              size="mini"
              icon="edit"
              onClick={() => {
                onChangeSelected(true);
              }}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

const StyledConfirm = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 2rem 1rem 2rem;
  background-color: #fff6f6;
`;

export default BillingStrategyMapping;
