import {
  AdjustmentKind,
  AdjustmentReasons,
  InventoryAdjustmentDetailStatuses,
  InventoryAdjustmentStatuses,
  InventoryAdjustmentTypes,
} from '@bluefox/models/InventoryAdjustment';
import { InventoryStatus, VaccinesStockData } from '@bluefox/models/Inventory';
import { VFCInconsistency } from '@bluefox/models/VFCInconsistency';
import { DbData, OnConflict } from '@bluefox/models/Generics';
import { Vaccine } from '@bluefox/models/Vaccine';
import { Practice } from '@bluefox/models/Practice';

export enum STEPS {
  PRIVATE_EXPIRED,
  PRIVATE,
  VFC_EXPIRED,
  VFC,
  BORROWING,
  APPLY,
}

export enum InventoryType {
  VFC = 'VFC',
  Private = 'Private',
}

export interface DataToSend {
  id?: string;
  lastAdjustmentDate: Date;
  doses: number;
  lastAdjustmentReason: string;
  practiceId: string;
  vaccineId: string;
  lot: string;
  expiration: string;
}

export enum stepsStatuses {
  PENDING,
  STARTED,
  COMPLETED,
}

export interface InventoryControlSteps {
  privateExpired: stepsStatuses;
  private: stepsStatuses;
  vfcExpired: stepsStatuses;
  vfc: stepsStatuses;
}

export interface InventoryAdjustmentProps {
  practiceId: string;
  onStepChange: (newStep: STEPS) => void;
  nextButtonClicked: boolean | undefined;
  cancelButtonClicked: boolean | undefined;
  scannedData: ScanData | undefined;
  onFinishInventoryControl: (
    inventoryAdjustment: InventoryAdjustmentData[]
  ) => void;
  data: VaccinesStockData | undefined;
  onFinishStep: () => void;
  currentStep: STEPS;
}

export interface ScanData {
  lot: string;
  parsedDate: string;
  ndc: string;
}

export interface ActionsMenuProps {
  onScan: (data: ScanData) => void;
  onClickNextButton: () => void;
  onClickCancelButton: () => void;
  currentStep: STEPS | undefined;
}

export interface BorrowingProps {
  adjustmentData: InventoryAdjustmentData[];
  inconsistencies: VFCInconsistency[];
  nextButtonClicked: boolean | undefined;
  onsFinishBorrowing: (borrowingCases: BorrowingCase[]) => void;
  currentStep: STEPS | undefined;
}

export interface BorrowingCase {
  inconsistency: VFCInconsistency;
  alternatives: InventoryAdjustmentData[];
}

export interface BorrowingSelectorProps {
  borrowingCases: BorrowingCase[];
  onSelectAlternative: (
    alternative: InventoryAdjustmentData,
    inconsistency: VFCInconsistency
  ) => void;
}

export interface Inventory extends DbData, OnConflict {
  lot: string;
  expiration: Date;
  doses: number;
  vaccineId: string;
  vaccine?: Vaccine;
  status: InventoryStatus;
  vfc: boolean;
  practice?: Practice;
  practiceId?: string;
  lastAdjustmentReason?: string;
  lastAdjustmentDate?: string;
  useNdc10?: string;
  saleNdc10?: string;
  claimVFCInconsistencies?: {};
}

export interface InventoryAdjustmentDetail extends DbData {
  inventoryAdjustmentId?: string;
  inventoryId?: string;
  currentDoses: number;
  newDoses: number;
  type: InventoryAdjustmentTypes;
  reason: AdjustmentReasons;
  status: InventoryAdjustmentDetailStatuses;
  inventory?:
    | Inventory
    | {
        data: Inventory;
      };
  comment?: string;
  selected?: boolean;
  __typename?: string;
  targetClaimVfcInconsistencies?: { data: {}; on_conflict: {} };
  sourceClaimVfcInconsistencies?: { data: {}; on_conflict: {} };
}

export interface InventoryAdjustment extends DbData {
  status?: InventoryAdjustmentStatuses;
  practiceId: string;
  details?: InventoryAdjustmentDetail[];
  inventoryAdjustmentDetails?: {
    data: InventoryAdjustmentDetail[];
  };
  vfc?: boolean;
}

export interface InventoryAdjustmentData {
  isTarget?: boolean;
  id?: string;
  name: string;
  lot: string;
  expiration: string;
  doses: number;
  newDoses?: number;
  lastAdjustmentDate?: Date;
  newAdjustmentDate?: Date;
  adjustmentReason?: AdjustmentReasons;
  isPrivate: boolean;
  isExpired: boolean;
  wasAdjusted: boolean;
  wasConfirmed?: boolean;
  vaccineId: string;
  comment?: string;
  saleNdc?: string;
  useNdc?: string;
  types?: string[];
  inconsistency?: VFCInconsistency;
}
