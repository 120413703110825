import { DbData, Enum } from './Generics';
import { Inventory } from './Inventory';
import { PracticeProfile } from './Practice';
export enum VFCInconsistencyStatuses {
  CREATED = 'created',
  PENDING = 'pending',
  SOLVED = 'solved',
  REDUNDANT = 'redundant',
}
export enum VFCInconsistencyTypes {
  BORROW = 'borrow',
  BAD_VFC = 'bad_vfc',
  UNKNOWN = 'unknown',
}

export interface VFCInconsistencyStatus extends Enum {}
export interface VFCInconsistencyType extends Enum {}

interface claimUpdate {
  claimId: string;
  id: string;
  insuranceCompanyId: string;
  insuranceName: string;
  memberId: string;
  payerId: string;
  status: string;
  vfcCriteria: string;
  vfcEligible: boolean;
}

export type VfcInconsistencyInsurance = {
  cpid: string;
  payerID: string;
  memberId: string;
  vfcEligible: boolean;
  insuranceCompanyName: string;
  insuranceCompanyId: string;
};

export interface PracticePatient {
  patientData: PatientData;
}

export interface PatientData {
  birthdate: Date;
  firstName: string;
  lastName: string;
}

export interface VFCInconsistency extends DbData {
  inventory?: Inventory;
  practice?: { name: string; profile: PracticeProfile };
  claimId: string;
  inventoryId?: string;
  vaccinationId: string;
  practiceId: string;
  claimUpdatesId: string;
  type: VFCInconsistencyTypes;
  status: VFCInconsistencyStatuses;
  claim?: {
    id: string;
    givenAt: Date;
    insurance: VfcInconsistencyInsurance[];
    practicePatient: PracticePatient;
  };
  notes?: string;
  //FOR USE ON FRONT
  changed?: boolean;
  initialStatus?: VFCInconsistencyStatuses;
  initialType?: VFCInconsistencyTypes | null;
  redundantWithId?: string;
  redundantWith?: VFCInconsistency;
  readyToBeSwapped?: boolean;
  borrowingReasonCode?: string;
  borrowingReasonCodeOtherDescription?: string;
  swappedDate?: Date | null;
}

export interface GetVFCInconsistenciesStatusesAndTypesData {
  types: Enum[];
  statuses: Enum[];
}
