import { gql } from '@apollo/client';

export const OrganizationsAndTheirPractices = gql`
  query OrganizationsSettingsAndTheirPractices {
    organizations(
      order_by: { name: asc }
      where: { practices: { isBillable: { _eq: true } } }
    ) {
      id
      name
      balance
      practices(order_by: { name: asc }, where: { isBillable: { _eq: true } }) {
        balance
        id
        handler
        name
        isBillable
      }
    }
  }
`;

export const OrganizationsAndTheirPracticesNotificationEnabled = gql`
  query OrganizationsSettingsAndTheirPractices {
    organizations(
      order_by: { name: asc }
      where: {
        practices: {
          settings: { _contains: { notifications: { enabled: true } } }
        }
      }
    ) {
      id
      name
      balance
      practices(
        order_by: { name: asc }
        where: { settings: { _contains: { notifications: { enabled: true } } } }
      ) {
        balance
        id
        handler
        name
      }
    }
  }
`;

export const OrganizationsSettingsAndTheirPractices = gql`
  query OrganizationsSettingsAndTheirPractices {
    organizations(
      order_by: { name: asc }
      where: { practices: { isBillable: { _eq: true } } }
    ) {
      id
      name
      balance
      practices(order_by: { name: asc }, where: { isBillable: { _eq: true } }) {
        id
        handler
        name
        isBillable
        balance
      }
      settings
    }
  }
`;

export const GetOrganizationById = (fields: string[]) => gql`
  query GetOrganizationById($id: uuid!) {
    response: organizations_by_pk(id: $id) {
      ${fields.join('\n')}
    }
  }
`;

export const SubscribeToOrganizationById = (fields: string[]) => gql`
  subscription SubscribeToOrganizationById($id: uuid!) {
    response: organizations_by_pk(id: $id) {
      ${fields.join('\n')}
    }
  }
`;
