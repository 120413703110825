import { gql } from '@apollo/client';

import { VaccineFullFragment } from './vaccines';

export const InventoryFullFragmentPP = gql`
  fragment InventoryFull on inventories {
    id
    lot
    doses
    expiration
    vfc
    status
    vaccineId
    lastAdjustmentReason
    lastAdjustmentDate
  }
`;

export const InventoryFullFragmentIT = gql`
  fragment InventoryFull on inventories {
    id
    lot
    doses
    expiration
    vfc
    status
    statusLog
    vaccineId
    lastAdjustmentReason
    lastAdjustmentDate
    vaccinationsCount
    orderedDoses
    alternativeLotNumber
  }
`;

export const ReceivedInventoryQuery = gql`
  query ReceivedInventoryQuery(
    $likeQuery: String!
    $typesFilter: jsonb_comparison_exp
    $inventoryFilter: inventories_bool_exp
  ) {
    vaccines(
      where: {
        inventories: { status: { _eq: received }, doses: { _neq: 0 } }
        types: $typesFilter
        _or: [
          { name: { _ilike: $likeQuery } }
          { inventories: { lot: { _ilike: $likeQuery } } }
        ]
      }
      order_by: { name: asc }
    ) {
      ...VaccineFull
      inventory: inventories(
        where: $inventoryFilter
        order_by: { expiration: asc }
      ) {
        ...InventoryFull
        threshold {
          noVfc
        }
        createdAt
      }
      inventoryVaccinationsGiven: inventories(where: { vfc: { _eq: false } }) {
        id
        vfc
        vaccinationsCount(args: { since_interval: "12 weeks" })
      }
      expiredInventory: inventories(
        where: {
          status: { _eq: expired }
          vfc: { _eq: false }
          doses: { _neq: 0 }
        }
        order_by: { expiration: asc }
      ) {
        ...InventoryFull
        threshold {
          noVfc
        }
      }
    }
    expiredVaccines: vaccines(
      where: {
        inventories: { status: { _eq: expired }, doses: { _neq: 0 } }
        types: $typesFilter
        _or: [
          { name: { _ilike: $likeQuery } }
          { inventories: { lot: { _ilike: $likeQuery } } }
        ]
      }
      order_by: { name: asc }
    ) {
      ...VaccineFull
      inventory: inventories(
        where: { status: { _eq: expired }, doses: { _neq: 0 } }
        order_by: { expiration: asc }
      ) {
        ...InventoryFull
      }
    }
  }

  ${InventoryFullFragmentPP}
  ${VaccineFullFragment}
`;

export const ReceivedInventoryQueryByPractice = gql`
  query ReceivedInventoryQueryByPractice(
    $likeQuery: String!
    $typesFilter: jsonb_comparison_exp
    $practiceId: uuid!
  ) {
    vaccines(
      where: {
        inventories: {
          status: { _eq: received }
          doses: { _neq: 0 }
          practiceId: { _eq: $practiceId }
        }
        types: $typesFilter
        _or: [
          { name: { _ilike: $likeQuery } }
          { inventories: { lot: { _ilike: $likeQuery } } }
        ]
      }
      order_by: { name: asc }
    ) {
      ...VaccineFull
      inventory: inventories(
        where: {
          status: { _eq: received }
          doses: { _neq: 0 }
          practiceId: { _eq: $practiceId }
        }
        order_by: { expiration: asc }
      ) {
        ...InventoryFull
      }
    }
    expiredVaccines: vaccines(
      where: {
        inventories: {
          status: { _eq: expired }
          doses: { _neq: 0 }
          practiceId: { _eq: $practiceId }
        }
        types: $typesFilter
        _or: [
          { name: { _ilike: $likeQuery } }
          { inventories: { lot: { _ilike: $likeQuery } } }
        ]
      }
      order_by: { name: asc }
    ) {
      ...VaccineFull
      inventory: inventories(
        where: {
          status: { _eq: expired }
          doses: { _neq: 0 }
          practiceId: { _eq: $practiceId }
        }
        order_by: { expiration: asc }
      ) {
        ...InventoryFull
      }
    }
  }

  ${InventoryFullFragmentIT}
  ${VaccineFullFragment}
`;

export const InventoryOrdersQuery = gql`
  query InventoryOrdersQuery {
    orders: inventories(
      where: { status: { _in: [ordered, confirmed, shipped] } }
      order_by: { expiration: asc }
    ) {
      ...InventoryFull
      vaccine {
        ...VaccineFull
      }
    }
  }

  ${InventoryFullFragmentIT}
  ${VaccineFullFragment}
`;

export const InventoryReceivedMutation = gql`
  mutation InventoryReceivedMutation($id: uuid!) {
    inventory: update_inventories_by_pk(
      _set: { status: received }
      pk_columns: { id: $id }
    ) {
      ...InventoryFull
    }
  }

  ${VaccineFullFragment}
  ${InventoryFullFragmentIT}
`;

const InventoryQuery = gql`
  query InventoryQuery(
    $practiceId: uuid!
    $inventoryCriterias: [inventories_bool_exp!]!
    $vaccinesCriterias: [vaccines_bool_exp!]!
  ) {
    inventories(
      where: { practiceId: { _eq: $practiceId }, _or: $inventoryCriterias }
      order_by: { vaccine: { name: asc } }
    ) {
      id
      lot
      expiration
      doses
      vaccineId
      status
      vfc
      lastAdjustmentDate
      lastAdjustmentReason
      vaccine {
        id
        name
        saleNdc
        manufacturer
        aka
      }
    }
    vaccines(where: { _or: $vaccinesCriterias }) {
      id
      name
      saleNdc
      manufacturer
      aka
    }
  }
`;

export const InventoryListForPdfQuery = gql`
  query InventoryListForPdfQuery($practiceId: uuid!) {
    privateInventory: inventories(
      where: {
        practice: { id: { _eq: $practiceId } }
        status: { _eq: received }
        doses: { _neq: 0 }
        vfc: { _eq: false }
      }
      order_by: { vaccine: { name: asc } }
    ) {
      id
      doses
      expiration
      lot
      vfc
      vaccine {
        id
        name
        saleNdc
      }
    }
    vfcInventory: inventories(
      where: {
        practice: { id: { _eq: $practiceId } }
        status: { _eq: received }
        doses: { _neq: 0 }
        vfc: { _eq: true }
      }
      order_by: { vaccine: { name: asc } }
    ) {
      id
      doses
      expiration
      lot
      vfc
      vaccine {
        id
        name
        saleNdc
      }
    }
  }
`;

export const getInventoryVaccinesQuery = gql`
  query inventoryVaccinesQuery($practiceId: uuid!) {
    inventories(
      where: {
        practiceId: { _eq: $practiceId }
        status: { _eq: received }
        doses: { _neq: 0 }
      }
    ) {
      vaccine {
        name
        id
      }
      id
      lot
      doses
    }
  }
`;

export const InventoryLotsByPracticeId = gql`
  query InventoryLotsByPracticeId($practiceId: uuid!) {
    inventoryLots: inventories(
      where: { practiceId: { _eq: $practiceId } }
      distinct_on: lot
      order_by: [{ lot: asc }]
    ) {
      id
      lot
    }
  }
`;

export const GetSimpleInventoryForBorrowing = gql`
  query Inventory($criteria: inventories_bool_exp) {
    inventories(where: $criteria) {
      id
      practiceId
      status
      expiration
      vaccineId
      doses
      vfc
      lot
      vaccine {
        id
        saleNdc
        aka
        name
        types
      }
    }
  }
`;

export const BorrowingSummaryPdfExportQuery = gql`
  query BorrowingSummaryPdfExportQuery(
    $criteria: inventory_adjustment_details_bool_exp
    $redundantCriteria: billing_claim_vfc_inconsistencies_bool_exp
  ) {
    details: inventory_adjustment_details(
      where: $criteria
      order_by: { inventory_adjustment: { vfc: asc }, type: asc }
    ) {
      id
      currentDoses
      newDoses
      type
      inventory {
        vaccine {
          name
          types
          saleNdc
        }
        lot
        vfc
      }
      targetClaimVfcInconsistencies {
        createdAt
        id
        borrowingReasonCode
        practiceId
        practice {
          id
          name
        }
        claimId
        inventoryId
        vaccinationId
        inventory {
          id
          expiration
          vfc
          lot
          doses
          vaccine {
            id
            name
            aka
            types
            saleNdc
          }
        }
        type
        status
        claimUpdatesId
        claim {
          id
          givenAt
          insurance: insuranceForPracticePortal {
            insuranceCompanyName
            insuranceCompanyId
            memberId
            vfcEligible
            vfcCriteria
            payerId
          }
          practicePatient {
            patientData {
              birthdate
              firstName
              lastName
            }
          }
        }
      }
      sourceClaimVfcInconsistencies {
        createdAt
        id
        borrowingReasonCode
        practiceId
        practice {
          id
          name
        }
        claimId
        inventoryId
        vaccinationId
        inventory {
          id
          expiration
          vfc
          lot
          doses
          vaccine {
            id
            name
            aka
            types
            saleNdc
          }
        }
        type
        status
        claimUpdatesId
        claim {
          id
          givenAt
          insurance: insuranceForPracticePortal {
            insuranceCompanyName
            insuranceCompanyId
            memberId
            vfcEligible
            vfcCriteria
            payerId
          }
          practicePatient {
            patientData {
              birthdate
              firstName
              lastName
            }
          }
        }
      }
    }
    redundants: billing_claim_vfc_inconsistencies(
      where: $redundantCriteria
      order_by: { inventory: { vfc: asc }, createdAt: desc }
    ) {
      borrowingReasonCode
      borrowingReasonCodeOtherDescription
      claim {
        insurance: insuranceForPracticePortal {
          insuranceCompanyName
          insuranceCompanyId
          memberId
          vfcEligible
          vfcCriteria
          payerId
        }
        practicePatient {
          patientData {
            firstName
            lastName
            birthdate
          }
        }
        givenAt
      }
      inventory {
        vfc
        vaccine {
          saleNdc
          name
        }
        lot
      }
      status
      redundantWith {
        id
        inventory {
          vfc
          vaccine {
            name
          }
          lot
        }
        claim {
          insurance: insuranceForPracticePortal {
            insuranceCompanyName
            memberId
            vfcEligible
          }
          practicePatient {
            patientData {
              firstName
              lastName
              birthdate
            }
          }
          givenAt
        }
      }
    }
  }
`;

export const BorrowingInventoryAdjustments = gql`
  query BorrowingInventoryAdjustments(
    $criteria: inventory_adjustments_bool_exp
  ) {
    adjustments: inventory_adjustments(
      where: $criteria
      order_by: { createdAt: asc }
    ) {
      id
      createdAt
      statusLog
    }
    aggregating: inventory_adjustments_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const InventoryByStatusesQuery = gql`
  query InventoryByStatusesQuery(
    $practiceId: uuid!
    $statuses: inventory_statuses_enum_comparison_exp
  ) {
    inventories(
      where: { practiceId: { _eq: $practiceId }, status: $statuses }
    ) {
      ...InventoryFull
      vaccine {
        ...VaccineFull
      }
    }
  }

  ${InventoryFullFragmentIT}
  ${VaccineFullFragment}
`;

export const InventoryByStatusesQueryPP = gql`
  query InventoryByStatusesQuery(
    $practiceId: uuid!
    $statuses: inventory_statuses_enum_comparison_exp
  ) {
    inventories(
      where: { practiceId: { _eq: $practiceId }, status: $statuses }
    ) {
      ...InventoryFull
      vaccine {
        ...VaccineFull
      }
    }
  }

  ${InventoryFullFragmentPP}
  ${VaccineFullFragment}
`;
