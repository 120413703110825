import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import MainLayout from '@ui/MainLayout';
import {
  Card,
  Container,
  Dropdown,
  Icon,
  Input,
  Menu,
  Message,
  Pagination,
  Placeholder,
  Segment,
  Table,
} from 'semantic-ui-react';
import {
  AllPracticesWithEcw,
  EcwAppointmentsQuery,
  GetEcwRawAppointmentsStatusReasonOptions,
} from '@graphql/bot';
import { debounce } from '@bluefox/lib/debounce';
import { DateFormats } from '@bluefox/models/Dates';
import { EcwRawAppointment } from '@bluefox/models/integrations/Ecw';
import { Practice } from '@bluefox/models/Practice';
import { whereLikeInput } from '@bluefox/graphql/utils';
import EcwAppointmentsRow from './EcwAppointmentsRow';
import { DateTime } from 'luxon';

interface PracticeOption {
  text: string;
  value: string;
  timezone: string;
}

interface AppointmentsData {
  appointments: EcwRawAppointment[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

type OperatorType<T> = {
  _eq?: T;
  _gte?: T | null;
  _lte?: T;
  _ilike?: string;
};

type CriteriaType = {
  practiceId?: OperatorType<string>;
  status?: OperatorType<string>;
  createdAt?: {
    _gte?: string | null;
    _lte?: string | null;
  };
  mrn?: OperatorType<string>;
};

enum AppointmentDateRangeType {
  FROM = 'from',
  TO = 'to',
}

type DateRange = {
  [AppointmentDateRangeType.FROM]?: Date | undefined;
  [AppointmentDateRangeType.TO]?: Date | undefined;
};

const ENTRIES_PER_PAGE = 15;
const AllStatus = { text: 'All', value: 'allStatus' };
const AllPractices = {
  text: 'All practices',
  value: 'allPractices',
};

const EcwAppointmentsList = () => {
  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);
  const [practiceId, setPracticeId] = useState<string>();
  const [searchPractice, setSearchPractice] = useState<string | undefined>(
    AllPractices.value
  );
  const [searchStatus, setSearchStatus] = useState<string>();
  const [searchPatientMrn, setSearchPatientMrn] = useState<string>();
  const [criteria, setCriteria] = useState<CriteriaType>({});
  const [page, setPage] = useState(0);
  const [practiceTz, setPracticeTz] = useState<string | undefined>();
  const [searchAppointmentDateRange, setSearchAppointmentDateRange] =
    useState<DateRange>({
      [AppointmentDateRangeType.FROM]: undefined,
      [AppointmentDateRangeType.TO]: undefined,
    });

  const [createdAtDirection, setCreatedAtDirection] = useState<
    'ascending' | 'descending' | undefined
  >();
  const [statusReasonOptions, setStatusReasonOptions] = useState<
    {
      [key: string]: string;
    }[]
  >();
  const [statusReason, setStatusReason] = useState<string>();
  const [statusReasonDic, setStatusReasonDic] = useState<{
    [key: string]: string;
  }>();

  const [EcwAppointmentsLazyQuery, { data, loading, refetch }] =
    useLazyQuery<AppointmentsData>(EcwAppointmentsQuery, {
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
    });

  useQuery(GetEcwRawAppointmentsStatusReasonOptions, {
    onCompleted: (data) => {
      const obj: { [key: string]: string } = {};
      const options = data.statusReasonOptions.map(
        ({ value, comment }: { value: string; comment: string }) => {
          obj[value] = comment;

          return { text: comment, value };
        }
      );
      setStatusReasonOptions([AllStatus, ...options]);
      setStatusReasonDic(obj);
    },
  });

  useQuery(AllPracticesWithEcw, {
    onCompleted: (data) => {
      if (!data?.allPractices || data.allPractices.length < 1) return;

      setPracticeOptions(
        data.allPractices.map((p: Practice) => {
          return {
            text: p.name,
            value: p.id,
            timezone: p.timezone,
          };
        })
      );
    },
  });

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const handlePracticeValue = useCallback(
    (value: string) => {
      const practiceValue =
        !value?.length || value.includes(AllPractices.value)
          ? undefined
          : value;

      setSearchPractice(practiceValue);
      setPracticeId(practiceValue);

      const practice = practiceOptions?.find((p) => p.value === practiceValue);

      setPracticeTz(practice?.timezone);
    },
    [setSearchPractice, setPracticeId, practiceOptions]
  );

  const handleStatusValue = useCallback(
    (value: string) => {
      const statusValue =
        !!value && value !== 'allStatus' ? value?.toString() : undefined;
      setSearchStatus(statusValue || '');
    },
    [setSearchStatus]
  );

  const handleAppointmentDateRangeFrom = (date: Date | undefined) => {
    setSearchAppointmentDateRange((prev) => ({
      ...prev,
      [AppointmentDateRangeType.FROM]: date,
    }));
  };

  const handleAppointmentDateRangeTo = (date: Date | undefined) => {
    setSearchAppointmentDateRange((prev) => ({
      ...prev,
      [AppointmentDateRangeType.TO]: date,
    }));
  };

  const handleStatusReason = (value: string) => {
    const statusReasonValue =
      !!value && value !== AllStatus.value ? value?.toString() : undefined;
    setStatusReason(statusReasonValue);
  };

  useEffect(() => {
    const appointmentDateFrom =
      searchAppointmentDateRange?.[AppointmentDateRangeType.FROM];
    const appointmentDateTo =
      searchAppointmentDateRange?.[AppointmentDateRangeType.TO];

    const _criteria = {
      ...(searchPractice ? { practiceId: { _eq: practiceId } } : {}),
      ...(searchStatus ? { status: { _eq: searchStatus } } : {}),
      ...(statusReason ? { statusReason: { _eq: statusReason } } : {}),
      ...(appointmentDateFrom || appointmentDateTo
        ? {
            encounterDate: {
              _gte: appointmentDateFrom
                ? DateTime.fromJSDate(appointmentDateFrom).toISODate()
                : undefined,
              _lte: appointmentDateTo
                ? DateTime.fromJSDate(appointmentDateTo).toISODate()
                : DateTime.fromJSDate(new Date()).toISODate(),
            },
          }
        : {}),
      ...(searchPatientMrn
        ? {
            mrn: {
              _ilike: whereLikeInput(searchPatientMrn),
            },
          }
        : {}),
    };

    setCriteria(_criteria);
    setPage(1);
  }, [
    searchPractice,
    searchStatus,
    searchPatientMrn,
    searchAppointmentDateRange?.[AppointmentDateRangeType.FROM],
    searchAppointmentDateRange?.[AppointmentDateRangeType.TO],
    statusReason,
  ]);

  useEffect(() => {
    if (!!searchPractice) {
      EcwAppointmentsLazyQuery();
    }
  }, [criteria, createdAtDirection]);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  return (
    <MainLayout
      path={[
        { text: 'ECW Integration', to: '/integration-ecw' },
        { text: 'Appointments' },
      ]}
    >
      <Container fluid>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="list" style={{ marginRight: '0.6rem' }} />
              Appointments
            </Card.Header>
            <Card.Description>
              <Menu borderless style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by practice"
                      value={searchPractice}
                      fluid
                      search
                      selection
                      onChange={(e, data) => {
                        handlePracticeValue(data.value?.toString() || '');
                      }}
                      options={[AllPractices, ...practiceOptions]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by status"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleStatusValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All status', value: 'allStatus' },
                        { text: 'Completed', value: 'completed' },
                        { text: 'Pending', value: 'pending' },
                        { text: 'Error', value: 'error' },
                      ]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Input
                      icon="search"
                      placeholder="Search Patient MRN..."
                      onChange={(_, { value }) => {
                        debouncedRef.current?.cancel();
                        debouncedRef.current = debounce(() => {
                          setSearchPatientMrn(value);
                        }, 500);

                        debouncedRef.current();
                      }}
                    />
                  </Menu.Item>
                </Menu.Menu>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Menu.Item>
                    <label
                      style={{
                        marginRight: '1.2rem',
                        width: '8rem',
                        lineHeight: '1.1rem',
                      }}
                    >
                      <b>Search by appointment date range:</b>
                    </label>
                    <DateTimePicker
                      placeholderText="From..."
                      tz={practiceTz}
                      selected={
                        searchAppointmentDateRange?.[
                          AppointmentDateRangeType.FROM
                        ]
                      }
                      onChange={(d) =>
                        handleAppointmentDateRangeFrom(
                          d ? (d as Date) : undefined
                        )
                      }
                      onSelect={(value) =>
                        handleAppointmentDateRangeFrom(
                          value ? (value as Date) : undefined
                        )
                      }
                      onClear={() => handleAppointmentDateRangeFrom(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <DateTimePicker
                      placeholderText="To..."
                      tz={practiceTz}
                      selected={
                        searchAppointmentDateRange?.[
                          AppointmentDateRangeType.TO
                        ]
                      }
                      onChange={(d) => {
                        handleAppointmentDateRangeTo(
                          d ? (d as Date) : undefined
                        );
                      }}
                      onSelect={(value) =>
                        handleAppointmentDateRangeTo(
                          value ? (value as Date) : undefined
                        )
                      }
                      onClear={() => handleAppointmentDateRangeTo(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <label
                      htmlFor="status-reason-dropdown"
                      style={{ fontWeight: 'bold' }}
                    >
                      Status Reason:
                    </label>
                    <Dropdown
                      id="status-reason-dropdown"
                      style={{ minWidth: '15rem' }}
                      placeholder="Status Reason"
                      labeled
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleStatusReason(data.value?.toString() || '');
                      }}
                      options={statusReasonOptions}
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              <Table sortable celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Practice</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Status Reason</Table.HeaderCell>
                    <Table.HeaderCell>Patient</Table.HeaderCell>
                    <Table.HeaderCell>Insurance</Table.HeaderCell>
                    <Table.HeaderCell>Appointment</Table.HeaderCell>
                    <Table.HeaderCell>Error</Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={createdAtDirection}
                      onClick={() =>
                        setCreatedAtDirection(
                          createdAtDirection === 'ascending'
                            ? 'descending'
                            : 'ascending'
                        )
                      }
                    >
                      Created At
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {loading ? (
                    <Table.Row>
                      <Table.Cell colSpan={8}>
                        <Segment basic>
                          <Placeholder fluid>
                            <Placeholder.Header>
                              <Placeholder.Line />
                              <Placeholder.Line />
                            </Placeholder.Header>
                          </Placeholder>
                        </Segment>
                      </Table.Cell>
                    </Table.Row>
                  ) : !!data?.appointments.length ? (
                    data.appointments.map((appointment) => {
                      return (
                        <EcwAppointmentsRow
                          key={appointment.id}
                          data={appointment}
                          onSave={refetch}
                          statusReasonDic={statusReasonDic}
                        />
                      );
                    })
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={8}>
                        <Message>No Appointments Found</Message>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                    <Table.HeaderCell colSpan={8} textAlign="right">
                      <Pagination
                        disabled={!total || total < ENTRIES_PER_PAGE}
                        defaultActivePage={1}
                        boundaryRange={0}
                        siblingRange={1}
                        activePage={page || 1}
                        onPageChange={(e, { activePage }) =>
                          setPage(activePage as number)
                        }
                        totalPages={totalPages}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

export default EcwAppointmentsList;
