import MainLayout from '@ui/MainLayout';
import {
  Accordion,
  Button,
  Card,
  Container,
  Grid,
  Icon,
} from 'semantic-ui-react';
import { InvoicingStrategy } from '@bluefox/models/Organization';
import OrganizationAndPracticeSelector, {
  SearchValuesProps as OrgAndOractSearchValuesProps,
} from '@bluefox/ui/OrganizationAndPracticeSelector';
import { useState } from 'react';
import BalanceMovementForm from '@ui/BalanceMovementForm';
import BalanceMovementsTable from '@bluefox/ui/BalanceMovements/BalanceMovementsTable';
import AdvancedFilters from '@bluefox/ui/BalanceMovements/AdvancedFilters';
import {
  BalanceMovementFilter,
  SelectAll,
} from '@bluefox/models/BalanceMovements';
import EntityBalance, {
  EntityBalanceProps,
} from '@bluefox/ui/BalanceMovements/entityBalance';

const BalanceMovements = () => {
  const [orgAndPractSearchValues, orgAndPractSetSearchValues] = useState<
    Partial<OrgAndOractSearchValuesProps>
  >({});

  const initialBalanceMovementFilter: BalanceMovementFilter = {
    type: SelectAll.ALL,
    reason: SelectAll.ALL,
    hasInvoice: SelectAll.ALL,
    dateFrom: undefined,
    dateTo: undefined,
  };

  const [isCurrentBalanceOpen, setIsCurrentBalanceOpen] = useState(true);
  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);
  const [balanceMovementFilter, setBalanceMovementFilter] =
    useState<BalanceMovementFilter>(initialBalanceMovementFilter);

  const organizationInvoicingStrategy =
    orgAndPractSearchValues.selectedOrganization?.settings?.invoicing?.strategy;

  const disableAddCreditButton = () => {
    return (
      !orgAndPractSearchValues.selectedOrganization ||
      !organizationInvoicingStrategy ||
      (organizationInvoicingStrategy === InvoicingStrategy.BY_PRACTICE &&
        orgAndPractSearchValues.selectedPractices?.length !== 1) ||
      (organizationInvoicingStrategy === InvoicingStrategy.BY_ORGANIZATION &&
        orgAndPractSearchValues.selectedPractices &&
        orgAndPractSearchValues.selectedPractices.length !== 1)
    );
  };

  const getEntitiesIds = (): EntityBalanceProps[] => {
    if (
      !orgAndPractSearchValues ||
      !orgAndPractSearchValues.selectedOrganization
    ) {
      return [];
    }

    const invoicingStrategy =
      orgAndPractSearchValues?.selectedOrganization?.settings?.invoicing
        ?.strategy;

    if (!invoicingStrategy) {
      return [];
    }

    if (invoicingStrategy === InvoicingStrategy.BY_ORGANIZATION) {
      return [
        {
          entityId: orgAndPractSearchValues.selectedOrganization.id,
          invoicingStrategy,
        },
      ];
    }

    if (orgAndPractSearchValues.selectedPractices) {
      return orgAndPractSearchValues.selectedPractices.map(
        (practice): EntityBalanceProps => ({
          entityId: practice.id,
          invoicingStrategy: invoicingStrategy as InvoicingStrategy,
        })
      );
    }

    return (
      orgAndPractSearchValues.practices?.map(
        (practice): EntityBalanceProps => ({
          entityId: practice.id,
          invoicingStrategy: invoicingStrategy as InvoicingStrategy,
        })
      ) || []
    );
  };

  const onBalanceMovementSaved = () => {
    setBalanceMovementFilter({
      type: SelectAll.ALL,
      reason: SelectAll.ALL,
      hasInvoice: SelectAll.ALL,
      dateFrom: undefined,
      dateTo: undefined,
    });
  };

  return (
    <MainLayout path={[{ text: 'Billing' }, { text: 'Balance Movements' }]}>
      <Container fluid>
        <Card fluid>
          <Card.Content>
            <Grid verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={12}>
                  <OrganizationAndPracticeSelector
                    organizationSettings
                    multiplePracticesSelect
                    useAlternativePracticeSelectorBehavior
                    searchValues={orgAndPractSearchValues}
                    setSearchValues={orgAndPractSetSearchValues}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <BalanceMovementForm
                    onBalanceMovementSaved={onBalanceMovementSaved}
                    organizationId={
                      orgAndPractSearchValues.selectedOrganization?.id
                    }
                    practiceId={
                      orgAndPractSearchValues?.selectedPractices
                        ? orgAndPractSearchValues?.selectedPractices[0].id
                        : undefined
                    }
                    disabled={disableAddCreditButton()}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {!organizationInvoicingStrategy && (
              <Card.Meta content="There is no Setting for this organization." />
            )}
          </Card.Content>
        </Card>

        <Accordion styled fluid>
          <Accordion.Title
            active={isAdvancedFiltersOpen}
            onClick={() => {
              if (!orgAndPractSearchValues.selectedOrganization) {
                return;
              }
              setIsAdvancedFiltersOpen(!isAdvancedFiltersOpen);
            }}
          >
            <Icon name="dropdown" />
            Advanced Filters
          </Accordion.Title>

          <Accordion.Content active={isAdvancedFiltersOpen}>
            <Card fluid>
              <Card.Content>
                <AdvancedFilters
                  balanceMovementFilter={balanceMovementFilter}
                  setBalanceMovementFilter={setBalanceMovementFilter}
                />
              </Card.Content>
            </Card>
          </Accordion.Content>
        </Accordion>
        <br />
        <Accordion styled fluid>
          <Accordion.Title
            active={isCurrentBalanceOpen}
            onClick={() => {
              setIsCurrentBalanceOpen(!isCurrentBalanceOpen);
            }}
          >
            <Icon name="dropdown" />
            Current Account Balance
          </Accordion.Title>

          <Accordion.Content active={isCurrentBalanceOpen}>
            <Grid divided={'vertically'}>
              <Grid.Row>
                {getEntitiesIds().length > 0 ? (
                  getEntitiesIds().map((data) => (
                    <EntityBalance
                      key={data.entityId}
                      entityId={data.entityId}
                      invoicingStrategy={data.invoicingStrategy}
                    />
                  ))
                ) : (
                  <Grid.Column>
                    <Card meta="Select an Organization or Practice to visualize the current Balance" />
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Accordion.Content>
        </Accordion>

        <Card fluid>
          <Card.Content>
            <Card.Header>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={5} floated="left">
                    <h3>Balance Movements</h3>
                  </Grid.Column>
                  <Grid.Column floated="right">
                    <Button
                      size="tiny"
                      icon={'refresh'}
                      onClick={() => {
                        setBalanceMovementFilter(initialBalanceMovementFilter);
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Header>
            <Card.Description>
              <BalanceMovementsTable
                advancedFilters={balanceMovementFilter}
                organizationId={
                  orgAndPractSearchValues.selectedOrganization?.id
                }
                practiceIds={orgAndPractSearchValues.practicesIds || []}
              />
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
      <br />
    </MainLayout>
  );
};

export default BalanceMovements;
