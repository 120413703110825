import { gql } from '@apollo/client';

export const DeveloMappingsQuery = gql`
  query DeveloMappingsQuery(
    $criteria: develo_mappings_bool_exp
    $limit: Int
    $offset: Int
  ) {
    develoMappings: develo_mappings(
      where: $criteria
      limit: $limit
      offset: $offset
    ) {
      created_at
      id
      key
      practiceId
      type
      value
      practice {
        id
        name
      }
    }
    develoMappingsCount: develo_mappings_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const DeveloAppointmentsQuery = gql`
  query DeveloAppointmentsQuery(
    $criteria: develo_appointments_bool_exp
    $limit: Int
    $offset: Int
  ) {
    appointments: develo_appointments(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: { practice: { name: asc }, createdAt: desc }
    ) {
      appointmentId
      appointmentStatus
      createdAt
      develoPracticeId
      encounterId
      endDate
      error
      id
      metadata
      mrn
      practiceId
      practitionerId
      startDate
      status
      practice {
        name
      }
    }
    aggregating: develo_appointments_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const DeleteMappingMutation = gql`
  mutation DeleteMappingMutation($mappingId: uuid!) {
    delete_develo_mappings_by_pk(id: $mappingId) {
      id
    }
  }
`;

export const UpdateDeveloMappingMutation = gql`
  mutation UpdateDeveloMappingMutation(
    $id: uuid!
    $key: String!
    $value: jsonb!
    $practiceId: uuid
  ) {
    update_develo_mappings_by_pk(
      pk_columns: { id: $id }
      _set: { key: $key, value: $value, practiceId: $practiceId }
    ) {
      id
      key
      practiceId
      value
    }
  }
`;

export const UpdateDeveloStatusMutation = gql`
  mutation UpdateDeveloStatusMutation($id: uuid!, $status: String!) {
    updatedStatus: update_develo_appointments_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      id
      status
    }
  }
`;

export const InsertDeveloMappingMutation = gql`
  mutation InsertDeveloMappingMutation(
    $key: String!
    $practiceId: uuid
    $type: String!
    $value: jsonb!
  ) {
    insert_develo_mappings_one(
      object: { key: $key, practiceId: $practiceId, type: $type, value: $value }
    ) {
      created_at
      id
      key
      practiceId
      type
      value
    }
  }
`;

export const ProcessDeveloAppointmentManuallyMutation = gql`
  mutation ProcessDeveloAppointmentManuallyMutation($appointmentId: uuid!) {
    ProcessDeveloAppointmentManually(appointmentId: $appointmentId) {
      code
      message
    }
  }
`;

export const AllPracticesWithDevelo = gql`
  query AllPracticesWithDevelo {
    allPractices: practices(
      order_by: { name: asc }
      where: {
        settings: { _contains: { develo: { enabled: true } } }
        suspended: { _eq: false }
      }
    ) {
      id
      name
    }
  }
`;

// Vaccinations to report ----

export const DeveloVaccinationsToReportQuery = gql`
  query DeveloVaccinationsToReportQuery(
    $criteria: develo_vaccinations_to_report_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    develoVaccinationsToReport: develo_vaccinations_to_report(
      order_by: { practice: { name: asc }, createdAt: desc }
      where: $criteria
      offset: $offset
      limit: $limit
    ) {
      id
      error
      metadata
      response
      status
      iisStatus
      action
      practiceId
      practicePatientId
      vaccinationId
      practice {
        name
        timezone
      }
      practicePatient {
        mrn
        patientData {
          lastName
          firstName
        }
      }
    }
    aggregating: develo_vaccinations_to_report_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const DeveloVaccinationsToReportSubscription = gql`
  subscription DeveloVaccinationsToReportSubscription($id: uuid!) {
    vaccinationToReportSubscription: develo_vaccinations_to_report_by_pk(
      id: $id
    ) {
      error
      status
    }
  }
`;

export const UpdateDeveloVaccinationToReportStatusEmrMutation = gql`
  mutation UpdateDeveloVaccinationToReportStatusEmrMutation(
    $id: uuid!
    $statusEmr: String!
  ) {
    updatedStatusEmr: update_develo_vaccinations_to_report_by_pk(
      pk_columns: { id: $id }
      _set: { status: $statusEmr }
    ) {
      id
      status
    }
  }
`;

export const DeveloUpdateIisStatusMutation = gql`
  mutation DeveloUpdateIisStatusMutation($id: uuid!, $iisStatus: String!) {
    updatedStatusEmr: update_develo_vaccinations_to_report_by_pk(
      pk_columns: { id: $id }
      _set: { iisStatus: $iisStatus }
    ) {
      id
      status
    }
  }
`;

export const TriggerDeveloVaccinationToReportMutation = gql`
  mutation TriggerDeveloVaccinationToReportMutation(
    $vaccinationToReportId: uuid!
    $action: String!
  ) {
    reportDeveloVaccinationManually(
      vaccinationToReportId: $vaccinationToReportId
      action: $action
    ) {
      message
      code
    }
  }
`;

export const ProvidersQuery = gql`
  query ProvidersQuery($practiceIds: [uuid!]) {
    providers: practice_accounts(
      where: {
        practiceId: { _in: $practiceIds }
        title: { _in: [MD, MA, DO, NP, Nurse, PA] }
      }
    ) {
      id
      title
      account {
        firstName
        lastName
        email
      }
    }
  }
`;
