import { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Loader, Message, Segment, Table } from 'semantic-ui-react';
import { useLazyQuery } from '@apollo/client';
import { GetBalanceMovements } from '@bluefox/graphql/balanceMovements';

import {
  BalanceMovementFilter,
  BalanceMovements,
  GetBalanceMovementsQueryResponse,
  HasInvoice,
  MovementReason,
  SelectAll,
} from '@bluefox/models/BalanceMovements';

import BalanceMovementsTableRow from '@bluefox/ui/BalanceMovements/BalanceMovementsTableRow';

import { GetInvoicePDFById } from '@bluefox/graphql/invoices';
import { Invoice } from '@bluefox/models/Invoice';
import InvoicePdfModal from '@bluefox/ui/Billing/InvoicePdfModal';
import { InvoiceAndBillingReportPdfProps } from '@bluefox/models/pdf/InvoiceAndBillingReportPdf';
import Pagination from '@bluefox/ui/Pagination';
import RowsPerPage from '@bluefox/ui/BalanceMovements/RowsPerPage';
import styled from 'styled-components';
import {
  BillingReportPageProps,
  InvoicePageProps,
} from '@bluefox/pdf/InvoiceAndBillingReportPdf';
import logoImg from '@assets/canid-invoice-logo.png';
import BalanceMovementsMoreInfoRow from '@bluefox/ui/BalanceMovements/BalanceMovementsMoreInfoRow';

type BalanceMovementTableProps = {
  organizationId?: string;
  practiceIds: string[];
  advancedFilters?: BalanceMovementFilter;
  isPracticePortal?: boolean;
};

const BalanceMovementsTable = ({
  organizationId,
  practiceIds,
  advancedFilters,
  isPracticePortal = false,
}: BalanceMovementTableProps) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [selectedMoreInfoMovement, setSelectedMoreInfoMovement] = useState<
    string | undefined
  >();
  const [lastInvoiceSelected, setLastInvoiceSelected] = useState<string>('nop');
  const [pdfDataWasLoaded, setPdfDataWasLoaded] = useState(false);
  const [shouldShowPdfModal, setShouldShowPdfModal] = useState(false);
  const [page, setPage] = useState(0);

  const [
    getBalanceMovements,
    { loading, error, data: balanceMovementsQueryResponse },
  ] = useLazyQuery<GetBalanceMovementsQueryResponse>(GetBalanceMovements);

  const [
    getInvoicePDFById,
    { data: getInvoicePdfDataResponse, error: getInvoiceError },
  ] = useLazyQuery<{ queryResponse: Pick<Invoice, 'pdfData'> }>(
    GetInvoicePDFById,
    {
      onCompleted: () => {
        setPdfDataWasLoaded(true);
      },
    }
  );

  const total = useMemo((): number => {
    return (
      balanceMovementsQueryResponse?.totalBalanceMovements.aggregate.count || 0
    );
  }, [balanceMovementsQueryResponse]);

  const totalPages = useMemo((): number => {
    return rowsPerPage === 0 ? 0 : Math.ceil(total / rowsPerPage);
  }, [total, rowsPerPage]);

  const buildFilters = useMemo(() => {
    return {
      where: {
        organizationId: organizationId ? { _eq: organizationId } : undefined,
        practiceId: practiceIds.length > 0 ? { _in: practiceIds } : undefined,
        type:
          advancedFilters?.type === SelectAll.ALL
            ? undefined
            : { _eq: advancedFilters?.type },
        reason:
          advancedFilters?.reason === SelectAll.ALL
            ? { _neq: MovementReason.INVOICE_PAYMENT }
            : { _eq: advancedFilters?.reason },
        invoiceId:
          advancedFilters?.hasInvoice === SelectAll.ALL
            ? undefined
            : { _is_null: advancedFilters?.hasInvoice === HasInvoice.NO },
        date:
          !advancedFilters?.dateFrom && !advancedFilters?.dateTo
            ? undefined
            : {
                _gte: advancedFilters?.dateFrom || undefined,
                _lte: advancedFilters?.dateTo || undefined,
              },
      },
      limit: rowsPerPage === 0 ? 100 : rowsPerPage,
      offset: !!page ? rowsPerPage * (page - 1) : 0,
    };
  }, [advancedFilters, organizationId, practiceIds, rowsPerPage, page]);

  const runQuery = useCallback(() => {
    if (!organizationId && practiceIds.length === 0) {
      return;
    }
    getBalanceMovements({
      variables: buildFilters,
    });
  }, [buildFilters, getBalanceMovements, organizationId, practiceIds]);

  const getPDFData = useCallback(
    async (invoiceId: string) => {
      if (lastInvoiceSelected === invoiceId) {
        setPdfDataWasLoaded(true);
        return;
      }

      setPdfDataWasLoaded(false);
      setLastInvoiceSelected(invoiceId);
      await getInvoicePDFById({
        variables: {
          invoiceId,
        },
      });
    },
    [getInvoicePDFById, lastInvoiceSelected, pdfDataWasLoaded]
  );

  const onShowPDF = useCallback(
    async (invoiceId: string) => {
      await getPDFData(invoiceId);
      setShouldShowPdfModal(true);
    },
    [getPDFData, setShouldShowPdfModal]
  );

  const addLogoImageToInvoiceData = useCallback(
    (
      pdfData: InvoiceAndBillingReportPdfProps
    ): InvoiceAndBillingReportPdfProps => {
      const { version, invoice, billingReport } = pdfData;
      return {
        version,
        invoice: {
          ...invoice,
          header: { ...invoice?.header, logoImg },
        } as InvoicePageProps,
        billingReport: {
          ...billingReport,
          footer: { ...billingReport?.footer, logoImg },
        } as BillingReportPageProps,
      };
    },
    []
  );

  const onClickMoreInfo = useCallback(
    async (balanceMovement: BalanceMovements) => {
      if (
        selectedMoreInfoMovement !== undefined &&
        selectedMoreInfoMovement === balanceMovement.id
      ) {
        setSelectedMoreInfoMovement(undefined);
        return;
      }
      setSelectedMoreInfoMovement(balanceMovement.id as string);
      await getPDFData(balanceMovement.invoice?.id as string);
    },
    [selectedMoreInfoMovement, getPDFData, setSelectedMoreInfoMovement]
  );

  useEffect(runQuery, [
    organizationId,
    practiceIds,
    advancedFilters,
    rowsPerPage,
    page,
  ]);

  if (error) {
    return <Message error>{error.message}</Message>;
  }
  if (getInvoiceError) {
    return <Message error>{getInvoiceError.message}</Message>;
  }
  return (
    <>
      {loading ? (
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Loader active={true} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Organizations (Practice)</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Reason</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>Remaining Balance</Table.HeaderCell>
              <Table.HeaderCell>Invoice Status</Table.HeaderCell>
              <Table.HeaderCell>Amount to Paid</Table.HeaderCell>
              <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {balanceMovementsQueryResponse?.balanceMovements.map(
              (balanceMovement) => (
                <>
                  <BalanceMovementsTableRow
                    isPracticePortal={isPracticePortal}
                    isActive={
                      pdfDataWasLoaded &&
                      selectedMoreInfoMovement !== undefined &&
                      selectedMoreInfoMovement === balanceMovement.id
                    }
                    onClickShowInvoice={() => {
                      setSelectedMoreInfoMovement(undefined);
                      onShowPDF(balanceMovement.invoice?.id as string);
                    }}
                    onClickMoreInfo={() => {
                      onClickMoreInfo(balanceMovement);
                    }}
                    key={`table-row-${balanceMovement.id}`}
                    balanceMovement={balanceMovement}
                  />
                  {pdfDataWasLoaded &&
                    selectedMoreInfoMovement !== undefined &&
                    selectedMoreInfoMovement === balanceMovement.id && (
                      <BalanceMovementsMoreInfoRow
                        balanceMovementId={balanceMovement.id}
                        key={`table-row-more-info-${balanceMovement.id}`}
                        pcAdminFeeClaimed={
                          getInvoicePdfDataResponse?.queryResponse?.pdfData
                            ?.invoice.footer?.pcAdminFeeClaimed
                        }
                        vcfAdminFeeClaimed={
                          getInvoicePdfDataResponse?.queryResponse?.pdfData
                            ?.invoice.footer?.vcfAdminFeeClaimed
                        }
                        timeRestored={
                          getInvoicePdfDataResponse?.queryResponse?.pdfData
                            ?.invoice.footer?.timeRestored
                        }
                      />
                    )}
                </>
              )
            )}
          </Table.Body>
        </Table>
      )}

      <Grid centered>
        <Grid.Column floated="left" width={10}>
          <Pagination
            total={total}
            colSpan={5}
            position="right"
            activePage={page}
            totalPages={totalPages}
            onPageChange={(newActivePage) => setPage(newActivePage)}
          />
        </Grid.Column>
        <Grid.Column floated="right" width={5}>
          <RowsPerPage
            rows={rowsPerPage}
            setRows={setRowsPerPage}
            localStorageItem="BalanceMovementRowConfig"
          />
        </Grid.Column>
      </Grid>

      <InvoicePdfModal
        invoiceProps={
          shouldShowPdfModal && pdfDataWasLoaded
            ? addLogoImageToInvoiceData(
                getInvoicePdfDataResponse?.queryResponse
                  .pdfData as InvoiceAndBillingReportPdfProps
              )
            : undefined
        }
        onClose={() => {
          setShouldShowPdfModal(false);
        }}
      />
    </>
  );
};
export const StyledPagination = styled.div({
  textAlign: 'center',
});

export default BalanceMovementsTable;
