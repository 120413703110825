import MainLayout from '@ui/MainLayout';
import { Accordion, Card, Container, Grid, Icon } from 'semantic-ui-react';
import InsurancesCompaniesPlansTable from '@screens/insurancesCompaniesPlans/insurancesCompaniesPlansTable';
import InsurancesCompaniesPlanForm from '@screens/insurancesCompaniesPlans/insurancesCompanyPlansForm';
import { useState } from 'react';
import {
  InsuranceCompaniesPlanFilter,
  InsuranceCompanyPlanInput,
} from '@bluefox/models/insuranceCompanyPlan';
import { InsurancesCompaniesPlansFilter } from '@screens/insurancesCompaniesPlans/insurancesCompaniesPlansFilter';

const InsurancesCompaniesPlans = () => {
  const [selectedPlan, setSelectedPlan] = useState<
    InsuranceCompanyPlanInput | undefined
  >();

  const [plansFilter, setPlansFilter] = useState<
    InsuranceCompaniesPlanFilter | undefined
  >();

  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);

  return (
    <MainLayout
      path={[{ text: 'Billing' }, { text: 'Insurances Companies Plans' }]}
    >
      <Container fluid>
        <Card fluid>
          <Card.Content>
            <Card.Header>
              <Grid>
                <Grid.Column floated="left">Plans</Grid.Column>
                <Grid.Column floated="right">
                  <InsurancesCompaniesPlanForm
                    plan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                  />
                </Grid.Column>
              </Grid>
            </Card.Header>

            <Card.Description>
              <Accordion styled fluid>
                <Accordion.Title
                  active={isAdvancedFiltersOpen}
                  onClick={() => {
                    setIsAdvancedFiltersOpen(!isAdvancedFiltersOpen);
                  }}
                >
                  <Icon name="dropdown" />
                  Advanced Filters
                </Accordion.Title>

                <Accordion.Content active={isAdvancedFiltersOpen}>
                  <InsurancesCompaniesPlansFilter
                    filters={plansFilter || {}}
                    filterSetter={setPlansFilter}
                  />
                </Accordion.Content>
              </Accordion>
              <br />
            </Card.Description>
            <br />
            <Card.Content>
              <InsurancesCompaniesPlansTable
                setSelectedPlan={setSelectedPlan}
                filters={plansFilter}
              />
            </Card.Content>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

export default InsurancesCompaniesPlans;
