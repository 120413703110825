import { useMemo } from 'react';
import {
  VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum,
  VaxSyncIntegrationFirstOptionsEnum,
  vaxSyncIntegrationFirstOptionsEnumToText,
  vaxSyncIntegrationInmunizationIncosistentSecondOptionsEnumToText,
  VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum,
  vaxSyncIntegrationInmunizationNotReportedSecondOptionsEnumToText,
} from '@bluefox/constants/notifications';
import {
  Notification,
  VaxSyncIntegrationNotificationContent,
} from '@bluefox/models/Notification';
import { renderGivenAt } from '@bluefox/ui/Notifications/common';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';
import { reformatYYYYhMMhDDStringtoMMsDDsYYYY } from '@bluefox/lib/formatters';

const renderFirstOptionMessage = (
  content: VaxSyncIntegrationNotificationContent,
  practiceTimezone?: string
) => {
  switch (content.firstOption) {
    case VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_EMR:
    case VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_IIS:
    case VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_INCONCISTENT_EMR_CANID:
      return `the ${
        vaxSyncIntegrationFirstOptionsEnumToText[content.firstOption]
      }`;
    case VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT:
      return content.freeText;
    case VaxSyncIntegrationFirstOptionsEnum.LOCKED_ENCOUNTER:
    case VaxSyncIntegrationFirstOptionsEnum.VACCINATION_SING_WRONG_DOS:
    case VaxSyncIntegrationFirstOptionsEnum.NO_APPOINTMENT:
    case VaxSyncIntegrationFirstOptionsEnum.VACCINATION_PENDING_SIGNATURE_PLEASE_SIGN:
    case VaxSyncIntegrationFirstOptionsEnum.NO_PROFILE_IN_EMR:
    case VaxSyncIntegrationFirstOptionsEnum.NO_PROFILE_IN_IIS:
      return vaxSyncIntegrationFirstOptionsEnumToText[content.firstOption];
    case VaxSyncIntegrationFirstOptionsEnum.NO_SCAN_ON_CANID:
      return `the vaccine "${content.vaccineName}", lot number "${
        content.lot
      }" (${
        content.inventoryVfc ? 'VFC' : 'Private'
      }) was not scanned in Canid on the Date of Service (DOS) ${renderGivenAt(
        content.givenAt,
        practiceTimezone
      )}. Please scan the vaccination in Canid.`;
    case VaxSyncIntegrationFirstOptionsEnum.INCONSISTENT_DOS:
      return `the Date of Service (DOS) is inconsistent between Canid and the EMR. In Canid, the vaccine was scanned on ${renderGivenAt(
        content.givenAt,
        practiceTimezone
      )}, while in the EMR, it was recorded with a DOS of ${reformatYYYYhMMhDDStringtoMMsDDsYYYY(
        content.typedDOS as string
      )}. Please confirm the correct DOS.`;
    case VaxSyncIntegrationFirstOptionsEnum.INCONSISTENT_VACCINE_INFORMATION:
      return `the vaccine information is inconsistent between Canid and the EMR. In Canid, the scanned vaccine is "${
        content.vaccineName
      }", lot number "${content.lot}" (${
        content.inventoryVfc ? 'VFC' : 'Private'
      }), while in the EMR, the recorded vaccine is "${
        content.typedVaccineName
      }", lot number "${content.typedLot}" (${
        content.typedVfc ? 'VFC' : 'Private'
      }). Please confirm the correct information.`;
    default:
      return null;
  }
};

const renderInmunizationNotReportedSecondOptionMessage = (
  content: VaxSyncIntegrationNotificationContent
) => {
  switch (
    content.secondOption as VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum
  ) {
    case VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_CLOSED:
    case VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_MISSED:
    case VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.GUARDIAN_INFO_MISSING:
      return vaxSyncIntegrationInmunizationNotReportedSecondOptionsEnumToText[
        content.secondOption as VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum
      ];
    default:
      return null;
  }
};

const renderInmunizationIncosistentSecondOptionMessage = (
  content: VaxSyncIntegrationNotificationContent
) => {
  switch (
    content.secondOption as VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum
  ) {
    case VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CREATE_ENCOUNTER:
    case VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.OPEN_ENCOUNTER:
    case VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CONFIRM_GUARDIAN_INFO_OR_CREATE_PROFILE:
      return vaxSyncIntegrationInmunizationIncosistentSecondOptionsEnumToText[
        content.secondOption as VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum
      ];
    default:
      return null;
  }
};

type Props = {
  notification: Notification;
  practiceTimezone?: string;
};

const VaxSyncIntegrationNotificationMessage = ({
  notification,
  practiceTimezone,
}: Props) => {
  const { content, currentTimezone } = useMemo(
    () => ({
      content: notification.content as VaxSyncIntegrationNotificationContent,
      currentTimezone: practiceTimezone
        ? practiceTimezone
        : notification?.practice?.timezone,
    }),
    [notification, practiceTimezone]
  );
  return (
    <>
      <p>
        For the patient{' '}
        <b>
          {`${content?.fullPatienName}, MRN ${
            isStringNotEmpty(content?.mrn) ? content?.mrn : '-'
          }`}
        </b>
        {`, DOS ${renderGivenAt(
          content?.givenAt,
          currentTimezone
        )}, ${renderFirstOptionMessage(content, currentTimezone)}`}
        {(content?.firstOption ===
          VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_EMR ||
          content?.firstOption ===
            VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_IIS) &&
          ` because ${renderInmunizationNotReportedSecondOptionMessage(
            content
          )}`}
        {content?.firstOption ===
          VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_INCONCISTENT_EMR_CANID &&
          `. Please ${renderInmunizationIncosistentSecondOptionMessage(
            content
          )}`}
      </p>
      {notification.note && (
        <p>
          <b>Rejected message:</b> {notification.note}
        </p>
      )}
    </>
  );
};

export default VaxSyncIntegrationNotificationMessage;
