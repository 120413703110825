import {
  VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum,
  VaxSyncIntegrationFirstOptionsEnum,
  vaxSyncIntegrationFirstOptionsEnumToText,
  vaxSyncIntegrationInmunizationIncosistentSecondOptionsEnumToText,
  VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum,
  vaxSyncIntegrationInmunizationNotReportedSecondOptionsEnumToText,
} from '@bluefox/constants/notifications';
import {
  CheckboxWrapperStyle,
  DropdownWrapperStyle,
  MainContextHightlitedStyle,
  MainContextParagraphStyle,
} from '../SharedStyles';
import { Checkbox, Dropdown, Form, Input } from 'semantic-ui-react';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';
import { renderGivenAt } from '@bluefox/ui/Notifications/common';

function buildDropdownData(optionEnum: VaxSyncIntegrationFirstOptionsEnum) {
  return {
    key: optionEnum,
    text: vaxSyncIntegrationFirstOptionsEnumToText[optionEnum],
    value: optionEnum,
  };
}

const firstOptionsDropdownDataV1 = [
  buildDropdownData(
    VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_EMR
  ),
  buildDropdownData(
    VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_IIS
  ),
  buildDropdownData(
    VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_INCONCISTENT_EMR_CANID
  ),
  buildDropdownData(
    VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_IIS
  ),
];

const firstOptionsDropdownDataV2 = [
  buildDropdownData(VaxSyncIntegrationFirstOptionsEnum.LOCKED_ENCOUNTER),
  buildDropdownData(
    VaxSyncIntegrationFirstOptionsEnum.VACCINATION_SING_WRONG_DOS
  ),
  buildDropdownData(VaxSyncIntegrationFirstOptionsEnum.NO_SCAN_ON_CANID),
  buildDropdownData(VaxSyncIntegrationFirstOptionsEnum.NO_APPOINTMENT),
  buildDropdownData(VaxSyncIntegrationFirstOptionsEnum.INCONSISTENT_DOS),

  buildDropdownData(
    VaxSyncIntegrationFirstOptionsEnum.VACCINATION_PENDING_SIGNATURE_PLEASE_SIGN
  ),
  buildDropdownData(VaxSyncIntegrationFirstOptionsEnum.NO_PROFILE_IN_EMR),
  buildDropdownData(VaxSyncIntegrationFirstOptionsEnum.NO_PROFILE_IN_IIS),
  buildDropdownData(
    VaxSyncIntegrationFirstOptionsEnum.INCONSISTENT_VACCINE_INFORMATION
  ),
];

const InmunizationNotReportedSecondOptionsDropdownData = [
  {
    key: VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_CLOSED,
    text: vaxSyncIntegrationInmunizationNotReportedSecondOptionsEnumToText[
      VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum
        .ENCOUNTER_CLOSED
    ],
    value:
      VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_CLOSED,
  },
  {
    key: VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_MISSED,
    text: vaxSyncIntegrationInmunizationNotReportedSecondOptionsEnumToText[
      VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum
        .ENCOUNTER_MISSED
    ],
    value:
      VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_MISSED,
  },
  {
    key: VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.GUARDIAN_INFO_MISSING,
    text: vaxSyncIntegrationInmunizationNotReportedSecondOptionsEnumToText[
      VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum
        .GUARDIAN_INFO_MISSING
    ],
    value:
      VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.GUARDIAN_INFO_MISSING,
  },
];

const InmunizationIncosistentSecondOptionsDropdownData = [
  {
    key: VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CREATE_ENCOUNTER,
    text: vaxSyncIntegrationInmunizationIncosistentSecondOptionsEnumToText[
      VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CREATE_ENCOUNTER
    ],
    value:
      VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CREATE_ENCOUNTER,
  },
  {
    key: VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.OPEN_ENCOUNTER,
    text: vaxSyncIntegrationInmunizationIncosistentSecondOptionsEnumToText[
      VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.OPEN_ENCOUNTER
    ],
    value:
      VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.OPEN_ENCOUNTER,
  },
  {
    key: VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CONFIRM_GUARDIAN_INFO_OR_CREATE_PROFILE,
    text: vaxSyncIntegrationInmunizationIncosistentSecondOptionsEnumToText[
      VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum
        .CONFIRM_GUARDIAN_INFO_OR_CREATE_PROFILE
    ],
    value:
      VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CONFIRM_GUARDIAN_INFO_OR_CREATE_PROFILE,
  },
];

export type FormValues = {
  firstOptionSelection?: VaxSyncIntegrationFirstOptionsEnum;
  secondOptionSelection?:
    | VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum
    | VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum;
  freeText?: string;
  version?: number;
  typedDOS?: string;
  typedVaccineName?: string;
  typedLot?: string;
  typedVfc?: boolean;
};

export function isFormValid(formValues: FormValues) {
  if (!formValues.firstOptionSelection) return false;
  switch (formValues.firstOptionSelection) {
    case VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_EMR:
    case VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_IIS:
    case VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_INCONCISTENT_EMR_CANID:
      if (!formValues.secondOptionSelection) return false;
      break;

    case VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT:
      if (!isStringNotEmpty(formValues.freeText)) return false;
      break;

    // v2:
    case VaxSyncIntegrationFirstOptionsEnum.LOCKED_ENCOUNTER:
    case VaxSyncIntegrationFirstOptionsEnum.VACCINATION_SING_WRONG_DOS:
    case VaxSyncIntegrationFirstOptionsEnum.NO_SCAN_ON_CANID:
    case VaxSyncIntegrationFirstOptionsEnum.NO_APPOINTMENT:
      break;

    case VaxSyncIntegrationFirstOptionsEnum.INCONSISTENT_DOS:
      if (!isStringNotEmpty(formValues.typedDOS)) return false;
      break;

    case VaxSyncIntegrationFirstOptionsEnum.VACCINATION_PENDING_SIGNATURE_PLEASE_SIGN:
    case VaxSyncIntegrationFirstOptionsEnum.NO_PROFILE_IN_EMR:
    case VaxSyncIntegrationFirstOptionsEnum.NO_PROFILE_IN_IIS:
      break;

    case VaxSyncIntegrationFirstOptionsEnum.INCONSISTENT_VACCINE_INFORMATION:
      if (
        !isStringNotEmpty(formValues.typedVaccineName) ||
        !isStringNotEmpty(formValues.typedLot) ||
        formValues.typedVfc === undefined
      ) {
        return false;
      }
  }

  return true;
}

type Props = {
  fullPatienName?: string;
  givenAt?: string | Date;
  practiceTimezone?: string;
  mrn?: string;
  vaccineName?: string;
  inventoryVfc?: boolean;
  lot?: string;
  formValues: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
};

const VaxSyncIntegrationNotificationForm = ({
  fullPatienName,
  givenAt,
  practiceTimezone,
  mrn,
  vaccineName,
  inventoryVfc,
  lot,
  formValues,
  setFormValues,
}: Props) => {
  return (
    <>
      <MainContextParagraphStyle>
        For the patient{' '}
        <MainContextHightlitedStyle>
          {fullPatienName} MRN {mrn || '-'}
        </MainContextHightlitedStyle>
        {` DOS ${renderGivenAt(givenAt, practiceTimezone)}, `}
        {formValues.firstOptionSelection !==
          VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT && (
          <>
            <DropdownWrapperStyle>
              <Dropdown
                clearable
                options={
                  formValues?.version === 2
                    ? firstOptionsDropdownDataV2
                    : firstOptionsDropdownDataV1
                }
                selection
                value={formValues?.firstOptionSelection}
                onChange={(_, { value }) => {
                  setFormValues((prevVal) => ({
                    firstOptionSelection:
                      value as VaxSyncIntegrationFirstOptionsEnum,
                    version: prevVal.version,
                  }));
                }}
              />
            </DropdownWrapperStyle>
            {(formValues?.firstOptionSelection ===
              VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_EMR ||
              formValues?.firstOptionSelection ===
                VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_IIS) && (
              <>
                {' because '}
                <DropdownWrapperStyle>
                  <Dropdown
                    clearable
                    options={InmunizationNotReportedSecondOptionsDropdownData}
                    selection
                    value={formValues?.secondOptionSelection}
                    onChange={(_, { value }) => {
                      setFormValues((prevVal) => ({
                        ...prevVal,
                        secondOptionSelection:
                          value as VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum,
                      }));
                    }}
                  />
                </DropdownWrapperStyle>
              </>
            )}
            {formValues?.firstOptionSelection ===
              VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_INCONCISTENT_EMR_CANID && (
              <>
                {'. Please '}
                <DropdownWrapperStyle>
                  <Dropdown
                    clearable
                    options={InmunizationIncosistentSecondOptionsDropdownData}
                    selection
                    value={formValues?.secondOptionSelection}
                    onChange={(_, { value }) => {
                      setFormValues((prevVal) => ({
                        ...prevVal,
                        secondOptionSelection:
                          value as VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum,
                      }));
                    }}
                  />
                </DropdownWrapperStyle>
              </>
            )}
          </>
        )}
        {formValues.firstOptionSelection ===
          VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT && (
          <>
            {' '}
            <Input
              fluid
              type="text"
              value={formValues?.freeText}
              onChange={(_, { value }) => {
                setFormValues((prevVal) => ({
                  ...prevVal,
                  freeText: value,
                }));
              }}
            />
          </>
        )}
        {formValues.firstOptionSelection ===
          VaxSyncIntegrationFirstOptionsEnum.NO_SCAN_ON_CANID && (
          <>
            <p>
              <b>Vaccine:</b> {vaccineName}
              <br />
              <b>Lot:</b> {lot}
              <br />
              <b>Inventory</b> {inventoryVfc ? 'VFC' : 'Private'}
            </p>
          </>
        )}
        {formValues.firstOptionSelection ===
          VaxSyncIntegrationFirstOptionsEnum.INCONSISTENT_DOS && (
          <>
            <p>
              <b>Date:</b> {renderGivenAt(givenAt, practiceTimezone)}
              <br />
              <label htmlFor="recorded-date">
                <b>Recorded Date: </b>{' '}
              </label>
              <Input
                id="recorded-date"
                type="date"
                required
                value={formValues.typedDOS}
                onChange={(_, { value }) =>
                  setFormValues((prevVal) => ({
                    ...prevVal,
                    typedDOS: value as string,
                  }))
                }
              />
            </p>
          </>
        )}
        {formValues.firstOptionSelection ===
          VaxSyncIntegrationFirstOptionsEnum.INCONSISTENT_VACCINE_INFORMATION && (
          <Form onSubmit={(e) => e.preventDefault()}>
            <p>
              <b>Scanned Vaccine:</b> <br />
              <Form.Group widths="equal">
                <Form.Input
                  label="Vaccine name"
                  type="text"
                  value={formValues?.typedVaccineName || ''}
                  onChange={(_, { value }) => {
                    setFormValues((prevVal) => ({
                      ...prevVal,
                      typedVaccineName: value,
                    }));
                  }}
                />
                <Form.Input
                  label="Lot"
                  type="text"
                  value={formValues?.typedLot || ''}
                  onChange={(_, { value }) => {
                    setFormValues((prevVal) => ({
                      ...prevVal,
                      typedLot: value,
                    }));
                  }}
                />
                <Form.Dropdown
                  label="Inventory"
                  clearable
                  selection
                  options={[
                    { key: 'vfc', text: 'VFC', value: true },
                    { key: 'private', text: 'Private', value: false },
                  ]}
                  value={formValues.typedVfc}
                  onChange={(_, { value }) => {
                    setFormValues((prevVal) => ({
                      ...prevVal,
                      typedVfc: value === '' ? undefined : (value as boolean),
                    }));
                  }}
                />
              </Form.Group>
            </p>
          </Form>
        )}
      </MainContextParagraphStyle>
      <MainContextParagraphStyle>
        Free Text:{' '}
        <CheckboxWrapperStyle>
          <Checkbox
            checked={
              formValues?.firstOptionSelection ===
              VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT
            }
            onChange={(_, { checked }) =>
              setFormValues((prevVal) => ({
                ...prevVal,
                firstOptionSelection: checked
                  ? VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT
                  : undefined,
                secondOptionSelection: undefined,
                freeText: undefined,
              }))
            }
          />
        </CheckboxWrapperStyle>
      </MainContextParagraphStyle>
    </>
  );
};

export default VaxSyncIntegrationNotificationForm;
