import InsuranceCompanyPicker from '@bluefox/ui/InsuranceCompanyPicker';
import {
  eligibilityOptions,
  InsuranceCompaniesPlanFilter,
} from '@bluefox/models/insuranceCompanyPlan';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from 'semantic-ui-react';
import { debounce } from '@bluefox/lib/debounce';

type Props = {
  filters: InsuranceCompaniesPlanFilter;
  filterSetter: Dispatch<
    SetStateAction<InsuranceCompaniesPlanFilter | undefined>
  >;
};

export const InsurancesCompaniesPlansFilter = ({
  filterSetter,
  filters,
}: Props) => {
  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const [searchVaccineInputValue, setSearchVaccineInputValue] =
    useState<string>();

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );
  return (
    <Form>
      <Form.Group>
        <InsuranceCompanyPicker
          placeholder="Insurance Name:"
          value={filters?.insuranceCompanyId}
          onCompanySelected={(company) => {
            filterSetter({
              ...filters,
              insuranceCompanyId: company.id,
            });
          }}
        />

        <Form.Input
          placeholder="Plan Name..."
          value={searchVaccineInputValue}
          onChange={(_, { value }) => {
            setSearchVaccineInputValue(value);
            debouncedRef.current?.cancel();
            debouncedRef.current = debounce(() => {
              filterSetter({
                ...filters,
                name: value,
              });
            }, 500);
            debouncedRef.current();
          }}
        />

        <Form.Select
          options={[
            ...eligibilityOptions,
            { text: 'All Eligibility', value: 'all' },
          ]}
          value={filters.isVfc === undefined ? 'all' : filters.isVfc}
          onChange={(event, { value }) => {
            filterSetter({
              ...filters,
              isVfc: value === 'all' ? undefined : (value as boolean),
            });
          }}
        />

        <Form.Button
          content="Clear"
          primary
          onClick={() => {
            setSearchVaccineInputValue('');
            filterSetter({
              insuranceCompanyId: undefined,
              isVfc: undefined,
              name: undefined,
            });
          }}
        />
      </Form.Group>
    </Form>
  );
};
