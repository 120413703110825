import React, { Dispatch, SetStateAction, useCallback } from 'react';
import {
  Button,
  Container,
  DropdownItemProps,
  Form,
  Label,
  Popup,
} from 'semantic-ui-react';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';
import BorrowingExcelExportModal from './BorrowingExcelExportModal';
import { GetVFCInconsistenciesStatusesAndTypesData } from '@bluefox/models/VFCInconsistency';
import { Link } from 'react-router-dom';
import VaccinePicker from '@bluefox/ui/VaccinePicker';
import { Vaccine } from '@bluefox/models/Vaccine';
import { SearchValuesBorrowing } from './inconsistencyList';

type Props = {
  practiceOptions: DropdownItemProps[];
  statusOptions: DropdownItemProps[];
  typeOptions: DropdownItemProps[];
  searchValues: Partial<SearchValuesBorrowing>;
  setSearchValues: Dispatch<SetStateAction<Partial<SearchValuesBorrowing>>>;
  searchFromDate: Date | null | undefined;
  searchToDate: Date | null | undefined;
  setSearchFromDate: any;
  setSearchToDate: any;
  onSubmit: () => void;
  criteria: any;
  VFCInconsistenciesStatusesAndTypes:
    | GetVFCInconsistenciesStatusesAndTypesData
    | undefined;
  selectedVaccine?: Vaccine;
  setSelectedVaccine?: any;
  ticketsCount: number;
};
const InconsistenciesFilter = ({
  practiceOptions,
  setSearchValues,
  searchValues,
  typeOptions,
  statusOptions,
  searchFromDate,
  setSearchFromDate,
  searchToDate,
  setSearchToDate,
  onSubmit,
  criteria,
  VFCInconsistenciesStatusesAndTypes,
  setSelectedVaccine,
  selectedVaccine,
  ticketsCount,
}: Props) => {
  const renderStringInput = useCallback(
    (label: string, objKey: string, placeholder: string) => (
      <Form.Field>
        <Form.Input
          label={label}
          type="text"
          placeholder={placeholder}
          onKeyDown={handleSearchOnKeyDown}
          value={searchValues[objKey] || ''}
          onChange={(e) =>
            setSearchValues((prevSearchValues) => ({
              ...prevSearchValues,
              [objKey]: e.target.value,
            }))
          }
        />
      </Form.Field>
    ),
    [searchValues, setSearchValues]
  );

  const renderMultiSelectStringArray = useCallback(
    (
      label: string,
      objKey: string,
      source: DropdownItemProps[],
      defaultValue: string,
      disabled?: boolean
    ) => {
      return (
        <Form.Dropdown
          label={label}
          fluid
          search
          selection
          disabled={disabled}
          onKeyDown={handleSearchOnKeyDown}
          defaultValue={defaultValue}
          value={searchValues[objKey] || ''}
          options={source}
          onChange={(e, { value }) =>
            setSearchValues((prevSearchValues) => ({
              ...prevSearchValues,
              [objKey]: value as string[],
            }))
          }
        />
      );
    },
    [searchValues, setSearchValues]
  );

  const handleSearchOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Container fluid>
      <Form
        onSubmit={(e, data) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Form.Group widths={'equal'} inline>
          {renderMultiSelectStringArray(
            'Practice',
            'practiceId',
            [
              {
                text: 'All practices',
                value: 'allPractices',
              },
              ...practiceOptions,
            ],
            'allPractices'
          )}
          {renderMultiSelectStringArray(
            'Status',
            'status',
            [{ text: 'All Statuses', value: 'allStatuses' }, ...statusOptions],
            'allStatuses'
          )}
          {renderMultiSelectStringArray(
            'Type',
            'type',
            [{ text: 'All Types', value: 'allTypes' }, ...typeOptions],
            'allTypes'
          )}
          {renderStringInput('Patient', 'patient', 'Filter by patient')}
          {renderStringInput(
            'Insurance',
            'insuranceCompanyName',
            'Filter by insurance'
          )}

          <Popup
            trigger={
              <Form.Button
                style={{ marginTop: '1rem' }}
                width={1}
                icon="search"
                type={'submit'}
                primary
              />
            }
            content="Search"
          />
          <Popup
            trigger={
              <Form.Button
                style={{ marginTop: '1rem' }}
                width={1}
                icon="close"
                negative
                onClick={() => {
                  setSearchValues({
                    practiceId: 'allPractices',
                    status: 'allStatuses',
                    type: 'allTypes',
                    lot: '',
                  });
                  setSelectedVaccine(undefined);
                  setSearchFromDate(undefined);
                  setSearchToDate(undefined);
                }}
              />
            }
            content="Clean filters"
          />
        </Form.Group>
        <Form.Group widths={'equal'} inline>
          <Form.Field>
            <Label>Filter by Vaccine</Label>
            <VaccinePicker
              dropdownProps={{
                clearable: true,
              }}
              placeholder="Filter by Vaccine"
              onChange={({ vaccine }) => setSelectedVaccine(vaccine)}
              value={selectedVaccine?.id}
            />
          </Form.Field>
          {renderStringInput('Lot', 'lot', 'Filter by lot')}

          <Form.Field>
            <Label style={{ marginRight: '0.5rem', minWidth: 'fit-content' }}>
              <b>Search by date range:</b>
            </Label>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <DateTimePicker
                placeholderText="From..."
                selected={searchFromDate}
                onChange={(d) => {
                  setSearchFromDate(d ? (d as Date) : undefined);
                }}
                onSelect={(value) =>
                  setSearchFromDate(value ? (value as Date) : undefined)
                }
                onClear={() => setSearchFromDate(undefined)}
                maxDate={new Date()}
                dateFormat={DateFormats.DATE}
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                dropdownMode="select"
                isClearable
              />

              <DateTimePicker
                placeholderText="To..."
                selected={searchToDate}
                onChange={(d) => {
                  setSearchToDate(d ? (d as Date) : undefined);
                }}
                onSelect={(value) =>
                  setSearchToDate(value ? (value as Date) : undefined)
                }
                onClear={() => setSearchToDate(undefined)}
                maxDate={new Date()}
                dateFormat={DateFormats.DATE}
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                dropdownMode="select"
                isClearable
              />
            </div>
          </Form.Field>
          <BorrowingExcelExportModal
            criteria={criteria}
            VFCInconsistenciesStatusesAndTypes={
              VFCInconsistenciesStatusesAndTypes
            }
          />
        </Form.Group>
        <Form.Group
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {renderMultiSelectStringArray(
            'With/Without Issue',
            'issue',
            [
              {
                text: 'With and without issue',
                value: 'allIssues',
              },
              {
                text: 'With issue only',
                value: 'with',
                disabled: !(ticketsCount > 0),
              },
              {
                text: 'Without issue only',
                value: 'without',
              },
            ],
            'allIssues'
          )}
          <div style={{ display: 'flex', gap: '1rem' }}>
            <Button
              as={Link}
              to="/solvePendingBorrows"
              style={{
                display: 'flex',
                marginLeft: '5px',
                placeItems: 'center',
              }}
            >
              Fix Pending Borrows
            </Button>
            <Button
              as={Link}
              to="/solvePossibleBorrowingCases"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              See Possible
              <br />
              Borrowing Tickets
            </Button>
          </div>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default InconsistenciesFilter;
