import {
  Button,
  Card,
  Checkbox,
  Dimmer,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react';
import { PatientData } from '@bluefox/models/Patient';
import { BillingClaim } from '@bluefox/models/Billing';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BillingNotificationContent,
  Notification,
  NotificationResponseType,
  NotificationStatus,
  NotificationStatusLogAction,
  NotificationType,
} from '@bluefox/models/Notification';
import { useApplicationState } from '@bluefox/contexts';
import { BillingNotificationFirstOptionsEnum } from '@bluefox/constants/notifications';
import { useLazyQuery } from '@apollo/client';
import { NotificationsQuery } from '@bluefox/graphql/notifications';
import BillingNotificationForm, {
  FormValues,
  isFormValid,
} from './BillingNotificationForm';
import BillingNotificationModalMessage from './BillingNotificationModalMessage';

type Props = {
  practiceId?: string;
  practicePatientId?: string;
  fullPatienName?: string;
  givenAt?: string | Date;
  practiceTimezone?: string;
  mrn?: string;
  patient?: PatientData;
  claim?: BillingClaim;
  open: boolean;
  formValues: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  onClose?: () => void;
  onCreate?: (notification: Notification) => {};
  onEdit?: (
    notification: Notification,
    content: BillingNotificationContent,
    newStatusLogItem: any
  ) => {};
};

const BillingNotificationModal = ({
  practiceId,
  practicePatientId,
  fullPatienName,
  givenAt,
  practiceTimezone,
  mrn,
  claim,
  open,
  formValues,
  setFormValues,
  onClose,
  onCreate,
  onEdit,
}: Props) => {
  const { session } = useApplicationState();
  const [editMode, setEditMode] = useState(false);
  const [enableCreateNewNotification, setEnableCreateNewNotification] =
    useState(false);
  const [viewPreviousNotifications, setViewPreviousNotifications] =
    useState(false);

  const [getNotifications, { data, loading, error }] =
    useLazyQuery<NotificationResponseType>(NotificationsQuery);

  const onOpen = useCallback(async () => {
    setEnableCreateNewNotification(false);
    setViewPreviousNotifications(false);
    try {
      await getNotifications({
        variables: {
          where: {
            entityRefId: { _eq: claim?.id },
          },
          withPractice: true,
        },
      });
    } catch (error) {}
  }, [claim?.id, getNotifications]);

  /**
   * Due to Modal onOpen is not trigger, it is needed to simulate
   * the event with a useEffect :(
   */
  useEffect(() => {
    if (open) {
      onOpen();
    }
  }, [onOpen, open]);

  const [
    notificationExist,
    notificationDoesNotExist,
    notifications,
    areMultipleNotifications,
    notification,
  ] = useMemo(() => {
    const notificationExist =
      data &&
      data.communication_notifications_aggregate.aggregate.count > 0 &&
      !enableCreateNewNotification;
    const notificationDoesNotExist =
      (data &&
        data.communication_notifications_aggregate.aggregate.count === 0) ||
      enableCreateNewNotification;
    const notifications = [...(data?.communication_notifications || [])].sort(
      (a, b) => {
        return (
          new Date(b.createdAt || 0).getTime() -
          new Date(a.createdAt || 0).getTime()
        );
      }
    );
    const areMultipleNotifications =
      data && data.communication_notifications_aggregate.aggregate.count > 1;
    const notification = notifications?.[0];
    return [
      notificationExist,
      notificationDoesNotExist,
      notifications,
      areMultipleNotifications,
      notification,
    ];
  }, [data, enableCreateNewNotification]);

  return (
    <Modal
      onClose={() => {
        setEditMode(false);
        onClose?.();
      }}
      open={open}
      size="small"
      closeIcon
    >
      <Modal.Header>Billing Notification</Modal.Header>
      <Modal.Content>
        {error && <Message error>{error.message}</Message>}
        {loading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        {notificationExist && !editMode && (
          <>
            {viewPreviousNotifications && (
              <div
                style={{
                  maxHeight: '60vh',
                  overflow: 'auto',
                  padding: '1rem',
                  marginBottom: '2rem',
                }}
              >
                {notifications.map((n) => (
                  <Card key={n.id} fluid>
                    <Card.Content>
                      <Card.Description>
                        <BillingNotificationModalMessage
                          notification={n as Notification}
                        />
                      </Card.Description>
                    </Card.Content>
                  </Card>
                ))}
              </div>
            )}
            {!viewPreviousNotifications && (
              <BillingNotificationModalMessage
                notification={notification as Notification}
              />
            )}
          </>
        )}
        {(notificationDoesNotExist || editMode) && (
          <BillingNotificationForm
            fullPatienName={fullPatienName}
            givenAt={givenAt}
            practiceTimezone={practiceTimezone}
            mrn={mrn}
            claim={claim}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        )}
        {areMultipleNotifications &&
          !editMode &&
          !enableCreateNewNotification && (
            <Checkbox
              label="View previous notifications"
              checked={viewPreviousNotifications}
              onChange={(_, { checked }) => {
                setViewPreviousNotifications(!!checked);
              }}
            />
          )}
      </Modal.Content>
      <Modal.Actions>
        {!editMode && notificationDoesNotExist && (
          <Button
            type="submit"
            disabled={!isFormValid(formValues)}
            primary
            onClick={() => {
              const notification: Notification = {
                practiceId,
                practicePatientId,
                createdBy: session?.account?.id,
                content: {
                  fullPatienName,
                  givenAt,
                  mrn,
                  type: NotificationType.claim,
                  firstOption:
                    formValues.firstOptionSelection as BillingNotificationFirstOptionsEnum,
                  ...(formValues.selectedCptCodes?.length
                    ? { selectedCptCodes: formValues.selectedCptCodes }
                    : {}),
                  freeText: formValues.freeText,
                  version: 2,
                },
                type: NotificationType.claim,
                status: NotificationStatus.pending_approval,
                statusLog: [
                  {
                    action: NotificationStatusLogAction.creation,
                    account: session?.account,
                    updatedAt: new Date(),
                    status: NotificationStatus.pending_approval,
                  },
                ],
                entityRefId: claim?.id,
              };
              onCreate?.(notification);
              setEditMode(false);
              onClose?.();
            }}
          >
            Send
          </Button>
        )}
        {!editMode && notificationExist && (
          <Button
            type="button"
            primary
            disabled={
              !notification ||
              notification.status !== NotificationStatus.pending_approval ||
              viewPreviousNotifications
            }
            onClick={() => {
              const content =
                notification?.content as BillingNotificationContent;
              setFormValues({
                firstOptionSelection: content.firstOption,
                version: content.version,
                ...(content.selectedCptCodes
                  ? { selectedCptCodes: content.selectedCptCodes }
                  : {}),
                ...(content.freeText ? { freeText: content.freeText } : {}),
              });
              setEditMode(true);
            }}
          >
            Edit Notification
          </Button>
        )}
        {editMode && (
          <Button
            type="submit"
            disabled={!isFormValid(formValues)}
            primary
            onClick={() => {
              function getFormContent(): Partial<BillingNotificationContent> {
                return {
                  firstOption:
                    formValues.firstOptionSelection as BillingNotificationFirstOptionsEnum,
                  selectedCptCodes: formValues.selectedCptCodes?.length
                    ? formValues.selectedCptCodes
                    : undefined,
                  freeText: formValues.freeText,
                };
              }

              if (editMode) {
                const oldContent =
                  notification?.content as BillingNotificationContent;
                const newStatusLog = {
                  action: NotificationStatusLogAction.contentEdit,
                  account: session?.account,
                  updatedAt: new Date(),
                  status: NotificationStatus.pending_approval,
                  oldContent: oldContent,
                };
                const newContent = {
                  ...oldContent,
                  ...getFormContent(),
                } as BillingNotificationContent;
                onEdit?.(
                  notification as Notification,
                  newContent,
                  newStatusLog
                );
                setEditMode(false);
              } else {
                const notification: Notification = {
                  practiceId,
                  practicePatientId,
                  createdBy: session?.account?.id,
                  content: {
                    fullPatienName,
                    givenAt,
                    mrn,
                    type: NotificationType.claim,
                    ...getFormContent(),
                  } as BillingNotificationContent,
                  type: NotificationType.claim,
                  status: NotificationStatus.pending_approval,
                  statusLog: [
                    {
                      action: NotificationStatusLogAction.creation,
                      account: session?.account,
                      updatedAt: new Date(),
                      status: NotificationStatus.pending_approval,
                    },
                  ],
                  entityRefId: claim?.id,
                };
                onCreate?.(notification);
                setEditMode(false);
                onClose?.();
              }
            }}
          >
            Save
          </Button>
        )}
        {(notification?.status === NotificationStatus.finalized ||
          notification?.status === NotificationStatus.rejected ||
          notification?.status === NotificationStatus.dismissed) &&
          !enableCreateNewNotification && (
            <Button
              secondary
              onClick={() => setEnableCreateNewNotification(true)}
            >
              Send a new notification
            </Button>
          )}
        <Button
          type="button"
          secondary
          onClick={() => {
            if (editMode || enableCreateNewNotification) {
              setEditMode(false);
              setEnableCreateNewNotification(false);
            } else {
              onClose?.();
            }
          }}
        >
          {editMode || enableCreateNewNotification ? 'Cancel' : 'Close'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BillingNotificationModal;
