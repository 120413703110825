import { gql } from '@apollo/client';

export const ClaimsQuery = gql`
  query ClaimsQuery(
    $criteria: vaccinations_bool_exp!
    $updatedAtCriteria: timestamptz_comparison_exp
    $status: billing_claim_statuses_enum
    $limit: Int
    $offset: Int
  ) {
    aggregating: billing_claims_aggregate(
      where: {
        _and: [
          { status: { _eq: $status } }
          { vaccinations: $criteria }
          { updatedAt: $updatedAtCriteria }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    claims: billing_claims(
      where: {
        _and: [
          { status: { _eq: $status } }
          { vaccinations: $criteria }
          { updatedAt: $updatedAtCriteria }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: { updatedAt: desc, controlNumber: desc }
    ) {
      id
      status
      updatedAt
      note
      rhcClaimNumber
      claimControlNumber
      givenAt
      updates(order_by: { createdAt: desc }, limit: 1) {
        response
      }
      cptCodes(order_by: { cptCode: asc }) {
        id
        cptCode
        units
        note
        status
        inMediationDueDate
      }
      practicePatient {
        id
        practice {
          id
          name
          npi
          profile
          handler
        }
        patientData {
          firstName
          lastName
          birthdate
        }
        insurances(where: { primary: { _eq: true } }) {
          memberId
          company {
            id
            name
          }
        }
      }
      vaccinations {
        id
        givenAt
        vaccine {
          name
        }
        prescriber {
          account {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const ClaimResubmitMutation = gql`
  mutation ClaimResubmitMutation($claimId: uuid!) {
    ClaimResubmit(claimId: $claimId) {
      code
      body
      message
      extensions
    }
  }
`;

export const BillingClaimsCount = gql`
  query BillingClaimsCount($criteria: billing_claims_vw_bool_exp) {
    aggregating: billing_claims_vw_aggregate(
      where: $criteria
      distinct_on: claimId
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const BillingClaimsViewOld = gql`
  query BillingClaimsViewOld(
    $criteria: billing_claims_vw_old_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [billing_claims_vw_old_order_by!]
  ) {
    billingClaims: billing_claims_vw_old(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      claimId
      totalClaimAmount
      claimControlNumber
      rhcClaimNumber
      status
      note
      inMediationDueDate
      checkDate
      checkNumber
      dismissedReason
      eobStatus
      appealMethod
      updatedAt
      practicePatientId
      practiceId
      practiceName
      practiceNpi
      practiceProfile
      firstName
      lastName
      birthdate
      fullName
      insuranceId
      insuranceCompanyId
      insuranceName
      insuranceMemberId
      insuranceVfcEligible
      insuranceVfcCriteria
      insurancePayerId
      inventoryVfcCount
      inventoryPrivateCount
      providerFirstName
      providerLastName
      prescriberFirstName
      prescriberLastName
      givenAt
      vaccinationsCptCodes
      unclaimedReason
      updateInsuranceSource
      updateInsuranceStatus
      updateInsuranceMetadata
      updateLastNpi
      updateLastEmployerId
      inMediationDueDateTo
      inMediationDueDateFrom
      inMediationReviewedAt
      uploadedToEmrAt
      paymentReportedToEmrAt
      emrClaimNumber
      createdAt
      practiceHandler
      selfPay
      fieldToCorrect
      tags
      updatedStatusApiAt
      errors
    }
  }
`;

export const BillingClaimsView = gql`
  query BillingClaimsView(
    $criteria: billing_claims_vw_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [billing_claims_vw_order_by!]
  ) {
    billingClaims: billing_claims_vw(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      claim {
        id
        status
        tags
        givenAt
        rhcClaimNumber
        totalClaimAmount
        controlNumber
        claimControlNumber
        appealMethod
        eobStatus
        fieldToCorrect
        selfPay
        note
        inMediationReviewedAt
        dismissedReason
        checkNumber
        uploadedToEmrAt
        paymentReportedToEmrAt
        emrClaimNumber
        practice {
          id
          name
          profile
          timezone
        }
        practicePatient {
          id
          patientData {
            firstName
            lastName
            birthdate
          }
          mrn
          insurances(
            where: { primary: { _eq: true } }
            limit: 1
            order_by: { createdAt: desc }
          ) {
            company {
              name
            }
          }
        }
        vaccinations(limit: 1) {
          id
          prescriber {
            id
            account {
              firstName
              lastName
            }
          }
          provider {
            id
            account {
              firstName
              lastName
            }
          }
        }
        cptCodes {
          id
          cptCode
          claimAmount
          paidAmount
          status
          units
          invoices {
            invoiceNo
          }
          vaccinePricing {
            id
            pricePerDose
          }
        }
      }
      claimId
      totalClaimAmount
      claimControlNumber
      rhcClaimNumber
      status
      note
      inMediationDueDate
      checkDate
      checkNumber
      dismissedReason
      eobStatus
      appealMethod
      updatedAt
      practicePatientId
      practiceId
      practiceName
      practiceNpi
      practiceProfile
      fullName
      birthdate
      insuranceId
      insuranceCompanyId
      insuranceName
      insuranceMemberId
      insuranceVfcEligible
      insuranceVfcCriteria
      insurancePayerId
      inventoryVfcCount
      inventoryPrivateCount
      providerFirstName
      providerLastName
      prescriberFirstName
      prescriberLastName
      givenAt
      vaccinationsCptCodes
      unclaimedReason
      updateInsuranceSource
      updateInsuranceStatus
      updateInsuranceMetadata
      updateLastNpi
      updateLastEmployerId
      inMediationDueDateTo
      inMediationDueDateFrom
      inMediationReviewedAt
      uploadedToEmrAt
      paymentReportedToEmrAt
      emrClaimNumber
      createdAt
      practiceHandler
      selfPay
      fieldToCorrect
      tags
      updatedStatusApiAt
      errors
      invoices
    }
  }
`;

export const BillingClaimFormQuery = gql`
  query BillinClaimFormQuery($claimId: uuid!) {
    claim: billing_claims_by_pk(id: $claimId) {
      id
      selfPay
      status
      tags
      givenAt
      rhcClaimNumber
      totalClaimAmount
      checkNumber
      controlNumber
      claimControlNumber
      checkDate
      inMediationDueDate
      eobStatus
      dismissedReason
      directChargeReason
      directChargeStatus
      unclaimedStatus
      appealMethod
      borrowingStatus
      note
      insurance {
        insuranceCompanyName
        memberId
      }
      practice {
        id
        name
        profile
        npi
      }
      practicePatient {
        id
        patientData {
          firstName
          lastName
          birthdate
        }
        insurances(
          where: { primary: { _eq: true } }
          order_by: { createdAt: desc }
          limit: 1
        ) {
          memberId
          company {
            name
          }
        }
      }
      vaccinations(limit: 1) {
        id
        prescriber {
          id
          account {
            firstName
            lastName
          }
        }
        provider {
          id
          account {
            firstName
            lastName
          }
        }
      }
      cptCodes {
        id
        claimId
        claimAmount
        cptCode
        paidAmount
        status
        units
        resolution
        inMediationAmount
        inMediationDueDate
        dismissedReason
        compensationAmount
        note
        invoice
        invoices {
          invoiceNo
        }
      }
      updates(
        where: { metadata: { _has_key: "insurance" } }
        distinct_on: claimId
        order_by: [{ claimId: asc }, { createdAt: desc }]
      ) {
        claimId
        id
        status
        insuranceName: metadata(path: "$.insurance.insuranceName")
        insuranceCompanyId: metadata(path: "$.insurance.insuranceCompanyId")
        memberId: metadata(path: "$.insurance.memberId")
        vfcEligible: metadata(path: "$.insurance.vfcEligible")
        vfcCriteria: metadata(path: "$.insurance.vfcCriteria")
        payerId: metadata(path: "$.insurance.payerID")
        insuranceForPracticePortal: metadata(
          path: "$.insuranceForPracticePortal"
        )
        alreadySubmittedData: metadata(path: "$.alreadySubmittedData")
      }
      lastUpdateFromApi: updates(
        where: { source: { _eq: "api" } }
        distinct_on: claimId
        order_by: [{ claimId: asc }, { createdAt: desc }]
      ) {
        npi: request(path: "$.providers[0].npi")
        employerId: request(path: "$.providers[0].employerId")
      }
      updateNotes: updates(
        where: { _not: { metadata: { _contains: { note: { url: "" } } } } }
        order_by: [{ claimId: asc }, { createdAt: desc }]
      ) {
        url: metadata(path: "$.note.url")
        createdAt
      }
    }
  }
`;

export const SaveClaimUpdateMutation = gql`
  mutation SaveClaimUpdateMutation(
    $claimId: uuid!
    $data: [billing_claim_cpt_codes_insert_input!]!
    $note: jsonb
    $claimControlNumber: String
    $rhcClaimNumber: String
    $inMediation: date
    $checkDate: date
    $status: billing_claim_statuses_enum!
    $checkNumber: String
    $dismissedReason: String
    $eobStatus: String
    $appealMethod: String
    $fieldToCorrect: String
    $prescriberId: uuid
    $borrowingStatus: String
    $unclaimedStatus: String
    $directChargeStatus: String
    $directChargeReason: String
  ) {
    insert_billing_claim_cpt_codes(
      objects: $data
      on_conflict: {
        constraint: claim_cpt_codes_pkey
        update_columns: [
          paidAmount
          note
          status
          resolution
          compensationAmount
          inMediationAmount
          inMediationDueDate
          dismissedReason
        ]
      }
    ) {
      affected_rows
    }
    update_billing_claims_by_pk(
      pk_columns: { id: $claimId }
      _set: {
        note: $note
        claimControlNumber: $claimControlNumber
        rhcClaimNumber: $rhcClaimNumber
        inMediationDueDate: $inMediation
        checkDate: $checkDate
        status: $status
        checkNumber: $checkNumber
        dismissedReason: $dismissedReason
        eobStatus: $eobStatus
        appealMethod: $appealMethod
        fieldToCorrect: $fieldToCorrect
        borrowingStatus: $borrowingStatus
        unclaimedStatus: $unclaimedStatus
        directChargeStatus: $directChargeStatus
        directChargeReason: $directChargeReason
      }
    ) {
      id
    }
  }
`;

export const SaveClaimUpdateWithInsertMutation = (
  withPrescriber: boolean
) => gql`
  mutation SaveClaimUpdateWithInsertMutation(
    $claimId: uuid!
    $data: [billing_claim_cpt_codes_insert_input!]!
    $obj: billing_claim_updates_insert_input!
    $note: jsonb
    $claimControlNumber: String
    $rhcClaimNumber: String
    $inMediation: date
    $checkDate: date
    $status: billing_claim_statuses_enum!
    $checkNumber: String
    $dismissedReason: String
    $eobStatus: String
    $appealMethod: String
    $fieldToCorrect: String
    $prescriberId: uuid
    $borrowingStatus: String
    $unclaimedStatus: String
    $directChargeStatus: String
    $directChargeReason: String
  ) {
    insert_billing_claim_cpt_codes(
      objects: $data
      on_conflict: {
        constraint: claim_cpt_codes_pkey
        update_columns: [
          paidAmount
          note
          status
          resolution
          compensationAmount
          inMediationAmount
          inMediationDueDate
          dismissedReason
        ]
      }
    ) {
      affected_rows
    }
    update_billing_claims_by_pk(
      pk_columns: { id: $claimId }
      _set: {
        note: $note
        claimControlNumber: $claimControlNumber
        rhcClaimNumber: $rhcClaimNumber
        inMediationDueDate: $inMediation
        checkDate: $checkDate
        status: $status
        checkNumber: $checkNumber
        dismissedReason: $dismissedReason
        eobStatus: $eobStatus
        appealMethod: $appealMethod
        fieldToCorrect: $fieldToCorrect
        borrowingStatus: $borrowingStatus
        unclaimedStatus: $unclaimedStatus
        directChargeStatus: $directChargeStatus
        directChargeReason: $directChargeReason
      }
    ) {
      id
    }
    insert_billing_claim_updates_one(object: $obj) {
      id
    }
    ${
      withPrescriber
        ? `update_vaccinations(
      where: { claimId: { _eq: $claimId } }
      _set: { prescribedBy: $prescriberId }
    ) {
      affected_rows
    }`
        : ``
    }
  }
`;

export const SaveClaimControlNumberMutation = gql`
  mutation SaveClaimControlNumberMutation(
    $claimId: uuid!
    $claimControlNumber: String!
  ) {
    controlNumber: update_billing_claims_by_pk(
      pk_columns: { id: $claimId }
      _set: { claimControlNumber: $claimControlNumber }
    ) {
      claimControlNumber
    }
  }
`;

export const UpdateBillingClaimStatusMutation = (
  isFromForm: boolean = true
) => gql`
  mutation UpdateBillingClaimStatusMutation(
    $id: uuid!
    $status: billing_claim_statuses_enum!
    $eobStatus: String!
    ${isFromForm ? '$dismissedReason: String!' : ''}
    $obj: billing_claim_updates_insert_input!
  ) {
    updateBillingClaimStatus: update_billing_claims_by_pk(
      pk_columns: { id: $id }
      _set: {
        status: $status
        eobStatus: $eobStatus
        ${isFromForm ? 'dismissedReason: $dismissedReason' : ''}
      }
    ) {
      id
      status
      dismissedReason
    }
    insert_billing_claim_updates_one(object: $obj) {
      id
    }
  }
`;

export const UpdateBillingClaimTagsMutation = gql`
  mutation UpdateBillingClaimTagsMutation($id: uuid!, $tags: jsonb!) {
    update_billing_claims_by_pk(
      pk_columns: { id: $id }
      _set: { tags: $tags }
    ) {
      id
    }
  }
`;

export const ClaimUpdatesQuery = gql`
  query ClaimUpdatesQuery($claimId: uuid!) {
    claimUpdates: billing_claim_updates(
      where: { claimId: { _eq: $claimId } }
      order_by: { createdAt: desc }
    ) {
      id
      claimId
      createdAt
      status
      metadata
      source
      response
      practiceAccount {
        account {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const ProcedureAmountsQuery = gql`
  query ProcedureAmountsQuery($type: String!) {
    cptCodes: procedure_amounts(where: { type: { _eq: $type } }) {
      cpt
    }
  }
`;

export const BillingProcedureAmountsQuery = gql`
  query ProcedureAmountsQuery {
    cptCodes: procedure_amounts(
      where: { type: { _eq: "Administration" } }
      distinct_on: cpt
    ) {
      cpt
    }
    cptCodesInventory: procedure_amounts(
      where: { type: { _eq: "Inventory" } }
      distinct_on: cpt
    ) {
      cpt
    }
  }
`;

export const BillingReportQuery = gql`
  query BillingReportQuery(
    $criteria: billing_claims_bool_exp!
    $criteriaCptCodes: billing_claim_cpt_codes_bool_exp!
  ) {
    canidProfile: practices(where: { handler: { _eq: "canid" } }) {
      profile
    }
    billingReport: billing_claims(
      where: $criteria
      order_by: { createdAt: asc, selfPay: asc }
    ) {
      id
      note
      checkNumber
      reviewedAt
      billableAt
      revisionStatus
      status
      selfPay
      cptCodes(where: $criteriaCptCodes) {
        id
        claimId
        cptCode
        claimAmount
        paidAmount
      }
      vaccinations {
        givenAt
        practice {
          handler
          name
        }
      }
      practicePatient {
        patientData {
          firstName
          lastName
          birthdate
        }
        insurances(where: { active: { _eq: true } }) {
          memberId
          vfcEligible
          vfcCriteria
          company {
            name
          }
        }
      }
      updates(
        where: { metadata: { _has_key: "insurance" } }
        order_by: { createdAt: desc }
      ) {
        metadata
      }
    }
    totalAmountCalculated: billing_claim_cpt_codes_aggregate(
      where: $criteriaCptCodes
    ) {
      aggregate {
        sum {
          totalAmount: paidAmount
        }
      }
    }
  }
`;

export const NewBillingReportQuery = gql`
  query BillingReportQuery(
    $limit: Int
    $offset: Int
    $criteria: billing_claims_bool_exp!
    $criteriaCptCodesBilliableSelected: billing_claim_cpt_codes_bool_exp!
    $criteriaCptCodesReviewedSelected: billing_claim_cpt_codes_bool_exp!
    $criteriaCptCodes: billing_claim_cpt_codes_bool_exp!
  ) {
    billingReport: billing_claims(
      limit: $limit
      offset: $offset
      where: $criteria
      order_by: { practice: { name: asc }, createdAt: asc, selfPay: asc }
    ) {
      id
      practice {
        name
      }
      note
      checkNumber
      reviewedAt
      billableAt
      revisionStatus
      status
      selfPay
      cptCodes(where: $criteriaCptCodes) {
        id
        claimId
        cptCode
        claimAmount
        paidAmount
        vaccinePricing {
          id
          pricePerDose
        }
      }
      vaccinations {
        givenAt
        givenAtDate
        practice {
          handler
          name
        }
      }
      practicePatient {
        patientData {
          firstName
          lastName
          birthdate
        }
        insurances(where: { active: { _eq: true } }) {
          memberId
          vfcEligible
          vfcCriteria
          company {
            name
          }
        }
      }
      updates(
        where: { metadata: { _has_key: "insurance" } }
        order_by: { createdAt: desc }
      ) {
        metadata
      }
    }
    billingReportCount: billing_claims_aggregate(where: $criteria) {
      aggregate {
        totalClaimsNumber: count
      }
    }
    totalBilliableSelectedAmount: billing_claim_cpt_codes_aggregate(
      where: $criteriaCptCodesBilliableSelected
    ) {
      aggregate {
        sum {
          totalBilliableAmount: paidAmount
        }
      }
    }
    totalReviewedSelectedAmount: billing_claim_cpt_codes_aggregate(
      where: $criteriaCptCodesReviewedSelected
    ) {
      aggregate {
        sum {
          totalReviewedAmount: paidAmount
        }
        totalReviewedCount: count
      }
    }
    totalAmountCalculated: billing_claim_cpt_codes_aggregate(
      where: $criteriaCptCodes
    ) {
      aggregate {
        sum {
          totalAmount: paidAmount
        }
      }
    }
  }
`;

export const ClaimsForInvoiceQuery = (getInvoice?: boolean) => gql`
  query ClaimsForInvoiceQuery(
    $practiceId: uuid
    $excludeClaimIds: _uuid
    $invoiceId: uuid
  ) {
    claims: billing_claims_for_invoice(
      args: {
        practiceid: $practiceId
        excludeclaimids: $excludeClaimIds
        invoiceid: $invoiceId
      }
    ) {
      vaccineId
      vaccineName
      cptCode
      quantity
      amount
    }
    practices(where: { id: { _eq: $practiceId } }) {
      id
      name
      profile
      state
      handler
      npi
      settings
    }
    
    ${
      getInvoice
        ? `invoices: billing_invoices(where: { id: { _eq: $invoiceId } }) {
          id
          totalPrivateAdminAmount
          totalVfcAdminAmount
        }`
        : ``
    }
  }
`;

export const PrivateAdminCptCodesForInvoiceQuery = gql`
  query PrivateAdminCptCodesForInvoiceQuery($claimIds: [uuid!]) {
    privateAdminCptCodes: billing_cpts_for_paid_amount_no_vfc(
      where: { claim_id: { _in: $claimIds } }
    ) {
      claim_cpt_code_id
      claim_id
      vfc_eligible
    }
  }
`;

export const TotalPrivateAdminAmountForInvoiceQuery = gql`
  query TotalPrivateAdminAmountForInvoiceQuery($claimIds: [uuid!]) {
    TotalPrivateAdminAmount: billing_total_admin_paid_amount_no_vfc(
      where: { claim_id: { _in: $claimIds } }
    ) {
      claim_id
      vfc_eligible
      total_admin_paid_amount
    }
  }
`;

export const VfcAdminCptCodesForInvoiceQuery = gql`
  query VfcAdminCptCodesForInvoiceQuery($practiceId: uuid, $invoiceId: uuid) {
    vfcAdminCptCodes: billing_cpts_for_paid_amount_vfc_fcn(
      args: { practiceid: $practiceId, invoiceid: $invoiceId }
    ) {
      claimId
      claimCptCodeId
      practiceId
      vfcEligible
    }
  }
`;

export const TotalVfcAdminAmountForInvoiceQuery = gql`
  query TotalVfcAdminAmountForInvoiceQuery($practiceId: uuid!) {
    TotalVfcAdminAmount: billing_total_admin_paid_amount_vfc_fcn(
      args: { practiceid: $practiceId }
    ) {
      practiceId
      vfcEligible
      totalAdminPaidAmount
    }
  }
`;

export const AdminVfcCptClaimsQuery = gql`
  query AdminVfcCptClaimsQuery($adminCptCodeIds: [uuid!]) {
    vfcAdminCptCodesClaims: billing_claim_cpt_codes(
      where: { id: { _in: $adminCptCodeIds } }
    ) {
      id
      cptCode
      paidAmount
      claim {
        practicePatient {
          practice {
            name
          }
          patientData {
            firstName
            lastName
            birthdate
          }
        }
        updates(
          order_by: { createdAt: desc }
          limit: 1
          where: { status: { _eq: submitted } }
        ) {
          metadata
        }
        vaccinations(order_by: { givenAt: asc }, limit: 1) {
          givenAt
        }
        status
        selfPay
      }
    }
  }
`;

export const GenerateInvoiceMutation = gql`
  mutation GenerateInvoiceMutation(
    $claims: [uuid]!
    $cptCodes: [uuid]!
    $invoiceAmount: numeric!
    $practiceId: uuid!
    $paymentDate: date
  ) {
    invoice: GenerateInvoice(
      claims: $claims
      cptCodes: $cptCodes
      invoiceAmount: $invoiceAmount
      practiceId: $practiceId
      paymentDate: $paymentDate
    ) {
      body
      code
    }
  }
`;

export const ClaimReviewQuery = gql`
  query ClaimReviewQuery(
    $limit: Int
    $offset: Int
    $practice: practices_bool_exp
    $reviewed: timestamptz_comparison_exp
    $searchPatient: practice_patients_bool_exp
    $searchInsurance: [billing_claims_bool_exp!]!
  ) {
    claims: billing_claims(
      limit: $limit
      offset: $offset
      order_by: {
        practicePatient: { patientData: { firstName: asc } }
        createdAt: asc
      }
      where: {
        status: { _in: [pending] }
        practice: $practice
        patientAndInsuranceInfoReviewedAt: $reviewed
        practicePatient: $searchPatient
        _or: $searchInsurance
      }
    ) {
      id
      practice {
        id
        handler
        name
      }
      practicePatient {
        id
        patientData {
          firstName
          lastName
          birthdate
        }
        insurances(where: { primary: { _eq: true } }) {
          id
          memberId
          company {
            id
            name
          }
          relationship
          holderFirstName
          holderLastName
          holderDob
          vfcEligible
        }
        notes
        claimsCount: claims_aggregate {
          aggregate {
            count
          }
        }
      }
      vaccinations {
        id
        givenAt
        inventory {
          vfc
        }
        vaccine {
          name
        }
      }
      patientAndInsuranceInfoReviewedAt
    }
    allPractices: practices(
      distinct_on: id
      where: { isBillable: { _eq: true } }
    ) {
      id
      name
    }
    aggregating: billing_claims_aggregate(
      where: {
        status: { _in: [pending] }
        patientAndInsuranceInfoReviewedAt: $reviewed
        practicePatient: $searchPatient
        _or: $searchInsurance
        practice: $practice
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const SetClaimPatientAndInsuranceReviewedAtMutation = gql`
  mutation SetClaimPatientAndInsuranceReviewedAtMutation(
    $claimId: uuid!
    $reviewedAt: timestamptz
  ) {
    update_billing_claims_by_pk(
      pk_columns: { id: $claimId }
      _set: { patientAndInsuranceInfoReviewedAt: $reviewedAt }
    ) {
      id
      patientAndInsuranceInfoReviewedAt
    }
  }
`;

export const ReviewedClaimMutation = gql`
  mutation ReviewedClaimMutation(
    $claimId: uuid!
    $reviewedAt: timestamptz
    $obj: billing_claim_updates_insert_input!
  ) {
    update_billing_claims_by_pk(
      pk_columns: { id: $claimId }
      _set: { reviewedAt: $reviewedAt }
    ) {
      id
      reviewedAt
    }
    insert_billing_claim_updates_one(object: $obj) {
      id
    }
  }
`;

export const ClaimRevisionStatusMutation = gql`
  mutation ClaimRevisionStatusMutation(
    $claimId: uuid!
    $revisionStatus: String!
    $obj: billing_claim_updates_insert_input!
  ) {
    update_billing_claims_by_pk(
      pk_columns: { id: $claimId }
      _set: { revisionStatus: $revisionStatus }
    ) {
      id
      revisionStatus
    }
    insert_billing_claim_updates_one(object: $obj) {
      id
    }
  }
`;

export const VaccineByInventoryCptCodeQuery = gql`
  query VaccineByInventoryCptCode($cptCode: jsonb!) {
    vaccines(where: { inventoryCptCodes: { _contains: $cptCode } }) {
      id
      name
      manufacturer
      inventoryCptCodes
    }
  }
`;

export const InsertVacciationForClaimMutation = gql`
  mutation InsertVacciationOneMutation(
    $givenAt: timestamptz!
    $vaccineId: uuid!
    $patientId: uuid!
    $practiceId: uuid!
  ) {
    insertVaccination: insert_vaccinations_one(
      object: {
        billed: true
        historic: true
        givenAt: $givenAt
        vaccineId: $vaccineId
        practicePatientId: $patientId
        practiceId: $practiceId
      }
    ) {
      id
    }
  }
`;

export const CptCodeTypeQuery = gql`
  query CptCodeTypeQuery($cptCode: String_comparison_exp!) {
    cptCodes: procedure_amounts(where: { cpt: $cptCode }, distinct_on: cpt) {
      cpt
      type
    }
  }
`;

export const InsertManualClaimMutation = gql`
  mutation InsertManualClaimMutation(
    $vaccinationId: uuid!
    $totalClaimAmount: numeric
    $status: billing_claim_statuses_enum
    $cptCodes: [billing_claim_cpt_codes_insert_input!]!
  ) {
    insert_billing_claims_one(
      object: {
        vaccinationId: $vaccinationId
        totalClaimAmount: $totalClaimAmount
        status: $status
        cptCodes: { data: $cptCodes }
      }
    ) {
      id
    }
  }
`;

export const BillingClaimsByPracticeQuery = gql`
  query BillingClaimsByPracticeQuery($criteria: billing_claims_vw_bool_exp) {
    claims: billing_claims_vw(where: $criteria) {
      claimId
      firstName
      lastName
      birthdate
      status
      insuranceName
    }
    aggregating: billing_claims_vw_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const UpdateReviewedAt = gql`
  mutation UpdateReviewedAt(
    $claimId: uuid!
    $inMediationReviewedAt: timestamptz!
  ) {
    claims: update_billing_claims_by_pk(
      _set: { inMediationReviewedAt: $inMediationReviewedAt }
      pk_columns: { id: $claimId }
    ) {
      id
      inMediationReviewedAt
    }
  }
`;

export const UpdateUploadedToEmr = gql`
  mutation UpdateUploadedToEmr($claimId: uuid!, $uploadedToEmrAt: timestamptz) {
    claims: update_billing_claims_by_pk(
      _set: { uploadedToEmrAt: $uploadedToEmrAt }
      pk_columns: { id: $claimId }
    ) {
      id
      uploadedToEmrAt
    }
  }
`;

export const UpdatePaymentReportedToEmr = gql`
  mutation UpdatePaymentReportedToEmr(
    $claimId: uuid!
    $paymentReportedToEmrAt: timestamptz
  ) {
    claims: update_billing_claims_by_pk(
      _set: { paymentReportedToEmrAt: $paymentReportedToEmrAt }
      pk_columns: { id: $claimId }
    ) {
      id
      paymentReportedToEmrAt
    }
  }
`;

export const SaveEmrClaimNumberMutation = gql`
  mutation SaveEmrClaimNumberMutation(
    $claimId: uuid!
    $emrClaimNumber: String!
  ) {
    claims: update_billing_claims_by_pk(
      _set: { emrClaimNumber: $emrClaimNumber }
      pk_columns: { id: $claimId }
    ) {
      id
      emrClaimNumber
    }
  }
`;

export const BillingInvoicesQuery = gql`
  query BillingInvoicesQuery(
    $criteria: billing_invoices_bool_exp
    $limit: Int
    $offset: Int
  ) {
    aggregating: billing_invoices_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
    invoices: billing_invoices(
      where: $criteria
      order_by: { date: desc }
      limit: $limit
      offset: $offset
    ) {
      id
      practice {
        id
        name
      }
      practicesData {
        name
      }
      date
      amount
      billMethod
      status
      message
      metadata
      paymentDate
      dueDate
      description
      claimCptCodes {
        cptCode
      }
      pdfData
    }
    allPractices: practices(
      order_by: { name: asc }
      where: { suspended: { _eq: false } }
    ) {
      id
      name
    }
  }
`;

export const BillingInvoicesByPracticeQuery = gql`
  query BillingInvoicesByPracticeQuery(
    $practiceId: uuid!
    $inventoryCpts: [String!]!
    $limit: Int
    $offset: Int
  ) {
    invoices: billing_invoices(
      where: { practiceId: { _eq: $practiceId } }
      limit: $limit
      offset: $offset
      order_by: { date: desc }
    ) {
      id
      invoiceNo
      date
      amount
      pdfData
      practicesData {
        name
      }
      cptCodesCount: claimCptCodes_aggregate(
        where: { cptCode: { _in: $inventoryCpts } }
      ) {
        aggregate {
          count
        }
      }
    }
    aggregating: billing_invoices_aggregate(
      where: { practiceId: { _eq: $practiceId } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const SaveInvoiceStatusMutation = gql`
  mutation SaveInvoiceStatusMutation($invoiceId: uuid!, $status: String!) {
    invoice: update_billing_invoices_by_pk(
      pk_columns: { id: $invoiceId }
      _set: { status: $status }
    ) {
      id
      status
    }
  }
`;

export const SaveInvoicePaymentDateMutation = gql`
  mutation SaveInvoicePaymentDateMutation(
    $invoiceId: uuid!
    $paymentDate: date
  ) {
    update_billing_invoices_by_pk(
      pk_columns: { id: $invoiceId }
      _set: { paymentDate: $paymentDate }
    ) {
      id
      paymentDate
    }
  }
`;

export const SaveInvoiceDescriptionMutation = gql`
  mutation SaveInvoiceDescriptionMutation(
    $invoiceId: uuid!
    $description: String
  ) {
    update_billing_invoices_by_pk(
      pk_columns: { id: $invoiceId }
      _set: { description: $description }
    ) {
      id
      description
    }
  }
`;

export const InvoiceStatusSubscription = gql`
  subscription InvoiceStatusSubscription($invoiceId: uuid!) {
    invoice: billing_invoices_by_pk(id: $invoiceId) {
      status
    }
  }
`;

export const GetLastInvoiceQuery = gql`
  query GetLastInvoiceQuery($practiceId: uuid!) {
    invoices: billing_invoices(
      where: { practice: { id: { _eq: $practiceId } } }
      order_by: { createdAt: desc }
      limit: 1
    ) {
      id
      date
      paymentDate
      description
    }
  }
`;

export const BillingUnclaimedCPT = gql`
  query BillingUnclaimedCPT($claimId: uuid!) {
    billingClaims: billing_claims(where: { id: { _eq: $claimId } }) {
      id
      vaccinationsCptCodes
    }
  }
`;

export const CptCodesByInvoiceQuery = gql`
  query cptCodesByInvoiceQuery($id: uuid!) {
    invoice: billing_invoices_by_pk(id: $id) {
      claimCptCodes {
        id
        cptCode
      }
    }
  }
`;

export const ClaimPreviewQuery = gql`
  query ClaimPreviewQuery($claimId: uuid!) {
    ClaimPreview(claimId: $claimId) {
      body {
        claimInformation {
          claimChargeAmount
          claimFilingCode
          serviceLines {
            placeOfService
            renderingProviderId
            professionalService {
              diagnosisPointer
              lineItemChargeAmount
              procedureCode
              procedureModifiers
              serviceUnitCount
            }
            serviceDate
          }
          signatureIndicator
          healthCareCodeInformation {
            diagnosisCode
            diagnosisTypeCode
          }
        }
        patient {
          address {
            address1
            city
            postalCode
            state
          }
          firstName
          dateOfBirth
          gender
          lastName
          memberId
          paymentResponsibilityLevelCode
          relationshipToInsured
          holder {
            dateOfBirth
            firstName
            lastName
          }
        }
        payer {
          name
          cpid
          id
        }
        provider {
          employerId
          npi
          organizationName
          firstName
          lastName
          taxonomyCode
          address {
            address1
            city
            postalCode
            state
          }
        }
        rendering {
          employerId
          npi
          organizationName
          firstName
          lastName
          taxonomyCode
          address {
            address1
            city
            postalCode
            state
          }
        }
      }
      extensions
      code
      message
    }
  }
`;

export const BillingDashboardInvoices = gql`
  query BillingDashboardInvoices($practiceIds: [uuid!]!, $from: timestamptz) {
    paid: billing_invoices(
      order_by: [{ date: asc }]
      where: {
        practiceId: { _in: $practiceIds }
        date: { _gte: $from }
        status: { _eq: "paid" }
      }
    ) {
      amount
      date
    }
    pending: billing_invoices(
      order_by: [{ date: asc }]
      where: {
        practiceId: { _in: $practiceIds }
        date: { _gte: $from }
        status: { _nin: ["paid", "discarded"] }
      }
    ) {
      amount
      date
    }
  }
`;

export const BillingDashboardVaccinationOverview = gql`
  query BillingDashboardVaccinationOverview(
    $practiceIds: [uuid!]!
    $year: float8!
    $month: float8!
  ) {
    billing_dashboard_vaccination_overview(
      where: {
        practiceId: { _in: $practiceIds }
        month: { _eq: $month }
        year: { _eq: $year }
      }
    ) {
      countVaccinationsTotal
      vaccineExpenses
      countVaccinationsPrivate
      countVaccinationsVfc
    }
  }
`;

export const BillingDashboardRecoveredFees = gql`
  query BillingDashboardRecoveredFees(
    $practiceIds: [uuid!]!
    $year: float8!
    $month: float8!
    $vfcEligible: [Boolean!]!
  ) {
    billing_dashboard_recovered_fees(
      where: {
        practiceId: { _in: $practiceIds }
        month: { _eq: $month }
        year: { _eq: $year }
        vfcEligible: { _in: $vfcEligible }
      }
    ) {
      paidAmount
      pendingFees
      type
      vfcEligible
    }
  }
`;

export const BillingDashboardClaimsStatuses = gql`
  query BillingDashboardClaimsStatuses(
    $practiceIds: [uuid!]!
    $year: float8!
    $month: float8!
    $vfcEligible: [Boolean!]!
  ) {
    billing_dashboard_claims_statuses(
      where: {
        practiceId: { _in: $practiceIds }
        month: { _eq: $month }
        year: { _eq: $year }
        vfcEligible: { _in: $vfcEligible }
      }
    ) {
      status
      paidAmount
      vfcEligible
    }
  }
`;

// TODO: add status in the future
export const getBillingInvoiceExtraItem = gql`
  query BillingInvoiceExtraItems($practiceIds: [uuid!]!) {
    billing_invoice_extra_items(
      where: {
        practiceId: { _in: $practiceIds }
        _or: [
          { invoiceId: { _is_null: true } }
          { invoice: { status: { _eq: "draft" } } }
        ]
      }
      order_by: { id: asc }
    ) {
      id
      inventoryId
      inventory {
        id
        vaccine {
          id
          name
        }
        lot
      }
      practiceId
      practice {
        id
        name
      }
      description
      quantity
      reason
      reviewedAt
      unitPrice
    }
  }
`;

export const insertBillingInvoiceExtraItem = gql`
  mutation InsertBillingInvoiceExtraItemsOne(
    $extraItem: billing_invoice_extra_items_insert_input = {}
  ) {
    insert_billing_invoice_extra_items_one(object: $extraItem) {
      id
    }
  }
`;

export const updateBillingInvoiceExtraItem = gql`
  mutation updateBillingInvoiceExtraItem(
    $id: uuid!
    $extraItem: billing_invoice_extra_items_set_input = {}
  ) {
    update_billing_invoice_extra_items_by_pk(
      pk_columns: { id: $id }
      _set: $extraItem
    ) {
      id
    }
  }
`;

export const deleteBillingInvoiceExtraItem = gql`
  mutation deleteBillingInvoiceExtraItemsByPk($id: uuid!) {
    delete_billing_invoice_extra_items_by_pk(id: $id) {
      id
    }
  }
`;

export const setBillingInvoiceExtraItemReviewAtMutation = gql`
  mutation SetExtraItemAsReviewed(
    $extraItemId: uuid!
    $reviewed: Boolean = false
  ) {
    SetExtraItemAsReviewed(extraItemId: $extraItemId, reviewed: $reviewed) {
      id
      reviewedAt
    }
  }
`;

export const BillingControlView = gql`
  query BillingControlView(
    $criteria: billing_claims_control_team_vw_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [billing_claims_control_team_vw_order_by!]
  ) {
    billingControlClaims: billing_claims_control_team_vw(
      where: $criteria
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      practiceId
      practiceName
      npi
      taxId
      practiceAddress
      claimId
      status
      eboStatus
      givenAt
      updatedAt
      rhcClaimNumber
      claimControlNumber
      totalClaimAmount
      checkNumber
      note
      tags
      errors
      practicePatientId
      patientFirstName
      patientLastName
      fullName
      patientBirthdate
      insuranceName
      insuranceCompanyId
      payerId
      vfcEligible
      vfcCriteria
      insuranceMemberId
      cptCodes
      providerFirstName
      providerLastName
      prescriberFirstName
      prescriberLastName
      prescriberId
    }
  }
`;

export const BillingReportingClaimsVw = gql`
  query BillingReportingClaimsVw(
    $limit: Int
    $offset: Int
    $order_by: [billing_claims_reporting_team_vw_order_by!]
    $criteria: billing_claims_reporting_team_vw_bool_exp
    $where: billing_claims_reporting_team_vw_bool_exp = {}
  ) {
    claims: billing_claims_reporting_team_vw(
      limit: $limit
      offset: $offset
      order_by: { givenAt: asc }
      where: $criteria
    ) {
      name
      firstName
      lastName
      birthdate
      givenAt
      totalClaimAmount
      claimId
    }
    aggregating: billing_claims_reporting_team_vw_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const BillingClaimsReportingCount = gql`
  query BillingClaimsReportingCount(
    $criteria: billing_claims_reporting_team_vw_bool_exp
  ) {
    aggregating: billing_claims_reporting_team_vw_aggregate(
      where: $criteria
      distinct_on: claimId
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const BillingPreviewPdf = gql`
  mutation BillingPreviewPdfMutation($data: UpsertInvoiceInput!) {
    UpsertInvoice(data: $data) {
      version
      billingReport {
        claimTables {
          key
          practice
          table {
            cptCodes
            dateOfService
            eobLink
            insurance {
              memberId
              name
            }
            key
            paidAmount
            patient {
              dateOfBirth
              name
            }
          }
          totalAmount
        }
        footer {
          pcAdminFeeClaimed
          timeRestored
          vcfAdminFeeClaimed
        }
      }
      invoice {
        header {
          amountDue
          clientLine1
          clientLine2
          date
        }
        body {
          claimTables {
            key
            practice
            table {
              amount
              cptCode
              key
              quantity
              vaccineName
            }
            totalAmount
          }
          discount {
            discountAmount
            discountPercentage
          }
          extraTable {
            table {
              description
              key
              practice
              quantity
              subtotal
              unitPrice
            }
            totalAmount
          }
          fee {
            feeAmount
          }
          totalClaims
          totalInvoice
        }
        footer {
          pcAdminFeeClaimed
          timeRestored
          vcfAdminFeeClaimed
        }
      }
    }
  }
`;

export const ConfirmInvoice = gql`
  mutation ConfirmInvoice(
    $organizationId: String = ""
    $emails: [String] = ""
    $time: String = ""
    $date: String = ""
    $emailBody: String = ""
    $dueDate: String = ""
  ) {
    ConfirmInvoice(
      data: {
        organizationId: $organizationId
        emails: $emails
        time: $time
        date: $date
        emailBody: $emailBody
        dueDate: $dueDate
      }
    ) {
      invoiceId
    }
  }
`;

export const DiscardInvoiceMutation = gql`
  mutation DiscardInvoiceMutation($organizationId: uuid!) {
    discardDraftInvoice(organizationId: $organizationId) {
      code
      message
    }
  }
`;

export const GetBorrowingByClaimId = gql`
  query GetBorrowingByClaimId($claimId: uuid!) {
    getBorrowingByClaimId(claimId: $claimId) {
      code
      body
      message
    }
  }
`;
