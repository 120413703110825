import {
  NotificationStatus,
  NotificationType,
} from '@bluefox/models/Notification';
import { ALL, allOption } from '@bluefox/lib/options';
import { Dispatch, SetStateAction } from 'react';
import {
  notificationTypeEnumToText,
  patientNotificationStatus_PP_ALL,
} from '@bluefox/constants/notifications';
import { Container, Form } from 'semantic-ui-react';
import { whereLikeInput } from '@bluefox/graphql/utils';
import NotificationStatusDropdown from '../NotificationStatusDropdown';
import { Source } from '../../Chat/types';

// Order type is not listed here
const patientTypeOptionsIT = [
  ...allOption,
  {
    key: NotificationType.athena,
    text: notificationTypeEnumToText[NotificationType.athena],
    value: NotificationType.athena,
  },
  {
    key: NotificationType.claim,
    text: notificationTypeEnumToText[NotificationType.claim],
    value: NotificationType.claim,
  },
  {
    key: NotificationType.develo,
    text: notificationTypeEnumToText[NotificationType.develo],
    value: NotificationType.develo,
  },
  {
    key: NotificationType.ecw,
    text: notificationTypeEnumToText[NotificationType.ecw],
    value: NotificationType.ecw,
  },
  {
    key: NotificationType.vax_sync,
    text: notificationTypeEnumToText[NotificationType.vax_sync],
    value: NotificationType.vax_sync,
  },
];

export type SearchValuesProps = {
  patientNameOrMRN?: string;
  dateOfService?: string;
  orderTableStatus?: NotificationStatus | typeof ALL;
  patienTableStatus?: NotificationStatus | typeof ALL;
};

export const initialPatientQueryIT: SearchValuesProps = {
  orderTableStatus: NotificationStatus.pending_approval,
  patienTableStatus: NotificationStatus.pending_approval,
};

export const initialPatientQueryPP: SearchValuesProps = {
  orderTableStatus: NotificationStatus.pending,
  patienTableStatus: NotificationStatus.pending,
};

function getNotificationStatusInQuery(
  source: 'IT' | 'PP',
  notificationStatus: NotificationStatus
) {
  if (source === 'PP' && notificationStatus === NotificationStatus.resolved) {
    if (
      notificationStatus === NotificationStatus.resolved ||
      notificationStatus === NotificationStatus.finalized
    )
      return [NotificationStatus.resolved, NotificationStatus.finalized];
  }
  return notificationStatus;
}

export function buildPatientQuery(
  searchValues: SearchValuesProps,
  source: Source
) {
  const ret = {
    ...(source === Source.PP
      ? { status: { _in: patientNotificationStatus_PP_ALL } }
      : {}),
    ...(searchValues.patientNameOrMRN
      ? {
          practicePatient: {
            _or: [
              {
                patientData: {
                  fullName: {
                    _ilike: whereLikeInput(searchValues.patientNameOrMRN),
                  },
                },
              },
              {
                mrn: { _ilike: whereLikeInput(searchValues.patientNameOrMRN) },
              },
            ],
          },
        }
      : {}),
    ...(searchValues.dateOfService
      ? {
          givenAt: { _eq: searchValues.dateOfService },
        }
      : {}),
    ...(searchValues.patienTableStatus && searchValues.patienTableStatus !== ALL
      ? {
          status: {
            _in: getNotificationStatusInQuery(
              source,
              searchValues.patienTableStatus
            ),
          },
        }
      : {}),
  };
  return ret;
}

type Props = {
  internalTools?: boolean;
  excelButtonLoading?: boolean;
  searchValues: SearchValuesProps;
  setSearchValues: Dispatch<SetStateAction<SearchValuesProps>>;
  onExportExcel?: () => void;
};

const PatientNotificationListFilter = ({
  internalTools,
  excelButtonLoading,
  searchValues,
  setSearchValues,
  onExportExcel,
}: Props) => {
  return (
    <Container>
      <Form onSubmit={(e) => e.preventDefault()}>
        <Form.Group widths="equal">
          <Form.Input
            label="Patient or MRN"
            type="text"
            placeholder="Search patient or MRN..."
            icon="search"
            value={searchValues?.patientNameOrMRN}
            onChange={(_, { value }) => {
              setSearchValues((prevValue) => ({
                ...prevValue,
                patientNameOrMRN: value,
              }));
            }}
          />
          <Form.Input
            label="Date of Service"
            type="date"
            value={searchValues.dateOfService}
            onChange={(_, { value }) =>
              setSearchValues((prevVal) => ({
                ...prevVal,
                dateOfService: value,
              }))
            }
          />
          <Form.Field>
            <label
              htmlFor="notification_status_dropdown_filter"
              style={{ marginRight: '1rem' }}
            >
              Status:
            </label>
            <NotificationStatusDropdown
              internalTools={internalTools}
              value={searchValues.patienTableStatus}
              onChange={(value) => {
                setSearchValues((prevValue) => ({
                  ...prevValue,
                  patienTableStatus: value as NotificationStatus | typeof ALL,
                }));
              }}
            />
          </Form.Field>
          {internalTools && (
            <Form.Field
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                marginBottom: '0.1rem',
              }}
            >
              <Form.Button
                loading={excelButtonLoading}
                inverted
                size="medium"
                color="blue"
                onClick={onExportExcel}
              >
                Export EXCEL
              </Form.Button>
            </Form.Field>
          )}
        </Form.Group>
      </Form>
    </Container>
  );
};

export default PatientNotificationListFilter;
