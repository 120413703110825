import IdleTimer from '@bluefox/lib/IdleTimer';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDarkreader } from 'react-darkreader';

import { useLSHandler } from './LocalStorage';
import { UserTitles } from '@bluefox/models/Users';

const TOKEN_KEY = 'token';
const UI_SETTINGS_KEY = 'ui-settings';

interface UISettings {
  isDarkModeOn: boolean;
  isDarkModeAuto: boolean;
}

interface Message {
  success?: boolean;
  warning?: boolean;
  error?: boolean;
  content: string;
}

export interface Account {
  id: string;
  name: string;
  npi: string;
  position: string;
  profile?: any;
  role?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  title?: UserTitles;
}

interface Practice {
  id: string;
  handler: string;
  state?: string;
  timezone?: string;
}

export interface Session {
  account?: Account;
  practice?: Practice;
}

interface ApllicationState {
  token: string | null;
  setToken: (token: string | null) => void;
  session?: Session;
  practice?: Practice;
  messages: Message[];
  addMessage: (message: Message) => void;
  setPractice: (practice: Practice) => void;
  isIdle: boolean;
  isEmbedded: boolean;
  isDarkModeOn: boolean;
  setIsDarkModeOn: (dm: boolean) => void;
  isDarkModeAuto: boolean;
  setIsDarkModeAuto: (auto: boolean) => void;
}

interface ApplicationStateProviderProps {
  children: React.ReactNode;
  onIdleChange?: (isIdle: boolean) => void;
  idleTimeout?: number;
  isEmbedded: boolean;
}

const systemDarkModeSelector = '(prefers-color-scheme: dark)';

const applicationState = React.createContext<ApllicationState>({
  token: '',
  setToken: () => {},
  session: {},
  messages: [],
  addMessage: () => {},
  setPractice: () => {},
  isIdle: false,
  isEmbedded: false,
  isDarkModeOn: false,
  setIsDarkModeOn: () => {},
  isDarkModeAuto: false,
  setIsDarkModeAuto: () => {},
});

export const useApplicationState = () => {
  return useContext(applicationState);
};

export const useSession = (): Session => {
  const { session } = useApplicationState();
  return session!;
};

export const ApplicationStateProvider = ({
  children,
  onIdleChange,
  idleTimeout = 60,
  isEmbedded = false,
}: ApplicationStateProviderProps) => {
  const lsHandler = useLSHandler();

  const [token, setTokenState] = useState<string | null>(
    lsHandler.getItem(TOKEN_KEY)
  );

  const [uiSettings, setUiSettings] = useState<UISettings>(
    JSON.parse(
      lsHandler.getItem(
        UI_SETTINGS_KEY,
        `{ "isDarkModeOn": false, "isDarkModeAuto": false }`
      )
    )
  );

  const [session, setSession] = useState<Session>();

  const [practice, setPractice] = useState<Practice>();

  const [messages, setMessages] = useState<Message[]>([]);

  const [isIdle, setIsIdle] = useState(false);

  const [isSystemDarkModeOn, setIsSystemDarkModeOn] = useState(
    window.matchMedia && window.matchMedia(systemDarkModeSelector).matches
  );

  const [isDarkModeAuto, setIsDarkModeAuto] = useState(
    uiSettings.isDarkModeAuto
  );
  const [isDarkModeOn, setIsDarkModeOn] = useState(uiSettings.isDarkModeOn);

  const [isDark, { toggle }] = useDarkreader(isDarkModeOn);

  const systemDarkModeListener = useCallback(
    (event: MediaQueryListEvent) => {
      setIsSystemDarkModeOn(event.matches);
    },
    [setIsSystemDarkModeOn]
  );

  useEffect(() => {
    window
      .matchMedia(systemDarkModeSelector)
      .addEventListener('change', systemDarkModeListener);

    return () => {
      window
        .matchMedia(systemDarkModeSelector)
        .removeEventListener('change', systemDarkModeListener);
    };
  }, [setIsSystemDarkModeOn, systemDarkModeListener]);

  useEffect(() => {
    if (isDarkModeAuto) setIsDarkModeOn(isSystemDarkModeOn);
  }, [isSystemDarkModeOn, isDarkModeAuto]);

  useEffect(() => {
    const newUiSettings = {
      isDarkModeOn,
      isDarkModeAuto,
    };
    lsHandler.setItem(UI_SETTINGS_KEY, JSON.stringify(newUiSettings));
    setUiSettings(newUiSettings);
    if (isDarkModeOn !== isDark) toggle();
  }, [isDarkModeOn, isDarkModeAuto, isDark, toggle]);

  const handleOnIdle = useCallback(() => {
    setIsIdle(true);
    if (onIdleChange) onIdleChange(true);
  }, [setIsIdle]);

  const handleOnReset = useCallback(() => {
    setIsIdle(false);
    if (onIdleChange) onIdleChange(false);
  }, [setIsIdle]);

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: idleTimeout, // idle after 60 seconds
      onIdle: handleOnIdle,
      onReset: handleOnReset,
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  const addMessage = useCallback(
    (message: Message) => {
      setMessages([...messages, message]);
    },
    [messages]
  );

  const setToken = useCallback(
    (t: string | null) => {
      lsHandler.setItem(TOKEN_KEY, t || '');
      setTokenState(t);
    },
    [lsHandler]
  );

  useEffect(() => {
    if (token) {
      const { account, practice } = jwtDecode<Session & JwtPayload>(token);

      setSession({
        account,
        practice,
      });
    } else {
      setSession({});
    }
  }, [token, setToken]);

  const checkToken = useCallback(() => {
    if (token) {
      const { exp } = jwtDecode<Session & JwtPayload>(token);
      if (exp! < (new Date().getTime() + 1) / 1000) {
        setMessages([
          {
            content:
              'You are currently not logged in. Please log in to access this information.',
            warning: true,
          },
        ]);
        lsHandler.setItem('logged-accounts', '');
        setToken(null);
        console.error('JWT Expired');
      }
    }
  }, [token, setToken]);

  useEffect(() => {
    checkToken();
    const ih = setInterval(checkToken, 60000);
    return () => clearInterval(ih);
  }, [checkToken]);

  return (
    <applicationState.Provider
      value={{
        token,
        setToken: (t: string | null) => setToken(t),
        session,
        practice,
        setPractice,
        messages,
        addMessage,
        isIdle,
        isEmbedded,
        isDarkModeOn,
        setIsDarkModeOn,
        isDarkModeAuto,
        setIsDarkModeAuto,
      }}
    >
      {children}
    </applicationState.Provider>
  );
};
