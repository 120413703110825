import { Button, Form, Message, Modal } from 'semantic-ui-react';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import InsuranceCompanyPicker from '@bluefox/ui/InsuranceCompanyPicker';
import {
  eligibilityOptions,
  InsuranceCompanyPlanInput,
} from '@bluefox/models/insuranceCompanyPlan';
import {
  CreateInsuranceCompanyPlan,
  GetInsurancesCompaniesPlans,
} from '@graphql/insuranceCompaniesPlans';
import { toast } from 'react-semantic-toasts';

type InsurancesCompaniesPlanFormProps = {
  plan?: InsuranceCompanyPlanInput;
  setSelectedPlan: Dispatch<
    SetStateAction<InsuranceCompanyPlanInput | undefined>
  >;
};

const InsurancesCompaniesPlanForm = ({
  plan,
  setSelectedPlan,
}: InsurancesCompaniesPlanFormProps) => {
  const [insuranceCompanyPlan, setInsuranceCompanyPlan] = useState<
    InsuranceCompanyPlanInput | undefined
  >();

  const [addInsurancePlanOpened, setAddInsurancePlanOpened] = useState(false);
  const [duplicatedNameErrorModalOpened, setDuplicatedNameErrorModalOpened] =
    useState(false);
  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);

  const [saveInsurancePlan] = useMutation(CreateInsuranceCompanyPlan);

  const disableSaveButton = () =>
    !insuranceCompanyPlan?.insuranceCompanyId || !insuranceCompanyPlan.name;

  const onModalClose = () => {
    setSelectedPlan(undefined);
    setInsuranceCompanyPlan(undefined);
    setAddInsurancePlanOpened(false);
    setConfirmationModalOpened(false);
  };

  const onClickConfirmButton = async () => {
    await saveInsurancePlan({
      refetchQueries: [GetInsurancesCompaniesPlans],
      variables: {
        insuranceCompanyPlan,
      },
      onCompleted: () => {
        toast({
          title: 'Insurance Plan created successfully',
          type: 'success',
          time: 2000,
        });
        onModalClose();
      },

      onError: (error) => {
        setConfirmationModalOpened(false);
        if (error.message.includes('insurance_plans_name_insensitive_unique')) {
          setDuplicatedNameErrorModalOpened(true);
          return;
        }
        toast({
          title: 'Something went wrong',
          type: 'error',
          time: 2000,
        });
      },
    });
  };

  useEffect(() => {
    if (plan !== undefined) {
      setInsuranceCompanyPlan(plan);
      setAddInsurancePlanOpened(true);
    }
  }, [plan]);

  return (
    <>
      <Modal
        closeIcon
        trigger={<Button color="green" size={'small'} icon="add" />}
        onOpen={() => setAddInsurancePlanOpened(true)}
        onClose={onModalClose}
        open={addInsurancePlanOpened}
      >
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field width={'9'}>
                <label>Select Insurance:</label>
                <InsuranceCompanyPicker
                  placeholder="Insurance Name:"
                  value={insuranceCompanyPlan?.insuranceCompanyId}
                  onCompanySelected={(company) => {
                    setInsuranceCompanyPlan({
                      ...(insuranceCompanyPlan as InsuranceCompanyPlanInput),
                      insuranceCompanyId: company.id,
                    });
                  }}
                />
              </Form.Field>

              <Form.Input
                width={'9'}
                label="Plan Name:"
                value={insuranceCompanyPlan?.name}
                onChange={(_, { value }) => {
                  setInsuranceCompanyPlan({
                    ...(insuranceCompanyPlan as InsuranceCompanyPlanInput),
                    name: value,
                  });
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Select
                label="Eligibility:"
                options={eligibilityOptions}
                value={insuranceCompanyPlan?.isVfc}
                onChange={(event, { value }) => {
                  setInsuranceCompanyPlan({
                    ...(insuranceCompanyPlan as InsuranceCompanyPlanInput),
                    isVfc: value as boolean,
                  });
                }}
              />
            </Form.Group>
            <Form.Group widths={'equal'}>
              <Form.TextArea
                label="Note"
                value={insuranceCompanyPlan?.notes}
                onChange={(event, { value }) => {
                  setInsuranceCompanyPlan({
                    ...(insuranceCompanyPlan as InsuranceCompanyPlanInput),
                    notes: value as string,
                  });
                }}
              />
            </Form.Group>
          </Form>

          <Modal.Actions>
            <Button
              style={{
                float: 'right',
                marginBottom: '1rem',
              }}
              content="Save"
              primary
              icon="save"
              disabled={disableSaveButton()}
              onClick={() => {
                setConfirmationModalOpened(true);
              }}
            />
            <Button
              type="button"
              content="Cancel"
              icon="cancel"
              style={{ float: 'right' }}
              onClick={onModalClose}
            />
          </Modal.Actions>
        </Modal.Content>
      </Modal>

      <Modal open={confirmationModalOpened}>
        <Modal.Content>
          You are about to {plan ? 'edit the selected ' : 'create a new '}
          Plan. Do you want to continue?
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              float: 'right',
              marginBottom: '1rem',
            }}
            content="Yes"
            primary
            onClick={onClickConfirmButton}
            icon="save"
          />
          <Button
            type="button"
            content="No"
            icon="cancel"
            style={{ float: 'right' }}
            onClick={() => setConfirmationModalOpened(false)}
          />
        </Modal.Actions>
      </Modal>

      <Modal open={duplicatedNameErrorModalOpened}>
        <Modal.Content>
          <Message
            warning
            header="Error!"
            content="A plan with the same name already exists under this insurance company. Try a different name or edit the existing plan."
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              float: 'right',
              marginBottom: '1rem',
            }}
            content="OK"
            primary
            onClick={() => setDuplicatedNameErrorModalOpened(false)}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default InsurancesCompaniesPlanForm;
