import { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';

type Props = {
  onSubmit?: (message: string) => void;
  isShownInExtensionWindow?: boolean;
};

const SendChatMessage = ({ onSubmit, isShownInExtensionWindow }: Props) => {
  const [text, setText] = useState('');

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit?.(text?.trim());
      setText('');
    }
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit?.(text?.trim());
        setText('');
      }}
    >
      <Form.Group inline>
        <Form.TextArea
          required
          onChange={(_, { value }) => setText(value as string)}
          onKeyDown={handleKeyDown}
          value={text}
          width={14}
          style={isShownInExtensionWindow ? { height: '3rem' } : {}}
        />
        <Form.Button type="submit" disabled={!isStringNotEmpty(text?.trim())}>
          Send
        </Form.Button>
      </Form.Group>
    </Form>
  );
};

export default SendChatMessage;
