import React, { useState } from 'react';
import { Button, Message, Modal, Popup, Table } from 'semantic-ui-react';
import TableHeaderRow from './TableHeaderRow';
import TableBodyRow from './TableBodyRow';
import { Ticket, TicketStatusEnum } from '@bluefox/models/Tickets';
import { Source } from '../Chat/types';

type ResolutionModalProps = {
  ticket: Ticket;
  ticketType: string;
  source: Source;
  status: TicketStatusEnum;
};

const ResolutionModal = ({
  ticket,
  ticketType,
  source,
  status,
}: ResolutionModalProps) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      closeIcon
      open={open}
      trigger={
        <Popup
          content="Show Resolution"
          trigger={
            <Button
              size="small"
              icon="zip"
              color="grey"
              onClick={() => setOpen(true)}
            />
          }
        />
      }
      onClose={() => setOpen(false)}
    >
      <Modal.Content>
        <Table>
          <Table.Header>
            <TableHeaderRow ticketType={ticketType} includeChat={false} />
          </Table.Header>
          <Table.Body>
            <TableBodyRow
              key={ticket.id}
              ticketType={ticketType}
              ticket={ticket}
              source={source}
              status={status}
              includeChat={false}
            />
          </Table.Body>
        </Table>
        <Message>Resolution: {ticket.detail.closeTicketResolution}</Message>
      </Modal.Content>
    </Modal>
  );
};

export default ResolutionModal;
