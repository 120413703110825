import React from 'react';
import { TicketTypes } from '@bluefox/models/Tickets';
import { Table } from 'semantic-ui-react';

type TableHeaderRowProps = {
  ticketType: string;
  includeChat: boolean;
  isEmbedded: boolean;
};

const TableHeaderRow = ({
  ticketType,
  includeChat,
  isEmbedded,
}: TableHeaderRowProps) => {
  switch (ticketType) {
    case TicketTypes.BUG_REPORT:
      return (
        <Table.Row>
          {!isEmbedded && <Table.HeaderCell>Created At</Table.HeaderCell>}
          <Table.HeaderCell>Description</Table.HeaderCell>
          {!isEmbedded && <Table.HeaderCell>Reported By</Table.HeaderCell>}
          {includeChat && <Table.HeaderCell>Action</Table.HeaderCell>}
        </Table.Row>
      );
    case TicketTypes.FEATURE_REQUEST:
      return (
        <Table.Row>
          {!isEmbedded && <Table.HeaderCell>Created At</Table.HeaderCell>}
          <Table.HeaderCell>Description of the Problem</Table.HeaderCell>
          <Table.HeaderCell>
            Description of the Proposed Solution
          </Table.HeaderCell>
          {!isEmbedded && <Table.HeaderCell>Requested By</Table.HeaderCell>}
          {includeChat && <Table.HeaderCell>Action</Table.HeaderCell>}
        </Table.Row>
      );
    case TicketTypes.WASTED_DOSES_REPORT:
      return (
        <Table.Row>
          {!isEmbedded && <Table.HeaderCell>Created At</Table.HeaderCell>}
          <Table.HeaderCell>Vaccine Type</Table.HeaderCell>
          <Table.HeaderCell>Lot Number</Table.HeaderCell>
          <Table.HeaderCell>Inventory Type</Table.HeaderCell>
          <Table.HeaderCell>Date of Wastage</Table.HeaderCell>
          <Table.HeaderCell>Quantity of Wasted Doses</Table.HeaderCell>
          {!isEmbedded && <Table.HeaderCell>Reported By</Table.HeaderCell>}
          {includeChat && <Table.HeaderCell>Action</Table.HeaderCell>}
        </Table.Row>
      );
    case TicketTypes.INVENTORY_RECEIPT_ISSUES:
      return (
        <Table.Row>
          {!isEmbedded && <Table.HeaderCell>Created At</Table.HeaderCell>}
          <Table.HeaderCell>Vaccine</Table.HeaderCell>
          <Table.HeaderCell>Lot Number</Table.HeaderCell>
          <Table.HeaderCell>Issue</Table.HeaderCell>
          <Table.HeaderCell>Issue Date</Table.HeaderCell>
          <Table.HeaderCell>N° of Expected Doses</Table.HeaderCell>
          <Table.HeaderCell>N° of Broken Doses</Table.HeaderCell>
          {!isEmbedded && <Table.HeaderCell>Reported By</Table.HeaderCell>}
          {includeChat && <Table.HeaderCell>Action</Table.HeaderCell>}
        </Table.Row>
      );
    case TicketTypes.CALL_REQUEST:
      return (
        <Table.Row>
          {!isEmbedded && <Table.HeaderCell>Created At</Table.HeaderCell>}
          <Table.HeaderCell>Category</Table.HeaderCell>
          <Table.HeaderCell>Reason</Table.HeaderCell>
          <Table.HeaderCell>Call Date</Table.HeaderCell>
          <Table.HeaderCell>Requested By</Table.HeaderCell>
          <Table.HeaderCell>Who Will Be Called</Table.HeaderCell>
          {!isEmbedded && <Table.HeaderCell>Ticket Creator</Table.HeaderCell>}
          {includeChat && <Table.HeaderCell>Action</Table.HeaderCell>}
        </Table.Row>
      );
    default:
      return null;
  }
};

export default TableHeaderRow;
