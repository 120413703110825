import { notificationStatusEnumToText } from '@bluefox/constants/notifications';
import { formatDateToYYYYhMMhDD } from '@bluefox/lib/formatters';
import { Notification } from '@bluefox/models/Notification';
import VaxSyncIntegrationNotificationMessage from '@bluefox/ui/Notifications/NotificationMessage/VaxSyncIntegrationNotificationMessage';

type Props = {
  notification: Notification;
  practiceTimezone?: string;
};

const VaxSyncIntegrationModalMessage = ({
  notification,
  practiceTimezone,
}: Props) => {
  return (
    <>
      <p>
        Notification sent on:{' '}
        <b>
          {formatDateToYYYYhMMhDD(new Date(notification.createdAt as string))}
        </b>
        . Message:
      </p>
      <VaxSyncIntegrationNotificationMessage
        notification={notification}
        practiceTimezone={
          practiceTimezone ? practiceTimezone : notification?.practice?.timezone
        }
      />
      <p>
        Status: <b>{notificationStatusEnumToText[notification.status]}</b>
      </p>
    </>
  );
};

export default VaxSyncIntegrationModalMessage;
