import { gql } from '@apollo/client';

export const CreateInsuranceCompanyPlan = gql`
  mutation CreateInsuranceCompanyPlan(
    $insuranceCompanyPlan: insurance_companies_plans_insert_input!
  ) {
    insert_insurance_companies_plans_one(
      object: $insuranceCompanyPlan
      on_conflict: {
        constraint: insurance_companies_plans_pkey
        update_columns: [isVfc, name, notes, insuranceCompanyId]
      }
    ) {
      insuranceCompanyId
    }
  }
`;

export const GetInsurancesCompaniesPlans = gql`
  query GetInsurancesCompaniesPlans(
    $where: insurance_companies_plans_bool_exp = {}
    $order_by: [insurance_companies_plans_order_by!] = {}
    $limit: Int = 100
    $offset: Int = 0
  ) {
    insurancesPlans: insurance_companies_plans(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      id
      insuranceCompanyId
      insuranceCompany {
        cpid
        id
        name
        payerId
      }
      isVfc
      name
      notes
    }
    totalInsurancesPlans: insurance_companies_plans_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
