import React, { useState, useMemo, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  Button,
  Dropdown,
  Label,
  Menu,
  Message,
  Popup,
  Table,
} from 'semantic-ui-react';
import { TicketQuery, UpdateTicketStatus } from '@bluefox/graphql/tickets';
import { TicketQueryResponse, TicketStatusEnum } from '@bluefox/models/Tickets';
import Pagination from '@bluefox/ui/Pagination';
import RowsPerPage from '@ui/Filters/RowsPerPage';
import PracticeFilter from '@ui/Filters/PracticeFilter';

export const vaccineOrdersTicketStatusOptions = [
  {
    key: TicketStatusEnum.REQUESTED,
    text: 'Requested',
    value: TicketStatusEnum.REQUESTED,
  },
  {
    key: TicketStatusEnum.CLOSED,
    text: 'Closed',
    value: TicketStatusEnum.CLOSED,
  },
];

interface UpdateTicketStatusType {
  ticketId: string;
  optionStatus: string;
}

const VaccineOrdersFromPracticePortal = () => {
  const [searchPractice, setSearchPractice] = useState<string>('all');
  const [rowsPerPage, setRowsPerPage] = useState<number>(0);
  const [page, setPage] = useState(1);

  const { data, refetch } = useQuery<TicketQueryResponse>(TicketQuery, {
    variables: {
      practiceFilter:
        searchPractice !== 'all'
          ? { _contains: { fields: [{ detail: searchPractice }] } }
          : {},
      type: 'Vaccine_order',
      status: 'requested',
      limit: rowsPerPage,
      offset: !!page ? rowsPerPage * (page - 1) : 0,
    },
    skip: rowsPerPage === 0,
    fetchPolicy: 'network-only',
  });

  const [updateTicketStatus] = useMutation(UpdateTicketStatus);

  const handleUpdateTicketStatus = async ({
    ticketId,
    optionStatus,
  }: UpdateTicketStatusType) => {
    try {
      await updateTicketStatus({
        variables: {
          id: ticketId,
          status: optionStatus,
        },
      });

      toast({
        title: 'Status changed successfully',
        type: 'success',
        time: 2000,
      });
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    } finally {
      refetch();
    }
  };

  const total = useMemo(
    () => data?.aggregating.aggregate.count || 0,
    [data?.aggregating.aggregate.count]
  );

  const totalPages = useMemo(
    () => Math.ceil(total / rowsPerPage),
    [rowsPerPage, total]
  );

  const formattedData = useMemo(
    () =>
      data?.tickets.map((ticket) => {
        const formattedObj = ticket.detail.fields.reduce(
          (acc: any, { title, detail }) => {
            acc[title.toLocaleLowerCase()] = detail;
            return acc;
          },
          {}
        );

        return {
          ...formattedObj,
          id: ticket.id,
          status: ticket.status,
        };
      }),
    [data?.tickets]
  );

  useEffect(() => {
    const rows = Number(
      localStorage.getItem('HomeVaccineOrdersFromPracticePortalRowConfig')
    );
    setRowsPerPage(rows >= 0 ? rows : 15);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [searchPractice, rowsPerPage]);

  return (
    <>
      <Menu borderless style={{ display: 'flex', flexDirection: 'column' }}>
        <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Menu.Item>
            <RowsPerPage
              rows={rowsPerPage}
              setRows={setRowsPerPage}
              localStorageItem="HomeVaccineOrdersFromPracticePortalRowConfig"
            />
          </Menu.Item>
          {rowsPerPage > 0 && (
            <>
              <Menu.Item>
                <PracticeFilter
                  practiceSearch={searchPractice}
                  setPracticeSearch={setSearchPractice}
                  suspended="notSuspended"
                  filterByHandler
                />
              </Menu.Item>
            </>
          )}
        </Menu.Menu>
      </Menu>
      {rowsPerPage > 0 && (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Practice</Table.HeaderCell>
              <Table.HeaderCell>Vaccine/Supply</Table.HeaderCell>
              <Table.HeaderCell>NDC</Table.HeaderCell>
              <Table.HeaderCell>Requested Doses/Quantity</Table.HeaderCell>
              <Table.HeaderCell width={2}>Status</Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {formattedData ? (
              formattedData?.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>{item.practice}</Table.Cell>
                    <Table.Cell>{item.vaccine || item.supply}</Table.Cell>
                    <Table.Cell>{item.ndc || '-'}</Table.Cell>
                    <Table.Cell>
                      <Label basic content={item.doses || item.quantity} />
                    </Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        onChange={(_, { value }) => {
                          if (!value) return;
                          handleUpdateTicketStatus({
                            ticketId: item.id,
                            optionStatus: value.toString(),
                          });
                        }}
                        selection
                        value={item.status}
                        options={vaccineOrdersTicketStatusOptions}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {item.notes && (
                        <Popup
                          on="click"
                          position="top right"
                          trigger={<Button size="small" content="Notes" />}
                          content={item.notes}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan={5}>
                  <Message>No vaccine request orders found</Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>

          <Pagination
            total={total}
            colSpan={11}
            position="right"
            activePage={page}
            totalPages={totalPages}
            onPageChange={(newActivePage) => setPage(newActivePage)}
          />
        </Table>
      )}
    </>
  );
};

export default VaccineOrdersFromPracticePortal;
