import React from 'react';
import { Inventory } from '@bluefox/models/models';
import { OutputDetail, OutputTicket, Ticket } from '@bluefox/models/Tickets';
import { ReactNode } from 'react';
import { Header, Label } from 'semantic-ui-react';
import {
  formatDatetimeToMMDDYYYYV2,
  formatDateToMMDDYYYYV2,
} from '@bluefox/lib/formatters';
import {
  PatientData,
  VFCInconsistency,
  VfcInconsistencyInsurance,
} from '@bluefox/models/VFCInconsistency';

export const borrowingStrings = {
  STATUSLOG: {
    FROM: 'Fix Pending Borrowing Tool', // inventory_adjustment table
  },
  UPDATECOLUMNS: {
    DOSES: 'doses', // inventory table
    SOURCEADJUSTMENTDETAIL: 'sourceAdjustmentDetailId', // claim_vfc_inconsistencies table
    TARGETADJUSTMENTDETAIL: 'targetAdjustmentDetailId', // claim_vfc_inconsistencies table
    STATUS: 'status', // claim_vfc_inconsistencies table
  },
  CONSTRAINT: {
    INVENTORIESPRIMARYKEY: 'inventories_pkey', // inventory table
    CLAIMVFCINCONSISTENCIESIDKEY: 'claim_vfc_inconsistencies_id_key', // claim_vfc_inconsistencies table
  },
  ISSUE: 'Issue', // ticket detail field | tickets table
  ISSUE_TYPE: 'issueType', // ticket detail field | tickets table
  ISSUE_DESCRIPTION: 'issueDescription', // ticket detail field | tickets table
};

export interface Inconsistency {
  createdAt: string;
  id: string;
  practiceId: string;
  practice: {
    name: string;
  };
  claimId: string;
  inventoryId: string;
  vaccinationId: string;
  inventory: {
    doses: number;
    expiration: Date;
    id: string;
    vfc: boolean;
    lot: string;
    vaccine: {
      id: string;
      name: string;
      aka: string;
      types: string[];
      saleNdc: string;
    };
  };
  updatedAt: string;
  type: string;
  status: string;
  claimUpdatesId: string;
  claim: {
    id: string;
    insurance: VfcInconsistencyInsurance[];
    givenAt: Date;
    insuranceCompanyName: string;
    practicePatient: {
      patientData: {
        birthdate: Date;
        firstName: string;
        lastName: string;
      };
    };
    updates: {
      claimId: string;
      id: string;
      status: string;
      insuranceName: string;
      insuranceCompanyId: string;
      memberId: string;
      vfcEligible: boolean;
      vfcCriteria: string;
      payerId: string;
    }[];
  };
}

export interface InconsistencyWithClaimIdsAndVaccineToReturn
  extends Inconsistency {
  vfcInconsistentClaims: Inconsistency[];
  vaccineToReturn: AlternativeToReturn[];
  rejected: number;
}

export interface AlternativeToReturn {
  index: number;
  inventoryId: string;
  quantity: number;
}
export interface Inconsistencies {
  vfcInconsistencies: Inconsistency[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

export interface Result {
  text?: string | undefined;
  content?: ReactNode;
  value: string | undefined;
}

export interface Change {
  change: number;
  type: string;
}

// This function groups all the same vaccine inconsistencies into only one object row
export const transformData = (
  data: Inconsistency[]
): InconsistencyWithClaimIdsAndVaccineToReturn[] => {
  const inconsistenciesByKey = data.reduce(
    (acc, item) => {
      const key = `${item?.inventory?.vaccine?.id}-${
        item?.inventory?.vfc ? 'vfc' : 'private'
      }`;
      const inconsistentClaims = acc[key]
        ? [...acc[key].vfcInconsistentClaims, item]
        : [item];
      const inconsistency: InconsistencyWithClaimIdsAndVaccineToReturn = {
        ...item,
        vaccineToReturn: [
          {
            index: 0,
            inventoryId: '',
            quantity: 0,
          },
        ],
        rejected: 0,
        vfcInconsistentClaims: inconsistentClaims,
      };

      return {
        ...acc,
        [key]: inconsistency,
      };
    },
    {} as { [key: string]: InconsistencyWithClaimIdsAndVaccineToReturn }
  );

  const result = Object.values(inconsistenciesByKey);
  result.sort((i1, i2) => {
    const name1 = `${i1.inventory.vfc ? 'A' : 'B'}${i1.inventory.vaccine.name}`;
    const name2 = `${i2.inventory.vfc ? 'A' : 'B'}${i2.inventory.vaccine.name}`;
    return name1.localeCompare(name2);
  });
  return result;
};

export function arraysContainSameItems(
  array1: string[] = [],
  array2: string[] = []
) {
  return (
    array1.length === array2.length &&
    array1.every((a) => array2.includes(a)) &&
    array2.every((a) => array1.includes(a))
  );
}

export function updateInventoryToReturn(
  array: AlternativeToReturn[],
  index: number,
  newInventoryId: string
) {
  return array.map((item) => {
    if (item.index === index) {
      return { ...item, inventoryId: newInventoryId };
    } else {
      return item;
    }
  });
}

export function getTotalQuantity(data: AlternativeToReturn[]) {
  return data.reduce((total, item) => total + (item.quantity || 0), 0);
}

// This function creates the possible alternatives vaccines to return
export function buildOptions(
  inconsistency: InconsistencyWithClaimIdsAndVaccineToReturn,
  index: number,
  inventory: Inventory[]
): Result[] {
  const results: Result[] = [];

  // Check for matches using types
  const matchedInventory = inventory.filter((inv) => {
    return (
      inv.vfc !== inconsistency.inventory.vfc &&
      arraysContainSameItems(
        inv.vaccine?.types,
        inconsistency.inventory.vaccine.types
      ) &&
      inv.doses > 0
    );
  });

  matchedInventory.forEach((result) => {
    const alreadyIn = results.find((r) => r.value === result.id);
    if (!alreadyIn) {
      return results.push({
        text: `${result.vaccine?.name} | ${
          result.vfc ? 'VFC' : 'Private'
        } - Lot: ${result.lot} - ${result.doses} doses`,
        content: (
          <Header as="h4">
            <Header.Content>
              {result.vaccine?.name}
              <Label color={result.vfc ? 'orange' : 'teal'} size="mini">
                {result.vfc ? 'VFC' : 'Private'}
              </Label>
              <br />
              Lot: {result.lot} | {result.doses} doses
            </Header.Content>
            <Header.Subheader>
              {result.vaccine?.types?.map((t) => (
                <Label key={t} color="black" size="mini">
                  {t}
                </Label>
              ))}
            </Header.Subheader>
          </Header>
        ),
        value: result.id,
      });
    }
  });

  const selectedResults = inconsistency.vaccineToReturn.map((selected) => {
    if (selected.index !== index) {
      return selected.inventoryId;
    } else {
      return null;
    }
  });

  const filteredResults = results.filter((result) => {
    if (result.value) {
      return !selectedResults.includes(result.value);
    } else {
      return false;
    }
  });

  return filteredResults;
}

export const handleAlternative = (
  inventoryId: string,
  index: number,
  alternativeInventoryId: string,
  inconsistencies: InconsistencyWithClaimIdsAndVaccineToReturn[],
  setInconsistencies: (
    inconsistencies: InconsistencyWithClaimIdsAndVaccineToReturn[]
  ) => void
) => {
  const updatedInconsistencies = inconsistencies.map((inconsistency) => {
    if (inconsistency.inventoryId === inventoryId) {
      const alternativeFound = inconsistency.vaccineToReturn.find(
        (alt) => alt.index === index
      );
      if (alternativeFound) {
        return {
          ...inconsistency,
          vaccineToReturn: updateInventoryToReturn(
            inconsistency.vaccineToReturn,
            index,
            alternativeInventoryId
          ),
        };
      } else {
        return {
          ...inconsistency,
          vaccineToReturn: [
            ...inconsistency.vaccineToReturn,
            {
              index,
              inventoryId: alternativeInventoryId,
              quantity: 0,
            },
          ],
        };
      }
    } else {
      return inconsistency;
    }
  });

  setInconsistencies(
    updatedInconsistencies.map((updatedInconsistency) => {
      if (
        !!updatedInconsistency.vaccineToReturn.length &&
        updatedInconsistency.vaccineToReturn[
          updatedInconsistency.vaccineToReturn.length - 1
        ].inventoryId === ''
      ) {
        return updatedInconsistency;
      } else {
        return {
          ...updatedInconsistency,
          vaccineToReturn: [
            ...updatedInconsistency.vaccineToReturn,
            {
              index: updatedInconsistency.vaccineToReturn.length,
              inventoryId: '',
              quantity: 0,
            },
          ],
        };
      }
    })
  );
};

export const handleAlternativeQuantity = (
  inventoryId: string,
  index: number,
  alternativeQuantity: number,
  inventory: Inventory[],
  setInconsistencies: (prevInconsistencies: any) => void
) => {
  setInconsistencies((prevInconsistencies: any) =>
    prevInconsistencies.map(
      (inconsistency: InconsistencyWithClaimIdsAndVaccineToReturn) => {
        if (inconsistency.inventoryId === inventoryId) {
          const totalConfirmedQuantity = getTotalQuantity(
            inconsistency.vaccineToReturn
          );
          const maxQuantityPossible =
            inconsistency.vfcInconsistentClaims.length -
            inconsistency.rejected -
            totalConfirmedQuantity +
            (inconsistency.vaccineToReturn[index]?.quantity || 0);

          const alternativeInventoryId =
            inconsistency.vaccineToReturn[index]?.inventoryId;
          const alternativeInventory = inventory.find(
            (inv) => inv.id === alternativeInventoryId
          );
          const availableAlternativeDoses = alternativeInventory
            ? alternativeInventory.doses
            : 0;

          const updatedVaccineToReturn = inconsistency.vaccineToReturn.map(
            (item: AlternativeToReturn, idx: number) => {
              if (idx === index) {
                return {
                  ...item,
                  quantity: Math.min(
                    Math.max(alternativeQuantity, 0),
                    maxQuantityPossible,
                    availableAlternativeDoses
                  ),
                };
              }
              return item;
            }
          );

          return {
            ...inconsistency,
            vaccineToReturn: updatedVaccineToReturn,
          };
        }
        return inconsistency;
      }
    )
  );
};

function toCamelCase(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, '');
}

export function transformTicketData(tickets: Ticket[]): OutputTicket[] {
  return tickets.map((ticket) => {
    const details: OutputDetail = {};
    let issueCount = 0; // To track occurrences of "issue"

    ticket.detail.fields.forEach((field) => {
      let key = toCamelCase(field.title);

      // Check for "issue" title to differentiate between types
      if (field.title === borrowingStrings.ISSUE) {
        issueCount++;
        key =
          issueCount === 1
            ? borrowingStrings.ISSUE_TYPE
            : borrowingStrings.ISSUE_DESCRIPTION;
      }

      details[key] = field.detail ? field.detail : '';
    });

    return {
      id: ticket.id || '',
      status: ticket.status,
      details,
      practiceId: ticket.practiceId,
      type: ticket.type,
      ticketsFiles: ticket.ticketsFiles,
      createdAt: ticket.createdAt,
      detail: ticket.detail,
    };
  });
}

export const chatHeaderForBorrowingRequest = (ticket: OutputTicket) => {
  const patientField = ticket?.details?.patient;
  const patientDateOfBirth = patientField
    ?.split('(')[1]
    ?.trim()
    ?.replace(')', '');
  const year = patientDateOfBirth?.slice(0, 4);
  const month = patientDateOfBirth?.slice(5, 7);
  const day = patientDateOfBirth?.slice(8, 10);
  return {
    patient: patientField?.split('(')[0].trim() || '',
    dateOfBirth: patientField ? `(${month}/${day}/${year})` : '',
    dateOfService: ticket.details.dateOfService
      ? formatDateToMMDDYYYYV2(ticket.details.dateOfService)
      : '',
    insuranceName: ticket.details.insurance || '',
  };
};

export const chatHeaderForBorrowingIssue = (
  patientData: PatientData | undefined,
  insurance: VfcInconsistencyInsurance | undefined,
  inventory: Inventory | undefined,
  inconsistency: VFCInconsistency
) => {
  return {
    patient: `${patientData?.lastName}, ${patientData?.firstName}` || '',
    dateOfBirth:
      (patientData?.birthdate &&
        formatDatetimeToMMDDYYYYV2(patientData?.birthdate)) ||
      '',
    insuranceName: insurance?.insuranceCompanyName || '',
    insuranceMemberId: insurance?.memberId || '',
    insuranceElegibilty: insurance?.vfcEligible,
    inventoryUsed: inventory?.vfc,
    ndc: inventory?.vaccine?.saleNdc,
    lot: inventory?.lot,
    dateOfService:
      formatDatetimeToMMDDYYYYV2(inconsistency.claim?.givenAt) || '',
    vaccine: inventory?.vaccine?.name,
  };
};
