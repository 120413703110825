import { DbData, Enum } from './Generics';
import { Inventory } from './Inventory';
import { Practice } from './Practice';

// ENUMS
export enum AdjustmentReasons {
  EXPIRED = 'expired',
  WASTAGE = 'wastage',
  MISSING_VACCINE = 'missing_vaccine',
  DIFFERENT_AMOUNT = 'amount_different_from_invoice',
  INVALID_ENTRY = 'invalid_entry',
  EMR_ISSUE = 'emr_issue',
  OTHER = 'other',
  REPLACE_PRIVATE_DOSES = 'replace_private_doses',
  TRANSF_COVID_VAX = 'transfer_covid_vax',
  UNACCOUNTED = 'unaccounted_for_provider_inventory',
  BORROWING = 'borrowing',
}

export enum InventoryAdjustmentTypes {
  ADJUSTMENT = 'adjustment',
  CONFIRMATION = 'confirmation',
  REMOVAL = 'removal', // Should use to remove inventory from a practice to another.
  EXPIRATION = 'expiration',
  AMENDED = 'amended',
  REJECTED = 'rejected',
  RECOVERED = 'recovered',
  RETURNED = 'returned'
}

export enum InventoryAdjustmentDetailStatuses {
  PENDING = 'pending',
  APPLIED = 'applied',
}
export enum InventoryAdjustmentStatuses {
  PARTIALLY_APPLIED = 'partially_applied',
  PENDING = 'pending',
  APPLIED = 'applied',
}

export enum AdjustmentKind {
  PRIVATE = 'private',
  VFC = 'vfc',
}

// END ENUMS

// INTERFACES

export interface AdjustmentReason extends Enum {
  value: AdjustmentReasons;
}
export interface InventoryAdjustmentStatus extends Enum {}
export interface InventoryAdjustmentType extends Enum {}
export interface AdjustmentDetailReason extends Enum {}

export interface InventoryAdjustmentDetail extends DbData {
  inventoryAdjustmentId?: string;
  inventoryId: string;
  currentDoses: number;
  newDoses: number;
  type: InventoryAdjustmentTypes;
  reason: AdjustmentReasons;
  status: InventoryAdjustmentDetailStatuses;
  inventory?: Inventory;
  comment?: string;
  selected?: boolean;
  __typename?: string;
  operatorAmmend?: {
    previousDoses?: number;
    newDoses?: number;
    reason?: string;
  };
  operatorRejection?: {
    reason?: string;
    previousDoses?: number;
    newDoses?: number;
  };
  originalNewDoses?: number;
}

export interface InventoryAdjustment extends DbData {
  status?: InventoryAdjustmentStatuses;
  practiceId: string;
  details?: InventoryAdjustmentDetail[];
  inventoryAdjustmentDetails?: {
    data: InventoryAdjustmentDetail[];
  };
  vfc?: boolean;
  practice?: Practice;
}

export interface InventoryAdjustmentData {
  id?: string;
  name: string;
  lot: string;
  expiration: string;
  doses: number;
  newDoses?: number;
  lastAdjustmentDate?: Date;
  newAdjustmentDate?: Date;
  adjustmentReason?: AdjustmentReasons;
  isPrivate: boolean;
  isExpired: boolean;
  wasAdjusted: boolean;
  wasConfirmed?: boolean;
  vaccineId: string;
  comment?: string;
  saleNdc?: string;
  useNdc?: string;
  types?: string[];
}

// END INTERFACES
