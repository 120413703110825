import { Header, Table } from 'semantic-ui-react';

import {
  AdjustmentReason,
  InventoryAdjustmentDetail,
  InventoryAdjustmentStatus,
} from '@bluefox/models/InventoryAdjustment';
import InventoryAdjustmentTableRow from '@screens/inventoryAdjustment/InventoryAdjustmentTableRow';

interface InventoryAdjustmentTableProps {
  statuses: InventoryAdjustmentStatus[];
  isApplied: boolean;
  updateAdjustmentDetails: (
    detailOrDetails: InventoryAdjustmentDetail | InventoryAdjustmentDetail[]
  ) => void;
  details: InventoryAdjustmentDetail[];
  onDetailSelected: (detail: InventoryAdjustmentDetail) => void;
  title: string;
  adjustmentReasons?: AdjustmentReason[];
}

const InventoryAdjustmentTable = ({
  statuses,
  isApplied,
  updateAdjustmentDetails,
  details,
  onDetailSelected,
  title,
  adjustmentReasons,
}: InventoryAdjustmentTableProps) => {
  return (
    <>
      <Header>{title}</Header>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>LOT</Table.HeaderCell>
            <Table.HeaderCell>Current Doses</Table.HeaderCell>
            <Table.HeaderCell>New Doses</Table.HeaderCell>
            <Table.HeaderCell width={1}>Status</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {details.map((detail) => {
            return (
              <InventoryAdjustmentTableRow
                adjustmentApplied={isApplied}
                key={`row-${detail.id}`}
                detail={detail}
                statuses={statuses}
                onSelectDetail={() => onDetailSelected(detail)}
                updateAdjustmentDetails={updateAdjustmentDetails}
                adjustmentReasons={adjustmentReasons}
              />
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};
export default InventoryAdjustmentTable;
