import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Modal, ModalProps } from 'semantic-ui-react';
import { usePractice, useApplicationState } from '@bluefox/contexts';
import React, { useCallback } from 'react';

interface CustomModalType {
  children?: React.ReactNode;
  modal?: React.ComponentType<ModalProps>;
  scrolling?: boolean;
  actions?: React.ReactNode;
  modalHeader?: string;
  open?: boolean;
  goBackPathSlices?: number;
}

const CustomModal = ({
  open = true,
  modalHeader,
  scrolling,
  children,
  actions,
  goBackPathSlices = -1,
}: CustomModalType) => {
  const { isEmbedded } = useApplicationState();
  const history = useHistory();
  const currentRouteArr = history.location.pathname.split('/');
  const goBackRouteArr = currentRouteArr.slice(0, goBackPathSlices);

  const handleOnClose = useCallback(() => {
    history.push(goBackRouteArr.join('/') + '/');
  }, [history]);
  return (
    <Modal
      style={{ marginTop: isEmbedded ? '4rem' : '' }}
      open={open}
      onClose={handleOnClose}
      size="large"
      closeIcon
    >
      {!isEmbedded && !!modalHeader && (
        <StyledHeader>{modalHeader}</StyledHeader>
      )}
      <Modal.Content scrolling={scrolling}>{children}</Modal.Content>
      {actions && <Modal.Actions>{actions}</Modal.Actions>}
    </Modal>
  );
};

export default CustomModal;
const StyledHeader = styled(Modal.Header)`
  font-size: 2rem !important;
`;
