import React from 'react';
import { Label, Table } from 'semantic-ui-react';
import { formatDateToMMDDYYYYV2, formatPackage } from '@bluefox/lib/formatters';
import ExpandedRow from './ExpandedRow';
import { VaccinePricingData } from '@screens/vaccines/VaccinesPriceList';

type Props = {
  vaccine: VaccinePricingData;
  index: number;
  activeIndex: number;
  onRowClick: (index: number) => void;
};

const VaccinesPriceListTableRow = ({
  vaccine,
  index,
  activeIndex,
  onRowClick,
}: Props) => {
  return (
    <>
      <Table.Row
        key={vaccine.id}
        onClick={() => {
          onRowClick(index);
        }}
      >
        <Table.Cell
          className={activeIndex === index ? 'highlighted-border-top-left' : ''}
        >
          {vaccine.name}
        </Table.Cell>
        <Table.Cell
          className={activeIndex === index ? 'highlighted-border-top' : ''}
        >
          {vaccine.saleNdc}
        </Table.Cell>
        <Table.Cell
          className={activeIndex === index ? 'highlighted-border-top' : ''}
        >
          <Label basic content={vaccine.inventoryCptCodes[0]} />
        </Table.Cell>
        <Table.Cell
          className={activeIndex === index ? 'highlighted-border-top' : ''}
        >
          {vaccine.manufacturer}
        </Table.Cell>
        <Table.Cell
          className={activeIndex === index ? 'highlighted-border-top' : ''}
        >
          {vaccine.packageType ? (
            <Label
              basic
              content={formatPackage(vaccine.packageType, vaccine.packageDoses)}
            />
          ) : (
            '-'
          )}
        </Table.Cell>
        <Table.Cell
          className={activeIndex === index ? 'highlighted-border-top' : ''}
        >
          {vaccine.vaccinePricingDefault.length
            ? `$${vaccine.vaccinePricingDefault[0].priceCents / 100}`
            : '-'}
        </Table.Cell>
        <Table.Cell
          className={
            activeIndex === index ? 'highlighted-border-top-right' : ''
          }
        >
          {vaccine.vaccinePricingDefault[0]?.date
            ? formatDateToMMDDYYYYV2(
                String(vaccine.vaccinePricingDefault[0]?.date)
              )
            : '-'}
        </Table.Cell>
      </Table.Row>
      {activeIndex === index && (
        <Table.Row>
          <Table.Cell
            colSpan={8}
            className={
              activeIndex === index
                ? 'highlighted-border-expanded-row-cell'
                : ''
            }
          >
            <ExpandedRow vaccine={vaccine} />
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};

export default VaccinesPriceListTableRow;
