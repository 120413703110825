import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import { BillingStrategy, MappedInsurance } from '@bluefox/models/Mappings';
import {
  SaveInsuranceMappingMutation,
  GetMappedInsurances,
} from '@graphql/mappings';
import { InsuranceCompanyById } from '@bluefox/graphql/insurances';
import { InsuranceCompany } from '@bluefox/models/Insurances';
import { Button, Checkbox, Form, Label, Segment } from 'semantic-ui-react';
import InsuranceCompanyPicker from '@bluefox/ui/InsuranceCompanyPicker';
import styled from 'styled-components';
import { InsuranceMappingByCompanyId } from '@bluefox/graphql/mappings';
import { BillingClaimsMappingType } from '@bluefox/models/Billing';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';

interface InsuranceMappingFormProps {
  close: () => void;
  practiceId?: string;
  data: MappedInsurance | null;
  refetchMappings: () => void;
}

interface InsuranceData {
  nameEmr: string;
  insuranceCompany: {
    name: string;
    payerId: number;
  };
  selfPay: boolean;
}

interface InsuranceCount {
  insurances: InsuranceData | undefined;
  count: number;
  nameEmr: string;
}

interface InsuranceCompanyData {
  insuranceCompanies: InsuranceCompany[];
}

type Value = Record<string, number>;

type BillingStrategyWithUndefinedId = {
  id?: string;
  cpid?: string;
  type?: string;
  enabled?: boolean;
  practiceId?: string;
};

const setClaimMapping = (
  mapping: BillingStrategyWithUndefinedId[] | BillingStrategy[] | undefined
) => {
  return mapping && mapping?.length > 0
    ? {
        id: mapping[0].id,
        enabled: mapping[0].enabled,
      }
    : { enabled: false };
};

const InsuranceMappingForm = ({
  close,
  practiceId,
  data,
  refetchMappings,
}: InsuranceMappingFormProps) => {
  const [insuranceCompanyId, setInsuranceCompanyId] = useState<
    string | undefined
  >(data?.insuranceCompany ? data?.insuranceCompany.id : undefined);
  const [insuranceEmr, setInsuranceEmr] = useState<string>(data?.nameEmr || '');
  const [insuranceCompanyIdForBilling, setInsuranceCompanyIdForBilling] =
    useState<string | undefined>(
      data?.insuranceCompanyForBilling?.id || undefined
    );
  const [insuranceOptions, setInsuranceOptions] = useState<
    InsuranceCount[] | undefined
  >([]);

  const [enableRendering, setEnableRendering] = useState<boolean | undefined>(
    false
  );
  const [enableTaxonomy, setEnableTaxonomy] = useState<boolean | undefined>(
    false
  );
  const [cpid, setCpid] = useState<string | undefined>(undefined);
  const [selfPay, setSelfPay] = useState<boolean>(data ? data.selfPay : false);
  const [box31, setBox31] = useState<
    BillingStrategyWithUndefinedId | undefined
  >({ enabled: false });
  const [ndcDossage, setNdcDossage] = useState<
    BillingStrategyWithUndefinedId | undefined
  >({ enabled: false });
  const [vfcWithInventoryAmount, setVfcWithInventoryAmount] = useState<
    BillingStrategyWithUndefinedId | undefined
  >({ enabled: false });
  const [decoupledCpts, setDecoupledCpts] = useState<
    BillingStrategyWithUndefinedId | undefined
  >({ enabled: false });

  const { data: insurancesData, refetch } = useQuery(GetMappedInsurances);

  const [getInsuranceCompanyData, { data: insuranceCompanyData }] =
    useLazyQuery<InsuranceCompanyData>(InsuranceCompanyById);

  const [getInsuranceMappingToogleData] = useLazyQuery(
    InsuranceMappingByCompanyId(
      !isStringNotEmpty(insuranceCompanyIdForBilling)
    ),
    {
      onCompleted(data) {
        if (data?.insurances?.length > 0) {
          const insurance = data?.insurances[0];
          setBox31(setClaimMapping(insurance.box31));
          setNdcDossage(setClaimMapping(insurance.ndcDossage));
          setVfcWithInventoryAmount(
            setClaimMapping(insurance.vfcWithInventoryAmount)
          );
          setDecoupledCpts(setClaimMapping(insurance.decoupledCpts));
        } else {
          setBox31({ enabled: false });
          setNdcDossage({ enabled: false });
          setVfcWithInventoryAmount({ enabled: false });
          setDecoupledCpts({ enabled: false });
        }
      },
    }
  );

  const [saveInsuranceMapping] = useMutation(SaveInsuranceMappingMutation);

  const handleSubmit = async () => {
    const newClaimMappings = [] as BillingStrategyWithUndefinedId[];
    pushNewMappingArray(
      box31,
      newClaimMappings,
      BillingClaimsMappingType.BOX_31
    );
    pushNewMappingArray(
      ndcDossage,
      newClaimMappings,
      BillingClaimsMappingType.NDC_DOSSAGE
    );
    pushNewMappingArray(
      vfcWithInventoryAmount,
      newClaimMappings,
      BillingClaimsMappingType.VFC_WITH_INVENTORY_AMOUNT
    );
    pushNewMappingArray(
      decoupledCpts,
      newClaimMappings,
      BillingClaimsMappingType.DECOUPLED_CPTS
    );
    try {
      await saveInsuranceMapping({
        variables: {
          object: {
            practiceId,
            ...(data?.id ? { id: data?.id } : {}),
            insuranceCompanyId: !selfPay ? insuranceCompanyId : undefined,
            nameEmr: insuranceEmr,
            ...(insuranceCompanyIdForBilling
              ? { insuranceCompanyIdForBilling }
              : {}),
            selfPay: selfPay,
            claimMappingTaxonomy: {
              data: {
                ...(data?.claimMappingTaxonomyId
                  ? { id: data.claimMappingTaxonomyId }
                  : {}),
                practiceId,
                ...(data?.id
                  ? { cpid: insuranceCompanyData?.insuranceCompanies[0].cpid }
                  : { cpid }),
                insuranceCompanyId: insuranceCompanyIdForBilling
                  ? insuranceCompanyIdForBilling
                  : insuranceCompanyId,
                type: 'taxonomy',
                enabled: selfPay ? false : enableTaxonomy,
              },
              on_conflict: {
                constraint: 'claim_mappings_pkey',
                update_columns: ['cpid', 'insuranceCompanyId', 'enabled'],
              },
            },
            claimMappingRendering: {
              data: {
                ...(data?.claimMappingRenderingId
                  ? { id: data.claimMappingRenderingId }
                  : {}),
                practiceId,
                ...(data?.id
                  ? { cpid: insuranceCompanyData?.insuranceCompanies[0].cpid }
                  : { cpid }),
                insuranceCompanyId: insuranceCompanyIdForBilling
                  ? insuranceCompanyIdForBilling
                  : insuranceCompanyId,
                type: 'rendering',
                enabled: selfPay ? false : enableRendering,
              },
              on_conflict: {
                constraint: 'claim_mappings_pkey',
                update_columns: ['cpid', 'insuranceCompanyId', 'enabled'],
              },
            },
          },
          claimMappings: newClaimMappings,
        },
      });
      toast({
        title: 'Mapping saved successfully',
        type: 'success',
        time: 1000,
      });
      close();
      refetch();
      refetchMappings();
    } catch (e) {
      toast({
        title: `Callback error: ${e}`,
        type: 'error',
        time: 5000,
      });
    }
  };

  const typeToggle = useCallback(
    (
      disabled: boolean,
      label: string,
      enable: boolean | undefined,
      setEnable: any,
      object: boolean
    ) => {
      return (
        <Form.Field disabled={disabled}>
          <Checkbox
            disabled={disabled}
            label={label}
            toggle
            checked={enable}
            onChange={(_, { checked }) => {
              object
                ? setEnable((prev: BillingStrategyWithUndefinedId) => {
                    return {
                      ...prev,
                      enabled: !!checked,
                    };
                  })
                : setEnable(!!checked);
            }}
          />
        </Form.Field>
      );
    },
    []
  );

  const renderInsuranceFormSegment = (
    title: string,
    value: string | undefined,
    setter: (value: string) => void,
    required: boolean,
    disabled: boolean
  ) => {
    return (
      <StyledSegment>
        <Form.Group>
          <Form.Field required={required} disabled={disabled}>
            <label>
              <b>Insurance ({title})</b>
            </label>
            <InsuranceCompanyPicker
              disabled={disabled}
              placeholder="Search insurance..."
              includePayerId
              value={value}
              onChange={(value) => {
                setter(value as string);
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          {typeToggle(
            disabled,
            'Rendering',
            enableRendering,
            setEnableRendering,
            false
          )}
          {typeToggle(
            disabled,
            'Taxonomy',
            enableTaxonomy,
            setEnableTaxonomy,
            false
          )}
          {typeToggle(disabled, 'Box 31', box31?.enabled, setBox31, true)}
          {typeToggle(
            disabled,
            'NDC Dossage',
            ndcDossage?.enabled,
            setNdcDossage,
            true
          )}
          {typeToggle(
            disabled,
            'VFC With Inventory Amount',
            vfcWithInventoryAmount?.enabled,
            setVfcWithInventoryAmount,
            true
          )}
          {typeToggle(
            disabled,
            'Decoupled CPTs',
            decoupledCpts?.enabled,
            setDecoupledCpts,
            true
          )}
        </Form.Group>
      </StyledSegment>
    );
  };

  const pushNewMappingArray = useCallback(
    (mapping, mappingArray, type) => {
      if (mapping?.id || mapping?.enabled === true) {
        mapping.id
          ? mappingArray.push({
              id: mapping.id,
              practiceId,
              enabled: mapping.enabled,
              cpid: cpid,
              type: type,
            })
          : mappingArray.push({
              practiceId,
              enabled: mapping.enabled,
              cpid: cpid,
              type: type,
            });
      }
    },
    [cpid, practiceId]
  );

  useEffect(() => {
    if (!insuranceCompanyId && !insuranceCompanyIdForBilling) return;
    getInsuranceCompanyData({
      variables: {
        id: insuranceCompanyIdForBilling
          ? insuranceCompanyIdForBilling
          : insuranceCompanyId,
      },
    });
  }, [
    getInsuranceCompanyData,
    insuranceCompanyId,
    insuranceCompanyIdForBilling,
  ]);

  useEffect(() => {
    if (!insuranceCompanyData) return;

    setCpid(insuranceCompanyData.insuranceCompanies[0].cpid);
  }, [insuranceCompanyData]);

  useEffect(() => {
    if (!data) return;
    const _box31 = data?.box31;
    const _ndcDossage = data?.ndcDossage;
    const _vfcWithInventoryAmount = data?.vfcWithInventoryAmount;
    const _decoupledCpts = data?.decoupledCpts;
    setEnableRendering(data.claimMappingRendering?.enabled || false);
    setEnableTaxonomy(data.claimMappingTaxonomy?.enabled || false);
    setBox31(setClaimMapping(_box31));
    setNdcDossage(setClaimMapping(_ndcDossage));
    setVfcWithInventoryAmount(setClaimMapping(_vfcWithInventoryAmount));
    setDecoupledCpts(setClaimMapping(_decoupledCpts));
  }, [data]);

  useEffect(() => {
    if (!insurancesData) return;

    const insurances = insurancesData?.insurances?.filter(
      (e: InsuranceData) =>
        e.nameEmr.toLowerCase() === data?.nameEmr.toLowerCase() &&
        e.insuranceCompany !== null
    );

    const objectCount = insurances?.reduce(
      (value: Value, current: InsuranceData) => {
        value[
          `${current?.nameEmr?.toLowerCase()}_${current?.insuranceCompany
            ?.payerId}_${current?.insuranceCompany?.name}`
        ] = value[
          `${current?.nameEmr?.toLowerCase()}_${current?.insuranceCompany
            ?.payerId}_${current?.insuranceCompany?.name}`
        ]
          ? value[
              `${current?.nameEmr?.toLowerCase()}_${current?.insuranceCompany
                ?.payerId}_${current?.insuranceCompany?.name}`
            ] + 1
          : 1;
        return value;
      },
      {}
    );

    setInsuranceOptions(
      Object.keys(objectCount)
        .map((insurance: string) => {
          return {
            count: objectCount[insurance] as number,
            nameEmr: insurancesData?.insurances
              ?.find(
                (e: InsuranceData) =>
                  (e.insuranceCompany?.payerId as unknown as String) ===
                  insurance?.split('_')[1]
              )
              ?.nameEmr.toLowerCase() as string,
            insurances: insurancesData?.insurances?.find((e: InsuranceData) => {
              return (
                (e.insuranceCompany?.name as unknown as String) ===
                insurance?.split('_')[2]
              );
            }),
          };
        })
        .sort((a, b) => b.count - a.count)
    );
  }, [data?.nameEmr, insurancesData]);

  useEffect(() => {
    getInsuranceMappingToogleData({
      variables: {
        practiceId: practiceId,
        insuranceCompanyId: insuranceCompanyIdForBilling
          ? insuranceCompanyIdForBilling
          : insuranceCompanyId,
      },
    });
  }, [
    getInsuranceMappingToogleData,
    insuranceCompanyId,
    insuranceCompanyIdForBilling,
    practiceId,
  ]);

  return (
    <Form onSubmit={handleSubmit}>
      {!data?.insuranceCompany?.id && data?.nameEmr && (
        <Form.Group>
          <Form.Field>
            <Label>Most Used Insurances</Label>
          </Form.Field>
          <Form.Field
            style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
          >
            {insuranceOptions?.map((insurance: InsuranceCount, i: number) => {
              return (
                <Form.Field key={i}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginBottom: '0' }}>
                      {insurance?.insurances?.insuranceCompany?.name} (
                      {insurance?.count} time/s used)
                    </p>
                  </div>
                  <p style={{ fontSize: '12px', fontWeight: '600' }}>
                    Payer ID: {insurance?.insurances?.insuranceCompany?.payerId}
                  </p>
                </Form.Field>
              );
            })}
          </Form.Field>
        </Form.Group>
      )}
      <Form.Group widths="equal">
        <Form.Field required>
          <Form.Input
            value={insuranceEmr}
            onChange={(_, { value }) => setInsuranceEmr(value)}
            fluid
            label="Insurance (EMR)"
            placeholder="Insurance (EMR)"
            required
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={4} style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Checkbox
            label="Self Pay"
            toggle
            checked={selfPay}
            onChange={() => {
              setSelfPay(!selfPay);
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        {insuranceCompanyIdForBilling ? (
          <Form.Field required>
            <label>
              <b>Insurance (Practice Portal)</b>
            </label>
            <InsuranceCompanyPicker
              disabled={selfPay}
              placeholder="Search insurance..."
              includePayerId
              value={insuranceCompanyId}
              onChange={(value) => {
                const parsedValue = value === '' ? undefined : value;
                setInsuranceCompanyId(parsedValue as string);
              }}
            />
          </Form.Field>
        ) : (
          renderInsuranceFormSegment(
            'Practrice Portal',
            insuranceCompanyId,
            setInsuranceCompanyId,
            true,
            selfPay
          )
        )}
      </Form.Group>
      <Form.Group>
        {insuranceCompanyIdForBilling ? (
          renderInsuranceFormSegment(
            'For Billing',
            insuranceCompanyIdForBilling,
            setInsuranceCompanyIdForBilling,
            false,
            selfPay
          )
        ) : (
          <Form.Field disabled={selfPay}>
            <label>
              <b>Insurance (For Billing)</b>
            </label>
            <InsuranceCompanyPicker
              disabled={selfPay}
              placeholder="Search insurance..."
              includePayerId
              value={insuranceCompanyIdForBilling}
              onChange={(value) => {
                setInsuranceCompanyIdForBilling(value as string);
              }}
            />
          </Form.Field>
        )}
      </Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={close} type="button">
          Cancel
        </Button>
        <Button
          primary
          type="submit"
          content="Save"
          icon="save"
          disabled={
            (!selfPay && (!insuranceCompanyId || !insuranceEmr)) ||
            (selfPay && !insuranceEmr)
          }
        />
      </Form.Field>
    </Form>
  );
};

const StyledSegment = styled(Segment)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem
  justify-content: flex-start;
`;

export default InsuranceMappingForm;
