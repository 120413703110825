import { useMutation } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-semantic-toasts';
import { Button, Form, Header, Icon, Modal, Popup } from 'semantic-ui-react';
import { AppendTicketDetail } from '@bluefox/graphql/tickets';

interface TicketClosedNoteProps {
  note?: string;
  ticketId?: string;
}

export const TicketClosedNote = ({ note, ticketId }: TicketClosedNoteProps) => {
  const [openNotes, setOpenNotes] = useState(false);
  const [notes, setNotes] = useState('');

  const [saveNote] = useMutation(AppendTicketDetail);

  const handleNoteSubmit = useCallback(async () => {
    try {
      await saveNote({
        variables: {
          id: ticketId,
          detail: {
            closeTicketResolution: notes,
          },
        },
      });
      toast({
        title: 'Notes saved successfully',
        type: 'success',
        time: 1000,
      });
      setOpenNotes(false);
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  }, [notes, saveNote]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Modal
        closeIcon
        onClose={() => setOpenNotes(false)}
        onOpen={() => setOpenNotes(true)}
        open={openNotes}
        trigger={<Button primary content="Resolution Message" size="mini" />}
      >
        <Modal.Content>
          <Header>Resolution Message</Header>
          <Form id="notes-form" onSubmit={handleNoteSubmit}>
            <Form.TextArea
              placeholder="There are no notes. You can add one and save it."
              defaultValue={note}
              onChange={(_, { value }) => {
                setNotes(value as string);
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            content="Close"
            onClick={() => setOpenNotes(false)}
          />
          <Button
            primary
            type="submit"
            icon="save"
            content="Save note"
            form="notes-form"
          />
        </Modal.Actions>
      </Modal>
      {notes && (
        <Popup
          content={notes}
          trigger={<Icon name="info circle" size="large" color="teal" />}
        />
      )}
    </div>
  );
};
