import { useCallback, useState } from 'react';
import { useMutation, useSubscription } from '@apollo/client';
import styled from 'styled-components';
import moment from 'moment';

import { toast } from 'react-semantic-toasts';
import {
  Button,
  Confirm,
  Dropdown,
  Icon,
  Label,
  List,
  Popup,
  Table,
} from 'semantic-ui-react';

import { DateFormats } from '@bluefox/models/Dates';
import { humanizeText } from '@bluefox/lib/humanize';
import {
  VaccinationsToReportInterface,
  VaccinationsToReportStatusOptions,
  iisStatusOptions,
} from '@bluefox/models/integrations/Develo';
import {
  setDropdownBackgroundColor,
  setDropdownBorderColor,
} from '@bluefox/lib/commonStyles';

import {
  DeveloUpdateIisStatusMutation,
  DeveloVaccinationsToReportQuery,
  DeveloVaccinationsToReportSubscription,
  TriggerDeveloVaccinationToReportMutation,
  UpdateDeveloVaccinationToReportStatusEmrMutation,
} from '@bluefox/graphql/integrations/develo';
import { notificationStatusEnumToBeShownText } from '@bluefox/constants/notifications';
import { Notification, NotificationStatus } from '@bluefox/models/Notification';
import { formatDateToMMDDYYYYV2 } from '@bluefox/lib/formatters/dates';

interface DeveloActionRowProps {
  data: VaccinationsToReportInterface;
  onSave: () => void;
  onSendNotification?: () => void;
  notificationsLoading: boolean;
  notifications?: Notification[];
}

interface DosageType {
  dose: number;
  unit: string;
}

const VaccinationsToReportRow = ({
  data,
  onSave,
  onSendNotification,
  notificationsLoading,
  notifications,
}: DeveloActionRowProps) => {
  const [showConfirmRunAction, setShowConfirmRunAction] = useState(false);

  const { data: vaccinationToReportSubData } = useSubscription(
    DeveloVaccinationsToReportSubscription,
    {
      variables: {
        id: data.id,
      },
      skip: !data,
    }
  );

  const [triggerVaccinationToReport] = useMutation(
    TriggerDeveloVaccinationToReportMutation,
    {
      variables: {
        vaccinationToReportId: data.id,
        action: data.action,
      },
      refetchQueries: [
        {
          query: DeveloVaccinationsToReportQuery,
        },
      ],
    }
  );

  const handleTriggerAction = () => {
    triggerVaccinationToReport()
      .then((r) => {
        toast({
          title: 'Action ran successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const [updateIisStatus] = useMutation(DeveloUpdateIisStatusMutation);

  const [updateStatusEmr] = useMutation(
    UpdateDeveloVaccinationToReportStatusEmrMutation
  );

  const handleIisStatusUpdate = (iisStatus: string) => {
    updateIisStatus({
      variables: {
        id: data.id,
        iisStatus,
      },
    })
      .then((r) => {
        toast({
          title: 'IIS status saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleStatusEmrUpdate = (statusEmr: string) => {
    updateStatusEmr({
      variables: {
        id: data.id,
        statusEmr,
      },
    })
      .then((r) => {
        toast({
          title: 'Status saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const inventoryUsedLabelText = () => {
    if (data.metadata.inventory?.vfc) return 'VFC';
    return 'Private';
  };

  const renderSendNotificationButton = useCallback(() => {
    const notification = notifications?.find((n) => n.entityRefId === data.id);
    return (
      <>
        <Popup
          content={`${
            notificationStatusEnumToBeShownText[
              notification?.status as NotificationStatus
            ] || 'Send a notification'
          }`}
          trigger={
            <Button
              style={{ margin: '0.1rem' }}
              loading={notificationsLoading}
              icon="send"
              size="mini"
              color={notification ? 'green' : 'blue'}
              onClick={onSendNotification}
            />
          }
        />
        {notification?.status && (
          <p>
            <b>Notification Status:</b>
            {` ${
              notificationStatusEnumToBeShownText[
                notification?.status as NotificationStatus
              ]
            }`}
          </p>
        )}
      </>
    );
  }, [data.id, notifications, notificationsLoading, onSendNotification]);

  return (
    <Table.Row>
      <Table.Cell>{data.practice.name}</Table.Cell>
      <Table.Cell>
        {`${data.metadata.patient.firstName} ${
          data.metadata.patient.lastName
        } (${data.metadata.patient.birthdate || '-'})`}
        <Label
          basic
          size="tiny"
          content={`MRN: ${data.metadata.patient.mrn}`}
        />
      </Table.Cell>
      <Table.Cell>
        {formatDateToMMDDYYYYV2(
          data.metadata.givenAt as unknown as string,
          data.practice.timezone
        )}
      </Table.Cell>
      <Table.Cell>
        <StyledDivCol>
          {data.metadata.primaryVaccinationId ? (
            <Popup
              trigger={
                <Icon
                  name="warning sign"
                  color="yellow"
                  style={{ margin: '0 auto' }}
                />
              }
              content="Associated"
              position="top center"
            />
          ) : null}
          {data.metadata.vaccine?.name || '-'}
          <Popup
            on="click"
            trigger={<a>More info...</a>}
            content={
              <List>
                <List.Item>
                  <List.Header>NDC</List.Header>
                  {data.metadata.vaccine?.saleNdc || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>Manufacturer</List.Header>
                  {data.metadata.vaccine?.manufacturer || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>Types</List.Header>
                  {data.metadata.vaccine?.types?.map(
                    (t: string, idx: number) => (
                      <Label key={idx} size="tiny" content={t} />
                    )
                  ) || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>Dosage</List.Header>
                  {data.metadata.vaccine?.dosage?.map(
                    (item: DosageType, idx: number) => {
                      return (
                        <p
                          key={idx}
                          style={{ margin: '0' }}
                        >{`${item.dose}${item.unit}`}</p>
                      );
                    }
                  ) || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>Inventory Used</List.Header>
                  {inventoryUsedLabelText() || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>Lot</List.Header>
                  {data.metadata.inventory?.lot || '-'}
                </List.Item>
                <List.Item>
                  <List.Header>Expiration</List.Header>
                  {data.metadata.inventory?.expiration || '_'}
                </List.Item>
              </List>
            }
          />
        </StyledDivCol>
      </Table.Cell>
      <Table.Cell>
        {data?.metadata?.prescriber?.account?.firstName
          ? `${data.metadata.prescriber.account.firstName} ${data.metadata.prescriber.account.lastName} (${data.metadata.prescriber.title})`
          : '-'}
      </Table.Cell>
      <Table.Cell>
        {data?.metadata?.provider?.account?.firstName
          ? `${data.metadata.provider.account.firstName} ${data.metadata.provider.account.lastName} (${data.metadata.provider.title})`
          : '-'}
      </Table.Cell>
      <Table.Cell>
        <Label
          basic={data.metadata.route !== 'SC'}
          size="small"
          color={data.metadata.route === 'SC' ? 'teal' : undefined}
          image
          content={data.metadata.route || '-'}
          detail={
            data.metadata.site
              ? humanizeText(data.metadata.site, {
                  capitalize: 'all',
                  delimiter: '_',
                })
              : '-'
          }
        ></Label>
      </Table.Cell>
      <Table.Cell>
        {vaccinationToReportSubData?.vaccinationToReportSubscription.error ? (
          <Popup
            on="click"
            trigger={<Button size="mini" content="Show Error" />}
            position="top right"
            content={
              vaccinationToReportSubData.vaccinationToReportSubscription.error
                .message
            }
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        <Label basic size="small" content={data?.action} />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          style={{
            backgroundColor: setDropdownBackgroundColor(
              vaccinationToReportSubData?.vaccinationToReportSubscription.status
            ),
            borderColor: setDropdownBorderColor(
              vaccinationToReportSubData?.vaccinationToReportSubscription.status
            ),
            minWidth: '8rem',
          }}
          fluid
          selection
          options={VaccinationsToReportStatusOptions}
          value={
            vaccinationToReportSubData?.vaccinationToReportSubscription.status
          }
          onChange={(e, data) => {
            handleStatusEmrUpdate(data.value?.toString() || '');
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          style={{
            backgroundColor:
              data.iisStatus !== 'pending' ? '#fcfff5' : '#f2f3f3',
            borderColor: data.iisStatus !== 'pending' ? '#558555' : '#6d7878',
            maxWidth: '6.5rem',
          }}
          fluid
          selection
          options={iisStatusOptions}
          value={data.iisStatus}
          onChange={(e, data) => {
            handleIisStatusUpdate(data.value?.toString() || '');
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          trigger={
            <Button
              size="mini"
              color="teal"
              icon="play"
              onClick={() => setShowConfirmRunAction(true)}
              disabled={data.status !== 'error'}
            />
          }
          content="Run Action"
        />
        {renderSendNotificationButton()}
      </Table.Cell>
      <Confirm
        size="mini"
        content="Are you sure you want to run subscription?"
        confirmButton="Yes"
        open={showConfirmRunAction}
        onCancel={() => setShowConfirmRunAction(false)}
        onConfirm={() => {
          handleTriggerAction();
          setShowConfirmRunAction(false);
        }}
      />
    </Table.Row>
  );
};

const StyledDivCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export default VaccinationsToReportRow;
