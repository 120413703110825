import { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  Form,
  Segment,
  Modal,
  Icon,
  Button,
  Popup,
  Label,
} from 'semantic-ui-react';
import { VFCInconsistency } from '@bluefox/models/VFCInconsistency';
import { BorrowingReportCodeMutation } from '@bluefox/graphql/billing';
import { VaccineBorrowingReportReasonCodes } from '@bluefox/constants/vaccineBorrowingReportCodes';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';

interface BorrowingReportCodeModalProps {
  inconsistency: VFCInconsistency;
  state: string;
}

const BorrowingReportCodeModal = ({
  inconsistency,
  state,
}: BorrowingReportCodeModalProps) => {
  const [open, setOpen] = useState(false);
  const [reasonCode, setReasonCode] = useState(
    inconsistency.borrowingReasonCode || ''
  );
  const [showTextArea, setShowTextArea] = useState<boolean>(false);
  const [reasonCodeDescription, setReasonCodeDescription] = useState(
    inconsistency.borrowingReasonCodeOtherDescription || ''
  );

  const options = useMemo(
    () =>
      VaccineBorrowingReportReasonCodes(
        state,
        inconsistency.inventory?.vfc as boolean
      ),
    [state, inconsistency]
  );

  const [updateBorrowingReportCode] = useMutation(BorrowingReportCodeMutation);

  const handleSubmit = async () => {
    try {
      await updateBorrowingReportCode({
        variables: {
          id: inconsistency.id,
          borrowingReasonCode: reasonCode,
          borrowingReasonCodeOtherDescription: showTextArea
            ? reasonCodeDescription
            : null,
        },
      });
      toast({
        title: 'The code has been assigned',
        type: 'success',
        time: 5000,
      });
    } catch (e) {
      toast({
        title: `There was an error assigning the code. Callback error: ${e}`,
        type: 'error',
        time: 5000,
      });
    }
    setOpen(false);
  };

  const handleClose = () => {
    setReasonCodeDescription(
      inconsistency.borrowingReasonCodeOtherDescription || ''
    );
    setReasonCode(inconsistency.borrowingReasonCode || '');
    setShowTextArea(
      isStringNotEmpty(inconsistency.borrowingReasonCodeOtherDescription)
    );
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    if (reasonCode === '' || options?.length === 0) return;
    const option = options?.find(
      (option) => option.value.toString() === reasonCode
    );
    if (option) {
      setShowTextArea(
        option.text.toLowerCase().includes('other') ||
          option.text.toLowerCase().includes('descri')
      );
    }
  };

  return (
    <Modal
      dimmer="blurring"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      trigger={
        <Popup
          content="Edit the borrow reason code"
          trigger={
            <Button as="div" labelPosition="left" onClick={handleOpen}>
              <Label as="a" basic>
                {inconsistency.borrowingReasonCode
                  ? inconsistency.borrowingReasonCode
                  : '-'}
              </Label>
              <Button primary icon>
                <Icon name="edit" />
              </Button>
            </Button>
          }
        />
      }
    >
      <Modal.Content>
        <Segment padded>
          <Form id="borrowing-inconsistency-issue-form" onSubmit={handleSubmit}>
            <Form.Dropdown
              selection
              label="Select the correct borrowing reason code from the chart."
              placeholder="Select a borrowing reason code."
              value={reasonCode}
              onChange={(e, { value }) => {
                const target = e.target as HTMLSpanElement;
                const text = target.textContent?.toLowerCase()!;
                setShowTextArea(
                  text.toLowerCase().includes('other') ||
                    text.toLowerCase().includes('descri')
                );
                return setReasonCode(value as string);
              }}
              options={options}
            />
            {showTextArea && (
              <Form.TextArea
                required
                value={reasonCodeDescription}
                onChange={(e) =>
                  setReasonCodeDescription(e.target?.value.toString())
                }
                label="Describe the reason:"
                placeholder="Describe the reason."
              />
            )}
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={handleClose}>
          Cancel
        </Button>

        <Button
          primary
          onClick={handleSubmit}
          disabled={
            !reasonCode ||
            (showTextArea && !reasonCodeDescription) ||
            reasonCode === inconsistency.borrowingReasonCode
          }
          form="borrowing-report-code-form-submit-button"
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BorrowingReportCodeModal;
