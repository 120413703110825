import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Modal,
  Form,
  Dropdown,
  Popup,
  Segment,
  Card,
  List,
  Checkbox,
  Icon,
  Table,
  Label,
  DropdownProps,
} from 'semantic-ui-react';
import { ContractPlansQuery, InsertContractPlan } from '@graphql/contratPlans';
import {
  ContractPlan,
  ContractPlanDiscount,
  DiscountTypeEnum,
  DiscountReasonEnum,
} from '@bluefox/models/ContractPlan';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { humanizeText } from '@bluefox/lib/humanize';
import { CatalogueVaccinePricingFromInventories } from '@graphql/vaccine';
import { dollarsToCents } from '@bluefox/lib/maths';
import { formatDateToYYYYhMMhDD } from '@bluefox/lib/formatters';
import { PackageTypes } from '@bluefox/models/Vaccine';
import vaccine from '@ui/Vaccines/Vaccine';

interface SelectedVaccine {
  id: string;
  name: string;
  saleNdc: string;
  manufacturer: string;
  packageType: string;
  packageDoses: number;
  inventoryCptCodes: string[];
  vaccinePricings: VaccinePricing[] | [];
  isNew?: boolean;
}

interface SelectedVaccineTest {
  vaccinePricingId: string;
  vaccineId: string;
  name: string;
  ndc: string;
  cptCode: string;
  active: boolean;
  planPrice: number;
  isNew: boolean;
  manufacturer: string;
  packageType: string;
  packageDoses: number;
}

interface VaccinePricing {
  id: string;
  cptCode: string;
  saleNdc: string;
  priceCents: number;
  vaccine: VaccineDetail;
  status: string;
}

interface VaccineDetail {
  id: string;
  name: string;
  manufacturer: string;
  saleNdc: string;
  packageType: string;
  packageDoses: number;
  inventoryCptCodes: string[];
}

interface VaccinePricingFromInventoriesData {
  vaccines: SelectedVaccine[];
}

interface FormValues {
  planType: string;
  planName: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  selectedVaccinesForPlan: any;
  discounts: ContractPlanDiscount[];
}

const initialFormValues = {
  planType: '',
  planName: '',
  startDate: undefined,
  endDate: undefined,
  selectedVaccinesForPlan: {},
  discounts: [],
};

interface DiscountReasonOption {
  key: string;
  text: string;
  value: string;
}

const initialDiscountReasonOptions: DiscountReasonOption[] = [
  {
    key: 'onlinePurchase',
    text: 'Online Purchase',
    value: DiscountReasonEnum.ONLINE_PURCHASE,
  },
  {
    key: 'paymentOnTime',
    text: 'Payment On Time',
    value: DiscountReasonEnum.PAYMENT_ON_TIME,
  },
];

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  contractPlan?: ContractPlan;
};

const ContractPlanForm = ({ open, onClose, onSave, contractPlan }: Props) => {
  const [selectedVaccines, setSelectedVaccines] = useState<{
    [key: string]: SelectedVaccineTest;
  }>({});

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [discountType, setDiscountType] = useState<string>(
    DiscountTypeEnum.AMOUNT
  );
  const [discountReason, setDiscountReason] = useState<string>();
  const [discountAmount, setDiscountAmount] = useState<number>();

  const [discountReasonOptions, setDiscountReasonOptions] = useState<
    DiscountReasonOption[]
  >(initialDiscountReasonOptions);
  const [selectedDiscountReason, setSelectedDiscountReason] = useState<
    string | undefined
  >(undefined);

  const { data: vaccinePricingFromInventoriesData } =
    useQuery<VaccinePricingFromInventoriesData>(
      CatalogueVaccinePricingFromInventories
    );

  const [saveContractPlan] = useMutation(InsertContractPlan);

  const cleanAndClose = () => {
    setFormValues(initialFormValues);
    setDiscountType(DiscountTypeEnum.AMOUNT);
    setDiscountReason(undefined);
    setDiscountAmount(undefined);
    setSelectedVaccines({});
    setSelectedDiscountReason(undefined);
    onClose();
  };

  const handleSubmit = async () => {
    if (!formValues.discounts.length) {
      return toast({
        title: 'Please add at least one discount to save Contract Plan',
        type: 'error',
        time: 5000,
      });
    }

    const formattedStartDate = formValues.startDate
      ? formatDateToYYYYhMMhDD(formValues.startDate)
      : undefined;

    const hasId = formattedStartDate === formValues.startDate;

    const newVaccinePricings = [];

    for (const key in selectedVaccines) {
      const vaccine = selectedVaccines[key];

      if (vaccine.isNew && !vaccine.active) {
        continue;
      }

      newVaccinePricings.push({
        ...(hasId
          ? {
              id: contractPlan?.vaccinePricings.find(
                (vp: any) => vp.vaccine.id === vaccine.vaccineId
              )?.id,
            }
          : {}),
        doses: vaccine.packageDoses,
        cptCode: vaccine.cptCode,
        date: formValues.startDate,
        saleNdc: vaccine.ndc,
        priceCents: dollarsToCents(vaccine.planPrice),
        priceCentsPerDose: dollarsToCents(
          vaccine.planPrice / vaccine.packageDoses
        ),
        price: vaccine.planPrice,
        pricePerDose: vaccine.planPrice / vaccine.packageDoses,
        status: vaccine.active ? 'active' : 'inactive',
        vaccine: {
          data: {
            id: vaccine.vaccineId,
            name: vaccine.name,
            manufacturer: vaccine.manufacturer,
            saleNdc: vaccine.ndc,
            packageType: vaccine.packageType,
            packageDoses: vaccine.packageDoses,
            inventoryCptCodes: [vaccine.cptCode],
          },
          on_conflict: {
            constraint: 'vaccines_pkey',
            update_columns: ['packageType', 'packageDoses'],
          },
        },
      });
    }

    try {
      await saveContractPlan({
        variables: {
          object: {
            id: contractPlan?.id,
            type: formValues.planType,
            name: formValues.planName,
            discounts: formValues.discounts,
            startDate: formValues.startDate,
            vaccinePricings: {
              data: newVaccinePricings,
              on_conflict: {
                constraint:
                  'vaccine_pricing_vaccine_id_contract_plan_id_date_key',
                update_columns: [
                  'price',
                  'priceCents',
                  'pricePerDose',
                  'priceCentsPerDose',
                  'status',
                ],
              },
            },
          },
        },
        refetchQueries: [
          {
            query: ContractPlansQuery,
          },
        ],
      });
      toast({
        title: 'Contract plan saved successfully',
        type: 'success',
        time: 2000,
      });

      cleanAndClose();
      if (onSave) onSave();
    } catch (error: any) {
      if (error.message === "unexpected null value for type 'numeric'") {
        toast({
          title:
            'Please make sure that all selected vaccines have catalogue price and a price set.',
          type: 'error',
          time: 5000,
        });

        return;
      } else {
        toast({
          title: `Callback error: ${error}`,
          type: 'error',
          time: 5000,
        });
      }

      cleanAndClose();
      if (onSave) onSave();
    }
  };

  const handleDiscountReasonChange = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setDiscountReason(data.value as string);
    setSelectedDiscountReason(data.value as string);
  };

  const handleDiscountReasonAddition = (
    e: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    const newOption = {
      key: data.value as string,
      text: data.value as string,
      value: data.value as string,
    };
    setDiscountReasonOptions((prevOptions) => [...prevOptions, newOption]);
    setSelectedDiscountReason(data.value as string);
  };

  const renderDiscounts = useCallback(() => {
    const handleRemoveDiscount = (discount: number) => {
      const updatedDiscounts = formValues.discounts.filter(
        (_, index) => index !== discount
      );
      setFormValues((prev) => ({
        ...prev,
        discounts: updatedDiscounts,
      }));
    };

    return formValues.discounts.map((item, index) => {
      return (
        <Segment
          key={index}
          style={{
            margin: '0.3rem 0',
            padding: '0.5rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            key={index}
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <b
                style={{
                  marginRight: '2.5rem',
                }}
              >
                {item.reason
                  ? humanizeText(item.reason, {
                      capitalize: 'first',
                      delimiter: '_',
                    })
                  : ''}
              </b>
              <p>
                {item.type === 'percentage'
                  ? `${item.discount}%`
                  : `$${item.discount}`}
              </p>
            </div>
            <Popup
              content="Remove discount"
              trigger={
                <Button
                  type="button"
                  size="mini"
                  icon="minus"
                  color="red"
                  onClick={() => handleRemoveDiscount(index)}
                />
              }
            />
          </div>
        </Segment>
      );
    });
  }, [formValues.discounts]);

  const handleSelectedVaccine = (
    vaccine: any,
    active: boolean,
    isNew: boolean
  ) => {
    if (!isNew && selectedVaccines?.[vaccine.id]) {
      setSelectedVaccines((prevState) => ({
        ...prevState,
        [vaccine.id]: {
          ...prevState?.[vaccine.id],
          active: active,
        },
      }));

      return;
    }

    setSelectedVaccines((prevState) => {
      if (prevState?.[vaccine.id]) {
        return {
          ...prevState,
          [vaccine.id]: {
            ...prevState?.[vaccine.id],
            active: active,
          },
        };
      }

      return {
        ...prevState,
        [vaccine.id]: {
          ...prevState?.[vaccine.id],
          vaccinePricingId: vaccine.vaccinePricings[0]
            ? vaccine.vaccinePricings[0].id
            : undefined,
          vaccineId: vaccine.id,
          name: vaccine.name,
          ndc: vaccine.saleNdc,
          cptCode: vaccine.inventoryCptCodes[0],
          planPrice: undefined,
          active: active,
          isNew: isNew,
          manufacturer: vaccine.manufacturer,
          packageType: vaccine.packageType,
          packageDoses: vaccine.packageDoses,
        },
      };
    });
  };

  const handleSelectedVaccinePlanPrice = (
    vaccineId: string,
    price: string | undefined
  ) => {
    setSelectedVaccines((prevState: any) => ({
      ...prevState,
      [vaccineId]: {
        ...prevState?.[vaccineId],
        planPrice: price,
      },
    }));
  };

  useEffect(() => {
    if (!contractPlan) return;

    setFormValues({
      planType: contractPlan.type,
      planName: contractPlan.name,
      startDate: contractPlan.startDate
        ? new Date(`${contractPlan?.startDate}T00:00:00`)
        : undefined,
      endDate: contractPlan.endDate,
      selectedVaccinesForPlan: contractPlan.vaccinePricings,
      discounts: contractPlan.discounts,
    });

    setSelectedVaccines(
      contractPlan.vaccinePricings.reduce((acc: any, vp: any) => {
        if (!acc[vp.vaccine.id]) {
          acc[vp.vaccine.id] = {
            vacinePricingId: vp.id,
            vaccineId: vp.vaccine.id,
            name: vp.vaccine.name,
            ndc: vp.vaccine.saleNdc,
            cptCode: vp.vaccine.inventoryCptCodes[0],
            active: vp.status === 'active' ? true : false,
            planPrice: vp.price,
            isNew: false,
            manufacturer: vp.vaccine.manufacturer,
            packageType: vp.vaccine.packageType,
            packageDoses: vp.vaccine.packageDoses,
          };
        }

        return acc;
      }, {})
    );
  }, [contractPlan, vaccinePricingFromInventoriesData?.vaccines]);

  return (
    <Modal onClose={cleanAndClose} open={open}>
      <ModalHeader>
        {contractPlan?.id ? 'Edit Contract Plan' : 'Create Contract Plan'}
      </ModalHeader>
      <ModalContent>
        <Form widths="equal" id="contract-plan-form" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Field>
              <Form.Input
                label="Plan Type"
                placeholder="Contract Plan Type"
                value={formValues.planType}
                onChange={(_, { value }) =>
                  setFormValues((prev) => ({
                    ...prev,
                    planType: value,
                  }))
                }
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                required
                label="Plan Name"
                placeholder="Contract Plan Name"
                value={formValues.planName}
                onChange={(_, { value }) =>
                  setFormValues((prev) => ({
                    ...prev,
                    planName: value,
                  }))
                }
              />
            </Form.Field>
            <Form.Field required>
              <label>Starting Date</label>
              <DateTimePicker
                onChange={(value) =>
                  setFormValues((prev) => ({
                    ...prev,
                    startDate: value as Date,
                  }))
                }
                selected={formValues.startDate}
                scrollableYearDropdown
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                required
              />
            </Form.Field>
          </Form.Group>
          <Card fluid>
            <Card.Content>
              <Card.Header
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                Discounts
                <Popup
                  content="Add Discount"
                  trigger={
                    <Button
                      type="button"
                      color="teal"
                      icon="plus"
                      onClick={() => {
                        setFormValues((prev) => ({
                          ...prev,
                          discounts: [
                            ...formValues.discounts,
                            {
                              type: discountType as DiscountTypeEnum,
                              reason: discountReason as DiscountReasonEnum,
                              discount: discountAmount as number,
                            },
                          ],
                        }));
                      }}
                      disabled={!discountReason || !discountAmount}
                    />
                  }
                />
              </Card.Header>
              <Card.Description>
                <Form.Group>
                  <Form.Field required>
                    <label>Type</label>
                    <Dropdown
                      placeholder="Discount type"
                      value={discountType}
                      onChange={(_, { value }) =>
                        setDiscountType(value as DiscountTypeEnum)
                      }
                      selection
                      options={[
                        {
                          key: 'percentage',
                          text: 'Percentage',
                          value: DiscountTypeEnum.PERCENTAGE,
                        },
                        {
                          key: 'amount',
                          text: 'Amount',
                          value: DiscountTypeEnum.AMOUNT,
                        },
                      ]}
                    />
                  </Form.Field>
                  <Form.Field required={!contractPlan}>
                    <label>Reason</label>
                    <Dropdown
                      search
                      allowAdditions
                      placeholder="Discount reason"
                      value={selectedDiscountReason}
                      onChange={handleDiscountReasonChange}
                      selection
                      onAddItem={handleDiscountReasonAddition}
                      options={discountReasonOptions}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      onWheel={(e: any) => e.target.blur()}
                      required={!contractPlan}
                      label="Discount"
                      placeholder="Discount"
                      type="number"
                      min="0"
                      step=".01"
                      value={discountAmount}
                      onChange={(_, { value }) =>
                        setDiscountAmount(parseFloat(value))
                      }
                    />
                  </Form.Field>
                </Form.Group>
                {renderDiscounts()}
              </Card.Description>
            </Card.Content>
          </Card>
          <Form.Group>
            <Form.Field>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Vaccine</Table.HeaderCell>
                    <Table.HeaderCell>Catalogue Price</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell width={3}>Plan Price</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {vaccinePricingFromInventoriesData?.vaccines.map((v) => {
                    return (
                      <VaccinesListRow
                        key={v.id}
                        vaccine={v}
                        onSelectedVaccine={handleSelectedVaccine}
                        active={
                          selectedVaccines?.[v.id]
                            ? selectedVaccines[v.id].active
                            : false
                        }
                        vaccinePlanPrice={
                          selectedVaccines?.[v.id]
                            ? selectedVaccines?.[v.id].planPrice
                            : undefined
                        }
                        isNew={
                          selectedVaccines?.[v.id]
                            ? selectedVaccines[v.id].isNew
                            : true
                        }
                        onPlanPriceChange={handleSelectedVaccinePlanPrice}
                      />
                    );
                  })}
                </Table.Body>
              </Table>
            </Form.Field>
          </Form.Group>
        </Form>
      </ModalContent>
      <ModalActions>
        <Button onClick={cleanAndClose}>Cancel</Button>
        <Button
          primary
          content="Save"
          type="submit"
          form="contract-plan-form"
        />
      </ModalActions>
    </Modal>
  );
};

// VACCINES LIST ROW

type VaccinesListRowProps = {
  vaccine: SelectedVaccine;
  onSelectedVaccine: (
    vaccine: SelectedVaccine,
    selected: boolean,
    isNew: boolean
  ) => void;
  active: boolean;
  vaccinePlanPrice: number | undefined;
  isNew: boolean;
  onPlanPriceChange: (vaccineId: string, price: string) => void;
};

const VaccinesListRow = ({
  vaccine,
  onSelectedVaccine,
  active,
  vaccinePlanPrice,
  isNew,
  onPlanPriceChange,
}: VaccinesListRowProps) => {
  return (
    <Table.Row>
      <Table.Cell>
        <div>{vaccine.name}</div>
        <Label basic size="small" content={`NDC: ${vaccine.saleNdc}`} />
        {!vaccine.vaccinePricings.length && (
          <Popup
            trigger={
              <Icon
                name="info circle"
                color="orange"
                style={{ marginLeft: '0.5rem' }}
              />
            }
            content="This vaccine does not have catalogue price"
          />
        )}
      </Table.Cell>
      <Table.Cell>
        {vaccine.vaccinePricings.length > 0 ? (
          vaccine.vaccinePricings.map((pricing) => (
            <List.Description key={pricing.id}>
              {`Catalogue Price: $${(pricing.priceCents / 100).toFixed(2)}`}
            </List.Description>
          ))
        ) : (
          <List.Description>Catalogue Price: N/A</List.Description>
        )}
      </Table.Cell>
      <Table.Cell>
        <Checkbox
          checked={active}
          onChange={(_, { checked }) =>
            onSelectedVaccine(vaccine, !!checked, isNew)
          }
        />
      </Table.Cell>
      <Table.Cell>
        {active ? (
          <Form.Input
            onWheel={(e: any) => e.target.blur()}
            style={{ maxWidth: '8rem' }}
            size="small"
            type="number"
            min="0"
            step=".01"
            placeholder="Plan price"
            value={vaccinePlanPrice || undefined}
            onChange={(_, { value }) => {
              onPlanPriceChange(vaccine.id, value);
            }}
          />
        ) : (
          'N/A'
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default ContractPlanForm;
