import { Practice } from '../Practice';

export interface MappedItem {
  id: string;
  key: string;
  type: string;
  value: {
    data: string;
    name?: string;
    insuranceCompanyId?: string;
  };
  practice?: Practice;
}

export interface DeveloMappingData {
  develoMappings: MappedItem[];
  develoMappingsCount: {
    aggregate: {
      count: number;
    };
  };
}

export enum MappingTypes {
  provider = 'provider',
  insuranceCompany = 'insuranceCompany',
  vfcCriteria = 'vfcCriteria',
  prescriber = 'prescriber',
}

export interface Appointment {
  id: string;
  practiceId: string;
  status: string;
  error: {
    message: string;
  };
  metadata: any;
  practice: Practice;
  createdAt: string;
  mrn: string;
  startDate: Date;
  appointmentStatus: string;
}

export const appointmentsStatusOptions = [
  {
    key: 'pending',
    text: 'Pending',
    value: 'pending',
  },
  {
    key: 'error',
    text: 'Error',
    value: 'error',
  },
  {
    key: 'completed',
    text: 'Completed',
    value: 'completed',
  },
];

// >>>>>>>> Vaccinations to report

export interface VaccinationsToReportInterface {
  id: string;
  error: any;
  metadata: any;
  response: any;
  status: string;
  iisStatus: string;
  practiceId: string;
  practicePatientId: string;
  action: string;
  practice: {
    name: string;
    timezone: string;
  };
  practicePatient: {
    mrn: string;
    patientData: {
      lastName: string;
      firstName: string;
    };
  };
  vaccinationId: string;
}

export const VaccinationsToReportStatusOptions = [
  {
    key: 'pending',
    text: 'Pending',
    value: 'pending',
  },
  {
    key: 'error',
    text: 'Error',
    value: 'error',
  },
  {
    key: 'completed',
    text: 'Completed',
    value: 'completed',
  },
  {
    key: 'finished',
    text: 'Finished',
    value: 'finished',
  },
];

export const iisStatusOptions = [
  {
    key: 'pending',
    text: 'Pending',
    value: 'pending',
  },
  {
    key: 'auto',
    text: 'Auto',
    value: 'auto',
  },
  {
    key: 'canid',
    text: 'Canid',
    value: 'canid',
  },
  {
    key: 'practice',
    text: 'Practice',
    value: 'practice',
  },
];

export const subscriptionStatusOptions = [
  {
    key: 'pending',
    text: 'Pending',
    value: 'pending',
  },
  {
    key: 'error',
    text: 'Error',
    value: 'error',
  },
  {
    key: 'completed',
    text: 'Completed',
    value: 'completed',
  },
];
