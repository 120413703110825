import { Button, Card, Popup } from 'semantic-ui-react';
import { BalanceMovements } from '@bluefox/models/BalanceMovements';

type TableActionButtonsProps = {
  showPfdDisabled: boolean;
  onClickShowInvoice: () => void;
  balanceMovement: BalanceMovements;
  onClickMoreInfo: () => void;
  isPracticePortal?: boolean;
};
export const TableActionButtons = (props: TableActionButtonsProps) => {
  const {
    showPfdDisabled,
    onClickShowInvoice,
    balanceMovement,
    onClickMoreInfo,
    isPracticePortal = false,
  } = props;

  return (
    <>
      <Popup
        content="Show Invoice"
        size="tiny"
        trigger={
          <Button
            size="mini"
            disabled={showPfdDisabled}
            onClick={() => {
              onClickShowInvoice?.();
            }}
            icon="file pdf outline"
          />
        }
      />

      {!isPracticePortal && (
        <Popup
          on={'click'}
          content={
            <Card>
              <Card.Content>{balanceMovement.comment}</Card.Content>
            </Card>
          }
          size="tiny"
          trigger={
            <Button
              size="mini"
              disabled={
                !balanceMovement.comment || balanceMovement.comment === ''
              }
              icon="comment"
            />
          }
        />
      )}

      <Popup
        on={'hover'}
        content={'More Info'}
        size="tiny"
        trigger={
          <Button
            size="mini"
            disabled={showPfdDisabled}
            icon="info"
            onClick={() => onClickMoreInfo()}
          />
        }
      />
    </>
  );
};
