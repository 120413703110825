import { useMemo } from 'react';
import {
  BillingNotificationFirstOptionsEnum,
  BillingNotificationFirstOptionsEnumToText,
} from '@bluefox/constants/notifications';
import {
  BillingNotificationContent,
  Notification,
} from '@bluefox/models/Notification';
import { renderGivenAt } from '../common';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';

const renderFirstOptionMessage = (content: BillingNotificationContent) => {
  switch (content.firstOption) {
    case BillingNotificationFirstOptionsEnum.CPT_CODE_REJECTION:
      return BillingNotificationFirstOptionsEnumToText[
        content.firstOption
      ].replace('[CPT Code]', content.selectedCptCodes?.join(', ') || ' - ');
    case BillingNotificationFirstOptionsEnum.PATIENT_WITH_NO_ACTIVE_POLICY:
    case BillingNotificationFirstOptionsEnum.INSURANCE_REQUIERES_TO_CONTACT_THEM:
    case BillingNotificationFirstOptionsEnum.INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY:
      return BillingNotificationFirstOptionsEnumToText[content.firstOption];
    case BillingNotificationFirstOptionsEnum.FREE_TEXT:
      return content.freeText;

    case BillingNotificationFirstOptionsEnum.THE_PROCEDURE_CODE_IS_INCONSISTENT_PATIENT_AGE:
      return BillingNotificationFirstOptionsEnumToText[
        content.firstOption
      ].replace('[CPT Code]', content.selectedCptCodes?.join(', ') || ' - ');

    case BillingNotificationFirstOptionsEnum.COORDINATION_OF_BENEFITS:
    case BillingNotificationFirstOptionsEnum.NO_ENROLLMENT_PATIENT_OVER_19:
    case BillingNotificationFirstOptionsEnum.MEDICAL_NECESSITY:
    case BillingNotificationFirstOptionsEnum.ADULT_PATIENT_WITHOUT_INSURANCE:
    case BillingNotificationFirstOptionsEnum.DUPLICATE_CLAIM:
      return BillingNotificationFirstOptionsEnumToText[content.firstOption];

    default:
      return null;
  }
};

export function renderBillingMessageCompleteAndSignDoc(
  docUrl: string,
  sendEmail: string
) {
  return (
    <>
      Please complete and sign the{' '}
      <a href={docUrl} target="_blank" rel="noopener noreferrer">
        document
      </a>{' '}
      and send to the email <a href={`mailto:${sendEmail}`}>{sendEmail}</a>
    </>
  );
}

export function renderBillingMessageMultipleCompleteAndSignDoc(
  firstOptionSelection?: BillingNotificationFirstOptionsEnum
) {
  switch (firstOptionSelection) {
    case BillingNotificationFirstOptionsEnum.INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY:
      return renderBillingMessageCompleteAndSignDoc(
        'https://docs.google.com/document/d/1Fgc6c5mFdPvDQxyEq8zz-KseNx6S46l9futGHPGLYYs/edit',
        'billing@canid.io'
      );
    case BillingNotificationFirstOptionsEnum.THE_PROCEDURE_CODE_IS_INCONSISTENT_PATIENT_AGE:
      return renderBillingMessageCompleteAndSignDoc(
        'https://docs.google.com/document/d/1Fgc6c5mFdPvDQxyEq8zz-KseNx6S46l9futGHPGLYYs/edit',
        'billing@canid.io'
      );
    case BillingNotificationFirstOptionsEnum.MEDICAL_NECESSITY:
      return renderBillingMessageCompleteAndSignDoc(
        'https://docs.google.com/document/d/1Fgc6c5mFdPvDQxyEq8zz-KseNx6S46l9futGHPGLYYs/edit',
        'billing@canid.io'
      );
    default:
      return null;
  }
}

type Props = {
  notification: Notification;
  practiceTimezone?: string;
};

const BillingNotificationMessage = ({
  notification,
  practiceTimezone,
}: Props) => {
  const { content, currentTimezone } = useMemo(
    () => ({
      content: notification.content as BillingNotificationContent,
      currentTimezone: practiceTimezone
        ? practiceTimezone
        : notification?.practice?.timezone,
    }),
    [notification, practiceTimezone]
  );

  return (
    <>
      <p>
        For the patient{' '}
        <b>
          {`${content?.fullPatienName}, MRN ${
            isStringNotEmpty(content?.mrn) ? content?.mrn : '-'
          }`}
        </b>
        {`, DOS ${renderGivenAt(
          content?.givenAt
          /* Billing givenAt already has the practice timezone applied */
          // currentTimezone
        )}, ${renderFirstOptionMessage(content)}. `}
        {content.firstOption ===
          BillingNotificationFirstOptionsEnum.PATIENT_WITH_NO_ACTIVE_POLICY && (
          <>
            Please confirm if the patient have an active policy and update the
            patient insurance information.
          </>
        )}
        {renderBillingMessageMultipleCompleteAndSignDoc(content?.firstOption)}
      </p>
      {notification.note && (
        <p>
          <b>Rejected message:</b> {notification.note}
        </p>
      )}
    </>
  );
};

export default BillingNotificationMessage;
