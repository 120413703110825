import React from 'react';
import {
  Ticket,
  TicketTypes,
  TicketStatusEnum,
  CallUrgency,
} from '@bluefox/models/Tickets';
import {
  formatDateToMMDDYYYYV2,
  formatToMMDDYYYY_HHMM_V2,
} from '@bluefox/lib/formatters';
import { Button, Icon, Table, Popup } from 'semantic-ui-react';
import TableHeaderRow from './TableHeaderRow';
import { humanizeText } from '@bluefox/lib/humanize';
import { InventoryType } from '@bluefox/models/models';
import { Source } from '@bluefox/ui/Chat/types';
import TicketChat from '@bluefox/ui/Chat/TicketChat';
import ResolutionModal from './ResolutionModal';
import { trimLongString } from '@bluefox/lib/trimLongString';
import { Link } from 'react-router-dom';
import { usePractice } from '@bluefox/contexts';

type TableBodyRowProps = {
  ticketType: string;
  ticket: Ticket;
  includeChat: boolean;
  source: Source;
  isEmbedded: boolean;
  status?: string[];
  timeZone?: string;
};

const TableBodyRow = ({
  ticketType,
  ticket,
  includeChat,
  source,
  isEmbedded,
  status = [],
  timeZone,
}: TableBodyRowProps) => {
  const { handler } = usePractice();
  const createdAt =
    ticket.createdAt && formatDateToMMDDYYYYV2(ticket.createdAt);
  const reportedBy = ticket.practiceAccount
    ? `${ticket.practiceAccount?.account.lastName}, ${ticket.practiceAccount?.account.firstName} (${ticket.practiceAccount?.account.email})`
    : ticket.detail.fields.find((t) => t.title === 'Email')?.detail || '-';

  const ticketStatus = (status: string) => {
    switch (status) {
      case TicketStatusEnum.OPEN:
        return humanizeText(status, {
          capitalize: 'first',
        });
      case TicketStatusEnum.CLOSED:
        return humanizeText(status, {
          capitalize: 'first',
        });
      case TicketStatusEnum.DONE:
        return humanizeText(TicketStatusEnum.CLOSED, {
          capitalize: 'first',
        });
      case TicketStatusEnum.IN_PROGRESS:
        return 'Under Review';
      default:
        return '';
    }
  };

  switch (ticketType) {
    case TicketTypes.BUG_REPORT:
      return (
        <Table.Row key={ticket.id}>
          {!isEmbedded && <Table.Cell>{createdAt || '-'}</Table.Cell>}
          <Table.Cell>
            {ticket.detail.fields.find((t) => t.title === 'Description')
              ?.detail ||
              ticket.detail.fields.find((t) => t.title === 'Error Description')
                ?.detail ||
              ticket.detail.fields.find(
                (t) => t.title === 'Error Short Description'
              )?.detail ||
              '-'}
          </Table.Cell>
          {!isEmbedded && <Table.Cell>{reportedBy}</Table.Cell>}
          {includeChat && (
            <Table.Cell>
              <Button.Group>
                <TicketChat
                  ticket={ticket}
                  componentSource={source}
                  chatHeader={
                    <Table fluid>
                      <Table.Header>
                        <TableHeaderRow
                          ticketType={ticketType}
                          includeChat={false}
                          isEmbedded={isEmbedded}
                        />
                      </Table.Header>
                      <Table.Body>
                        <TableBodyRow
                          ticketType={ticketType}
                          ticket={ticket}
                          includeChat={false}
                          source={source}
                          isEmbedded={isEmbedded}
                        />
                      </Table.Body>
                    </Table>
                  }
                />
                {status.includes(TicketStatusEnum.CLOSED) &&
                  ticket.detail.closeTicketResolution && (
                    <ResolutionModal
                      ticket={ticket}
                      source={source}
                      ticketType={ticketType}
                      status={status}
                    />
                  )}
                {isEmbedded && (
                  <Popup
                    content="See complete info on web app"
                    trigger={
                      <Button
                        icon="external share"
                        style={{ margin: 0 }}
                        as={Link}
                        to={`/${handler}/tickets/${ticketType}/${ticket.id}`}
                        target="blank"
                      />
                    }
                  />
                )}
              </Button.Group>
              <p style={{ margin: '0.25rem 0' }}>
                <b>Status:</b> {ticketStatus(ticket.status)}
              </p>
              {ticket.detail.showNewMessageTo === source && (
                <p style={{ margin: '0.25rem 0' }}>
                  <Icon name="flag" color="red" /> New comment
                </p>
              )}
            </Table.Cell>
          )}
        </Table.Row>
      );
    case TicketTypes.FEATURE_REQUEST:
      const purpose =
        ticket.detail.fields.find((t) => t.title === 'Feature Purpose')
          ?.detail || '-';
      const embeddedPurpose =
        purpose.length > 50 && isEmbedded
          ? trimLongString(purpose, 50)
          : purpose;
      const description =
        ticket.detail.fields.find((t) => t.title === 'Feature Description')
          ?.detail || '-';
      const formattedDescription =
        description.length > 50 && isEmbedded
          ? trimLongString(description, 50)
          : description;
      return (
        <Table.Row key={ticket.id}>
          {!isEmbedded && <Table.Cell>{createdAt || '-'}</Table.Cell>}
          <Table.Cell>{embeddedPurpose}</Table.Cell>
          <Table.Cell>{formattedDescription}</Table.Cell>
          {!isEmbedded && <Table.Cell>{reportedBy}</Table.Cell>}
          {includeChat && (
            <Table.Cell>
              <Button.Group>
                <TicketChat
                  ticket={ticket}
                  componentSource={source}
                  chatHeader={
                    <Table fluid>
                      <Table.Header>
                        <TableHeaderRow
                          ticketType={ticketType}
                          includeChat={false}
                          isEmbedded={isEmbedded}
                        />
                      </Table.Header>
                      <Table.Body>
                        <TableBodyRow
                          ticketType={ticketType}
                          ticket={ticket}
                          includeChat={false}
                          source={source}
                          isEmbedded={isEmbedded}
                        />
                      </Table.Body>
                    </Table>
                  }
                />
                {status.includes(TicketStatusEnum.CLOSED) &&
                  ticket.detail.closeTicketResolution && (
                    <ResolutionModal
                      ticket={ticket}
                      source={source}
                      ticketType={ticketType}
                      status={status}
                    />
                  )}
                {isEmbedded && (
                  <Popup
                    content="See complete info on web app"
                    trigger={
                      <Button
                        icon="external share"
                        style={{ margin: 0 }}
                        as={Link}
                        to={`/${handler}/tickets/${ticketType}/${ticket.id}`}
                        target="blank"
                      />
                    }
                  />
                )}
              </Button.Group>
              <p style={{ margin: '0.25rem 0' }}>
                <b>Status:</b> {ticketStatus(ticket.status)}
              </p>
              {ticket.detail.showNewMessageTo === source && (
                <p style={{ margin: '0.25rem 0' }}>
                  <Icon name="flag" color="red" /> New comment
                </p>
              )}
            </Table.Cell>
          )}
        </Table.Row>
      );
    case TicketTypes.WASTED_DOSES_REPORT:
      const dateOfWastage = ticket.detail.fields.find(
        (t) => t.title === 'Date of Wastage'
      )?.detail;
      const formattedDateOfWastage =
        dateOfWastage && formatDateToMMDDYYYYV2(dateOfWastage);
      return (
        <Table.Row key={ticket.id}>
          {!isEmbedded && <Table.Cell>{createdAt || '-'}</Table.Cell>}
          <Table.Cell>
            {ticket.detail.fields.find((t) => t.title === 'Vaccine Type')
              ?.detail || '-'}
          </Table.Cell>
          <Table.Cell>
            {ticket.detail.fields.find((t) => t.title === 'Lot Number')
              ?.detail || '-'}
          </Table.Cell>
          <Table.Cell>
            {ticket.detail.fields.find((t) => t.title === 'Inventory Type')
              ?.detail === InventoryType.VFC.toLowerCase()
              ? InventoryType.VFC
              : InventoryType.Private || '-'}
          </Table.Cell>
          <Table.Cell>{formattedDateOfWastage || '-'}</Table.Cell>
          <Table.Cell>
            {ticket.detail.fields.find(
              (t) => t.title === 'Number of Wasted Doses'
            )?.detail || '-'}
          </Table.Cell>
          {!isEmbedded && <Table.Cell>{reportedBy}</Table.Cell>}
          {includeChat && (
            <Table.Cell>
              <Button.Group>
                <TicketChat
                  ticket={ticket}
                  componentSource={source}
                  chatHeader={
                    <Table fluid>
                      <Table.Header>
                        <TableHeaderRow
                          ticketType={ticketType}
                          includeChat={false}
                          isEmbedded={isEmbedded}
                        />
                      </Table.Header>
                      <Table.Body>
                        <TableBodyRow
                          ticketType={ticketType}
                          ticket={ticket}
                          includeChat={false}
                          source={source}
                          isEmbedded={isEmbedded}
                        />
                      </Table.Body>
                    </Table>
                  }
                />
                {isEmbedded && (
                  <Popup
                    content="See complete info on web app"
                    trigger={
                      <Button
                        size="small"
                        icon="external share"
                        style={{ margin: 0 }}
                        as={Link}
                        to={`/${handler}/tickets/${ticketType}/${ticket.id}`}
                        target="blank"
                      />
                    }
                  />
                )}
              </Button.Group>
              <p style={{ margin: '0.25rem 0' }}>
                <b>Status:</b> {ticketStatus(ticket.status)}
              </p>
              {ticket.detail.showNewMessageTo === source && (
                <p style={{ margin: '0.25rem 0' }}>
                  <Icon name="flag" color="red" /> New comment
                </p>
              )}
            </Table.Cell>
          )}
        </Table.Row>
      );
    case TicketTypes.INVENTORY_RECEIPT_ISSUES:
      const issueDate = ticket.detail.fields.find(
        (t) => t.title === 'Issue Date'
      )?.detail;
      const formattedIssueDate = issueDate
        ? formatDateToMMDDYYYYV2(issueDate)
        : undefined;
      const issue = ticket.detail.fields.find((t) => t.title === 'Issue ')
        ?.detail;
      const formattedIssue = issue
        ? humanizeText(issue, {
            capitalize: 'all',
            delimiter: '_',
          })
        : undefined;
      return (
        <Table.Row key={ticket.id}>
          {!isEmbedded && <Table.Cell>{createdAt || '-'}</Table.Cell>}
          <Table.Cell>
            {ticket.detail.fields.find((t) => t.title === 'Vaccine Type')
              ?.detail || '-'}
          </Table.Cell>
          <Table.Cell>
            {ticket.detail.fields.find((t) => t.title === 'Lot Number')
              ?.detail || '-'}
          </Table.Cell>
          <Table.Cell>{formattedIssue || '-'}</Table.Cell>
          <Table.Cell>{formattedIssueDate || '-'}</Table.Cell>
          <Table.Cell>
            {ticket.detail.fields.find(
              (t) => t.title === 'Number of Expected Doses'
            )?.detail || '-'}
          </Table.Cell>
          <Table.Cell>
            {ticket.detail.fields.find(
              (t) => t.title === 'Number of Broken Doses'
            )?.detail || '-'}
          </Table.Cell>
          {!isEmbedded && <Table.Cell>{reportedBy}</Table.Cell>}
          {includeChat && (
            <Table.Cell>
              <Button.Group>
                <TicketChat
                  ticket={ticket}
                  componentSource={source}
                  chatHeader={
                    <Table fluid>
                      <Table.Header>
                        <TableHeaderRow
                          ticketType={ticketType}
                          includeChat={false}
                          isEmbedded={isEmbedded}
                        />
                      </Table.Header>
                      <Table.Body>
                        <TableBodyRow
                          ticketType={ticketType}
                          ticket={ticket}
                          includeChat={false}
                          source={source}
                          isEmbedded={isEmbedded}
                        />
                      </Table.Body>
                    </Table>
                  }
                />
                {isEmbedded && (
                  <Popup
                    content="See complete info on web app"
                    trigger={
                      <Button
                        size="small"
                        icon="external share"
                        style={{ margin: 0 }}
                        as={Link}
                        to={`/${handler}/tickets/${ticketType}/${ticket.id}`}
                        target="blank"
                      />
                    }
                  />
                )}
              </Button.Group>
              <p style={{ margin: '0.25rem 0' }}>
                <b>Status:</b> {ticketStatus(ticket.status)}
              </p>
              {ticket.detail.showNewMessageTo === source && (
                <p style={{ margin: '0.25rem 0' }}>
                  <Icon name="flag" color="red" /> New comment
                </p>
              )}
            </Table.Cell>
          )}
        </Table.Row>
      );
    case TicketTypes.CALL_REQUEST:
      const callDate = ticket.detail.fields.find(
        (t) => t.title === 'Date/Time Client Wants To Be Called'
      )?.detail;
      const formattedCallDate =
        callDate !== CallUrgency.ASAP
          ? formatToMMDDYYYY_HHMM_V2(callDate, timeZone)
          : callDate;
      return (
        <Table.Row key={ticket.id}>
          {!isEmbedded && <Table.Cell>{createdAt || '-'}</Table.Cell>}
          <Table.Cell>
            {ticket.detail.fields.find((t) => t.title === 'Area Requested')
              ?.detail || '-'}
          </Table.Cell>
          <Table.Cell>
            {ticket.detail.fields.find((t) => t.title === 'Call Purpose')
              ?.detail || '-'}
          </Table.Cell>
          <Table.Cell>{formattedCallDate || '-'}</Table.Cell>
          <Table.Cell>
            {ticket.detail.fields.find((t) => t.title === 'Requested By')
              ?.detail || '-'}
          </Table.Cell>
          <Table.Cell>
            {ticket.detail.fields.find((t) => t.title === 'Call To')?.detail ||
              '-'}
          </Table.Cell>
          {!isEmbedded && <Table.Cell>{reportedBy}</Table.Cell>}
          {includeChat && (
            <Table.Cell>
              <Button.Group>
                <TicketChat
                  ticket={ticket}
                  componentSource={source}
                  chatHeader={
                    <Table fluid>
                      <Table.Header>
                        <TableHeaderRow
                          ticketType={ticketType}
                          includeChat={false}
                          isEmbedded={isEmbedded}
                        />
                      </Table.Header>
                      <Table.Body>
                        <TableBodyRow
                          ticketType={ticketType}
                          ticket={ticket}
                          includeChat={false}
                          source={source}
                          isEmbedded={isEmbedded}
                        />
                      </Table.Body>
                    </Table>
                  }
                />
                {isEmbedded && (
                  <Popup
                    content="See complete info on web app"
                    trigger={
                      <Button
                        size="small"
                        icon="external share"
                        style={{ margin: 0 }}
                        as={Link}
                        to={`/${handler}/tickets/${ticketType}/${ticket.id}`}
                        target="blank"
                      />
                    }
                  />
                )}
              </Button.Group>
              <p style={{ margin: '0.25rem 0' }}>
                <b>Status:</b> {ticketStatus(ticket.status)}
              </p>
              {ticket.detail.showNewMessageTo === source && (
                <p style={{ margin: '0.25rem 0' }}>
                  <Icon name="flag" color="red" /> New comment
                </p>
              )}
            </Table.Cell>
          )}
        </Table.Row>
      );
    default:
      return null;
  }
};

export default TableBodyRow;
