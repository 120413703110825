import { Address } from './Address';
import { Insurance } from './Insurances';
import { Practice } from './Practice';
import { Vaccination } from './Vaccination';
import { Guardian, GuardianPatient } from './Guardian';
import { CountAggregation } from './Aggregations';

export enum Sex {
  'Male' = 'Male',
  'Female' = 'Female',
  'Unknown' = 'Unknown',
}

interface PatientPractice {
  mrn: string;
  practice: {
    name: string;
  };
  insurances: Insurance[];
}

export interface Patient {
  id: string;
  fullName: string;
  birthdate: Date;
  fromExtension: boolean;
  hasBasicInfo: boolean;
  completedAt?: Date;
  practices: PatientPractice[];
}

export interface PatientsData {
  patients: PracticePatient[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

export interface PatientData {
  practicePatientId: string;
  id: string;
  preferredName?: string;
  fullName?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  sex?: Sex;
  raceAndCreed: string;
  ethnicity: string;
  birthdate: Date;
  email: string;
  race: string;
  phone: string;
  address: Address;
  fromExtension: boolean;
  guardians?: GuardianPatient[];
  practices?: Practice[];
}

export type Race =
  | 'American Indian or Alaska Native'
  | 'Asian'
  | 'Black or African American'
  | 'Native Hawaiian or other pacific Islander'
  | 'White'
  | 'Other Race'
  | 'Prefer not to answer'
  | 'unknown';

export interface PatientNotes {
  topic: string;
  note: string;
}

export enum PatientNotesTopics {
  PATIENT_INFO = 'patient_info',
  INSURANCE_COMPANY = 'insurance_company',
  MEMBER_ID = 'member_id',
  PRACTICE_ACCOUNT_NUMBER = 'patient_account_number',
  CLAIM_ISSUES = 'claims_issues',
  PROVIDER = 'provider',
}

export const noteTopicOptions = [
  {
    key: 'patientInfo',
    text: 'Patient info',
    value: PatientNotesTopics.PATIENT_INFO,
  },
  {
    key: 'insuranceCompany',
    text: 'Insurance company',
    value: PatientNotesTopics.INSURANCE_COMPANY,
  },
  {
    key: 'memberId',
    text: 'Member Id',
    value: PatientNotesTopics.MEMBER_ID,
  },
  {
    key: 'patientAccountNumber',
    text: 'Patient account number',
    value: PatientNotesTopics.PRACTICE_ACCOUNT_NUMBER,
  },
  {
    key: 'claimsIssues',
    text: 'Claims issues',
    value: PatientNotesTopics.CLAIM_ISSUES,
  },
  {
    key: 'provider',
    text: 'Provider',
    value: PatientNotesTopics.PROVIDER,
  },
];

export interface PracticePatient {
  id: string;
  practice: Practice;
  mrn?: string;
  patientData: PatientData;
  insurances: Insurance[];
  active?: boolean;
  immunizationSyncedAt?: Date;
  issPatientId?: string;
  consentForIis?: boolean;
  practiceId?: string;
  createdAt?: Date;
  isVfcConfirmed?: boolean;
  vaccinations?: Vaccination[];
  todaysVaccinations?: Vaccination[];
  notes: PatientNotes[];
  patientId: string;
  isSelfPay?: boolean;
  claimsCount?: CountAggregation;
}

export const raceOptions = [
  {
    key: 'americanIndianOrAlaskaNative',
    text: 'American Indian or Alaska Native',
    value: 'American Indian or Alaska Native',
  },
  {
    key: 'asian',
    text: 'Asian',
    value: 'Asian',
  },
  {
    key: 'blackOrAfricanAmerican',
    text: 'Black or African American',
    value: 'Black or African American',
  },
  {
    key: 'nativeHawaiianOrOtherPacificIslander',
    text: 'Native Hawaiian or other pacific Islander',
    value: 'Native Hawaiian or other pacific Islander',
  },
  {
    key: 'white',
    text: 'White',
    value: 'White',
  },
  {
    key: 'otherRace',
    text: 'Other Race',
    value: 'Other Race',
  },
  {
    key: 'preferNotToAnswer',
    text: 'Prefer not to answer',
    value: 'Prefer not to answer',
  },
  {
    key: 'unknown',
    text: 'Unknown',
    value: 'unknown',
  },
];

export const ethnicityOptions = [
  {
    key: 'notHispanicOrLatino',
    text: 'Not hispanic or Latino',
    value: 'Not hispanic or Latino',
  },
  {
    key: 'hispanicOrLatino',
    text: 'Hispanic or Latino',
    value: 'Hispanic or Latino',
  },
  {
    key: 'unknown',
    text: 'Unknown',
    value: 'Unknown',
  },
  {
    key: 'preferNotToAnswer',
    text: 'Prefer Not to Answer',
    value: 'Prefer Not to Answer',
  },
];
