import { Grid, Table } from 'semantic-ui-react';
import InsurancesCompaniesPlansTableRow from '@screens/insurancesCompaniesPlans/insurancesCompaniesPlansTableRow';
import { useLazyQuery } from '@apollo/client';
import { GetInsurancesCompaniesPlans } from '@graphql/insuranceCompaniesPlans';
import {
  InsuranceCompaniesPlanFilter,
  InsuranceCompanyPlan,
  InsuranceCompanyPlanInput,
} from '@bluefox/models/insuranceCompanyPlan';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Pagination from '@bluefox/ui/Pagination';
import RowsPerPage from '@ui/Filters/RowsPerPage';

type Props = {
  setSelectedPlan: Dispatch<
    SetStateAction<InsuranceCompanyPlanInput | undefined>
  >;
  filters?: InsuranceCompaniesPlanFilter;
};

const InsurancesCompaniesPlansTable = ({ setSelectedPlan, filters }: Props) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState(0);

  const [getInsurancesPlans, { data: getInsurancesPlansResponse }] =
    useLazyQuery<{
      insurancesPlans: InsuranceCompanyPlan[];
      totalInsurancesPlans: {
        aggregate: { count: number };
      };
    }>(GetInsurancesCompaniesPlans);

  const total =
    getInsurancesPlansResponse?.totalInsurancesPlans.aggregate.count || 0;
  const totalPages = rowsPerPage === 0 ? 0 : Math.ceil(total / rowsPerPage);

  const buildFilters = () => {
    return {
      where: {
        insuranceCompanyId: filters?.insuranceCompanyId
          ? { _eq: filters.insuranceCompanyId }
          : undefined,
        name: filters?.name ? { _ilike: `%${filters.name}%` } : undefined,
        isVfc:
          filters?.isVfc !== undefined ? { _eq: filters.isVfc } : undefined,
      },
      limit: rowsPerPage === 0 ? 100 : rowsPerPage,
      offset: !!page ? rowsPerPage * (page - 1) : 0,
    };
  };

  const runQuery = () => {
    const queryParams = buildFilters();
    getInsurancesPlans({
      variables: queryParams,
    });
  };

  useEffect(runQuery, [filters, rowsPerPage, page]);

  return (
    <>
      <Grid centered>
        <Grid.Column floated="left" width={10}>
          <Pagination
            total={total}
            colSpan={5}
            position="right"
            activePage={page}
            totalPages={totalPages}
            onPageChange={(newActivePage) => setPage(newActivePage)}
          />
        </Grid.Column>
        <Grid.Column floated="right" width={5}>
          <RowsPerPage
            rows={rowsPerPage}
            setRows={setRowsPerPage}
            localStorageItem="BalanceMovementRowConfig"
          />
        </Grid.Column>
      </Grid>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Plan Name</Table.HeaderCell>
            <Table.HeaderCell>Eligibility</Table.HeaderCell>
            <Table.HeaderCell width={5}>Notes</Table.HeaderCell>
            <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {getInsurancesPlansResponse?.insurancesPlans?.map((plan) => (
            <InsurancesCompaniesPlansTableRow
              plan={plan}
              key={plan.id}
              onEditButtonClick={() => {
                // @ts-ignore
                const { insuranceCompany, __typename, ...selectedPlan } = plan;
                setSelectedPlan(selectedPlan);
              }}
            />
          ))}
        </Table.Body>
      </Table>
      <Grid centered>
        <Grid.Column floated="left" width={10}>
          <Pagination
            total={total}
            colSpan={5}
            position="right"
            activePage={page}
            totalPages={totalPages}
            onPageChange={(newActivePage) => setPage(newActivePage)}
          />
        </Grid.Column>
        <Grid.Column floated="right" width={5}>
          <RowsPerPage
            rows={rowsPerPage}
            setRows={setRowsPerPage}
            localStorageItem="BalanceMovementRowConfig"
          />
        </Grid.Column>
      </Grid>
    </>
  );
};

export default InsurancesCompaniesPlansTable;
