import React, { useState } from 'react';
import { Button, Grid, DropdownItemProps, Form } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';

import {
  AdjustmentReason,
  AdjustmentReasons,
  InventoryAdjustmentData,
} from '@bluefox/models/InventoryAdjustment';

interface InventoryAdjustmentActionsProps {
  onCancelAdjustment: () => void;
  makeAdjustment: (inventory: InventoryAdjustmentData) => void;
  inventory: InventoryAdjustmentData;
  adjustmentReasons?: AdjustmentReason[];
}

const InventoryAdjustmentActions = (props: InventoryAdjustmentActionsProps) => {
  const { onCancelAdjustment, makeAdjustment, adjustmentReasons, inventory } =
    props;

  // States
  const [selectedReason, setSelectedReason] = useState<
    AdjustmentReasons | undefined
  >(inventory.adjustmentReason);
  const [comment, setComment] = useState<string | undefined>(inventory.comment);
  const [newDoses, setNewDoses] = useState<number | undefined>(
    inventory.newDoses
  );
  // States

  // Queries & Mutations

  // Queries & Mutations

  //Logic

  const onClickConfirmButton = (inventory: InventoryAdjustmentData) => {
    if (newDoses === undefined || !selectedReason) {
      toast({
        title: 'Please set a new doses amount and select a Reason',
        type: 'error',
        time: 5000,
      });
      return;
    }
    inventory.newDoses = newDoses;
    inventory.adjustmentReason = selectedReason;
    inventory.wasAdjusted = true;
    inventory.newAdjustmentDate = new Date();
    inventory.comment = comment;
    makeAdjustment(inventory);
  };
  //Logic

  //useEffects

  //useEffects

  return (
    <Grid>
      <Grid.Column width={14}>
        <Form>
          <Form.Group>
            <Form.Input
              value={newDoses}
              onChange={(event, data) => {
                if (data.value === '') {
                  setNewDoses(undefined);
                  return;
                }
                setNewDoses(Number(data.value));
              }}
              onKeyPress={(event: React.KeyboardEvent) => {
                if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              width={4}
              fluid
              label="Current Doses: "
              placeholder="Current Doses in the Fridge"
            />
            <Form.Select
              placeholder="Select a Reason"
              width={12}
              label="Reason: "
              value={selectedReason}
              options={
                adjustmentReasons?.filter(
                  (data) => data.value !== AdjustmentReasons.EXPIRED
                ) as DropdownItemProps[]
              }
              onChange={(event, data) => {
                setSelectedReason(data.value as AdjustmentReasons);
              }}
              labeled
              fluid
            />
          </Form.Group>
          <Form.Group>
            <Form.TextArea
              fluid
              value={comment}
              label="Comment: "
              width={16}
              onChange={(event, data) => {
                setComment(data.value as string);
              }}
            />
          </Form.Group>
        </Form>
      </Grid.Column>

      <Grid.Column
        width={2}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <>
          <Button
            primary
            onClick={(event) => {
              event.preventDefault();
              onClickConfirmButton(inventory);
            }}
            content="Confirm"
          />
          <br />

          <Button
            negative
            onClick={(event) => {
              event.preventDefault();
              onCancelAdjustment();
            }}
            content="Close"
          />
        </>
      </Grid.Column>
    </Grid>
  );
};

export default InventoryAdjustmentActions;
