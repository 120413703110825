import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Table, Label, Button, Modal, Form, Dropdown } from 'semantic-ui-react';

import { Vaccination } from '@bluefox/models/Vaccination';

import {
  SetVaccinationInventoryMutation,
  HistoricInconsistentVaccinationsQuery,
  InconsistentVaccinationErrorEnumQuery,
} from '@graphql/vaccinations';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import GS1 from '@bluefox/lib/gs1';
import { useCallback, useState } from 'react';
import InventoryPicker from '@bluefox/ui/InventoryPicker';
import { Inventory } from '@bluefox/models/Inventory';
import { DateFormats } from '@bluefox/models/Dates';
import { humanizeText } from '@bluefox/lib/humanize';

interface InconsistentVaccinationsData {
  vaccinations: Vaccination[];
}

interface InconsistentVaccinationsProps {
  practiceId?: string;
}

interface InconsistentVaccinationError {
  value: string;
  comment: string;
}

interface InconsistentVaccinationErrorOption {
  value: string;
  text: string;
}

interface InconsistentVaccinationErrorEnum {
  inconsistentVaccinationErrorsEnum: InconsistentVaccinationError[];
}

function getScannedData(barcode: string) {
  try {
    const gs1 = new GS1(barcode);
    return {
      lot: gs1.getLot(),
      exp: gs1.getExp(),
      ndc: gs1.getNdc(),
    };
  } catch {
    return null;
  }
}

const InconsistentVaccinations = (props: InconsistentVaccinationsProps) => {
  const { data, refetch } = useQuery<InconsistentVaccinationsData>(
    HistoricInconsistentVaccinationsQuery,
    {
      variables: {
        practiceId: props.practiceId,
      },
    }
  );

  const { data: errorsEnum } = useQuery<InconsistentVaccinationErrorEnum>(
    InconsistentVaccinationErrorEnumQuery
  );

  const [selectedVaccination, setSelectedVaccination] = useState<Vaccination>();
  const [errorsEnumOptions, setErrorsEnumOptions] = useState<
    InconsistentVaccinationErrorOption[]
  >([]);

  const handleOnSave = () => {
    refetch();
    setSelectedVaccination(undefined);
  };

  useEffect(() => {
    if (!errorsEnum) return;

    setErrorsEnumOptions(
      errorsEnum?.inconsistentVaccinationErrorsEnum?.map((e) => {
        return {
          text: e.comment,
          value: e.value,
        };
      })
    );
  }, [errorsEnum]);

  return (
    <>
      <Table style={{ height: '100%', overflowY: 'auto' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Patient</Table.HeaderCell>

            <Table.HeaderCell>Vaccine</Table.HeaderCell>
            <Table.HeaderCell>Scanned Data</Table.HeaderCell>
            <Table.HeaderCell>Given At</Table.HeaderCell>

            <Table.HeaderCell>Error</Table.HeaderCell>

            <Table.HeaderCell>Notes</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data?.vaccinations.map((v) => {
            const scannedData = v.barcode ? getScannedData(v.barcode) : null;

            return (
              <Table.Row key={v.id}>
                <Table.Cell>
                  {v.practicePatient?.patientData.lastName},{' '}
                  {v.practicePatient?.patientData.firstName} / DOB:{' '}
                  {v.practicePatient?.patientData.birthdate}
                </Table.Cell>

                <Table.Cell>{v.vaccine?.name} </Table.Cell>
                <Table.Cell>
                  {scannedData ? (
                    <Label>
                      Lot: {scannedData.lot}
                      <Label.Detail>
                        Exp:{' '}
                        <Moment format="MM-DD-YYYY">{scannedData.exp}</Moment>
                      </Label.Detail>
                    </Label>
                  ) : (
                    v.barcode
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Moment format="MM-DD-YYYY hh:mm a">{v.givenAt}</Moment>
                </Table.Cell>

                <Table.Cell>
                  {!!v.inconsistentVaccinationError &&
                    humanizeText(v.inconsistentVaccinationError, {
                      capitalize: 'all',
                      delimiter: '_',
                    })}
                </Table.Cell>

                <Table.Cell>{v.notes?.inconsistency}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {!!selectedVaccination && (
        <SetInventoryModal
          vaccination={selectedVaccination}
          onSave={handleOnSave}
          onClose={() => setSelectedVaccination(undefined)}
          errorsEnumOptions={errorsEnumOptions}
        />
      )}
    </>
  );
};

//--------------------------------SetInventoryModal--------------------------------//

interface SetInventoryModalProps {
  vaccination: Vaccination;
  onSave?: () => void;
  onClose?: () => void;
  errorsEnumOptions: InconsistentVaccinationErrorOption[];
}

const SetInventoryModal = ({
  vaccination,
  onSave,
  onClose,
  errorsEnumOptions,
}: SetInventoryModalProps) => {
  const [setVaccinationInventory, { loading }] = useMutation(
    SetVaccinationInventoryMutation
  );

  const [inventory, setInventory] = useState<Inventory>();
  const [
    inconsistentVaccinationErrorValue,
    setInconsistentVaccinationErrorValue,
  ] = useState<string>();
  const [notesValue, setNotesValue] = useState<string>();

  const handleSave = useCallback(() => {
    if (!inventory) return;

    setVaccinationInventory({
      variables: {
        vaccinationId: vaccination.id,
        inventoryId: inventory.id,
        vaccineId: inventory.vaccineId,
        inconsistentVaccinationError: inconsistentVaccinationErrorValue,
        ...(notesValue
          ? {
              notes: {
                ...vaccination.notes,
                inconsistency: notesValue,
              },
            }
          : {}),
      },
    }).then(() => {
      if (onSave) onSave();
    });
  }, [
    inventory,
    vaccination,
    setVaccinationInventory,
    onSave,
    inconsistentVaccinationErrorValue,
    notesValue,
  ]);

  const scannedData = vaccination.barcode
    ? getScannedData(vaccination.barcode)
    : null;

  return (
    <>
      <Modal size="small" open={true} onClose={onClose} closeOnEscape={false}>
        <Modal.Header>Set Inventory</Modal.Header>
        <Modal.Content>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan={2}>
                  Vaccination Information
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="right" active>
                  Practice
                </Table.Cell>
                <Table.Cell>{vaccination.practice?.name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="right" active>
                  Patient
                </Table.Cell>
                <Table.Cell>
                  {vaccination.practicePatient?.patientData.lastName},{' '}
                  {vaccination.practicePatient?.patientData.firstName} / DOB:{' '}
                  {vaccination.practicePatient?.patientData.birthdate}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="right" active>
                  Vaccine
                </Table.Cell>
                <Table.Cell>{vaccination.vaccine?.name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="right" active>
                  Given at
                </Table.Cell>
                <Table.Cell>
                  <Moment format="MM-DD-YYYY hh:mm a">
                    {vaccination.givenAt}
                  </Moment>
                </Table.Cell>
              </Table.Row>
              {!!scannedData ? (
                <>
                  <Table.Row>
                    <Table.Cell textAlign="right" active>
                      Lot
                    </Table.Cell>
                    <Table.Cell>{scannedData.lot}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" active>
                      Expiration
                    </Table.Cell>
                    <Table.Cell>
                      {moment(scannedData.exp).format(DateFormats.DATE)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" active>
                      NDC
                    </Table.Cell>
                    <Table.Cell>{scannedData.ndc}</Table.Cell>
                  </Table.Row>
                </>
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={2} warning>
                    Wasn't able to get the scanned information (
                    {vaccination.barcode})
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          {!!vaccination?.practice && (
            <Form>
              <Form.Field required>
                <label>Pick an inventory entry</label>
                <InventoryPicker
                  practiceId={vaccination?.practice?.id}
                  onSelect={(i) => {
                    setInventory(i);
                  }}
                />
              </Form.Field>
              <Form.Field required>
                <label>Select why this was an inconsistent vaccination</label>
                <Dropdown
                  labeled
                  fluid
                  selection
                  options={[
                    {
                      text: 'Select one error option',
                      value: '',
                    },
                    ...errorsEnumOptions,
                  ]}
                  value={inconsistentVaccinationErrorValue}
                  onChange={(e, data) => {
                    setInconsistentVaccinationErrorValue(
                      data.value?.toString() || ''
                    );
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Notes</label>
                <Form.TextArea
                  placeholder="There are no notes for this inconsistency. You can add one. Detail 'Other' error here."
                  value={notesValue}
                  onChange={(_, { value }) => {
                    setNotesValue(value as string);
                  }}
                />
              </Form.Field>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Save"
            icon="save"
            onClick={handleSave}
            disabled={
              !inventory || loading || !inconsistentVaccinationErrorValue
            }
            loading={loading}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default InconsistentVaccinations;
