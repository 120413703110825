import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Dropdown, Header, Label } from 'semantic-ui-react';

import {
  UsersData,
  UserTitles,
  User,
  withoutCounselingValue,
} from '@bluefox/models/Users';
import { UsersQuery } from '@bluefox/graphql/users';
import { usePractice } from '@bluefox/contexts';

interface ProviderPickerProps {
  defaultValue?: string;
  onChange?: (providerId?: string) => void;
  suspended?: boolean;
  placeholder?: string;
  prescriber?: boolean;
  isEmbedded?: boolean;
  practiceId?: string;
  onProviderSelected?: (provider: User) => void;
  isClereable?: boolean;
  disabled?: boolean;
  fluid?: boolean;
  withoutCounseling?: boolean;
  showFirstPrescriber?: boolean;
  title?: UserTitles;
}

interface DropDownOption {
  key: string;
  value?: string;
  text: string;
}

const ProviderPicker = ({
  defaultValue,
  onChange = () => {},
  suspended,
  placeholder,
  prescriber,
  isEmbedded,
  practiceId,
  isClereable = true,
  disabled,
  fluid,
  onProviderSelected = () => {},
  withoutCounseling,
  showFirstPrescriber,
  title,
}: ProviderPickerProps) => {
  const practice = usePractice();

  const [providers, setProviders] = useState<DropDownOption[]>();
  const [prescribers, setPrescribers] = useState<DropDownOption[]>();

  const { data, loading } = useQuery<UsersData>(UsersQuery, {
    variables: {
      criteria: {
        practiceId: { _eq: practiceId ? practiceId : practice.id },
        suspended: {
          _eq: false,
        },
        ...(suspended !== undefined ? { suspended } : {}),
      },
    },
  });

  const [selected, setSelected] = useState<string | undefined>(
    !defaultValue && withoutCounseling && title !== UserTitles.Other
      ? withoutCounselingValue
      : defaultValue
  );

  useEffect(() => onChange(selected), [selected, onChange]);

  useEffect(() => {
    if (!data) return;

    const selectedProvider = data.practice_accounts.find((provider) => {
      return provider.id === selected;
    });

    if (selectedProvider) onProviderSelected(selectedProvider);
  }, [data, selected]);

  const prescriberTitles = (title: UserTitles): boolean => {
    return [
      UserTitles.DO,
      UserTitles.MD,
      UserTitles.PA,
      UserTitles.NP,
    ].includes(title);
  };

  const providerTitles = (title: UserTitles): boolean => {
    return [
      UserTitles.DO,
      UserTitles.MD,
      UserTitles.NP,
      UserTitles.Nurse,
      UserTitles.PA,
      UserTitles.MA,
    ].includes(title);
  };

  useEffect(() => {
    if (!data) return;

    const _prescribers = data.practice_accounts
      .filter((u: User) => {
        if (u.profile?.permissionAs) {
          return !!u.profile?.permissionAs?.prescriber;
        }
        return prescriberTitles(u.title as UserTitles);
      })
      .map((p: User) => {
        const text =
          p.title === UserTitles.Nurse
            ? `${p.title} ${p.account.firstName} ${p.account.lastName}`
            : `${p.account.firstName} ${p.account.lastName} ${p.title}`;
        return {
          key: p.id,
          value: p.id,
          text,
          content: (
            <Header size="tiny">
              <Header.Content>
                {text}
                <Header.Subheader>
                  NPI: {p.account.npi}
                  {p.suspended && (
                    <Label size="tiny" color="orange">
                      Suspended
                    </Label>
                  )}
                </Header.Subheader>
              </Header.Content>
            </Header>
          ),
        };
      });

    const prescriberOptions = withoutCounseling
      ? [
          ..._prescribers,
          {
            key: withoutCounselingValue,
            text: 'Without Counseling',
            value: withoutCounselingValue,
            content: <Header size="tiny">Without Counseling</Header>,
          },
        ]
      : _prescribers;

    if (
      withoutCounseling &&
      !defaultValue &&
      showFirstPrescriber &&
      title !== UserTitles.Other
    ) {
      setSelected(_prescribers[0].value);
    }

    setPrescribers(prescriberOptions);

    const _providers = data.practice_accounts
      .filter((u: User) => {
        if (u.profile?.permissionAs) {
          return !!u.profile?.permissionAs?.provider;
        }
        return providerTitles(u.title as UserTitles);
      })
      .map((p: User) => {
        const text =
          p.title === UserTitles.Nurse
            ? `${p.title} ${p.account.firstName} ${p.account.lastName}`
            : `${p.account.firstName} ${p.account.lastName} ${p.title}`;

        return {
          key: p.id,
          value: p.id,
          text,
          content: (
            <Header size="tiny">
              <Header.Content>
                {text}
                <Header.Subheader>
                  NPI: {p.account.npi}
                  {p.suspended && (
                    <Label size="tiny" color="orange">
                      Suspended
                    </Label>
                  )}
                </Header.Subheader>
              </Header.Content>
            </Header>
          ),
        };
      });

    setProviders(_providers);
  }, [data]);

  return (
    <>
      {data && (prescribers || providers) && (
        <Dropdown
          search
          fluid={fluid}
          style={{
            minWidth: isEmbedded ? '0' : null,
            maxWidth: isEmbedded ? '13rem' : null,
          }}
          selection
          loading={loading}
          disabled={
            loading ||
            !data?.practice_accounts ||
            !data?.practice_accounts.length ||
            disabled
          }
          onChange={(_, { value }) => setSelected(value as string)}
          value={selected}
          placeholder={
            !data?.practice_accounts ? 'No available Providers' : placeholder
          }
          selectOnBlur={false}
          clearable={isClereable}
          options={prescriber ? prescribers : providers}
        />
      )}
    </>
  );
};

export default ProviderPicker;
