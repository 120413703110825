import { useState, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import {
  Container,
  Table,
  Header,
  Loader,
  Message,
  Segment,
  Dropdown,
  Menu,
  List,
  Icon,
  Modal,
  Button,
} from 'semantic-ui-react';
import Pagination from '@bluefox/ui/Pagination';
import MainLayout from '@ui/MainLayout';
import { GetTickets, UpdateTicketStatus } from '@bluefox/graphql/tickets';
import {
  TicketDetailFieldType,
  TicketTypes,
  Ticket,
  ticketStatusOptions,
  TicketStatusEnum,
  ticketTypeOptions,
  TicketsResponse,
} from '@bluefox/models/Tickets';
import { humanizeText } from '@bluefox/lib/humanize';
import { formatDateToMMDDYYYY } from '@bluefox/lib/formatters';
import PracticeFilter from '@ui/Filters/PracticeFilter';
import TicketFilesTable from './TicketFilesTable';
import { Source } from '@bluefox/ui/Chat/types';
import TicketChat from '@bluefox/ui/Chat/TicketChat';
import TableHeaderRow from '@bluefox/ui/Tickets/TableHeaderRow';
import TableBodyRow from '@bluefox/ui/Tickets/TableBodyRow';
import { TicketClosedNote } from '@bluefox/ui/Tickets/TicketClosedNote';
import { useApplicationState } from '@bluefox/contexts';

interface UpdateTicketStatusType {
  ticketId: string;
  optionStatus: string;
}

const ENTRIES_PER_PAGE = 15;

const statusChangeDisabled = (ticket: Ticket) =>
  ticket.type === TicketTypes.PACKING_SLIP &&
  ticket.status === TicketStatusEnum.CLOSED;

const TicketsScreen = () => {
  window.scrollTo(0, 0);
  const { isEmbedded } = useApplicationState();
  const [searchPractice, setSearchPractice] = useState<string>();
  const [status, setStatus] = useState<string[]>([
    TicketStatusEnum.OPEN,
    TicketStatusEnum.IN_PROGRESS,
  ]);
  const [type, setType] = useState<string[]>([TicketTypes.BUG_REPORT]);
  const [page, setPage] = useState(1);

  const { data, loading, error, refetch } = useQuery<TicketsResponse>(
    GetTickets(false),
    {
      variables: {
        criteria: {
          detail:
            searchPractice !== 'all'
              ? { _contains: { fields: [{ detail: searchPractice }] } }
              : {},
          status: { _in: status },
          type: { _in: type },
        },
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
      fetchPolicy: 'network-only',
    }
  );
  const [saveTicket] = useMutation(UpdateTicketStatus);

  const handleUpdateStatus = ({
    ticketId,
    optionStatus,
  }: UpdateTicketStatusType) => {
    saveTicket({
      variables: {
        id: ticketId,
        status: optionStatus,
      },
      refetchQueries: [
        {
          query: GetTickets(false),
        },
      ],
    }).then(() => refetch());
  };

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const vaccineOrderStatusOptions = useMemo(
    () => [
      {
        key: TicketStatusEnum.REQUESTED,
        text: 'Requested',
        value: TicketStatusEnum.REQUESTED,
      },
      {
        key: TicketStatusEnum.CLOSED,
        text: 'Closed',
        value: TicketStatusEnum.CLOSED,
      },
    ],
    []
  );

  const showChat = [
    TicketTypes.BUG_REPORT,
    TicketTypes.FEATURE_REQUEST,
    TicketTypes.INVENTORY_RECEIPT_ISSUES,
    TicketTypes.WASTED_DOSES_REPORT,
    TicketTypes.CALL_REQUEST,
  ];

  const handleStatusValue = useCallback((value: [string]) => {
    const statusValue = !value?.length
      ? [TicketStatusEnum.OPEN, TicketStatusEnum.IN_PROGRESS]
      : value;
    setStatus(statusValue);
  }, []);

  const handleTypeValue = useCallback((value: [string]) => {
    const typeValue = !value?.length ? [TicketTypes.BUG_REPORT] : value;
    setType(typeValue);
  }, []);

  return (
    <MainLayout path={[{ text: 'Tickets' }]} loading={loading}>
      <Container fluid data-automation-id="it-tickets">
        <Segment
          basic
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Header as="h1">
            <Icon name="ticket" size="huge" />
            Ticket Reports
          </Header>
          <Menu fluid borderless>
            <Menu.Item>
              <PracticeFilter
                practiceSearch={searchPractice}
                setPracticeSearch={setSearchPractice}
                filterByHandler
                suspended="notSuspended"
              />
            </Menu.Item>
            <Menu.Menu
              position="right"
              style={{ display: 'flex', flexWrap: 'wrap' }}
            >
              <Menu.Item>
                <label>Status: </label>
                <Dropdown
                  labeled
                  onChange={(_, { value }) => {
                    if (!value) return;
                    handleStatusValue(value as [string]);
                  }}
                  placeholder="Select Status"
                  selection
                  multiple
                  value={status}
                  options={ticketStatusOptions}
                />
              </Menu.Item>
              <Menu.Item>
                <label>Ticket Type: </label>
                <Dropdown
                  labeled
                  onChange={(_, { value }) => {
                    if (!value) return;
                    handleTypeValue(value as [string]);
                  }}
                  placeholder="Select Ticket Type"
                  multiple
                  selection
                  value={type}
                  options={ticketTypeOptions}
                />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Segment>
        {loading && <Loader />}
        {error && <Message error>{error.message}</Message>}
        <Table fluid>
          <Table.Header>
            <Table.Row key="tableHeaders">
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell>Ticket Type</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Detail</Table.HeaderCell>
              <Table.HeaderCell>Attached files</Table.HeaderCell>
              <Table.HeaderCell>Full details</Table.HeaderCell>
              {showChat.some((_type) => type.includes(_type)) && (
                <Table.HeaderCell>Action</Table.HeaderCell>
              )}
              {status.includes(TicketStatusEnum.CLOSED) &&
                [TicketTypes.BUG_REPORT, TicketTypes.FEATURE_REQUEST].some(
                  (_type) => type.includes(_type)
                ) && <Table.HeaderCell>Resolution</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!!data?.tickets.length ? (
              data?.tickets.map(({ ticket }: { ticket: Ticket }) => {
                const ticketCreator = ticket.practiceAccount
                  ? `${ticket.practiceAccount?.account.lastName}, ${ticket.practiceAccount?.account.firstName} (${ticket.practiceAccount?.account.email})`
                  : '-';
                const showChatButton = showChat.includes(ticket.type);
                return (
                  <>
                    <Table.Row key={ticket.id}>
                      <Table.Cell>
                        {formatDateToMMDDYYYY(ticket.createdAt || '')}
                      </Table.Cell>
                      <Table.Cell>
                        {humanizeText(ticket.type, { delimiter: '_' })}
                      </Table.Cell>
                      <Table.Cell>
                        <Dropdown
                          onChange={(_, { value }) => {
                            if (!value) return;
                            handleUpdateStatus({
                              ticketId: ticket.id || '',
                              optionStatus: value.toString(),
                            });
                          }}
                          selection
                          value={ticket.status}
                          options={
                            [
                              TicketTypes.VACCINE_ORDER,
                              TicketTypes.PACKING_SLIP,
                            ].includes(ticket.type)
                              ? vaccineOrderStatusOptions
                              : ticketStatusOptions
                          }
                          disabled={statusChangeDisabled(ticket)}
                        />
                      </Table.Cell>
                      <Table.Cell width={8}>
                        <List relaxed>
                          {ticket.detail.fields?.map(
                            (ticketFields: TicketDetailFieldType) => (
                              <List.Item key={ticketFields.title}>
                                <List.Content>
                                  <StyledListHeader>
                                    {ticketFields.title}
                                  </StyledListHeader>
                                  <StyledListDescription>
                                    {ticketFields.detail}
                                  </StyledListDescription>
                                </List.Content>
                              </List.Item>
                            )
                          )}
                        </List>
                      </Table.Cell>
                      <Table.Cell width={4}>
                        <List relaxed>{ticket.ticketsFiles?.length}</List>
                      </Table.Cell>
                      <Table.Cell>
                        <Modal
                          closeIcon
                          trigger={
                            <Button color="teal">
                              <Icon style={{ margin: '0' }} name="eye" />
                            </Button>
                          }
                        >
                          <Modal.Content>
                            <Table definition>
                              <Table.Header>
                                <Table.Row key={'descriptionHeader'}>
                                  <Table.HeaderCell />
                                  <Table.HeaderCell>
                                    Description
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {ticket.detail.fields?.map(
                                  (ticketFields: any) => (
                                    <Table.Row key={'ticketFieldsRow'}>
                                      <Table.Cell>
                                        {ticketFields.title}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {ticketFields.detail}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                                <Table.Row key={'ticketCreatorRow'}>
                                  <Table.Cell>Ticket Creator</Table.Cell>
                                  <Table.Cell>{ticketCreator}</Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                            {!!ticket.ticketsFiles?.length && (
                              <TicketFilesTable
                                ticketFiles={ticket.ticketsFiles}
                              />
                            )}
                          </Modal.Content>
                          <Modal.Actions>
                            <Dropdown
                              onChange={(_, { value }) => {
                                if (!value) return;
                                handleUpdateStatus({
                                  ticketId: ticket.id || '',
                                  optionStatus: value.toString(),
                                });
                              }}
                              selection
                              defaultValue={ticket.status}
                              options={
                                [
                                  TicketTypes.VACCINE_ORDER,
                                  TicketTypes.PACKING_SLIP,
                                ].includes(ticket.type)
                                  ? vaccineOrderStatusOptions
                                  : ticketStatusOptions
                              }
                              disabled={statusChangeDisabled(ticket)}
                            />
                          </Modal.Actions>
                        </Modal>
                      </Table.Cell>
                      {showChatButton && (
                        <Table.Cell>
                          <TicketChat
                            ticket={ticket}
                            componentSource={Source.IT}
                            chatHeader={
                              <Table fluid>
                                <Table.Header>
                                  <TableHeaderRow
                                    ticketType={ticket.type}
                                    includeChat={false}
                                    isEmbedded={isEmbedded}
                                  />
                                </Table.Header>
                                <Table.Body>
                                  <TableBodyRow
                                    ticketType={ticket.type}
                                    ticket={ticket}
                                    source={Source.IT}
                                    isEmbedded={isEmbedded}
                                    status={status}
                                    includeChat={false}
                                  />
                                </Table.Body>
                              </Table>
                            }
                          />
                          {ticket.detail.showNewMessageTo === Source.IT && (
                            <p style={{ margin: '0.25rem 0' }}>
                              <Icon name="flag" color="red" /> New comment
                            </p>
                          )}
                        </Table.Cell>
                      )}
                      {ticket.status === TicketStatusEnum.CLOSED &&
                        [
                          TicketTypes.BUG_REPORT,
                          TicketTypes.FEATURE_REQUEST,
                        ].includes(ticket.type) && (
                          <Table.Cell>
                            <TicketClosedNote
                              note={ticket.detail.closeTicketResolution}
                              ticketId={ticket.id}
                            />
                          </Table.Cell>
                        )}
                    </Table.Row>
                  </>
                );
              })
            ) : (
              <Table.Row key={'noTicketsFoundRow'}>
                <Table.Cell colSpan={10}>
                  <Message>No Tickets Found</Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>

          <Pagination
            total={total}
            colSpan={10}
            position="right"
            activePage={page}
            totalPages={totalPages}
            onPageChange={(newActivePage) => setPage(newActivePage)}
          />
        </Table>
      </Container>
    </MainLayout>
  );
};

export default TicketsScreen;

const ellipsisStyle = css`
  width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledListHeader = styled(List.Header)`
  ${ellipsisStyle}
`;
const StyledListDescription = styled(List.Description)`
  ${ellipsisStyle}
`;
